import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

import { ShoppingCartStorageService } from './shop/core/services/shopping-cart-storage.service';

@Component({
  selector: 'diy-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  thankYouPage = false;
  leadCapture = false;

  constructor(
    private _cartBaseService: ShoppingCartStorageService,
    private _location: Location,
  ) {
    this.thankYouPage = this._location.isCurrentPathEqualTo('/thank-you');
    this.leadCapture = this._location.path().endsWith('/welcome');
  }

  onActivate(event) {
    window.scroll(0, 0);
  }

  async ngOnInit() {
    await this._cartBaseService.retrieveCartData();
  }
}
