/**
 * Created by petre on 28.08.2018.
 */
import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  /* tslint:disable */
  selector: '[formControlName][phoneMask]'
  /* tslint:enable */
})
export class PhoneMaskDirective {

  constructor(
    private _ngControl: NgControl
  ) { }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event) {
    this.onInputChange(event);
  }

  // @HostListener('keydown.backspace', ['$event'])
  // keydownBackspace(event) {
  //   this.onInputChange(event.target.value, true);
  // }

  onInputChange(event) {
    const newVal = event.replace(/\D/g, '');
    // if (backspace && newVal.length <= 6) {
    //   newVal = newVal.substring(0, newVal.length - 1);
    // }
    // if (newVal.length === 0) {
    //   newVal = '';
    // } else if (newVal.length <= 3) {
    //   newVal = newVal.replace(/^(\d{0,3})/, '($1)');
    // } else if (newVal.length <= 6) {
    //   newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
    // } else {
    //   newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
    // }
    this._ngControl.valueAccessor.writeValue(newVal);
  }
}
