import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  HostListener,
  ElementRef,
  ViewChild,
} from '@angular/core';

import { COUNTRIES } from './countries';
import { ICountryCode } from '../../types/phone-selector/ICountryCode';

@Component({
  selector: 'diy-phone-selector',
  templateUrl: './phone-selector.component.html',
  styleUrls: ['./phone-selector.component.scss']
})
export class PhoneSelectorComponent implements OnInit {

  isOpen = false;

  countries = COUNTRIES;
  selectedCountry: ICountryCode;
  activeItemIndex = -1;

  @ViewChild('select', { static: true }) select: ElementRef;

  @Output() countrySelected: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private _elementRef: ElementRef,
  ) { }

  ngOnInit() {
    this.selectValue(this.countries[0]);
  }

  toggleOptions() {
    this.isOpen = !this.isOpen;
    this.activeItemIndex = -1;
    this.select.nativeElement.scrollTop = 0;
  }

  selectValue(value: ICountryCode) {
    this.selectedCountry = value;
    this.countrySelected.emit(value.prefix);
    this.isOpen = false;
  }

  // Close selector when clicked outside
  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    if (!this._elementRef.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }

  @HostListener('document:keydown', ['$event'])
  keyHandler(event: any) {
    if (!this.isOpen) {
      return;
    }

    let keyPressed = event.key;
    if (!keyPressed) {
      const charCode = event.keyCode || event.key;
      if (!event.shiftKey && (charCode >= 65 && charCode <= 90)) {
        keyPressed = String.fromCharCode(charCode + 32);
      } else {
        keyPressed = String.fromCharCode(event.keyCode || event.key);
      }
    }

    this.activeItemIndex = this.countries.findIndex(country => country.name[0].toLowerCase() === keyPressed.toLowerCase());
    setTimeout(() => {
      const item = this._elementRef.nativeElement.querySelector('li.focus');
      this.select.nativeElement.scrollTop = item.offsetTop;
    }, 50);
  }

}
