import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { ApplyDiscountVoucher, RemoveDiscountVoucher } from '../../../../state-manager/shopping-cart/shopping-cart.actions';

import { InvoiceApi } from '../../../core/api/invoiceApi';

import { SessionService } from '../../../../utility/session.service';
import { CookieService } from '../../../../core/services/cookie.service';

import { IAppState } from '../../../../types/app-state/IAppState';
import { IShoppingCart } from '../../../../types/shopping-cart/IShoppingCart';
import { ICartItem } from '../../../../types/shopping-cart/ICartItem';
import { IVoucher } from '../../../../types/voucher/IVoucher';
import { IVoucherResponse } from '../../../../types/voucher/IVoucherResponse';
import { ICustomerInfo } from '../../../../types/quote/IQuote';

@Component({
  selector: 'diy-promo-code',
  templateUrl: './promo-code.component.html',
  styleUrls: ['./promo-code.component.scss']
})
export class PromoCodeComponent implements OnInit, OnDestroy {

  voucherForm: FormGroup;

  promoCode: string;
  errorMessage: string;
  showSuccess = false;
  isLoading = false;
  alreadyApplied = false;

  private _subscriptions$: Subscription[] = [];
  private _cartItems: ICartItem[];
  private _orderTotal: number;
  private _customerEmail: string;

  constructor(
    private _store: Store<IAppState>,
    private _formBuilder: FormBuilder,
    private _invoiceApi: InvoiceApi,
    private _sessionService: SessionService,
    private _cookieService: CookieService,
  ) {
    this.voucherForm = _formBuilder.group({
      promoCode: ['', Validators.compose([
        Validators.required,
        Validators.minLength(2)
      ])]
    });
  }

  ngOnInit() {
    const subscription$ = this._store.pipe(select('shoppingCart'))
      .subscribe((data: IShoppingCart) => {
        this._cartItems = data.cartItems;
        this._orderTotal = data.orderTotal;
        this._checkCart();
      });

    this._subscriptions$.push(subscription$);

    if (this._sessionService.isSalesLoggedIn()) {
      const customerInfo: ICustomerInfo = this._cookieService.getJson('customerInfo');
      this._customerEmail = customerInfo.email;
    } else if (this._sessionService.isLoggedIn()) {
      this._customerEmail = this._sessionService.user.email;
    } else { // fallback
      const customerInfo: ICustomerInfo = this._cookieService.getJson('customerInfo');
      this._customerEmail = customerInfo.email;
    }
  }

  ngOnDestroy() {
    this._subscriptions$.forEach(subscription$ => subscription$.unsubscribe());
  }

  async applyVoucher() {
    this.isLoading = true;
    this.errorMessage = undefined;
    this.voucherForm.disable();

    const promoCode = this.voucherForm.value.promoCode;
    this.promoCode = promoCode;
    const productIds = this._getProductIds();
    const res: IVoucherResponse = await this._invoiceApi.checkPromoCode(promoCode, productIds, this._orderTotal, this._customerEmail);

    if (res && res.success) {
      this.isLoading = false;

      if (res.valid && res.products && res.products.length) {
        this.showSuccess = true;
        const voucher: IVoucher = {
          code: promoCode,
          discount: res.discount,
          type: res.type
        };
        this._store.dispatch(new ApplyDiscountVoucher({ voucher: voucher, productIds: res.products }));
      } else {
        this.showSuccess = false;
        this.voucherForm.enable();
        return this.errorMessage = res.reason ? res.reason : 'Promo code is invalid. Please try again.';
      }

    } else {
      this.showSuccess = false;
      this.errorMessage = 'Could not set the promo code. Please try again.';
    }
  }

  removeVoucher() {
    this.showSuccess = false;
    this.errorMessage = undefined;
    this.voucherForm.enable();
    this._store.dispatch(new RemoveDiscountVoucher());
  }

  /**
   * Check if there is a voucher applied to the items in cart
   */
  private _checkCart() {
    this._cartItems.forEach(cartItem => {
      cartItem.cartItemOrders.forEach(cartItemOrder => {
        if (cartItemOrder.orderItem.price.voucher) {
          const promoCode = cartItemOrder.orderItem.price.voucher.code;
          this.promoCode = promoCode;
          this.voucherForm.disable();
          this.voucherForm.controls.promoCode.patchValue(promoCode);
          this.alreadyApplied = true;
          this.showSuccess = true;
        }
      });
    });
  }

  private _getProductIds(): number[] {
    const productIds: number[] = [];
    if (this._cartItems && this._cartItems.length) {
      this._cartItems.forEach(cartItem => {
        cartItem.cartItemOrders.forEach(cartItemOrder => {
          productIds.push(Number(cartItemOrder.orderItem.product.id));
        });
      });
    }
    return productIds;
  }

}
