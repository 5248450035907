import { Component, Input, OnInit, HostBinding } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// Services
import { AmplitudeService } from '../../../core/services/amplitude';
import { CampaignTrackingService } from '../../../core/services/campaign-tracking.service';
import { TrackingService } from '../../../core/services/tracking';

// State management
import { UpdateCategory } from '../../../state-manager/order-item/order-item.actions';

// Types
import { ICategory } from '../../../types/category/ICategory';
import { IProduct } from '../../../types/product/IProduct';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../types/app-state/IAppState';

@Component({
  selector: 'diy-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  @HostBinding('class.product-host') productHost = false;

  @Input() category: ICategory;
  @Input() showDiscount = false;

  product: IProduct;

  maxChars = 90;
  loading = false;
  buttonName = 'Select';
  recommended = false;

  mainImage = 'assets/img/home/new-featured/thumbnail.svg';

  @Input('product') set theProduct(product: IProduct) {
    this.product = product;
    const mainImage = this.product.images.find(image => image.main);
    if (mainImage) {
      this.mainImage = mainImage.medium;
    } else if (this.product.images[0] && this.product.images[0].medium) {
      this.mainImage = this.product.images[0].medium;
    }
  }


  constructor(
    private _router: Router,
    private _amp: AmplitudeService,
    private _tracking: TrackingService,
    protected _store: Store<IAppState>,
    private _route: ActivatedRoute,
    private _campaignTrackingService: CampaignTrackingService,
  ) {
    this._campaignTrackingService.handleCampaignTracking(this._route.snapshot.queryParams);
  }

  ngOnInit() {
    this.productHost = true;

    if (this.product.name === 'Face Masks') {
      this.buttonName = 'Learn More';
    }

    if (this.product.name === 'Stickers' && !this.showDiscount) {
      this.recommended = true;
    }
  }

  selectProduct() {
    if ( typeof window.dataLayer.find(element => element.event === 'product-impression') !== 'undefined' ) {
      const clickedProduct = window.dataLayer.find(element => element.event === 'product-impression').ecommerce.impressions.find(
        product => product.id === this.product.id
      );
      window.dataLayer.push({ ecommerce: null }); // clear the ecommerce object
      window.dataLayer.push({
        'event': 'select_item',
        'ecommerce': {
          'item_list_id': this.category.id,
          'item_list_name': this.category.name, 
          'items': [
            {
              'item_name': this.product.name, // required
              'item_id': this.product.id, // required
              'price': this.product.pricingTable, // required
              'item_brand': 'The/Studio', // likely The/Studio
              'item_category': this.category.name, // product category
              'item_variant': 'None', 
              'index': clickedProduct.position, // position on the page, ensure each page tracks position the same way
              'quantity': 1,
              'item_list_id': this.category.id,
              'item_list_name': this.category.name, 
            }
          ]
          
        }
      });
    }

    this._amp.logEvent('Select Product', {
      category: this.category.name,
      category_id: this.category.id,
      productName: this.product.name,
      product_id: this.product.id
    });

    this._tracking.sendAltoCloudData(['record', 'product.selected', this.product.id, {
      id: this.product.id,
      name: this.product.name
    }]);

    this.loading = true;
    this._store.dispatch(new UpdateCategory(this.category));

    if (this.product.name === 'Face Masks') {
      return window.location.href = 'https://thestudio.com/custom-masks/'
    }

    this._router.navigate(['/category', this.category.id, 'product', this.product.id, 'draft'], { queryParamsHandling: 'merge' });
  }
}
