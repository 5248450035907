import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subject, zip, Subscription } from 'rxjs';

import { environment } from '../../../../environments/environment';

import { SessionService } from '../../../utility/session.service';
import { CookieService } from '../../../core/services/cookie.service';
import { TrackingService } from '../../../core/services/tracking';
import { CustomerDataProvider } from '../../core/api/CustomerDataProvider';
import { AmplitudeService } from '../../../core/services/amplitude';
import { OrderMessengerService } from '../../../core/services/order-messenger';
import { BundlePaymentApi } from '../../core/api/bundlePaymentApi';

import { SetBundleInvoiceId } from '../../../state-manager/bundle/bundle.actions';

import { ICustomerPaymentData } from '../../../types/api/ICustomerPaymentData';
import { IFormValidation, IPaymentError, IShippingData, IFormState, IBillingData, ICardData } from '../../../types/payment/IFormState';
import { ITransactionData } from '../../../types/api/ITransactionData';
import { UserRole } from '../../../types/user/IUser';
import { IPaymentSummary, IPaymentProduct } from '../../../types/payment/IPaymentSummary';
import { ICustomerInfo } from '../../../types/quote/IQuote';
import { IBundle } from '../../../types/bundle/IBundle';
import { IBundlePayment } from '../../../types/bundle/IBundlePayment';
import { IAppState } from '../../../types/app-state/IAppState';
import { TSModalComponent } from 'tscommon';

@Component({
  templateUrl: './bundle-payment.component.html',
  styleUrls: [
    '../payment/payment.component.scss',
    './bundle-payment.component.scss'
  ]
})
export class BundlePaymentComponent implements OnInit, OnDestroy {
  processing = false;

  customerData: ICustomerPaymentData;

  // trigger validation for forms
  formValidation$: Subject<IFormValidation> = new Subject();

  // handling errors for form components
  shippingError$: Subject<IPaymentError> = new Subject<IPaymentError>();
  billingError$: Subject<IPaymentError> = new Subject<IPaymentError>();
  cardError$: Subject<IPaymentError> = new Subject<IPaymentError>();

  // draft data
  shippingDraftData: IShippingData;
  billingDraftData: IShippingData;
  cardDraftData: IShippingData;

  summaryData: IPaymentSummary;

  @ViewChild('bundleLoginPrompt', { static: true }) bundleLoginPrompt: TSModalComponent;

  private _subscriptions$: Subscription[] = [];

  private _shippingData$ = new Subject<IFormState>();
  private _billingData$ = new Subject<IFormState>();
  private _paymentData$ = new Subject<IFormState>();

  private _customerInfo: ICustomerInfo;
  private _bundle: IBundle;

  constructor(
    private _session: SessionService,
    private _tracking: TrackingService,
    private _cookieService: CookieService,
    private _amp: AmplitudeService,
    private _oms: OrderMessengerService,
    private _customerApi: CustomerDataProvider,
    private _router: Router,
    private _store: Store<IAppState>,
    private _bundlePaymentApi: BundlePaymentApi,
  ) {
    this._oms.updateStep(7);

    const subscription$ = zip(
      this._shippingData$,
      this._billingData$,
      this._paymentData$
    ).subscribe((formsState: Array<IFormState>) => {
      const values = formsState.map(data => data.formData);
      const payment: ICardData = values[2] as ICardData;

      if (formsState[0].isValid && formsState[1].isValid && formsState[2].isValid) {
        return this._processOrder((values[0] as IShippingData), (values[1] as IBillingData), payment);
      }

      return this._handleErrors(!formsState[0].isValid, !formsState[1].isValid, !formsState[2].isValid);
    });

    this._subscriptions$.push(subscription$);

    this._loadDataFromDraft();

    if (environment.enableLeadCapture) {
      this._customerInfo = this._cookieService.getJson('customerInfo');
    }
  }

  async ngOnInit() {
    const subscription$ = this._store.pipe(select('bundleData'))
      .subscribe((data: IBundle) => {
        this._bundle = data;
        this.summaryData = this._getSummaryData(data);
      });

    this._subscriptions$.push(subscription$);

    if (this._bundle) {
      this._amp.logEvent('Navigation', {
        page: 'payment',
        navigationStep: 7,
        bundle_id: this._bundle.id
      });
    }

    this._tracking.sendAltoCloudData(['pageview', {
      location: location.pathname,
      title: 'Bundle Payment'
    }]);

    // get data for logged in customer
    if (this._session.isLoggedIn()) {
      this.customerData = await this._getCustomerData();
    }
  }

  ngOnDestroy() {
    this._subscriptions$.forEach(subscription$ => subscription$.unsubscribe());
  }

  showLoginModal() {
    this.bundleLoginPrompt.show();
  }

  async onAuthComplete() {
    this.bundleLoginPrompt.hide();
    this.customerData = await this._getCustomerData();
  }

  isLogged(): boolean {
    return this._session.isLoggedIn();
  }

  /**
   * Trigger validation
   */
  triggerValidation() {
    this.formValidation$.next({
      validate: true,
      isConfirmed: true
    });
  }

  /**
   * Emit new value for received shipping data
   */
  getShippingData(data: IFormState) {
    this._shippingData$.next(data);
  }

  /**
   * Emit new value for received billing data
   */
  getBillingData(data: IFormState) {
    this._billingData$.next(data);
  }

  /**
   * Emit new value for received card data
   */
  getPaymentData(data: IFormState) {
    this._paymentData$.next(data);
  }

  private async _processOrder(
    shippingData: IShippingData,
    billingData: IBillingData,
    paymentData: ICardData
  ): Promise<void> {
    this.processing = true;

    if (billingData.sameAsShipping) {
      billingData = this._patchBillingData(shippingData);
    }

    const email = this._customerInfo && this._customerInfo.email ? this._customerInfo.email :
      this._session.isLoggedIn() ? this._session.user.email :
        shippingData['email'] ? shippingData['email'] : '';

    const paymentDetails: IBundlePayment = {
      customer: {
        name: shippingData.name,
        email: email
      },
      address: {
        shipping: shippingData,
        billing: billingData
      },
      payment: paymentData,
      bundle: this._bundle.id
    };

    // get campaign tracking cookie and set it on order payload
    // delete cookie after setting it
    paymentDetails.campaignTracking = this._cookieService.get('campaignTracking');
    this._cookieService.delete('campaignTracking');

    // get optimizely tracking cookie and set it on order payload
    // delete cookie after setting it
    paymentDetails.optimizelyTracking = this._cookieService.get('optimizely_thestudio');
    this._cookieService.delete('optimizely_thestudio');

    const resp = await this._bundlePaymentApi.submitBundlePayment(paymentDetails);

    this._cookieService.delete('campaignTracking');
    this._reactOnOrder(resp);
  }

  private _reactOnOrder(data) {
    if (data) {
      this.processing = false;

      if (data.invoiceId) {
        // Add invoiceId to bundle
        this._store.dispatch(new SetBundleInvoiceId(data.invoiceId));
      }

      if (data.success) {
        if (data.transactionData) {
          this._sendTransactionData(data.transactionData);
        }

        this._amp.logEvent('Payment', {
          status: 'success',
          bundle_id: this._bundle.id
        });

        return this._router.navigate(['/shop/bundle-success']);

      } else {

        this._amp.logEvent('Payment', {
          status: 'failed',
          failed_reason: data.message,
          bundle_id: this._bundle.id
        });

        this.cardError$.next({
          hasError: true,
          message: data.message
        });

      }
    }
  }

  /**
   * Emit new errors
   */
  private _handleErrors(shippingError, billingError, cardError) {
    if (shippingError) {
      return this.shippingError$.next({
        hasError: true,
        message: 'Invalid shipping address'
      });
    }

    if (billingError) {
      return this.billingError$.next({
        hasError: true,
        message: 'Invalid billing address'
      });
    }

    if (cardError) {
      return this.cardError$.next({
        hasError: true,
        message: 'Invalid payment data'
      });
    }
  }

  /**
   * Load data from draft for shipping and billing data
   */
  private _loadDataFromDraft() {
    const paymentData = this._cookieService.getJson('paymentData');
    if (paymentData) {
      this.shippingDraftData = paymentData['shippingData'];
      this.billingDraftData = paymentData['billingData'];
    }
  }

  /**
   * Update billing data if same as shipping
   */
  private _patchBillingData(shippingData: IShippingData): IBillingData {
    return Object.assign({}, {
      address: shippingData.address,
      city: shippingData.city,
      country: shippingData.country,
      phone: shippingData.phone,
      state: shippingData.state,
      zip: shippingData.zip,
      name: shippingData.name,
      sameAsShipping: true
    });
  }

  /**
   * Send transaction data after payment has been made
   */
  private _sendTransactionData(transactionData: ITransactionData): void {
    // update data with constant values
    const data = Object.assign(transactionData, {
      transactionAffiliation: 'CREATE',
      transactionTax: 0.00
    });

    this._tracking.sendGoogleData(data);
  }

  /**
   * Try to get customer for logged in customer
   */
  private async _getCustomerData() {
    const user = this._session.user;

    let resp = null;
    if (user.role === UserRole.CUSTOMER) {
      resp = await this._customerApi.getCustomerData();

      if (resp) {
        return {
          shipping: resp.shippingData,
          billing: resp.billingData,
          card: resp.cardData
        };
      } else {
        this._session.resetSession();
        this.showLoginModal();
      }

    }
  }

  /**
   * Format bundle data to use on payment summary component
   */
  private _getSummaryData(bundle: IBundle): IPaymentSummary {
    const options = bundle.items.map(item => {
      return { name: `${item.productName} X ${item.quantity} QTY` };
    });
    const product: IPaymentProduct = {
      name: bundle.name,
      price: bundle.price,
      options: [{
        name: 'Bundle Contents:',
        price: bundle.price,
        selections: options
      }]
    };
    return {
      items: [{
        product: product,
        total: bundle.price
      }],
      total: bundle.price
    };
  }
}
