<!-- Header -->
<div class="header-content">
  <diy-header></diy-header>
  <diy-order-details></diy-order-details>
  <diy-promo-banner></diy-promo-banner>
  <!-- we have the ngIf as we are setting currentStep after resolving route -->
  <diy-order-step-tracking *ngIf="currentStep" [currentStep]="currentStep"></diy-order-step-tracking>
</div>

<!-- Page content -->
<div class="container home-container">
  <div class="homePage">
    <div class="inner-wrapper heading-wrapper">
      <div class="heading-primary">
        <h1>Step {{ currentStep }}: Select a Product</h1>
        <p>Select a product and begin customizing.</p>
      </div>
      <div class="search-primary">
        <diy-search></diy-search>
      </div>
    </div>

    <div class="inner-wrapper featured-wrapper">
      <div class="featured-container">
        <div class="featured-info">Create any custom design for fashion accessory and promotional products such as
          patches,
          pins, socks, and caps. We offer inexpensive options with no MOQ and fast turnaround. Start today!</div>
        <div class="featured-products">
          <h1>FEATURED PRODUCTS</h1>
          <div class="featured-carousel">
            <owl-carousel-o [options]="carouselOptions">
              <ng-template [width]="265" carouselSlide *ngFor="let product of featuredProducts">
                <diy-product [product]="product" [category]="getProductCategory(product)" [showDiscount]="true">
                </diy-product>
              </ng-template>
            </owl-carousel-o>
          </div>
        </div>
      </div>
    </div>

    <div class="inner-wrapper products-wrapper" *ngFor="let category of categories">
      <div class="products-container">
        <h1>{{category.name}} <a (click)="goToCategory(category.id, category.name)">See All ></a></h1>
        <div class="products-carousel">
          <owl-carousel-o [options]="carouselOptions">
            <ng-template [width]="265" carouselSlide *ngFor="let product of category.products">
              <diy-product [product]="product" [category]="category"></diy-product>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>

  </div>
</div>
