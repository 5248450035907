import {
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild
} from '@angular/core';

import { IImage } from '../../types/images/IImage';

@Component({
  selector: 'diy-zoomable',
  templateUrl: './zoomable.component.html',
  styleUrls: ['./zoomable.component.scss']
})
export class ZoomableComponent {

  @ViewChild('zoomable') zoomable: ElementRef;
  @Input() image: IImage;

  /**
   * Listener for mouseover event
   */
  @HostListener('mouseover', ['$event'])
  zoomIn($event) {
    $event.preventDefault();
    $event.stopPropagation();
    if (this.image && ($event.target === this.zoomable.nativeElement)) {
      this.zoomable.nativeElement.style.transform = 'scale(2)';
    }
  }


  /**
   * Listener for mouseout event
   */
  @HostListener('mouseout', ['$event'])
  zoomOut($event) {
    $event.preventDefault();
    $event.stopPropagation();
    if (this.image && ($event.target === this.zoomable.nativeElement)) {
      this.zoomable.nativeElement.style.transform = 'scale(1)';
    }
  }

  /**
   * Listener for mousemove/touchmove event
   */
  @HostListener('mousemove', ['$event'])
  onMove($event) {
    $event.preventDefault();
    $event.stopPropagation();
    if (this.image && ($event.target === this.zoomable.nativeElement)) {
      const clientX = $event.touches ? $event.touches[0].clientX : $event.clientX;
      const clientY = $event.touches ? $event.touches[0].clientY : $event.clientY;
      const zoomer = this.zoomable.nativeElement;
      const dimensions = zoomer.getBoundingClientRect();
      zoomer.style.transformOrigin = `${((clientX - dimensions.left) / dimensions.width) * 100}%
            ${((clientY - dimensions.top) / dimensions.height) * 100}%`;
    }
  }
}
