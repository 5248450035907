import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import smoothscroll from 'smoothscroll-polyfill'; // safary poylfill

import { TSConfirmComponent } from 'tscommon';

import { environment } from '../../../environments/environment';

// Providers
import { BundleProvider } from '../../core/api/BundleProvider';

// Services
import { AmplitudeService } from '../../core/services/amplitude';
import { TrackingService } from '../../core/services/tracking';
import { DraftService } from '../../core/services/draft.service';
import { NavigatorService } from '../../core/services/navigator';
import { SessionService } from '../../utility/session.service';
import { QuoteService } from '../../core/services/quote.service';
import { BundleIntegrityService } from '../../core/services/bundle-integrity.service';
import { PriceCalculatorService } from '../../core/services/price-calculator';
import { CampaignTrackingService } from '../../core/services/campaign-tracking.service';
import { CookieService } from '../../core/services/cookie.service';

import { Utils } from '../../core/services/utils';

// State management
import { AddVariation } from '../../state-manager/order-item/order-item.actions';

// Draft base component
import { DraftBaseComponent } from '../../core/components/draft/draftBase.component';

// Types
import { IOrderItem } from '../../types/app-state/IOrderItem';
import { IBundleItem } from '../../types/bundle/IBundle';
import { IVariationType, IVariationTypeListItem, AvailableVariationTypeTypes } from '../../types/product/variations/IVariationType';
import { ProductOrderSteps } from '../../types/step/IStep';
import { IVariation } from '../../types/product/variations/IVariation';
import { ISelectedVariation } from '../../types/app-state/ISelectedVariation';
import { IAppState } from '../../types/app-state/IAppState';
import { ICustomQuantity } from '../../types/product/variations/ICustomQuantity';

@Component({
  templateUrl: './customize.component.html',
  styleUrls: ['./customize.component.scss'],
})
export class CustomizeComponent extends DraftBaseComponent implements OnInit, OnDestroy {

  bundleItem: IBundleItem;
  customisationLock = false;

  // boolean to keep track of whether the upgrade modal was shown
  upgradePromptShown = false;

  hasUpgrade = false;

  priceUpgradeInfo = {
    currentPrice: 0,
    currentDiscountedPrice: 0,
    currentQty: 0,
    upgradePrice: 0,
    upgradeDiscountedPrice: 0,
    upgradeQty: 0,
  };
  minQty = 0;
  quantityUpgradeVariation: IVariation;

  bundleCustomisationList: IVariationTypeListItem[] = [];

  currentUid = '';
  loading: boolean;
  orderItem: IOrderItem;

  // Define current step for step-tracker
  currentStep = ProductOrderSteps.customize;

  private _orderItem$: Observable<IOrderItem> = this._activatedRoute.snapshot.data.orderItem;
  private _subscriptions$: Subscription[] = [];

  @ViewChild('confirmModal', { static: true }) confirm: TSConfirmComponent;

  constructor(
    protected draftService: DraftService,
    protected router: Router,
    protected tracking: TrackingService,
    protected session: SessionService,
    protected cookies: CookieService,
    private _activatedRoute: ActivatedRoute,
    private _amp: AmplitudeService,
    private _navigator: NavigatorService,
    private _bundleProvider: BundleProvider,
    private _quoteService: QuoteService,
    private _bis: BundleIntegrityService,
    private _elementRef: ElementRef,
    private _store: Store<IAppState>,
    private _campaignTrackingService: CampaignTrackingService,
  ) {
    super(draftService, router, tracking, session, cookies);
    this.loading = true;
    this._campaignTrackingService.handleCampaignTracking(this._activatedRoute.snapshot.queryParams);
  }

  async ngOnInit() {
    // kick off the polyfill!
    smoothscroll.polyfill();

    let subscription$ = this._orderItem$
      .subscribe((data: IOrderItem) => {
        this.orderItem = data;

        this.customisationLock = !!data.bundleItem;

        this.loading = false;
        this.checkPriceUpgradeInfo(data);
      });

    this._subscriptions$.push(subscription$);

    subscription$ = this._activatedRoute.queryParams.subscribe(params => {
      this.currentUid = params['uid'] ? params['uid'] : '';
    });

    this.checkUserIsLoggedIn();

    this._amp.logEvent('Navigation', {
      page: 'customize',
      navigationStep: 4,
      category: this.orderItem.category.name,
      category_id: this.orderItem.category.id,
      productName: this.orderItem.product.name,
      product_id: this.orderItem.product.id
    });

    this.tracking.sendAltoCloudData(['pageview', {
      location: location.pathname,
      title: 'Customize'
    }]);

    window.dataLayer.push({ ecommerce: null }); 
    // customer lands on product detail page
    window.dataLayer.push({
      'event': 'view_item',
      'ecommerce': {
        'currencyCode': 'USD',
        'value': 0,
        'items': [
          {
            'item_name': this.orderItem.product.name,
            'item_id': this.orderItem.product.id,
            'price': 0,
            'quantity': 1,
            'item_brand': 'The/Studio',
            'item_category': this.orderItem.category.name,
            'item_variant': 'None',
            'item_list_name': this.orderItem.category.name,
            'item_list_id': this.orderItem.category.id,
          }
        ]
      }
    });

    // handle upsell confirm modal yes/ no
    const modalSub = this.confirm.onClose$.subscribe(confirm => {
      if (confirm) {
        window.dataLayer.push({
          'event': 'select_promotion',
          'ecommerce': {
            'creative_name': this.getUpsellTitle(),
            'creative_slot': 'upgrade_modal',
            'promotion_id': 'upsell',
            'promotion_name': 'upgrade modal',
            'items': [
              {
                'item_name': this.orderItem.product.name,
                'item_id': this.orderItem.product.id,
                'price': 0,
                'quantity': 1,
                'item_brand': 'The/Studio',
                'item_category': this.orderItem.category.name,
                'item_variant': this.orderItem.variationTypes[0].vtName,
                'item_list_name': this.orderItem.category.name,
                'item_list_id': this.orderItem.category.id,
              }
            ],
          }
        });

        this._upgradeOrder();
      }

      this._amp.logEvent('Upsell', {
        value: !!confirm,
      });

      this.upgradePromptShown = true;
      modalSub.unsubscribe();
      this._navigator.navigate('specialOptions', this._activatedRoute.snapshot.queryParams);
    });

    this._subscriptions$.push(subscription$);
    this._handleBundle();
    
  }

  checkPriceUpgradeInfo(orderItem: IOrderItem) {
    const selectedQuantity = orderItem.variationTypes.find(selected => selected.vtCategory === AvailableVariationTypeTypes.quantity);

    if (selectedQuantity) {
      const quantityVT = orderItem.product.variationTypes.find(vt => vt.category === AvailableVariationTypeTypes.quantity);

      // sort variations by quantity
      const sortedQuantityVT = [...quantityVT.variations]
        .sort((a: IVariation, b: IVariation) => Utils.convertToNumber(a.name) - Utils.convertToNumber(b.name));

      const minQty = Utils.convertToNumber(sortedQuantityVT[0].name);

      // first get list of applicable quantity variations and sort it by qty value
      const availableDIYQuantityVts = sortedQuantityVT.filter(variation => variation.showInDIY);

      // second, get index of currently selected variation
      let betterOfferIndex = 0;
      let selectedQuantityValue = 0;

      if ('customType' in selectedQuantity.variation) {
        selectedQuantityValue = (<ICustomQuantity>selectedQuantity.variation).quantityValue;

        betterOfferIndex = availableDIYQuantityVts
          .findIndex(variation => Utils.convertToNumber(variation.name) > selectedQuantityValue);
      } else {
        betterOfferIndex = availableDIYQuantityVts.findIndex(variation => variation.id === selectedQuantity.variation.id) + 1;
        selectedQuantityValue = Utils.convertToNumber(selectedQuantity.variation.name);
      }

      this.quantityUpgradeVariation = availableDIYQuantityVts[betterOfferIndex];

      this.priceUpgradeInfo = {
        currentPrice: 0,
        currentDiscountedPrice: 0,
        currentQty: 0,
        upgradePrice: 0,
        upgradeDiscountedPrice: 0,
        upgradeQty: 0,
      };

      // if there is a higher variation available in the array, get both costs
      if (this.quantityUpgradeVariation) {
        this.priceUpgradeInfo.currentQty = selectedQuantityValue;

        // get current selection prices
        if ('customType' in selectedQuantity.variation) {

          // get original price
          this.priceUpgradeInfo.currentPrice = PriceCalculatorService.getCustomQuantityPrice(
            minQty, selectedQuantityValue, orderItem, quantityVT, false
          );

          // get discounted price
          this.priceUpgradeInfo.currentDiscountedPrice = PriceCalculatorService.getCustomQuantityPrice(
            minQty, selectedQuantityValue, orderItem, quantityVT
          );
        } else {

          // get original price
          this.priceUpgradeInfo.currentPrice = PriceCalculatorService.getQuantityVariationPrice(
            selectedQuantity.variation, selectedQuantityValue, orderItem, quantityVT, false
          );

          // get discounted price
          this.priceUpgradeInfo.currentDiscountedPrice = PriceCalculatorService.getQuantityVariationPrice(
            selectedQuantity.variation, selectedQuantityValue, orderItem, quantityVT
          );
        }

        this.priceUpgradeInfo.upgradeQty = Utils.convertToNumber(this.quantityUpgradeVariation.name);

        // get upgrade price
        this.priceUpgradeInfo.upgradePrice = PriceCalculatorService.getQuantityVariationPrice(
          this.quantityUpgradeVariation, this.priceUpgradeInfo.upgradeQty, orderItem, quantityVT, false
        );

        // get upgrade discounted price
        this.priceUpgradeInfo.upgradeDiscountedPrice = PriceCalculatorService.getQuantityVariationPrice(
          this.quantityUpgradeVariation, this.priceUpgradeInfo.upgradeQty, orderItem, quantityVT
        );

        this.hasUpgrade = true;
      } else {

        this.quantityUpgradeVariation = undefined;
        this.hasUpgrade = false;
      }
    }
  }

  disableNextStep() {
    if (!this.orderItem) {
      return true;
    }
    // allow moving forward only when all variation types are present
    return this.orderItem.product.variationTypes.length > this.orderItem.variationTypes.length;
  }

  nextStep() {
    if (this.disableNextStep()) {
      this.tracking.sendAltoCloudData(['record', 'userStuck']);
      return;
    }

    if (environment.showUpgradeModal && this.hasUpgrade && !this.upgradePromptShown) {
      window.dataLayer.push({ ecommerce: null }); 
      window.dataLayer.push({
        'event': 'view_promotion',
        'ecommerce': {
          'creative_name': this.getUpsellTitle(),
          'creative_slot': 'upgrade_modal',
          'promotion_id': 'upsell',
          'promotion_name': 'upgrade modal',
          'items': [
            {
              'item_name': this.orderItem.product.name,
              'item_id': this.orderItem.product.id,
              'price': 0,
              'quantity': 1,
              'item_brand': 'The/Studio',
              'item_category': this.orderItem.category.name,
              'item_variant': this.orderItem.variationTypes[0].vtName,
              'item_list_name': this.orderItem.category.name,
              'item_list_id': this.orderItem.category.id,
            }
          ],
        }
      });

      this.confirm.show();
      return this.upgradePromptShown = true;
    }

    return this._navigator.navigate('specialOptions', this._activatedRoute.snapshot.queryParams);
  }

  getUpsellTitle() {
    const unitDiff = Math.floor(this.priceUpgradeInfo.upgradeQty - this.priceUpgradeInfo.currentQty);
    return `Upgrade and get ${unitDiff} more ${this.orderItem.product.name}!`;
  }

  getUpsellSubTitle() {
    const priceCost = this.priceUpgradeInfo.upgradeDiscountedPrice - this.priceUpgradeInfo.currentDiscountedPrice;
    const unitDiff = Math.floor(this.priceUpgradeInfo.upgradeQty - this.priceUpgradeInfo.currentQty);
    return `Spend ${priceCost.toFixed(2)} more to get ${unitDiff} pieces`;
  }

  scrollToNextSection(tabIndex: string) {
    const nextSectionElement = this._elementRef.nativeElement.querySelector(`#section${tabIndex + 1}`) ||
      this._elementRef.nativeElement.querySelector('#continue');

    if (nextSectionElement) {
      const y = nextSectionElement.getBoundingClientRect().top + window.scrollY;
      window.scroll({ top: Math.ceil(y - (y * 0.075)), behavior: 'smooth' });
    }
  }

  private _upgradeOrder() {
    const quantityVT = this.orderItem.product.variationTypes.find(vt => vt.category === AvailableVariationTypeTypes.quantity);

    const selectedVariation: ISelectedVariation = {
      variation: this.quantityUpgradeVariation,
      multiple: false,
      vtID: quantityVT.id,
      vtDBName: quantityVT.dbname,
      vtName: quantityVT.name,
      vtCategory: quantityVT.category
    };

    this._store.dispatch(new AddVariation(selectedVariation));
  }


  private async _handleBundle() {
    // check if the customer is logged in and has a bundle,
    if (this.session.isLoggedIn()) {

      // regular users
      let email = this.session.user.email;

      // ust users
      if (this.session.isSalesLoggedIn()) {
        const customerInfo = this._quoteService.getCustomerInfo();
        email = customerInfo.email;
      }

      const productId = String(this.orderItem.product.id);

      let bundleItems = await this._bundleProvider.getAvailableItems(productId, email);

      // check for duplicates and matching product
      bundleItems = this._bis.getValidBundleItem(bundleItems, productId, this.currentUid);

      if (bundleItems.length > 0) {
        this.bundleItem = bundleItems[0];
        this._setBundleCustomisationList(this.orderItem.product.variationTypes, this.bundleItem.variations);
      }
    }
  }

  /**
   * Used to display the variation Type: Variation Name list
   */
  private _setBundleCustomisationList(variationTypes: IVariationType[], variationIds: number[]) {
    this.bundleCustomisationList = [];
    variationTypes.map(vt => {
      const matchingVT = vt.variations.find(variation => !!~variationIds.indexOf(variation.id));
      if (matchingVT) {
        this.bundleCustomisationList.push({
          variationTypeName: vt.name,
          variationName: matchingVT.name
        } as IVariationTypeListItem);
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscriptions$.map(subscription$ => subscription$.unsubscribe());
  }

  checkUserIsLoggedIn() {
    const user = this.cookies.getJson("quotes")
      ? this.cookies.getJson("quotes")[0].customerId
      : undefined;
    
    if (user) {
      window.dataLayer.find(
        (element) => element.event === "dataLayer-initialized"
      ).userId = user;
      window.dataLayer.find(
        (element) => element.event === "dataLayer-initialized"
      ).loggedIn = "y";
      sessionStorage.setItem("userId", user);
      window.dataLayer.push({
        event: "dataLayer-initialized",
        loggedIn: "y",
        userId: user
      });
    }
  }

  private _findPos(obj) {
    let curtop = 0;
    if (obj.offsetParent) {
      do {
        curtop += obj.offsetTop;
      } while (obj = obj.offsetParent);
      return [curtop];
    }
  }
}
