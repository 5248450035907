import { Component, OnDestroy, ViewChild, ElementRef, AfterViewChecked, OnInit, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { IAppState } from '../../../types/app-state/IAppState';
import { IOrderItem } from '../../../types/app-state/IOrderItem';
import { IDeliveryOption } from '../../../types/turnaround/IDeliveryOption';

import { DateUtils } from '../../../utility/DateUtils';
import { IImage } from '../../../types/images/IImage';
import { PageHeightFixService } from '../../../utility/page-height-fix.service';
import { IPackagingOrder } from '../../../types/app-state/IPackagingOrder';
import { ComStr } from '../../services/ComStr';

@Component({
  selector: 'diy-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss']
})
export class OrderSummaryComponent implements OnInit, OnDestroy, AfterViewChecked {

  currentOrderItem: IOrderItem;
  packagingOrderItem: IPackagingOrder;
  showSummary = true;

  productCollapsed = false;
  packagingCollapsed = false;
  artworkSrc: string;

  @Input() currentStep: number;
  @ViewChild('summaryRef') summaryRef: ElementRef;

  private _subscriptions$: Subscription[] = [];

  constructor(
    private _store: Store<IAppState>,
    private _pageHeightFix: PageHeightFixService
  ) {

    let subscription$: Subscription = this._store.pipe(select('orderItem'))
      .subscribe((data: IOrderItem) => {
        this.currentOrderItem = { ...data };
        this.artworkSrc = this._getSidebarImage();
      });

    this._subscriptions$.push(subscription$);

    subscription$ = this._store.pipe(select('packagingOrder'))
      .subscribe((data: IPackagingOrder) => this.packagingOrderItem = data);

    this._subscriptions$.push(subscription$);
  }

  ngOnInit() {
    this.showSummary = !!this.currentOrderItem;
  }

  ngOnDestroy() {
    this._subscriptions$.map(subscription => subscription.unsubscribe());
  }

  ngAfterViewChecked() {
    if (this.summaryRef) {
      this._pageHeightFix.tallestPageHeight.next(this.summaryRef.nativeElement.offsetHeight);
    }
  }

  toggleProduct() {
    this.productCollapsed = !this.productCollapsed;
  }

  togglePackaging() {
    this.packagingCollapsed = !this.packagingCollapsed;
  }

  formatTatDate(deliveryOption: IDeliveryOption, skipSample: boolean): string {

    if (deliveryOption.isGuaranteed && deliveryOption.guaranteedDate) {
      const formatter = Intl.DateTimeFormat('en-us', { month: 'long' });

      return `${formatter.format(new Date(deliveryOption.guaranteedDate))} ${new Date(deliveryOption.guaranteedDate).getDate()}`;
    }

    return DateUtils.estimateInterval(deliveryOption, skipSample);
  }

  /**
   * Return the image that will be displayed in the sidebar
   */
  private _getSidebarImage(): string {
    let image: string;

    if (!this.currentOrderItem.artwork || !this.currentOrderItem.artwork.files) {
      if (this.currentOrderItem.product && this.currentOrderItem.product.images.length) {
        // return product image if they are set
        image = this._getImage(this.currentOrderItem.product.images).medium;
      } else {
        // return category thumbnail or nothing when nothing is uploaded
        // keep in else branch so we don't overwrite the image variable
        image = (this.currentOrderItem.category && this.currentOrderItem.category.images.length) ?
          this._getImage(this.currentOrderItem.category.images).medium : '';
      }

    } else {
      return ComStr.getDisplayableImage(this.currentOrderItem.artwork.files);
    }

    return ComStr.checkImageFormat(image);
  }

  private _getImage(images: IImage[]) {
    let image = images.find(img => img.main);
    if (!image) {
      image = images[0];
    }
    return image;
  }

}
