<!-- Header -->
<div class="header-content">
  <div id="vwoTracking"></div>
  <diy-header></diy-header>
</div>


<!-- Page content -->
<div class="container">
  <div class="order-confirmation">
    <div class="image">
      <!-- <img src="/assets/img/Shoppingcarticon.svg" alt="Shopping Cart Icon" /> -->
      <img src="/assets/img/box-check.svg" alt="Shopping Cart Icon" />
    </div>
    <h1 class="order-confirmation__title">Payment Received</h1>
    <div class="order-confirmation__informations">
      <p>Thank you. Your orders have been placed.</p>
      <p>Invoice ID: {{ invoiceId }}</p>
    </div>

    <!-- Temporarily disabled survey for success page -->
    <!-- <diy-survey *ngIf="shoppingCart.invoiceId" [invoiceId]="shoppingCart.invoiceId"></diy-survey> -->

    <div class="order-confirmation__products">
      <div
        *ngFor="let orderItem of orderItems"
        class="order-confirmation__product"
      >
        <div class="order-confirmation__product--row">
          <div class="order-confirmation__product--column">
            <div class="heading">Product</div>
            <div class="data">{{ orderItem.productName }}</div>
          </div>
          <div class="order-confirmation__product--column">
            <div class="heading">Quantity</div>
            <div class="data">{{ orderItem.quantity }}</div>
          </div>
          <div class="order-confirmation__product--column">
            <div class="heading">Total</div>
            <div class="data">
              {{ orderItem.total | currency: "USD":"symbol":"1.0-2" }}
            </div>
          </div>
          <div class="order-confirmation__product--column">
            <div class="heading">Status</div>
            <div
              *ngIf="!orderItem.pendingArtwork; else pending"
              class="data success"
            >
              Order Placed
            </div>
            <ng-template #pending>
              <div class="data error">Pending Artwork</div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>

    <div class="order-confirmation__buttons">
      <a
        class="order-confirmation__button continue-shopping"
        href="/?skipDraft=true"
      >
        CREATE ANOTHER
      </a>
      <a
        class="order-confirmation__button review-orders"
        href="{{ customerDashURL }}"
        >VIEW ALL ORDERS</a
      >
    </div>
  </div>
</div>
