import { Injectable } from '@angular/core';
import amplitude from 'amplitude-js/amplitude.js';

import { environment } from '../../../environments/environment';

import { SessionService } from '../../utility/session.service';

@Injectable({
  providedIn: 'root'
})
export class AmplitudeService {

  client;

  constructor(
    private _sessionService: SessionService,
  ) {
    amplitude.init(environment.amplitudeApiKey);
    this.client = amplitude.getInstance();
  }

  logEvent(name: string, properties: any) {
    if (this._sessionService.isSalesLoggedIn()) {
      const userData = this._sessionService.user;
      properties = { ...properties, sales: true, salesEmail: userData.email };
    }
    this.client.logEvent('DIY - ' + name, properties);
  }

  /**
  * Identify user by setting user_id
  * @param {number} user_id
  */
  setUserId(user_id: string) {
    this.client.setUserId(user_id);
  }
}
