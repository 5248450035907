import { Component, Input } from '@angular/core';
import { ISelectedVariation } from '../../../../types/app-state/ISelectedVariation';
import { IPrice } from '../../../../types/product/IPrice';
import { IVariation } from '../../../../types/product/variations/IVariation';
import { PriceCalculatorService } from '../../../../core/services/price-calculator';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'diy-special-options-summary',
  templateUrl: './special-options-summary.component.html',
  styleUrls: ['./special-options-summary.component.scss']
})
export class SpecialOptionsSummaryComponent {

  @Input() selectedVariations: ISelectedVariation[];
  @Input() price: IPrice;

  getThumbnail(variation: IVariation): string {
    if (variation.images.length < 1) {
      return environment.placeholderImageURL;
    }

    return variation.images[0].small;
  }

  getSpecialOptionPrice(variation: IVariation) {
    return PriceCalculatorService.getVariationPrice(variation, this.price.quantity, this.price.pricePerUnit);
  }
}
