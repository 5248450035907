import { IDeliveryOption } from '../types/turnaround/IDeliveryOption';

export class DateUtils {
  public static guaranteedDateFormat(date: Date): string {
    date = date ? new Date(date) : undefined;

    return (!date) ?
      '' :
      `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
  }

  public static tatGuaranteedDateFormat(date: Date): string {
    date = date ? new Date(date) : undefined;
    if (date) {
      const month = `${date.getMonth() + 1}`.padStart(2, '0');
      const day = `${date.getDate()}`.padStart(2, '0');
      return `${month}/${day}/${date.getFullYear()}`;
    }
    return '';
  }

  public static estimateInterval(deliveryOption: IDeliveryOption, skipSample: boolean): string {

    const formatter = Intl.DateTimeFormat('en-us', { month: 'short' });
    let min = new Date(deliveryOption.datesInterval.normalStart);
    let max = new Date(deliveryOption.datesInterval.normalEnd);

    if (skipSample) {
      min = new Date(deliveryOption.datesInterval.ssStart);
      max = new Date(deliveryOption.datesInterval.ssEnd);
    }
    return `${formatter.format(min)} ${min.getDate()} - ${formatter.format(max)} ${max.getDate()}`;

  }
}
