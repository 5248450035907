import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngrx/store';

import { OptionBase } from './option-base.component';
import { IVariation } from '../../../types/product/variations/IVariation';
import { ISelectedVariation } from '../../../types/app-state/ISelectedVariation';
import { IOrderItem } from '../../../types/app-state/IOrderItem';
import { IAppState } from '../../../types/app-state/IAppState';
import { AddSpecialOption, RemoveSpecialOption } from '../../../state-manager/order-item/order-item.actions';

@Component({
  selector: 'diy-single-option-tab',
  templateUrl: './single-option.component.html',
  styleUrls: ['./options-common.component.scss']
})
export class SingleOptionComponent extends OptionBase implements OnInit {

  orderItem: IOrderItem;
  selectedSpecialOption: IVariation;

  @Output() optionSelected: EventEmitter<ISelectedVariation> = new EventEmitter<ISelectedVariation>();

  constructor(private _store: Store<IAppState>) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.orderItem.specialOptions.length) {
      const match = this.orderItem.specialOptions.find((selElm) => selElm.vtID === this.specialOption.id);

      if (match) {
        this.selectedSpecialOption = (match.variation as IVariation);
        this.hasSelection = true;
      }
    }

    if (this.tabIndex === 1) {
      this.collapseStatus = false;
    }
  }

  chooseOption(variation: IVariation) {
    if (!this.isCompatible(variation)) {
      return;
    }

    const selectedVariation: ISelectedVariation = {
      variation: variation,
      multiple: false,
      vtID: this.specialOption.id,
      vtDBName: this.specialOption.dbname,
      vtName: this.specialOption.name,
      vtCategory: this.specialOption.category
    };

    if (!this.specialOption.required && this.selectedSpecialOption && this.selectedSpecialOption === variation) {
      this._removeOption(selectedVariation);
    } else {
      this._selectOption(selectedVariation, variation);
      this.optionSelected.emit(selectedVariation);
    }
  }

  private _selectOption(option: ISelectedVariation, variation: IVariation) {
    this.selectedSpecialOption = variation;

    this.hasSelection = true;
    this.showError = false;
    this._store.dispatch(new AddSpecialOption(option));
  }

  private _removeOption(option: ISelectedVariation) {
    this.selectedSpecialOption = undefined;
    this.hasSelection = false;
    this.showError = false;
    this._store.dispatch(new RemoveSpecialOption(option));
  }

}
