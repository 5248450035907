<form [formGroup]="form" class="payment__item payment--billing">

  <div *ngIf="errorMessage" class="card-form__danger">
    <p>{{ errorMessage }}</p>
  </div>

  <p class="payment__item--title">Billing Address </p>

  <div class="checkbox-wrap">
    <input formControlName="sameAsShipping" (change)="toggleShipping()" type="checkbox" id="sameAsShippingCheckbox" />
    <label for="sameAsShippingCheckbox"><span class="checkbox-icon"></span> Same as Shipping</label>
  </div>

  <div *ngIf="!shippingLikeBilling" class="billing-wrap">
    <div *ngIf="!showBillingForm" class="">
      <h2 class="payment-address-title">Choose a billing address </h2>

      <div *ngFor="let address of customerBillingData" class="shipping-address"
        [ngClass]="{'active': selectedBillingAddress === address}" (click)="chooseAddress(address)">
        {{address.name}} <br />
        {{address.address}} <br />
        {{address.city}}, {{address.state}} {{address.zip}}
      </div>
      <button class="simple-green-btn" (click)="toggleBillingForm(true)">+ New Address</button>
    </div>

    <!-- Address -->
    <div *ngIf="showBillingForm" class="payment__address-form">
      <button *ngIf="customerBillingData?.length" class="simple-green-btn cancel-billing-form"
        (click)="toggleBillingForm(false)">Close</button>

      <div class="form-row form-row--split">
        <input type="text" placeholder="First Name" class="form-input" />
        <input type="text" placeholder="Last Name" class="form-input" />
      </div>

      <input class="form-input" type="text" placeholder="Address" formControlName="address"
        [ngClass]="getStateClass(form.get(['address']))" />

      <input class="form-input" type="text" placeholder="Company" formControlName="company"
        [ngClass]="getStateClass(form.get(['company']))" />

      <div class="form-row--split">
        <input class="form-input form--city" type="text" placeholder="City" formControlName="city"
          [ngClass]="getStateClass(form.get(['city']))" />
        <input class="form-input payment__address-form--state form--state" type="text" placeholder="State"
          formControlName="state" [ngClass]="getStateClass(form.get(['state']))" />
        <input class="form-input payment__address-form--zip form--zip" type="text" placeholder="Zip"
               formControlName="zip" [ngClass]="getStateClass(form.get(['zip']))" />
      </div>

      <div class="form-row--split">
        <input class="form-input payment__address-form--phone" type="text" placeholder="Phone" formControlName="phone"
          [ngClass]="getStateClass(form.get(['phone']))" />
        <input class="form-input" type="text" placeholder="Country" formControlName="country"
          [ngClass]="getStateClass(form.get(['country']))" />
      </div>

      <br />

      <div *ngIf="!isLoggedIn" class="checkbox-wrap">
        <input formControlName="saveBilling" type="checkbox" id="saveBillingCheckbox" value="false" />
        <label for="saveBillingCheckbox"><span class="checkbox-icon"></span> Save Billing address</label>
      </div>
      <button *ngIf="isLoggedIn" class="save-btn" [disabled]="!form.valid || isLoading" (click)="saveAddress()">
        <span *ngIf="!isLoading; else loading;">Save Address</span>
      </button>
    </div>
  </div>
</form>

<ng-template #loading>
  <span>Loading</span>
</ng-template>
