import { Injectable } from '@angular/core';

import { Utils } from './utils';

import { IPricingTable } from '../../types/product/IPricingTable';
import { IVariationType, PackagingVariationTypeTypes } from '../../types/product/variations/IVariationType';
import { ISelectedVariation } from '../../types/app-state/ISelectedVariation';
import { IProduct } from '../../types/product/IProduct';
import { IVariation } from '../../types/product/variations/IVariation';
import { IPrice } from '../../types/product/IPrice';
import { ICustomQuantity } from '../../types/product/variations/ICustomQuantity';
import { ISetupFee } from '../../types/product/ISetupFee';
import { DiscountTypes } from '../../types/voucher/IVoucherResponse';
import { IVoucher } from '../../types/voucher/IVoucher';
import { IBundleQuantity } from '../../types/product/variations/IBundleQuantity';
import { ITurnaround } from '../../types/turnaround/ITurnaround';
import { IBundleItem } from '../../types/bundle/IBundle';
import { IOrderItem } from '../../types/app-state/IOrderItem';
import { VariationManager } from './variation-manager.service';

/**
 * This class should be fully static
 * The calc functions should ALWAYS copy variation types and special options
 * into local scoped arrays and do the math HERE
 * Destroyer of sanity. Yes those are business rules.
 */
@Injectable()
export class PriceCalculatorService {

  public static redoPrice(
    product: IProduct,
    price: IPrice,
    baseVariations: ISelectedVariation[],
    optionalVariations?: ISelectedVariation[],
    turnaround?: ITurnaround,
    bundleItem?: IBundleItem
  ): IPrice {
    if (!product) {
      // throw error if product is not set;
      throw new Error('');
    }
    const newPrice = { ...price };

    // if the user hasn't picked enough variations we can't search the pricing table
    if (baseVariations.length < product.pricingTable.columnCount) {
      return newPrice;
    }

    // find the chosen quantity variation Type, then the number of units
    const quantityVT: IVariationType = product.variationTypes
      .find(arrayElement => arrayElement.category === 'qty' || arrayElement.category === PackagingVariationTypeTypes.quantity);

    const quantity = this._getQuantityValue(baseVariations, quantityVT.id);

    // try to get the price per unit value
    newPrice.pricePerUnit = this._getPriceByQuantity(quantityVT, quantity, product.pricingTable, baseVariations);

    // regular flow
    if (!newPrice.pricePerUnit) {
      // therow error if price per unit is not set
      throw new Error('');
    }

    newPrice.quantity = quantity;
    newPrice.customizeTotal = 0;

    newPrice.customizeTotal = newPrice.quantity * newPrice.pricePerUnit;

    if (!bundleItem) {
      // orders with bundles ignore flattening
      if (newPrice.customizeTotal < 100) {
        newPrice.customizeTotal = 100;
        newPrice.customPricePerUnit = 100 / newPrice.quantity;
      } else {
        newPrice.customPricePerUnit = 0;
      }
    }

    newPrice.specialOptionsTotal = 0;

    optionalVariations
      .map(selected => {
        const variation = (selected.variation as IVariation);
        newPrice.specialOptionsTotal += PriceCalculatorService.getVariationPrice(variation, newPrice.quantity, newPrice.pricePerUnit);
      });

    const initialSubTotal = newPrice.customizeTotal + newPrice.specialOptionsTotal;

    newPrice.guaranteedFee = 0;
    newPrice.shippingTotal = 0;

    // try to get shipping price only if options are selected
    if (turnaround && turnaround.selectedOption) {
      newPrice.shippingTotal = PriceCalculatorService.getShippingOptionCost(turnaround.selectedOption.name, initialSubTotal);

      if (turnaround.selectedOption.isGuaranteed) {
        newPrice.guaranteedFee = (initialSubTotal + newPrice.shippingTotal) * 0.15;
      }
    }

    newPrice.bundleTotal = 0;
    if (bundleItem) {
      const bundleQty = Number(bundleItem.quantity);
      newPrice.bundleTotal = newPrice.pricePerUnit * bundleQty;
    }

    newPrice.subTotal = newPrice.customizeTotal + newPrice.specialOptionsTotal;
    newPrice.finalTotal =
      newPrice.customizeTotal + newPrice.specialOptionsTotal + newPrice.shippingTotal + newPrice.guaranteedFee - newPrice.bundleTotal;

    if (newPrice.finalTotal <= 0) {

      // default reset all discount amounts if final total is less than 0
      newPrice.discountAmount = 0;
      newPrice.promotionalDiscountValue = 0;
      newPrice.promotionalDiscount = 0;
      return newPrice;
    }

    return this._applyDiscounts(newPrice, product.productDiscount, !!bundleItem);
  }

  public static getVariationPrice(variation: IVariation, quantity: number, pricePerUnit: number): number {

    let variationPrice = 0;

    if (variation.valueType === 'amount') {
      variationPrice = Number(variation.value) * quantity;
    } else if (variation.valueType === 'percent') {
      variationPrice = Number(variation.value) * (pricePerUnit * quantity) / 100;
    }

    if (variation.setupFee && variation.setupFee.length > 0) {
      variationPrice += PriceCalculatorService._getSetupFee(variation.setupFee, quantity);
    }


    return variationPrice;
  }

  public static getQuantityVariationPrice(
    variation: IVariation,
    qty: number,
    orderItem: IOrderItem,
    variationType: IVariationType,
    applyDiscount = true,
  ) {

    if (orderItem && orderItem.product.pricingTable.columnCount > orderItem.variationTypes.length + 1) {
      return 0;
    }

    const selectedVariation: ISelectedVariation = {
      variation: variation,
      multiple: false,
      vtID: variationType.id,
      vtDBName: variationType.dbname,
      vtName: variationType.name,
      vtCategory: variationType.category
    };

    const price = PriceCalculatorService.getVariationCost(orderItem.product, orderItem.variationTypes, selectedVariation);
    let productDiscount = orderItem.product.productDiscount;
    if (!applyDiscount) {
      productDiscount = 0;
    }
    return PriceCalculatorService.flattenTotal(price * qty, productDiscount);
  }

  public static getCustomQuantityPrice(
    minQty: number,
    qty: number,
    orderItem: IOrderItem,
    variationType: IVariationType,
    applyDiscount = true,
  ) {
    if (qty < minQty) {
      return 0;
    }
    const selectedVariation = VariationManager.createSelectedCustomQty(qty, variationType);
    const price = PriceCalculatorService.getVariationCost(orderItem.product, orderItem.variationTypes, selectedVariation);
    let productDiscount = orderItem.product.productDiscount;

    if (!applyDiscount) {
      productDiscount = 0;
    }

    return PriceCalculatorService.flattenTotal(price * qty, productDiscount);
  }

  // Print flag changes return type from number to string ($deliveryCost + $guaranteedCost)
  public static getShippingOptionCost(type: string, price: number): number {

    if (type === 'rush') {
      return price * 0.2;
    }

    if (type === 'urgent') {
      return price * 0.5;
    }

    // default
    return 0;
  }


  public static getVariationCost(product: IProduct, selVariations: ISelectedVariation[], selVariation: ISelectedVariation) {

    // clone existing variations
    const clonedChosenVariations = JSON.parse(JSON.stringify(selVariations));

    const position = clonedChosenVariations.findIndex(arrElem => {
      return arrElem.vtID === selVariation.vtID;
    });

    if (position > -1) {
      clonedChosenVariations.splice(position, 1, selVariation);
    } else {
      clonedChosenVariations.push(selVariation);
    }
    const quantityVT: IVariationType = product.variationTypes.find(arrayElement => arrayElement.category === 'qty');
    const quantity = this._getQuantityValue(clonedChosenVariations, quantityVT.id);

    return this._getPriceByQuantity(quantityVT, quantity, product.pricingTable, clonedChosenVariations);
  }

  public static flattenTotal(total: number, productDiscount: number) {

    let finalTotal = 0;
    let discountAmount = 0;

    if (productDiscount > 0) {
      discountAmount = total * productDiscount / 100;
    }

    // first breakpoint
    if (total <= (100 + discountAmount)) {
      finalTotal = 100;
    } else {
      finalTotal = total - discountAmount;
    }

    return finalTotal;
  }

  public static getGuaranteedFee(customiseTotal: number, deliveryCost: number) {
    return (customiseTotal + deliveryCost) * 0.15;
  }

  // Calculate the setup fee (return 0 for no cost)
  static _getSetupFee(setupOptions: ISetupFee[], quantity: number): number {

    const setupFee = setupOptions.find(option => quantity >= option.start && quantity <= option.end);

    return setupFee ? setupFee.value : 0;
  }

  private static _applyDiscounts(price: IPrice, productDiscount: number, flatten: boolean) {

    // do not apply discounts and flattening until there is a final total to apply them on
    if (price.voucher && price.voucher.type === DiscountTypes.PERCENT) {
      price.finalTotal = this._calculateVoucherDiscount(price.voucher, price.finalTotal);
    }

    let currentTotal = price.finalTotal;

    let discountAmount = 0;

    // apply product discount
    if (productDiscount > 0) {
      discountAmount = currentTotal * productDiscount / 100;
    }

    // apply voucher discount
    if (price.voucher && price.voucher.type === DiscountTypes.AMOUNT) {
      currentTotal -= price.voucher.discount;
    }

    // only apply flattening when the flag is sent because bundled items do not get flattened price
    if (!flatten && (currentTotal - discountAmount) < 100) {
      price.finalTotal = 100;
      price.discountAmount = currentTotal - 100;
    } else {
      price.finalTotal = currentTotal - discountAmount;
      price.discountAmount = discountAmount;
    }

    price.displayDiscount = (price.discountAmount / currentTotal) * 100;

    // apply promotional discount
    if (price.promotionalDiscount) {
      price.promotionalDiscountValue = (price.finalTotal * price.promotionalDiscount) / 100;
      price.finalTotal -= price.promotionalDiscountValue;
    }

    return price;
  }

  private static _getQuantityValue(selVariations: ISelectedVariation[], quantityVTId: string) {
    const chosenQuantityVariation = selVariations.find(arrayElement => arrayElement.vtID === quantityVTId);

    // if there is not quantity selected, either custom, bundle or regular, we cannot determine the price, so return 0
    if (!chosenQuantityVariation) {
      return 0;
    }

    let quantity = 0;

    // detect custom variations( bundle / quantity)
    if ('customType' in chosenQuantityVariation.variation) {

      if (chosenQuantityVariation.variation.customType === 'bundle') {
        // bundle quantity
        quantity = (chosenQuantityVariation.variation as IBundleQuantity).unitsSelected;
      } else {
        // custom quantity
        quantity = (chosenQuantityVariation.variation as ICustomQuantity).quantityValue;
      }
    } else {
      // regular variation quantity
      quantity = parseInt((chosenQuantityVariation.variation as IVariation).name, 10);
    }
    return quantity;
  }


  private static _getPriceByQuantity(
    quantityVariationType: IVariationType,
    quantity: number,
    pricingTable: IPricingTable,
    originalSelectedVariations: ISelectedVariation[]
  ): number {

    if (originalSelectedVariations.length < pricingTable.columnCount) {
      return 0;
    }
    // first, deep clone the original variations
    // we will try to replace quantity with a variation in the pricing table for price calculations only
    const selectedVariations = JSON.parse(JSON.stringify(originalSelectedVariations));

    // first try to find a variation that matches the quantity value
    const foundVariation = quantityVariationType.variations.find(el => parseInt(el.name, 10) === quantity);

    // if we have an existing variation that matches the custom quantity inputted by the user, use that for price match
    if (foundVariation) {

      const selectedQuantity = selectedVariations.find(selVt => selVt.vtID === quantityVariationType.id);
      selectedQuantity.variation = Object.assign({}, foundVariation);

      // replace "custom" quantity variation with one from pricing table
      const customQtySelectedVariations = selectedVariations
        .map(selected => selected.vtID === quantityVariationType.id
          ? Object.assign({}, selected, { variation: foundVariation })
          : selected
        );

      return PriceCalculatorService._getPricingTableValue(customQtySelectedVariations, pricingTable);
    }

    // if it's custom quantity, interpolate value
    return PriceCalculatorService._interpolateQuantity(quantity, quantityVariationType, selectedVariations, pricingTable);

  }

  // Pricing table look-up for pre-defined variations
  private static _getPricingTableValue(chosenVariations: ISelectedVariation[], pricingTable: IPricingTable): number {

    // because the variation type columns are dynamic, count them so we know how many to match
    const matchCount = pricingTable.columnCount;

    const result = pricingTable.rows.filter(pricingRow => {
      let matches = 0;

      chosenVariations.map(chosenVariation => {
        if (pricingRow[chosenVariation.vtDBName] === chosenVariation.variation.id + '') {
          matches++;
        }
      });

      return matches === matchCount;
    });

    if (result.length < 1) {
      return 0;
    }

    return Number(result[0].price);
  }

  // get nearest variation in pricing table value
  private static _getNeighbourPrice(
    value: number,
    originalChosenVar: ISelectedVariation[],
    qtyVariation: IVariationType,
    pt: IPricingTable
  ): number {
    const clonedChosen = originalChosenVar.map(a => Object.assign({}, a));

    const variation = Utils.getVariationByNumberValue(value, qtyVariation.variations);

    const oldQtyIndex = clonedChosen.findIndex(el => {
      return el.vtDBName === 'Quantity';
    });

    const newSelected: ISelectedVariation = {
      variation: variation,
      multiple: false,
      vtID: qtyVariation.id,
      vtDBName: qtyVariation.dbname,
      vtName: qtyVariation.name,
      vtCategory: qtyVariation.category
    };

    // remove existing qty variation from clone
    clonedChosen.splice(oldQtyIndex, 1, newSelected);

    return this._getPricingTableValue(clonedChosen, pt);
  }


  // Algorithm for custom quantity pricing
  private static _interpolateQuantity(
    quantity: number,
    quantityVT: IVariationType,
    selectedVariations: ISelectedVariation[],
    pt: IPricingTable
  ): number {
    const qtyOptions = Utils.getSortedVariationValues(quantityVT.variations);

    // 2: find value neighbours
    let min = 0;
    let max = 0;

    const closest = qtyOptions.reduce(function (prev, curr) {
      return (Math.abs(curr - quantity) < Math.abs(prev - quantity) ? curr : prev);
    });

    const closestIndex = qtyOptions.indexOf(closest);

    if (quantity > closest) {
      min = closest;
      max = (closestIndex + 1 === qtyOptions.length) ? qtyOptions[closestIndex] : qtyOptions[closestIndex + 1];
    } else if (quantity === closest) {
      min = (closestIndex - 1 > 0) ? qtyOptions[closestIndex - 1] : qtyOptions[closestIndex];
      max = (closestIndex + 1 === qtyOptions.length) ? qtyOptions[closestIndex] : qtyOptions[closestIndex + 1];
    } else {
      min = (closestIndex - 1 >= 0) ? qtyOptions[closestIndex - 1] : qtyOptions[closestIndex];
      max = closest;
    }

    // 3: Find the prices for these values
    const priceForMax = PriceCalculatorService._getNeighbourPrice(max, selectedVariations, quantityVT, pt);
    const priceForMin = PriceCalculatorService._getNeighbourPrice(min, selectedVariations, quantityVT, pt);

    // 4: interpolate the results
    return this._getInterpolatedQtyPrice(min, priceForMin, max, priceForMax, quantity);
  }


  /**
   * Formula for determining custom quantity pricing
   * @param Xa nearest quantity (down)
   * @param Ya nearest price (up)
   * @param Xb nearest quantity (up)
   * @param Yb nearest price (down)
   * @param X the custom quantity
   * @return number price value
   */
  private static _getInterpolatedQtyPrice(Xa: number, Ya: number, Xb: number, Yb: number, X: number): number {
    return ((Yb - Ya) / (Xb - Xa) * (X - Xa) + Ya);
  }

  private static _calculateVoucherDiscount(voucher: IVoucher, finalTotal: number): number {
    const discountAmount: number = (voucher.discount / 100) * finalTotal;
    return finalTotal - discountAmount;
  }
}
