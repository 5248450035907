import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';

@Injectable()
export class AuthProvider {

  constructor(private _http: HttpClient) { }

  private routes = {
    login: environment.baseUrl + 'api/diy/customer/login',
    forgotPassword: environment.baseUrl + 'api/diy/customer/password-reset',
    checkCustomerEmail: environment.baseUrl + 'api/diy/customer/has-data',
  };

  async login(email: string, password: string): Promise<any> {
    try {
      return await this._http.post(this.routes.login, { email: email, password: password }).toPromise();

    } catch (err) {
      // console.error(err);
      return false;
    }
  }

  async forgotPassword(email: string): Promise<any> {
    try {
      return await this._http.post(this.routes.forgotPassword, { email: email }).toPromise();
    } catch (err) {
      console.error(err);
    }
  }

  async checkCustomerEmail(email: string): Promise<any> {
    try {
      return await this._http.post(this.routes.checkCustomerEmail, { email: email }).toPromise();
    } catch (err) { }
  }

}
