// External
import { Component, OnInit, OnDestroy, AfterViewInit, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, Observable } from 'rxjs';
import smoothscroll from 'smoothscroll-polyfill';

// Env
import { environment } from '../../../environments/environment';

// Utils
import { DateUtils } from '../../utility/DateUtils';

// Services
import { AmplitudeService } from '../../core/services/amplitude';
import { VariationManager } from '../../core/services/variation-manager.service';
import { DraftService } from '../../core/services/draft.service';
import { DraftBaseComponent } from '../../core/components/draft/draftBase.component';
import { Utils } from '../../core/services/utils';
import { PageHeightFixService } from '../../utility/page-height-fix.service';
import { TrackingService } from '../../core/services/tracking';
import { CampaignTrackingService } from '../../core/services/campaign-tracking.service';
import { SessionService } from '../../utility/session.service';
import { CookieService } from '../../core/services/cookie.service';
import { PaymentSummaryService } from '../../shop/core/services/payment-summary.service';

// Actions
import { AddCartItem, UpdateCartItemOrder } from '../../state-manager/shopping-cart/shopping-cart.actions';
import { UpdateTurnaround } from '../../state-manager/order-item/order-item.actions';

// Types
import { IOrderItem } from '../../types/app-state/IOrderItem';
import { IAppState } from '../../types/app-state/IAppState';
import { ITurnaround } from '../../types/turnaround/ITurnaround';
import { IDeliveryOption } from '../../types/turnaround/IDeliveryOption';
import { ICartItem, ICartItemOrder } from '../../types/shopping-cart/ICartItem';
import { IShoppingCart } from '../../types/shopping-cart/IShoppingCart';
import { ProductOrderSteps } from '../../types/step/IStep';
import { IPackagingOrder } from '../../types/app-state/IPackagingOrder';
import {ICustomerPaymentData} from '../../types/api/ICustomerPaymentData';
import {UserRole} from '../../types/user/IUser';
import {CustomerDataProvider} from '../../shop/core/api/CustomerDataProvider';

const MINIMUM_GUARANTEE_VALUE = 300;

enum SkipSampleStatus {
  lessThanDefault,
  default,
  mandatory,
}

@Component({
  selector: 'diy-turnaround',
  templateUrl: './turnaround.component.html',
  styleUrls: ['./turnaround.component.scss']
})
export class TurnaroundComponent extends DraftBaseComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('turnaroundPage', { static: true }) turnaroundPage: ElementRef;
  
  orderItem: IOrderItem;

  selectedOption: IDeliveryOption;
  turnaround: ITurnaround;
  currentSkipSampleStatus: SkipSampleStatus;

  // Define current step for step-tracker
  currentStep = ProductOrderSteps.turnaround;

  skipSampleDefault = environment.skipSampleDefault ? environment.skipSampleDefault : 1000;
  skipSampleMandatory = environment.skipSampleMandatory ? environment.skipSampleMandatory : 5000;

  loadedTat = false;
  skipSampleDisabled = false;
  skipSampleStatus = {
    lessThanDefault: SkipSampleStatus.lessThanDefault,
    default: SkipSampleStatus.default,
    mandatory: SkipSampleStatus.mandatory,
  };
  cartItems: ICartItemOrder[] = [];
  private _orderItem$: Observable<IOrderItem> = this._activatedRoute.snapshot.data.orderItem;
  private _subscriptions$: Subscription[] = [];
  private _packagingOrder: IPackagingOrder;
  private _editParams: Params;

  constructor(
    protected draftService: DraftService,
    protected router: Router,
    protected trackingService: TrackingService,
    protected session: SessionService,
    protected cookies: CookieService,
    private _activatedRoute: ActivatedRoute,
    private _amp: AmplitudeService,
    private _store: Store<IAppState>,
    private _pageHeightFix: PageHeightFixService,
    private _renderer: Renderer2,
    private _campaignTrackingService: CampaignTrackingService,
    private _paymentSummaryService: PaymentSummaryService,
  ) {
    super(draftService, router, trackingService, session, cookies);
    this._campaignTrackingService.handleCampaignTracking(this._activatedRoute.snapshot.queryParams);
  }
  
  // lifecycle hooks
  async ngOnInit() {
    let subscription$: Subscription;
    subscription$ = this._orderItem$
      .subscribe((data: IOrderItem) => {
        this.orderItem = data;
        this.turnaround = { ...data.turnaround };
        if (data.turnaround && data.turnaround.selectedOption) {
          this.turnaround.availableOptions = this.turnaround.availableOptions.map(turnaroundOption => {
            if (data.turnaround.selectedOption.name === turnaroundOption.name) {
              turnaroundOption = Object.assign({}, data.turnaround.selectedOption);
            }
            return turnaroundOption;
          });
        }
      });
    this._subscriptions$.push(subscription$);

    if (this.orderItem.price.finalTotal < this.skipSampleDefault) {
      this.turnaround.skipSample = true;
      this.currentSkipSampleStatus = SkipSampleStatus.lessThanDefault;
    }

    if (this.orderItem.price.finalTotal > this.skipSampleDefault && this.orderItem.price.finalTotal < this.skipSampleMandatory) {
      this.turnaround.skipSample = false;
      this.currentSkipSampleStatus = SkipSampleStatus.default;
    }

    if (this.orderItem.price.finalTotal > this.skipSampleMandatory) {
      this.turnaround.skipSample = false;
      this.currentSkipSampleStatus = SkipSampleStatus.mandatory;
      this.skipSampleDisabled = true;
    }

    subscription$ = this._store.pipe(select('shoppingCart')).subscribe((data: IShoppingCart) => {
      data.cartItems.forEach((cartItem) => {
        this.cartItems = this.cartItems.concat(cartItem.cartItemOrders);
      });
    });
    this._subscriptions$.push(subscription$);

    subscription$ = this._store.pipe(select('packagingOrder')).subscribe((data: IPackagingOrder) => {
      this._packagingOrder = data;
    });
    this._subscriptions$.push(subscription$);

    const quantity = VariationManager.getQuantityFromStack(this.orderItem.variationTypes);

    this._amp.logEvent('Navigation', {
      page: 'turnaround time',
      navigationStep: 6,
      category: this.orderItem.category.name,
      category_id: this.orderItem.category.id,
      productName: this.orderItem.product.name,
      product_id: this.orderItem.product.id,
      quantity: quantity
    });

    this.trackingService.sendAltoCloudData(['pageview', {
      location: location.pathname,
      title: 'Turnaround'
    }]);

    subscription$ = this._activatedRoute.queryParams.subscribe(params => {
      if (params && params['uid'] && params['index']) {
        this._editParams = params;
      } else if (this.cartItems.length !== 0) {
        let instanceOfCombinedShipping = 0;
        this.cartItems.forEach(cartItem => {
          if (cartItem.combinedWith) {
            instanceOfCombinedShipping++;
          }
        });
        this._editParams = {
          ...this._editParams,
          quoteId: this.cartItems[this.cartItems.length - 1].orderItem.quoteId,
          uid: this.cartItems[this.cartItems.length - 1].uid,
          index: this.cartItems.length - 1 - instanceOfCombinedShipping
        };
      }
    });
    this._subscriptions$.push(subscription$);
    this.loadedTat = true;

    if (this._editParams && this.cartItems.length === 0) {
      this._addToCart();
    }

    // initiate with standard delivery
    if (this.turnaround.selectedOption === undefined) {
      this.turnaround = {
        ...this.turnaround,
        selectedOption: this.turnaround.availableOptions[0]
      };
      this._store.dispatch(new UpdateTurnaround(this.turnaround));
      this._addToCart();
    }

    // find current cartItem delivery option
    if (this.cartItems.find(item => item.orderItem.quoteId === this.orderItem.quoteId)) {
      this.turnaround = {
        ...this.turnaround,
        selectedOption:
          this.cartItems.find(item => item.orderItem.quoteId === this.orderItem.quoteId).orderItem.turnaround.selectedOption ?
          this.cartItems.find(item => item.orderItem.quoteId === this.orderItem.quoteId).orderItem.turnaround.selectedOption :
          this.turnaround.availableOptions[0]
      };
      this._store.dispatch(new UpdateTurnaround(this.turnaround));
      this._addToCart(); // to update cart with selected Skip Sample value
    }
    window.dataLayer.push({ ecommerce: null }); // clear the ecommerce object

    // Step 1: Filter out duplicate cartItem.uid values
    const uniqueCartItems = this.cartItems.filter((cartItem, index, self) => 
      index === self.findIndex((ci) => ci.uid === cartItem.uid)
    );

    // Step 2: Map the filtered array to the desired format
    const items = uniqueCartItems.map(cartItem => ({
      item_name: cartItem.orderItem.product.name,
      item_id: cartItem.uid,
      price: cartItem.orderItem.price.pricePerUnit,
      item_brand: 'The/Studio',
      item_category: cartItem.orderItem.category.name,
      item_variant: cartItem.orderItem.product.variationTypes[0].name,
      quantity: cartItem.orderItem.price.quantity,
      item_list_id: cartItem.orderItem.category.id,
      item_list_name: cartItem.orderItem.category.name,
    }));
    console.log(items);
    window.dataLayer.push({
      'event': 'begin_checkout',
      'ecommerce': {
        'currencyCode': 'USD',
        'value': this.cartItems.reduce((acc, cartItem) => {
          return acc + cartItem.orderItem.price.finalTotal;
        }, 0),
        
        'items': items
      }
    });
    // If there are no items in shopping cart, redirect to home
    if (!this.cartItems.length) {
      this.router.navigate(['/']);
    }

    smoothscroll.polyfill();

    this.updateHyrosTrackingCode("https://182992.t.hyros.com/v1/lst/universal-script?ph=d7cb2d3b87ddc384cfc78b93c3a41292a992ed961977270eb515da16baa3f0fd&tag=!Payment-CS");
  }

  updateHyrosTrackingCode(src) {
    const hyros = document.querySelector("#hyros-scripts") as HTMLScriptElement;
    if(this.session.isSalesLoggedIn()) {
      hyros.src = '';
    }else{
      hyros.src = src;
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscriptions$.map(subscription$ => subscription$.unsubscribe());

    this.updateHyrosTrackingCode("https://182992.t.hyros.com/v1/lst/universal-script?ph=d7cb2d3b87ddc384cfc78b93c3a41292a992ed961977270eb515da16baa3f0fd&tag=!clicked");
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();

    const turnaroundHeight = this.turnaroundPage.nativeElement.offsetHeight;
    const subscription$ = this._pageHeightFix.tallestPageHeight.subscribe(summaryHeight => {
      if (turnaroundHeight < summaryHeight) {
        this._renderer.setStyle(this.turnaroundPage.nativeElement, 'min-height', summaryHeight + 'px');
      }
    });
    this._subscriptions$.push(subscription$);
  }
  // end of lifecycle hooks

  updateDeliveryOption(deliveryOption: IDeliveryOption) {
    this.selectedOption = deliveryOption;
    this.turnaround = {...this.turnaround, selectedOption: Object.assign({}, deliveryOption)};
    this._store.dispatch(new UpdateTurnaround(this.turnaround));

    this.trackingService.sendAltoCloudData(['record', 'delivery.type', deliveryOption.name]);
    if (deliveryOption.guaranteedDate) {
      const guaranteedDate = DateUtils.guaranteedDateFormat(new Date(deliveryOption.guaranteedDate));
      this.trackingService.sendAltoCloudData(['record', 'delivery.type', 'guaranteed', {
        date: guaranteedDate
      }]);
    }

    // Scroll to action buttons if guaranteed delivery is disabled
    if (this.orderItem.price.finalTotal < MINIMUM_GUARANTEE_VALUE) {
      const nextSectionElement = this.turnaroundPage.nativeElement;

      if (nextSectionElement) {
        const y = nextSectionElement.getBoundingClientRect().bottom + window.scrollY;
        window.scroll({ top: y, behavior: 'smooth' });
      }
    }
  }

  toggleSkipSample() {

    if (this.skipSampleDisabled) {
      return;
    }

    this.turnaround = {...this.turnaround, skipSample: !this.turnaround.skipSample};

    if (!this.turnaround.availableOptions.length) {
      for (let i = 0; i < this.turnaround.availableOptions.length; i++) {
        this.turnaround.availableOptions[i].guaranteedDate = null;
        this.turnaround.availableOptions[i].isGuaranteed = false;
      }
    }

    if (this.turnaround.selectedOption) {
      this.turnaround.selectedOption = {...this.turnaround.selectedOption, guaranteedDate: null};
      this.turnaround.selectedOption.isGuaranteed = false;
    }

    this._store.dispatch(new UpdateTurnaround(this.turnaround));
    this._addToCart();

    this._amp.logEvent('Turnaround Skip Sample', {
      checked: this.turnaround.skipSample,
      productId: this.orderItem.product.id,
    });

  }

  nextDisabled() {
    return this.turnaround.selectedOption === undefined ||
      (this.turnaround.selectedOption.isGuaranteed && !this.turnaround.selectedOption.guaranteedDate);
  }

  goToCart() {
    if (this.nextDisabled()) {
      this.tracking.sendAltoCloudData(['record', 'userStuck']);
      return;
    }

    // this._addToCart();
    this.router.navigate(['shop/cart']);
  }

  goToCheckout() {
    if (this.nextDisabled()) {
      this.tracking.sendAltoCloudData(['record', 'userStuck']);
      return;
    }

    this._addToCart();

    this.router.navigate(['shop/payment']);
  }

  checkItemInCart(): boolean {
    if (this._editParams && this._editParams['uid']) {
      const index = this.cartItems.findIndex(cartItem => cartItem.uid === this._editParams['uid']);
      return !!~index;
    }
    return false;
  }

  private _addToCart() {
    if (this.checkItemInCart()) {
      const cartItemOrder: ICartItemOrder = {
        uid: this._editParams['uid'],
        initialTurnaround: Object.assign({}, this.turnaround.selectedOption),
        orderItem: Object.assign({}, {
          ...this.orderItem,
          turnaround: {
            ...this.orderItem.turnaround,
            selectedOption: this.turnaround.selectedOption,
            skipSample: this.turnaround.skipSample
          },
        }),
        packagingOrder: Object.assign({}, this._packagingOrder)
      };

      this._store.dispatch(new UpdateCartItemOrder({ cartItemOrder, index: this._editParams['index'] }));
      this.trackingService.sendAltoCloudData(['record', 'product.updated', this.orderItem.product.name, {
        id: this.orderItem.product.id,
        name: this.orderItem.product.name,
        price: this.orderItem.price.finalTotal,
        category: this.orderItem.category.name,
        quantity: this.orderItem.price.quantity,
      }]);
    } else {
      const cartItemOrder: ICartItemOrder = {
        uid: this._editParams !== undefined ? this._editParams['uid'] : Utils.generateUid(),
        initialTurnaround: Object.assign({}, this.orderItem.turnaround.selectedOption),
        orderItem: Object.assign({}, this.orderItem),
        packagingOrder: Object.assign({}, this._packagingOrder)
      };
      const cartItem: ICartItem = {
        cartItemOrders: [cartItemOrder]
      };
      this._store.dispatch(new AddCartItem({ cartItem: cartItem }));
      // this.trackingService.gtagAction('add_to_cart', { cartItem: cartItem });

      this.trackingService.sendAltoCloudData(['record', 'product.added', this.orderItem.product.name, {
        id: this.orderItem.product.id,
        name: this.orderItem.product.name,
        price: this.orderItem.price.finalTotal,
        category: this.orderItem.category.name,
        quantity: this.orderItem.price.quantity,
      }]);
    }

    this._subscriptions$.map(subscription$ => subscription$.unsubscribe());
  }
}
