import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

// Types
import { IOrderItem } from '../../types/app-state/IOrderItem';
import { IPackagingOrder } from '../../types/app-state/IPackagingOrder';
import { IAppState } from '../../types/app-state/IAppState';
import { ISelectedVariation } from '../../types/app-state/ISelectedVariation';
import { ICustomQuantity } from '../../types/product/variations/ICustomQuantity';
import { ICustomSize } from '../../types/product/variations/ICustomSize';
import { ITurnaround } from '../../types/turnaround/ITurnaround';
import { IBundleQuantity } from '../../types/product/variations/IBundleQuantity';

// Types

/**
 * @author Liviu Dima
 */
@Injectable()
export class NavigatorService {

  private _orderItem$: Observable<IOrderItem> = this._store.select('orderItem');
  private _packagingOrder$: Observable<IPackagingOrder> = this._store.select('packagingOrder');

  constructor(
    private _store: Store<IAppState>,
    private _router: Router,
  ) { }

  /**
   * Navigate to specified path and trim params
   */
  async navigate(path: string, currentQueryParams: Params = {}) {
    const cartOrderParams = this.getCartOrderParams(currentQueryParams);

    const stateQueryParams = await this.generateStateQueryParams();

    const navigationQueryParams = { ...stateQueryParams, ...cartOrderParams };

    const newParams = Object.assign({}, navigationQueryParams);

    if (!currentQueryParams['reorder']) {
      switch (path) {

        case 'customize':
          delete newParams['selections_vt'];
          delete newParams['selections_sp'];
          delete newParams['delivery'];
          delete newParams['skipSample'];
          delete newParams['guaranteedDate'];
          break;

        case 'specialOptions':
          delete newParams['selections_sp'];
          delete newParams['delivery'];
          delete newParams['skipSample'];
          delete newParams['guaranteedDate'];
          break;

        case 'packaging':
        case 'packaging-size':
        case 'packaging-type':
        case 'packaging-option':
        case 'packaging-artwork':
        case 'packaging-review':
          delete newParams['delivery'];
          delete newParams['skipSample'];
          delete newParams['guaranteedDate'];
          break;

        case 'turnaround':
        case 'artwork':
          delete newParams['delivery'];
          delete newParams['skipSample'];
          delete newParams['guaranteedDate'];
          break;
      }
    }

    const orderItem = await this._orderItem$.pipe(take(1)).toPromise();

    if (path.indexOf('packaging') === 0 && path.includes('-', 9)) {
      const newPath = path.slice(10);
      this._router.navigate(
        ['category', orderItem.category.id, 'product', orderItem.product.id, 'packaging', newPath],
        { queryParams: newParams }
      );
    } else {
      this._router.navigate(['category', orderItem.category.id, 'product', orderItem.product.id, path], { queryParams: newParams });
    }
  }


  /**
   * Returns QueryParams that reflects the apps tate
   * @param orderItem (optional) -> for cart orders we will send the orderItem param to generate state
   */
  async generateStateQueryParams(_orderItem?: IOrderItem, _packagingOrder?: IPackagingOrder) {
    // if orders are sent as function params overwrite state orderItem & packagingOrder
    const orderItem = _orderItem ? _orderItem : await this._orderItem$.pipe(take(1)).toPromise();
    const packagingOrder = _packagingOrder ? _packagingOrder : await this._packagingOrder$.pipe(take(1)).toPromise();

    const customizeParams = this._getSelectedVariationsParams(orderItem.variationTypes, 'selections_vt', {
      c_qty: undefined,
      c_height: undefined,
      c_width: undefined,
      b_id: undefined,
      b_qty: undefined,
      selections_vt: undefined,
    });

    const specialOptionsParams = this._getSelectedVariationsParams(orderItem.specialOptions, 'selections_sp');

    const packagingParams = this._getSelectedVariationsParams(packagingOrder.packagingSelections, 'packagingSelections', {
      cp_height: undefined,
      cp_width: undefined,
      packagingSelections: undefined,
    });

    const tatParams = this._getUpdatedTatParams(orderItem.turnaround);

    const reorderQueryParams = {};
    if (orderItem.reorderId) {
      reorderQueryParams['reorderId'] = orderItem.reorderId;
    }
    if (orderItem.firstInquiry) {
      reorderQueryParams['firstInquiry'] = orderItem.firstInquiry;
    }
    if (orderItem.quoteId) {
      reorderQueryParams['quoteId'] = orderItem.quoteId;
    }

    return {
      ...customizeParams,
      ...specialOptionsParams,
      ...packagingParams,
      ...tatParams,
      ...reorderQueryParams,
      quoteId: orderItem.quoteId
    };
  }


  getCartOrderParams(queryParams: Params) {
    const newQueryParams = {};
    if (queryParams['uid']) {
      newQueryParams['uid'] = queryParams['uid'];
    }
    if (typeof queryParams['index'] !== 'undefined') {
      newQueryParams['index'] = queryParams['index'];
    }

    return newQueryParams;
  }

  /**
   * Generate query params for selected variations
   */
  private _getSelectedVariationsParams(selectedVariations: ISelectedVariation[], paramName: string, resetObj?: any) {
    let queryParams = {};

    const selectedVariationsIds = selectedVariations
      .filter(selectedVariation => !selectedVariation.variation.hasOwnProperty('customType'))
      .map(selectedVariation => selectedVariation.variation.id);

    // handle regular variations
    if (selectedVariationsIds.length) {
      const urlSafeSOs = (selectedVariationsIds.length > 1) ? selectedVariationsIds.join(',') : String(selectedVariationsIds);

      queryParams = {
        [paramName]: urlSafeSOs,
      };
    }

    const customOptions = selectedVariations.filter(chVar => chVar.variation.hasOwnProperty('customType'));

    // handle custom size and quantity
    if (customOptions.length) {

      for (let i = 0; i < customOptions.length; i++) {
        if (customOptions[i].variation.hasOwnProperty('quantityValue')) {

          queryParams = {
            ...queryParams, ...{
              c_qty: (customOptions[i].variation as ICustomQuantity).quantityValue
            }
          };
        } else if (customOptions[i].variation.hasOwnProperty('unitsSelected')) {

          queryParams = {
            ...queryParams, ...{
              b_id: (customOptions[i].variation as IBundleQuantity).bundleId,
              b_qty: (customOptions[i].variation as IBundleQuantity).unitsSelected
            }
          };
        } else {
          const customSizeParams = paramName === 'packagingSelections' ? {
            cp_height: (customOptions[i].variation as ICustomSize).height,
            cp_width: (customOptions[i].variation as ICustomSize).width
          } : {
              c_height: (customOptions[i].variation as ICustomSize).height,
              c_width: (customOptions[i].variation as ICustomSize).width
            };

          queryParams = { ...queryParams, ...customSizeParams };
        }
      }
    }

    return resetObj ? { ...resetObj, ...queryParams } : queryParams;
  }

  /**
   * Get turnaround queryParams from selected TAT in app state
   */
  private _getUpdatedTatParams(turnaround: ITurnaround) {

    if (!turnaround) {
      return {};
    }

    const queryParams = {
      skipSample: turnaround.skipSample,
    };

    if (turnaround.selectedOption) {
      queryParams['delivery'] = turnaround.selectedOption.name;
      queryParams['guaranteedDate'] = turnaround.selectedOption.guaranteedDate;
    }
    return queryParams;
  }
}
