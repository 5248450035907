<div #optionAnchor class="specialOption"
  [ngClass]="!specialOption?.required ? 
  {'expanded': expanded, 'closed': !collapseStatus, 'opened': collapseStatus } 
  : {'expanded': expanded, 'closed': collapseStatus, 'opened': !collapseStatus } ">
  <h4 (click)="collapseOption()">
    {{tabIndex}}. {{specialOption.name}}
    <ng-container *ngIf="!specialOption?.required">
      <span>(Optional)</span>
      <span class="accordion-arrow" [ngClass]="{'initial': collapseStatus, 'rotated': !collapseStatus}">&rsaquo;</span>
    </ng-container>
  </h4>
  <h5>Select one.</h5>

  <span *ngIf="specialOption.required && !hasSelection && showError" class="danger">
    Please select one of the following required options
  </span>

  <div class="specialOption__items single-select">
    <div *ngFor="let variation of getVariations();let i=index;" class="specialOption__style" [ngClass]="{
                'active': selectedSpecialOption === variation,
                'incompat': !isCompatible(variation)
             }">


      <div class="specialOption__style--image">
        <div (click)="chooseOption(variation)">
          <img alt="spImg" src="{{getThumbnail(variation)}}" />
        </div>
        <div class="specialOption__style--image-icon" diyGallery [images]="variation?.images"></div>
      </div>

      <div class="specialOption__style--wrap" (click)="chooseOption(variation)">

        <div class="specialOption__style--content">
          <div class="specialOption__style--contentWrap">
            <p class="specialOption__style--title">{{variation.name}}</p>
            <p class="specialOption__style--description" [innerHtml]="variation?.info ? variation.info : '' | safeHtml">
            </p>
          </div>
          <span class="specialOption--price">
            <span *ngIf="getSpecialOptionPrice(variation) > 0; else free">
              {{ getSpecialOptionPrice(variation) | currency:'USD' }}
            </span>

            <ng-template #free>
              <span>FREE</span>
            </ng-template>
          </span>
        </div>

        <div class="select-wrap">
          <input type="radio" id="{{specialOption?.dbname}}_{{i}}" [checked]="selectedSpecialOption === variation"
            [disabled]="!isCompatible(variation)" />
          <label class="button button--green" (click)="$event.preventDefault()"
            [class.btn-disabled]="!isCompatible(variation)" for="{{specialOption?.dbname}}_{{i}}"
            id="label_{{specialOption?.dbname}}_{{i}}">
            <span>Select</span>
          </label>
        </div>
      </div>
      <p *ngIf="!isCompatible(variation)" class="specialOption__style--incompatibility">
        <span class="alert--icon"></span>
        Incompatible with prior selection
      </p>
    </div>
  </div>

  <ng-container *ngIf="specialOption.variations.length > displayCount">
    <button class="see-more button--green" (click)="expandOption()">See
      <span *ngIf="expanded">Less</span>
      <span *ngIf="!expanded">More</span>
    </button>
  </ng-container>
</div>
