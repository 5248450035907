import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';

import { SessionService } from '../../utility/session.service';
import { QuoteService } from '../services/quote.service';

import { IQuote } from '../../types/quote/IQuote';

@Injectable()
export class IdentifyCustomerGuard implements CanDeactivate<boolean> {

  constructor(
    private _session: SessionService,
    private _quoteService: QuoteService,
  ) { }

  canDeactivate(component: any): boolean {
    const quote: IQuote = this._quoteService.getCurrentQuote();
    if (!quote && !this._session.isSpecialAdmin()) {
      return false;
    }

    return true;
  }
}
