import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

import { SessionService } from '../../utility/session.service';
import { QuoteService } from '../services/quote.service';
import { BundleProvider } from '../../core/api/BundleProvider';

import { IQuote, ICustomerInfo } from '../../types/quote/IQuote';
import { IAppState } from '../../types/app-state/IAppState';
import { ICartItemOrder } from '../../types/shopping-cart/ICartItem';
import { CampaignTrackingService } from '../services/campaign-tracking.service';

@Injectable()
export class AuthGuard implements CanActivate {
  returnUrl: string;

  private _shoppingCart$ = this._store.select('shoppingCart');

  bundleCheckStatus = false;

  /**
   * Class constructor
   */
  constructor(
    private router: Router,
    private _quoteService: QuoteService,
    private _store: Store<IAppState>,
    private _session: SessionService,
    private _bundleProvider: BundleProvider,
    private _campaignTracking: CampaignTrackingService,
  ) { }

  /**
   * @param route
   * @param state
   * @return {boolean}
   */
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const customerInfo: ICustomerInfo = this._quoteService.getCustomerInfo();
    this._campaignTracking.handleCampaignTracking(route.queryParams);
    // if the user is a sales rep and no customerInfo cookie is present
    // redirect to identify customer page
    if (this._session.isSalesLoggedIn()) {
      if (!customerInfo) {
        this.router.navigate(['customer']);
        return false;
      }
      return true;
    }

    if (!environment.enableLeadCapture) {
      return true;
    }

    const prodId = Number(route.params['productId']);
    const catId = Number(route.params['categoryId']);
    const quoteId = Number(route.queryParams['quoteId']);

    const reorderId = route.queryParams['reorderId'];
    const firstInquiry = route.queryParams['firstInquiry'];

    // if there's not quote data saved in the cookie, redirect to login
    if (!await this.quoteGuard(quoteId, route, Number(prodId))) {
      if (prodId && catId) {
        this.returnUrl = state.url;
        this.router.navigate(
          ['/category', catId, 'product', prodId, 'welcome'],
          { queryParams: { firstInquiry: firstInquiry, reorderId: reorderId } }
        );
      } else {
        this.router.navigate(['/']);
      }
      return false;
    }

    // prevent firing the api request on all pages
    if (!this.bundleCheckStatus) {
      // if there is a quote, check for bundle promotion
      const hasBundle = await this._bundleProvider.checkBundle(Number(prodId), customerInfo.email);

      // mark request as checked
      this.bundleCheckStatus = true;
      // if the user is not logged in,and has a bundle
      if (!this._session.isLoggedIn() && hasBundle) {

        // redirect priority 0: bundle login
        this.returnUrl = state.url;
        this.router.navigate(['/shop/bundle-login']);
        return;
      }
    }


    return true;
  }

  /**
   * @param prodId
   * @return {boolean}
   */
  async quoteGuard(quoteId: number, route: ActivatedRouteSnapshot, prodId: number): Promise<boolean> {
    const quotes: IQuote[] = this._quoteService.getQuotes();

    const customerInfo: ICustomerInfo = this._quoteService.getCustomerInfo();

    // if there are no quotes return false
    if (!customerInfo || !quotes) {
      return false;
    }

    const urlQuote = quotes.find(quote => Number(quote.quoteId) === Number(quoteId));
    // if we find that quoteId from url is in quotes return true
    if (urlQuote && urlQuote.productId === prodId) {
      return true;
    }

    // if we have product id in URL check for differences between quotes and cart
    // and if we have a quote for this product that is not in cart
    // then update that quote in the URL

    let productQuote;
    if (prodId) {
      // Get all cart orders
      const cartOrders = [];
      const shoppingCart = await this._shoppingCart$.pipe(take(1)).toPromise();
      for (const cartItem of shoppingCart.cartItems) {
        for (const cartOrder of cartItem.cartItemOrders) {
          cartOrders.push(cartOrder);
        }
      }

      const productCartOrdersQuoteIds = cartOrders
        .filter((order: ICartItemOrder) => Number(order.orderItem.product.id) === prodId)
        .map((order: ICartItemOrder) => order.orderItem.quoteId);

      productQuote = quotes
        .filter((quote: IQuote) => quote.productId === prodId)
        .find((quote: IQuote) => !productCartOrdersQuoteIds.includes(quote.quoteId));
    }

    // If we found a hanging quote that is not in cart for the current product
    // Continue with that quote
    let newQuote;
    if (!productQuote) {
      newQuote = await this._quoteService.createQuote(customerInfo, prodId);
    } else {
      newQuote = productQuote;
    }

    let url = '';

    if (route.url.length && route.url.length >= 4) {

      url = this
        .router
        .createUrlTree(
          ['/category', route.params.categoryId, 'product', route.params.productId, route.url[4].path],
          { queryParams: { ...route.queryParams, quoteId: newQuote.quoteId } }
        )
        .toString();

    } else {
      url = this
        .router
        .createUrlTree(
          ['/category', route.params.categoryId, 'product', route.params.productId],
          { queryParams: { ...route.queryParams, quoteId: newQuote.quoteId } }
        )
        .toString();
    }


    this.router.navigateByUrl(url);
    return true;
  }
}

