// External
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { Store } from "@ngrx/store";

// Base component
import { IdleBaseComponent } from "../../core/components/idleTracking/idle.component";

// Services
import { DraftApi } from "../../core/api/draftApi";
import { AmplitudeService } from "../../core/services/amplitude";
import { CookieService } from "../../core/services/cookie.service";
import { DraftService } from "../../core/services/draft.service";
import { CampaignTrackingService } from "../../core/services/campaign-tracking.service";
import { TrackingService } from "../../core/services/tracking";
import { SessionService } from "../../utility/session.service";

// State Management
import {
  ClearProduct,
  ClearCategory,
  RemoveArtwork,
} from "../../state-manager/order-item/order-item.actions";

// Types
import { IOrderItem } from "../../types/app-state/IOrderItem";
import { ICustomerInfo } from "../../types/quote/IQuote";
import { IDraft } from "../../types/draft/IDraft";
import { ProductOrderSteps } from "../../types/step/IStep";
import { IAppState } from "../../types/app-state/IAppState";

/**
 * @author Liviu Dima
 */
@Component({
  selector: "diy-draft",
  templateUrl: "./draft.component.html",
  styleUrls: ["./draft.component.scss"],
})
export class DraftComponent extends IdleBaseComponent implements OnInit {
  productId: string;
  categoryId: string;
  isLoading = true;
  drafts: IDraft[];
  currentStep: number;

  private _orderItem$: Observable<IOrderItem> =
    this._activatedRoute.snapshot.data.orderItem;
  private _customerInfo: ICustomerInfo;

  constructor(
    protected tracking: TrackingService,
    protected router: Router,
    protected session: SessionService,
    protected cookies: CookieService,
    private _activatedRoute: ActivatedRoute,
    private _amp: AmplitudeService,
    private _store: Store<IAppState>,
    private _draftApi: DraftApi,
    private _draftService: DraftService,
    private _location: Location,
    private _campaignTrackingService: CampaignTrackingService
  ) {
    super(tracking, router, session, cookies);

    this._campaignTrackingService.handleCampaignTracking(
      this._activatedRoute.snapshot.queryParams
    );
  }

  async ngOnInit() {
    // Define current step for step-tracker
    this.currentStep = ProductOrderSteps.draft;

    this.tracking.sendAltoCloudData([
      "pageview",
      {
        location: location.pathname,
        title: "Draft",
      },
    ]);

    const orderItem = await this._orderItem$.pipe(take(1)).toPromise();

    const skip = /true/i.test(
      this._activatedRoute.snapshot.queryParams["skipDraft"]
    );
    if (skip) {
      return this.router.navigate(
        [
          "/category",
          orderItem.category.id,
          "product",
          orderItem.product.id,
          "customize",
        ],
        { queryParamsHandling: "merge" }
      );
    }

    if (orderItem) {
      this._amp.logEvent("Navigation", {
        page: "draft",
        navigationStep: 2.5,
        category: orderItem.category.name,
        category_id: orderItem.category.id,
        productName: orderItem.product.name,
        product_id: orderItem.product.id,
      });
    }

    this._customerInfo = this.cookies.getJson("customerInfo");

    this.drafts =
      this._customerInfo && this._customerInfo.email
        ? await this._draftService.getDrafts(this._customerInfo.email) // if we have a customer email, get drafts for it.
        : [];

    // if using skipDraft url param, skip redirecting
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const skipDraft = urlParams.get("skipDraft");
    if (!skipDraft) {
      // if there was no draft in cookies or from server data go to artwork page
      if (!this.drafts.length) {
        return this.router.navigate(
          [
            "/category",
            orderItem.category.id,
            "product",
            orderItem.product.id,
            "customize",
          ],
          { queryParamsHandling: "merge" }
        );
      }
    }
    this.isLoading = false;
  }

  async resumeDraft(draft: IDraft) {
    const orderItem = await this._orderItem$.pipe(take(1)).toPromise();

    this._amp.logEvent("Draft", {
      option: "Resume",
      category: orderItem.category.name,
      category_id: orderItem.category.id,
      productName: orderItem.product.name,
      product_id: orderItem.product.id,
    });

    this._store.dispatch(new ClearProduct());
    this._store.dispatch(new ClearCategory());

    this._draftService.loadArtworkFromDraft(draft);

    this.router.navigate(draft.urlParams.urlData, {
      queryParams: draft.urlParams.queryParams,
    });
  }

  async startFresh() {
    const orderItem = await this._orderItem$.pipe(take(1)).toPromise();

    this._amp.logEvent("Draft", {
      option: "Discard",
      category: orderItem.category.name,
      category_id: orderItem.category.id,
      productName: orderItem.product.name,
      product_id: orderItem.product.id,
    });

    this._store.dispatch(new RemoveArtwork());

    sessionStorage.setItem("discardedDraft", "true");

    await this._draftApi.discardDrafts(this._customerInfo.email);

    this.cookies.delete("hasDraft");

    const skip = /true/i.test(
      this._activatedRoute.snapshot.queryParams["skip"]
    );

    if (skip) {
      this._location.back();
    } else {
      this.router.navigate(
        [
          "/category",
          orderItem.category.id,
          "product",
          orderItem.product.id,
          "customize",
        ],
        { queryParams: this._activatedRoute.snapshot.queryParams }
      );
    }
  }
}
