import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { SalesRepProductFlowApi } from '../../api/salesRepProductFlowApi';
import { SessionService } from '../../../utility/session.service';
import { OrderMessengerService } from '../../../core/services/order-messenger';
import { QuoteService } from '../../services/quote.service';

import { IQuoteData } from '../../../types/api/IToken';
import { IQuote } from '../../../types/quote/IQuote';
import { UserRole } from '../../../types/user/IUser';

const QUOTE = 'diy_quote';
const NOTES = 'diy_scripts';

@Component({
  selector: 'diy-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit, OnDestroy {

  quote: IQuoteData;

  private _subscriptions$: Subscription[] = [];

  constructor(
    private _session: SessionService,
    private _oms: OrderMessengerService,
    private _quoteService: QuoteService,
    private _salesRepProductFlowApi: SalesRepProductFlowApi,
  ) { }

  async ngOnInit() {

    const subscription$ = this._oms.getStep().subscribe(async (step) => {

      this.quote = await this._getSalesData();
      if (this.quote && this.quote.scripts && this.quote.scripts.length) {
        sessionStorage.setItem(NOTES, JSON.stringify(this.quote.scripts));
      }
    });
    this._subscriptions$.push(subscription$);
  }

  ngOnDestroy(): void {
    this._subscriptions$.map(subscription$ => subscription$.unsubscribe());
  }

  private async _getSalesData(): Promise<any> {
    if (this._session.isSpecialAdmin() || !this._session.isLoggedIn() || !this._session.isGranted(UserRole.SALES)) {
      this.quote = null;
      sessionStorage.removeItem(QUOTE);
      return;
    }
    try {
      const quoteDataString = sessionStorage.getItem(QUOTE);
      if (quoteDataString) {
        return JSON.parse(quoteDataString);
      } else {
        const quote: IQuote = this._quoteService.getCurrentQuote();
        if (quote && quote.quoteId) {
          const quoteData = await this._salesRepProductFlowApi.getSalesData(quote.quoteId);
          if (quoteData && quoteData.success) {
            sessionStorage.setItem(QUOTE, JSON.stringify(quoteData));
            return quoteData;
          }
        }
      }
    } catch (err) {
      return;
    }
  }
}
