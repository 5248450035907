import { PackagingOrderActionTypes, PackagingOrderActionsUnion } from './packaging-order.actions';
import { IArtwork } from '../../types/artwork/IArtwork';
import { IPackagingOrder } from '../../types/app-state/IPackagingOrder';
import { IPrice } from '../../types/product/IPrice';
import { OrderManagerService } from '../../core/services/order-manager.service';
import { PriceCalculatorService } from '../../core/services/price-calculator';

const initialArtwork: IArtwork = {
  files: undefined,
  description: ''
};

const initialPrice: IPrice = {
  pricePerUnit: 0,
  units: 0,
  discountedTotal: 0,
  setupFee: 0,
  shippingTotal: 0,
  customizeTotal: 0,
  guaranteedFee: 0,
  specialOptionsTotal: 0,
  subTotal: 0,
  finalTotal: 0,
  displayDiscount: 0,
  discountAmount: 0,
  quantity: 0,
  productDiscount: 0,
  promotionalDiscount: 0,
  promotionalDiscountValue: 0
};

const initialState: IPackagingOrder = {
  product: undefined,

  artwork: initialArtwork,

  price: initialPrice,

  packagingSelections: [],
  incompatibilities: []
};

export function reducer(state: IPackagingOrder = initialState, action: PackagingOrderActionsUnion): IPackagingOrder {
  switch (action.type) {

    case PackagingOrderActionTypes.SelectProduct: {
      return { ...state, product: action.payload };
    }

    case PackagingOrderActionTypes.AddPackagingArtwork: {
      return { ...state, artwork: action.payload };
    }

    case PackagingOrderActionTypes.SetPackagingOrder: {
      return action.payload;
    }

    case PackagingOrderActionTypes.ResetPackagingOrder: {
      return {
        product: undefined,

        artwork: {
          files: undefined,
          description: ''
        },

        price: {
          pricePerUnit: 0,
          units: 0,
          discountedTotal: 0,
          setupFee: 0,
          shippingTotal: 0,
          customizeTotal: 0,
          guaranteedFee: 0,
          specialOptionsTotal: 0,
          subTotal: 0,
          finalTotal: 0,
          displayDiscount: 0,
          discountAmount: 0,
          quantity: 0,
          productDiscount: 0,
          promotionalDiscount: 0,
          promotionalDiscountValue: 0
        },
        packagingSelections: [],
        incompatibilities: []
      } as IPackagingOrder;
    }

    case PackagingOrderActionTypes.RemovePackagingArtwork: {
      const newArtwork: IArtwork = {
        files: undefined,
        description: (state.artwork) ? state.artwork.description : ''
      };

      return { ...state, artwork: newArtwork };
    }

    case PackagingOrderActionTypes.AddPackagingVariation: {
      const newState = {
        ...state,
        packagingSelections: OrderManagerService.addVariation(state.packagingSelections, action.payload)
      };
      newState.incompatibilities = OrderManagerService.getIncompatibilities([newState.packagingSelections]);

      const pricingTableSelected = OrderManagerService.getPricingTableSelections(newState.packagingSelections, state.product.pricingTable);

      const ptIds = pricingTableSelected.map(pts => pts.vtID);
      const specialOptionsSelected = newState.packagingSelections.filter(selected => !ptIds.includes(selected.vtID));

      try {
        newState.price = PriceCalculatorService.redoPrice(
          newState.product,
          newState.price,
          pricingTableSelected,
          specialOptionsSelected
        );
      } catch (err) {
        newState.price = Object.assign({}, initialPrice);
      }

      return newState;
    }

    case PackagingOrderActionTypes.RemovePackagingVariation: {
      const newState = {
        ...state,
        packagingSelections: OrderManagerService.removeVariation(state.packagingSelections, action.payload)
      };
      newState.incompatibilities = OrderManagerService.getIncompatibilities([newState.packagingSelections]);

      const pricingTableSelected = OrderManagerService.getPricingTableSelections(newState.packagingSelections, state.product.pricingTable);

      const ptIds = pricingTableSelected.map(pts => pts.vtID);
      const specialOptionsSelected = newState.packagingSelections.filter(selected => !ptIds.includes(selected.vtID));

      try {
        newState.price = PriceCalculatorService.redoPrice(
          newState.product,
          newState.price,
          pricingTableSelected,
          specialOptionsSelected
        );
      } catch (err) {
        newState.price = Object.assign({}, initialPrice);
      }

      return newState;
    }

    case PackagingOrderActionTypes.ReplacePackagingVariations: {

      const newState = {
        ...state,
        packagingSelections: action.payload
      };

      newState.incompatibilities = OrderManagerService.getIncompatibilities([newState.packagingSelections]);

      const pricingTableSelected = OrderManagerService.getPricingTableSelections(newState.packagingSelections, state.product.pricingTable);

      const ptIds = pricingTableSelected.map(pts => pts.vtID);
      const specialOptionsSelected = newState.packagingSelections.filter(selected => !ptIds.includes(selected.vtID));

      try {
        newState.price = PriceCalculatorService.redoPrice(
          newState.product,
          newState.price,
          pricingTableSelected,
          specialOptionsSelected
        );
      } catch (err) {
        newState.price = Object.assign({}, initialPrice);
      }

      return newState;
    }

    default: {
      return state;
    }
  }
}
