import { Action } from '@ngrx/store';

import { IBundle } from '../../types/bundle/IBundle';

export enum BundleActionTypes {
  SetBundleData = '[AppState] Set Bundle Data',
  ClearBundleData = '[AppState] Clear Bundle Data',
  SetBundleInvoiceId = '[AppState] Set Bundle Invoice Id',
}

export class SetBundleData implements Action {
  readonly type = BundleActionTypes.SetBundleData;
  constructor(public payload: IBundle) { }
}

export class ClearBundleData implements Action {
  readonly type = BundleActionTypes.ClearBundleData;
  constructor() { }
}

export class SetBundleInvoiceId implements Action {
  readonly type = BundleActionTypes.SetBundleInvoiceId;
  constructor(public payload: string) { }
}

export type BundleActionUnion = SetBundleData | ClearBundleData | SetBundleInvoiceId;
