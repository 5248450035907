import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Subscription } from 'rxjs';

import { VTBaseComponent } from '../VTBase.component';

// service
import { AmplitudeService } from '../../../../core/services/amplitude';
import { VariationManager } from '../../../../core/services/variation-manager.service';
import { AddVariation } from '../../../../state-manager/order-item/order-item.actions';

// types
import { IAppState } from '../../../../types/app-state/IAppState';
import { IOrderItem } from '../../../../types/app-state/IOrderItem';
import { IBundleQuantity } from '../../../../types/product/variations/IBundleQuantity';

@Component({
  selector: 'diy-bundle-quantity',
  templateUrl: './bundle-quantity.component.html',
  styleUrls: ['../../customize.component.scss']
})
export class BundleQuantityComponent extends VTBaseComponent implements OnInit {

  productName = '';
  customQtyEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  customQtyGroup: FormGroup;
  popUpState = false;
  remainingBundleUnits = 0;

  constructor(
    protected _amp: AmplitudeService,
    private _formBuilder: FormBuilder,
    protected _store: Store<IAppState>
  ) {
    super(_amp, _store);
    this.customQtyGroup = this._formBuilder.group({
      qty: ['']
    });
  }

  ngOnInit() {

    const storeSub = this._store.pipe(select('orderItem'))
      .subscribe((orderItem: IOrderItem) => {

        this.orderItem = orderItem;
        const selected = orderItem.variationTypes;
        this.productName = orderItem.product.name;
        this.bundleItem = orderItem.bundleItem;

        if (selected) {
          const selectedVariation = selected.find(sel => sel.vtName === 'Quantity');
          if (selectedVariation) {
            this.currentlySelected = selectedVariation;
          }
        }
      });

    this._subscriptions$.push(storeSub);
    this._subscriptions$.push(this._customQtyValidation$());

    this.selectBundle();
  }

  selectBundle() {
    this.customQtyEnabled.next(false);
    const bundleVariation = VariationManager.createBundleQty(this.bundleItem, this.variationType);

    this._logQty(bundleVariation);

    this._store.dispatch(new AddVariation(bundleVariation));
  }

  selectCustomQuantity() {
    this.customQtyEnabled.next(true);
    const customQuantity = Number(this.customQtyGroup.controls['qty'].value);

    this.removeVariationType();
    if (this.customQtyGroup.controls['qty'].valid && customQuantity > 0) {

      const bundleVariation = VariationManager.createBundleQty(this.bundleItem, this.variationType, customQuantity);

      this._logQty(bundleVariation);

      this._store.dispatch(new AddVariation(bundleVariation));
    }
  }

  getCustomQuantityLabel(): string {

    if (!this.currentlySelected) {
      return '';
    }

    const selectedVar = this.currentlySelected.variation as IBundleQuantity;
    const creditDiff = this.getCreditDiff();

    if (creditDiff <= 0) {
      return '';
    }

    const price = this.orderItem.price.pricePerUnit;
    return '= ' + selectedVar.unitsAvailable + ' credit balance + '
      + creditDiff + ' quantity at $' + price.toFixed(2) + ' each';
  }

  getCreditDiff(): number {
    if (!this.currentlySelected) {
      return 0;
    }
    const selectedVar = this.currentlySelected.variation as IBundleQuantity;
    return selectedVar.unitsSelected - selectedVar.unitsAvailable;
  }

  private _customQtyValidation$(): Subscription {
    return this.customQtyEnabled.subscribe(value => {

      if (this.bundleItem) {

        this.customQtyGroup.controls['qty'].clearValidators();
        if (value) {
          this.customQtyGroup.controls['qty'].setValidators([
            Validators.min(this.bundleItem.quantity),
            Validators.required
          ]);
          this.customQtyGroup.enable();
        } else {
          this.customQtyGroup.controls['qty'].setValidators([
            Validators.min(this.bundleItem.quantity),
          ]);
        }
        this.customQtyGroup.controls['qty'].updateValueAndValidity();
      }
    });
  }

}
