<div class="qty-banner">
  Additional {{orderItem.product.productDiscount }}% OFF • LIMITED TIME ONLY
</div>
<div class="variation">
  <div class="variation__title">
    <p>You have {{bundleItem.quantity}} available credits available for {{productName}}.</p>
    <div class="variation__info">
      <button>i</button>
      <span>{{variationType.info}}</span>
    </div>
  </div>

  <p class="variation__alert">Oops! You forgot to select quantity. This step is required.</p>

  <div>

    <div class="variation__item">
      <label>
        <input type="radio" name="bundleSelector" (change)="selectBundle()"
               [checked]="!(customQtyEnabled | async)" />
        <span class="variation__item--quantity">
          Use credit balance and order {{bundleItem.quantity}} quantity with no
          additional cost</span>
      </label>
    </div>
  </div>

  <div class="or-brake">Or</div>

  <div class="variation__item">
    <label>
      <input type="radio" name="bundleSelector" [checked]="(customQtyEnabled | async)"
             (change)="selectCustomQuantity()" />
      <span>Order more quantity than credit balance</span>
    </label>

    <div class="variation__custom--quantity" [formGroup]="customQtyGroup">
      <input id="customQty" type="text" formControlName="qty" (input)="selectCustomQuantity()"
             (focus)="selectCustomQuantity()" placeholder="Units" />
      <!-- <label for="customQty">Units</label> -->
      <span *ngIf="getCreditDiff() > 0" class="extra-cost-label">
        {{getCustomQuantityLabel()}}
      </span>
    </div>
    <div class="min-order">
      * Minimum order amount is {{bundleItem.quantity}} quantity
    </div>
  </div>
</div>
