<div class="row">

  <div class="col">

    <div class="flex-row">
      <div class="flex-row">
        <p>{{summaryItem?.product?.name}}</p>
        <p>
          {{summaryItem?.product?.price | currency:'USD':'symbol':'1.0-2'}}
        </p>
      </div>

      <div (click)="toggle()" class="indicator">
        <span class="accordion-arrow" [ngClass]="{'initial': isCollapsed, 'rotated': !isCollapsed}">&rsaquo;</span>
      </div>
    </div>

    <div [hidden]="isCollapsed">

      <div class="my-3" *ngFor="let option of summaryItem?.product?.options">
        <div class="flex-row">
          <p>{{option.name}}</p>
          <p>{{option.price | currency:'USD':'symbol':'1.0-2'}}</p>
        </div>

        <div *ngIf="option.selections?.length">
          <p *ngFor="let selection of option.selections">
            {{selection.name}}
          </p>
        </div>
      </div>

    </div>

    <!-- Savings -->
    <div class="flex-row text-danger" *ngIf="summaryItem?.product?.savings">
      <p>Savings:</p>
      <p>-{{summaryItem?.product?.savings | currency:'USD':'symbol':'1.0-2'}}</p>
    </div>

    <!-- Promo code savings -->
    <div *ngIf="summaryItem?.product?.voucher; let voucher" class="flex-row text-danger">
      <p>Promo Code:</p>

      <p>
        <span *ngIf="voucher.type === discountTypes.PERCENT; else amountVoucher">
          {{voucher.code}}
        </span>

        <ng-template #amountVoucher>
          <span>
            -{{(voucher.discount) | currency:'USD':'symbol':'1.0-2'}}
          </span>
        </ng-template>
      </p>
    </div>

    <!-- Total -->
    <div class="flex-row font-weight-bold mb-3">
      <p>Item total:</p>
      <p>{{summaryItem?.total | currency:'USD':'symbol':'1.0-2'}}</p>
    </div>

    <label class="text-primary d-md-none" (click)="toggle()">
      <small *ngIf="!isCollapsed">View less</small>
      <small *ngIf="isCollapsed">View more</small>
    </label>

  </div>
</div>
<hr>
