// External
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Environment
import { environment } from '../../../../environments/environment';

// Services
import { DataTransformerService } from '../../../core/api/data-transformer.service';

// Types
import { IProduct } from '../../../types/product/IProduct';

/**
 * The requests made through this api
 * should hit cached json files
 * @author Liviu Dima
 */
@Injectable()
export class PackagingProvider {

  constructor(
    private _http: HttpClient
  ) { }

  private routes = {
    product: environment.baseUrl + environment.cacheDir + 'products/'
  };

  /**
   * Get Product Info (with variations / options / turnarounds
   */
  async getProduct(productId: number): Promise<IProduct> {
    try {
      const res = await this._http.get(this.routes.product + productId + '.json').toPromise();
      return DataTransformerService.createProduct(res);
    } catch (err) {
      console.error(err);
    }
  }

}
