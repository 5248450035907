import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

import { ComStr } from './ComStr';
import { CookieService } from './cookie.service';

@Injectable()
export class CampaignTrackingService {

  constructor(
    private _cookieService: CookieService,
  ) { }

  handleCampaignTracking(queryParams: Params) {
    const regex = RegExp(ComStr.UTM_REGEX);
    const utm = {};

    for (const key in queryParams) {
      if (queryParams.hasOwnProperty(key) && regex.test(key)) {
        utm[key] = queryParams[key];
      }
    }

    if (Object.keys(utm).length) {
      this._cookieService.setJson('orderTracking', utm, 1);
    }
  }

}
