import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { VTBaseComponent } from '../VTBase.component';

// services
import { AmplitudeService } from '../../../../core/services/amplitude';

// types
import { IOrderItem } from '../../../../types/app-state/IOrderItem';
import { IVariation } from '../../../../types/product/variations/IVariation';
import { IAppState } from '../../../../types/app-state/IAppState';
import { IBundleItem } from '../../../../types/bundle/IBundle';

@Component({
  selector: 'diy-style',
  templateUrl: 'style.component.html',
  styleUrls: ['../../customize.component.scss']
})
export class StyleComponent extends VTBaseComponent implements OnInit {

  constructor(protected _amp: AmplitudeService, protected _store: Store<IAppState>, ) {
    super(_amp, _store);
  }

  ngOnInit() {
    const storeSub = this._store.pipe(select('orderItem'))
      .subscribe((orderItem: IOrderItem) => {
        this.orderItem = orderItem;
        const selected = orderItem.variationTypes;

        this._handleBundleItem(orderItem.bundleItem);

        if (selected) {
          this.currentlySelected = selected.find(_selected => _selected.vtID === this.variationType.id);
        }
      });

    this._subscriptions$.push(storeSub);
  }

  selectStyle(variation: IVariation) {
    if (this.currentlySelected && this.currentlySelected.variation.id === variation.id) {
      this.removeVariationType();
    } else {
      this.chooseVariation(variation);
    }
  }

  isChecked(variation: IVariation): boolean {

    if (!this.currentlySelected) {
      return false;
    }

    return this.currentlySelected.variation.id === variation.id;
  }

  private _handleBundleItem(newBundleItemState: IBundleItem) {

    // only execute when changes to bundleItem appear
    if (this.bundleItem !== newBundleItemState) {

      this.removeVariationType();
      this.bundleItem = newBundleItemState;

      if (this.bundleItem) {
        this.bundleVariation = this.variationType.variations
          .find(variation => !!~newBundleItemState.variations.indexOf(variation.id));

        this.chooseVariation(this.bundleVariation);
      }
    }
  }
}
