import { Injectable } from '@angular/core';

// Types
import { ISelectedVariation } from '../../types/app-state/ISelectedVariation';
import { IPricingTable } from '../../types/product/IPricingTable';

@Injectable()
export class OrderManagerService {

  // please not not combine with add special options again.
  public static addVariation(selectedVariations: ISelectedVariation[] = [], newVar: ISelectedVariation): ISelectedVariation[] {
    const newVariations = [...selectedVariations];

    // pricing table variation types are single select
    // Check by variation type ID, IGNORE variation ids because customSize, customQuantity and bundleQuantity do NOT have ids
    const position = newVariations.findIndex(arrElem => {
      return arrElem.vtID === newVar.vtID;
    });

    // first check if the variation isn't already added
    if (position > -1) {
      // if another variation of the same variation type exists, replace it
      newVariations.splice(position, 1, newVar);
    } else {
      // if it is the first element
      newVariations.push(newVar);
    }

    return newVariations;
  }

  public static removeVariation(chosenVariations: ISelectedVariation[], chVar: ISelectedVariation): ISelectedVariation[] {
    const newVariations = [...chosenVariations];

    const position = newVariations.findIndex(arrElem => {
      return arrElem.vtID === chVar.vtID;
    });

    // first check if the variation isn't already added
    if (position > -1) {
      // if another variation of the same variation type exists, replace it
      newVariations.splice(position, 1);
    }

    return newVariations;
  }

  public static addSpecialOption(chosenVariations: ISelectedVariation[], chVar: ISelectedVariation): ISelectedVariation[] {
    const newVariations = [...chosenVariations];

    const position = newVariations.findIndex(arrElem => {
      if (chVar.multiple) {
        // check both by variation id whether it exists
        return (arrElem.vtID === chVar.vtID && arrElem.variation.id === chVar.variation.id);
      } else {
        return (arrElem.vtID === chVar.vtID);
      }
    });

    // first check if the variation isn't already added
    if (position > -1) {

      if (chVar.multiple) {
        // multiple select options get pushed directly
        newVariations.push(chVar);
      } else {
        // if another variation of the same variation type exists, replace it
        newVariations.splice(position, 1, chVar);
      }

    } else {
      // if it is a new element
      newVariations.push(chVar);
    }

    return newVariations;
  }

  public static removeSpecialOption(chosenVariations: ISelectedVariation[], chVar: ISelectedVariation): ISelectedVariation[] {
    const newVariations = [...chosenVariations];

    const position = newVariations.findIndex(arrElem => {
      if (chVar.multiple) {
        // check both by variation id whether it exists
        return (arrElem.vtID === chVar.vtID && arrElem.variation.id === chVar.variation.id);
      } else {
        return (arrElem.vtID === chVar.vtID);
      }
    });

    newVariations.splice(position, 1);
    return newVariations;

  }

  public static getPricingTableSelections(selectionStack: ISelectedVariation[], pricingTable: IPricingTable): ISelectedVariation[] {
    const row = pricingTable.rows[0];
    const matchingSelected = selectionStack.filter(selected => row.hasOwnProperty(selected.vtDBName));

    return matchingSelected;
  }


  /**
   * Get an array of numeric ids incompatible with current selection
   */
  public static getIncompatibilities(selectedVariationsStack: ISelectedVariation[][]): number[] {
    let incompatibilities = [];

    for (const selectedVariations of selectedVariationsStack) {
      for (const selectedVariation of selectedVariations) {
        if (selectedVariation.variation.incompatibilities.length) {
          incompatibilities = incompatibilities.concat(selectedVariation.variation.incompatibilities);
        }
      }
    }

    return incompatibilities;
  }
}
