import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// Services-
import { SalesRepProductFlowApi } from '../../core/api/salesRepProductFlowApi';
import { SessionService } from '../../utility/session.service';
import { OrderMessengerService } from '../../core/services/order-messenger';
import { QuoteService } from '../../core/services/quote.service';

// Types
import { IAccessPayload } from '../../types/api/IToken';
import { IQuote } from '../../types/quote/IQuote';

/**
 * @author Andrei Diaconu
 */
@Component({
  templateUrl: './authorization.component.html',
  styleUrls: ['./authorization.component.scss']
})
export class AuthorizationComponent implements OnInit {
  constructor(
    private _oms: OrderMessengerService,
    private _salesRepProductFlowApi: SalesRepProductFlowApi,
    private _route: ActivatedRoute,
    private _router: Router,
    private _session: SessionService,
    private _quoteService: QuoteService,
  ) {
    this._oms.updateStep(0);
  }

  ngOnInit() {
    this._route.params.subscribe(async (params) => {
      if (params.token) {
        await this._validateToken(params.token);
      }
    });
  }

  private async _validateToken(authToken: string) {
    try {
      if (authToken) {
        const accessPayload: IAccessPayload = await this._salesRepProductFlowApi.validateAuthToken(authToken);
        if (accessPayload && accessPayload.token && accessPayload.userData) {
          sessionStorage.setItem('discardedDraft', 'true');
          this._session.setSession(accessPayload.token, accessPayload.userData, true);
          if (accessPayload.redirectUrl) {
            this._router.navigateByUrl(accessPayload.redirectUrl);
            return;
          }
          const quote: IQuote = this._quoteService.getCurrentQuote();
          if (!quote && !this._session.isSpecialAdmin()) {
            this._router.navigate(['customer']);
            return;
          }
        }
      }
      this._router.navigate(['/']);
    } catch (err) {
      this._router.navigate(['/']);
      console.error(err);
    }
  }

}
