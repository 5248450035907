<form [formGroup]="form" class="payment__item" id="payment-block">
  <div *ngIf="errorMessage" class="card-form__danger">
    <p>{{ errorMessage }}</p>
  </div>
  <p class="payment__item--title">
    Payment
    <button *ngIf="showCardForm && customerCardData?.length" class="simple-green-btn cancel-form" (click)="toggleCardForm(false)">Cancel</button>
  </p>
  <div class="payment__item--subtitle">
    <span>We accept the following:</span>
    <img src="../../../../../assets/img/payment/american.png" alt="">
    <img src="../../../../../assets/img/payment/visa.png" alt="">
    <img src="../../../../../assets/img/payment/discover.png" alt="">
    <img src="../../../../../assets/img/payment/mastercard.png" alt="">
    <img src="../../../../../assets/img/payment/paypal.png" alt="">
  </div>
  <br>

  <!-- Partial payment - only rendered if payment method is not check -->
  <div class="form-row">
    <label for="partialPayment">Partial Payment</label>
    <select *ngIf="enablePartialPayment" class="form-input" formControlName="partialPayment" id="partialPayment"
      (change)="updatePartialPaymentValidation($event.target.value);">
      <option value="No">No</option>
      <option value="Yes">Yes</option>
    </select>
    <select *ngIf="!enablePartialPayment" class="form-input" formControlName="partialPayment" id="partialPayment">
      <option value="No">No</option>
    </select>
  </div>

  <!-- Partial payment data -->
  <div [hidden]="form.value.partialPayment !== 'Yes'">

    <div class="form-row">
      <label for="prePaymentPercentage">Pre Payment Percentage</label>
      <select class="form-input" formControlName="prePaymentPercentage" id="prePaymentPercentage" (change)="computePartialPayment($event.target.value)">
        <option value="" selected disabled>Pre Payment Percentage</option>
        <option *ngFor="let prePercent of prePaymentPercentages" [value]="prePercent">{{prePercent}}%</option>
      </select>
    </div>

    <div class="form-row">
      <label for="prePaymentAmount">Pre Payment Amount</label>
      <span class="input-icon-wrapper amount-icon"><input type="text" placeholder="Pre Payment Amount" class="form-input"
          formControlName="prePaymentAmount" id="prePaymentAmount" /></span>
    </div>

    <div class="form-row">
      <label for="totalInvoiceAmount">Total Payment Amount</label>
      <span class="input-icon-wrapper amount-icon"><input type="text" placeholder="Total Payment Amount" class="form-input"
          formControlName="totalInvoiceAmount" id="totalInvoiceAmount" /></span>
    </div>

    <div class="form-row">
      <label for="remainingBalance">Remaining Balance</label>
      <span class="input-icon-wrapper amount-icon"><input type="text" placeholder="Remaining Balance" class="form-input"
          formControlName="remainingBalance" id="remainingBalance" /></span>
    </div>

    <div class="form-row">
      <label for="remainingBalanceDue">Remaining Balance Due</label>
      <select class="form-input" formControlName="remainingBalanceDue" id="remainingBalanceDue" (change)="checkRemainingBalanceDue($event.target.value)">
        <option value="" selected disabled>Select Remaining Balance Due</option>
        <option [value]="remainingBalanceDue.beforeSample">After Mockup Approved - Before Sample Creation</option>
        <option [value]="remainingBalanceDue.beforeProduction">After Sample Approved - Before Full Production</option>
        <option [value]="remainingBalanceDue.beforeShipping">After Production - Before Shipping</option>
        <option [value]="remainingBalanceDue.afterShipping">After Shipping</option>
      </select>
    </div>

    <div class="form-row" [hidden]="hidePartialPaymentDays$ | async">
      <label for="partialPaymentDays">Partial Payment Days</label>
      <input type="number" placeholder="Partial Payment Days" class="form-input" formControlName="partialPaymentDays"
        id="partialPaymentDays" />
    </div>
  </div>

  <!-- Payment method -->
  <div class="form-row">
    <label for="paymentMethod">Payment Method</label>
    <select class="form-input" formControlName="paymentMethod" id="paymentMethod" (change)="updatePaymentMethod($event.target.value)">
      <option [value]="paymentMethod.Card">Credit Card</option>
      <option [value]="paymentMethod.Paypal">PayPal</option>
      <option [value]="paymentMethod.Check">Check</option>
      <option [value]="paymentMethod.NET30">NET 30</option>
      <option [value]="paymentMethod.NET60">NET 60</option>
    </select>
  </div>

  <!-- Card data -->
  <div [hidden]="form.value.paymentMethod !== paymentMethod.Card">
    <div *ngIf="!showCardForm" class="payment__block">

      <div *ngFor="let cardData of customerCardData" class="existing-card" [ngClass]="{'active': selectedCard === cardData}"
        (click)="selectCard(cardData)">
        <div class="cc-thumb-wrap"><img src="{{ getCCThumb(cardData.type) }}" width="100%" /></div>
        <div class="cc-data">
          {{ cardData.type }} ending in {{ cardData.number }}<br />
          {{ cardData.holder }} {{ getExpirationDate(cardData.expire) }}
        </div>
      </div>

      <button class="simple-green-btn" (click)="toggleCardForm(true)">+ Add Credit Card</button>
    </div>

    <div formGroupName="cardData" *ngIf="showCardForm">
      <div class="form-row">
        <input type="text" placeholder="Cardholder Name" formControlName="cardHolder" class="form-input mr-3" [ngClass]="getStateClass(form.get(['cardData', 'cardHolder']))" />

        <input type="text" placeholder="Card Number" formControlName="cardNumber" class="form-input" [ngClass]="getStateClass(form.get(['cardData', 'cardNumber']))" />
      </div>

      <div class="form-row">
        <label for="ccv">Card Security Code (CCV/CVV)</label>
        <input type="text" formControlName="cardCCV" id="ccv" class="form-input ccv" placeholder="CCV" minlength="3"
          maxlength="6" [ngClass]="getStateClass(form.get(['cardData', 'cardCCV']))" />
      </div>
      <div class="form-row">
        <label for="ced-month">Card Expiration Date</label>
        <input type="text" placeholder="MM" formControlName="cardExpireMo" id="ced-month" minlength="1" maxlength="2"
          class="form-input date" [ngClass]="getStateClass(form.get(['cardData', 'cardExpireMo']))" />

        <span class="form-row__separator">/</span>
        <input type="text" placeholder="YY" formControlName="cardExpireYr" id="ced-year" minlength="2" maxlength="2"
          class="form-input date" [ngClass]="getStateClass(form.get(['cardData', 'cardExpireYr']))" />
      </div>

      <button class="save-btn" [disabled]="isLoading" (click)="saveCard()">
        <span *ngIf="!isLoading; else loading">Save Card</span>
      </button>
    </div>
  </div>

  <!-- Check Data -->
  <div formGroupName="checkData" [hidden]="(form.value.paymentMethod !== paymentMethod.Check) && (form.value.paymentMethod !== paymentMethod.NET30) && (form.value.paymentMethod !== paymentMethod.NET60)">
    <div *ngIf="form.controls.checkData.errors && form.controls.checkData.errors.wrongSumForCheck" class="card-form__danger">
      <p>{{ form.controls.checkData.errors.wrongSumForCheck }}</p>
    </div>
    <div class="form-row">
      <label for="initialInvoice">Initial Invoice</label>

      <span class="input-icon-wrapper percent-icon"><input type="number" placeholder="Initial Invoice" class="form-input"
          formControlName="initialInvoice" id="initialInvoice" (change)="checkInitialInvoice($event.target.value)" /></span>
    </div>

    <div class="form-row">
      <label for="mockup">Mockup</label>

      <span class="input-icon-wrapper percent-icon"><input type="number" placeholder="Mockup" class="form-input"
          formControlName="mockup" id="mockup" /></span>
    </div>

    <div class="form-row">
      <label for="sample">Sample</label>

      <span class="input-icon-wrapper percent-icon"><input type="number" placeholder="Sample" class="form-input"
          formControlName="sample" id="sample" /></span>
    </div>

    <div class="form-row">
      <label for="shipping">Shipping</label>

      <span class="input-icon-wrapper percent-icon"><input type="number" placeholder="Shipping" class="form-input"
          formControlName="shipping" id="shipping" (change)="checkShippingValue($event.target.value)" /></span>
    </div>

    <div class="form-row">
      <label for="finalInvoice">Final Invoice</label>
      <span class="input-icon-wrapper percent-icon"><input type="number" placeholder="Final Invoice" class="form-input"
          formControlName="finalInvoice" id="finalInvoice" /></span>
    </div>

    <div class="form-row" [hidden]="hideAfterShipFolloup$ | async">
      <label for="afterShipFollowup">After Ship Follow-up</label>
      <input type="number" placeholder="After Ship FollowUp" class="form-input" formControlName="afterShipFollowup" id="afterShipFollowup" />
    </div>

    <div class="form-row" [hidden]="hidePaymentAfterProductionReason$ | async">
      <label for="paymentAfterProductionReason">Payment After Production Reason</label>
      <input type="text" placeholder="Payment After Production Reason" class="form-input" formControlName="paymentAfterProductionReason"
        id="paymentAfterProductionReason" />
    </div>
  </div>
</form>
