import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// Base component
import { IdleBaseComponent } from '../../core/components/idleTracking/idle.component';

// Services
import { CookieService } from '../../core/services/cookie.service';
import { DraftService } from '../../core/services/draft.service';
import { CampaignTrackingService } from '../../core/services/campaign-tracking.service';
import { TrackingService } from '../../core/services/tracking';
import { SessionService } from '../../utility/session.service';

// Types
import { ProductOrderSteps } from '../../types/step/IStep';
import { ICategory } from '../../types/category/ICategory';
import { ICustomerInfo } from '../../types/quote/IQuote';

@Component({
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent extends IdleBaseComponent implements OnInit {

  category: ICategory = this._activatedRoute.snapshot.data.category;

  // Define current step for step-tracker
  currentStep = ProductOrderSteps.product;

  constructor(
    protected tracking: TrackingService,
    protected router: Router,
    protected session: SessionService,
    protected cookies: CookieService,
    private _activatedRoute: ActivatedRoute,
    private _draftService: DraftService,
    private _router: Router,
    private _campaignTrackingService: CampaignTrackingService,
  ) {
    super(tracking, router, session, cookies);

    this._checkDrafts();
    this._campaignTrackingService.handleCampaignTracking(this._activatedRoute.snapshot.queryParams);
  }

  async ngOnInit() {
    this.tracking.sendAltoCloudData(['pageview', {
      location: location.pathname,
      title: 'Category'
    }]);
  }

  private async _checkDrafts() {
    // Check for drafts
    const customerInfo: ICustomerInfo = this.cookies.getJson('customerInfo');
    if (customerInfo && customerInfo.email) {
      let hasDrafts = false;

      hasDrafts = await this._draftService.hasDrafts(customerInfo.email);

      // if there was no draft in cookies or from server data go to artwork page
      if (hasDrafts) {
        // hard-coded values for category and product
        return this._router.navigate(
          ['/category', 3, 'product', 92, 'draft'],
          { queryParams: { skip: true } }
        );
      }
    }
  }


}
