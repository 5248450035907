<!-- Header -->
<div class="header-content">
  <diy-header></diy-header>
  <diy-order-details></diy-order-details>
  <diy-promo-banner></diy-promo-banner>
  <!-- we have the ngIf as we are setting currentStep after resolving route -->
  <diy-order-step-tracking *ngIf="currentStep" [currentStep]="currentStep">
  </diy-order-step-tracking>
</div>

<!-- Page content -->
<div class="container">

  <div class="specialOptions">
    <div class="heading-primary">
      <h1>Step {{ currentStep }}: Special Options</h1>
      <p>To get your order started, fill in the options that best fit your needs. As you make
        customization
        selections
        the Order Summary on the right will update and reflect each choice – any upgrades that add
        additional cost
        to
        your order will also be reflected.</p>
    </div>

    <div *ngIf="optionsInitialized; else loading" class="specialOptions__container">
      <div *ngFor="let required of requiredSpecialOptions; let tabIndex = index;" class="specialOptionRow"
        id="spOption_{{required.id}}">

        <diy-single-option-tab id="sp{{ tabIndex + 1 }}" *ngIf="!required.multiple" [orderItem]="orderItem"
          [showError]="showError" [tabIndex]="tabIndex + 1" [specialOption]="required"
          (optionSelected)="scrollToNextSection(tabIndex + 1)" [collapseStatus]="!currentGroup">
        </diy-single-option-tab>

        <diy-multi-option-tab id="sp{{ tabIndex + 1 }}" *ngIf="required.multiple" [orderItem]="orderItem"
          [showError]="showError" [tabIndex]="tabIndex + 1" [specialOption]="required" [collapseStatus]="!currentGroup">
        </diy-multi-option-tab>
      </div>

      <div *ngFor="let optionals of optionalSpecialOptions; let tabIndex = index;" class="specialOptionRow">

        <diy-single-option-tab id="sp{{ requiredSpecialOptions.length + tabIndex + 1 }}" *ngIf="!optionals.multiple"
          [orderItem]="orderItem" [showError]="showError" [tabIndex]="requiredSpecialOptions.length + tabIndex + 1"
          [specialOption]="optionals"
          (optionSelected)="scrollToNextSection(requiredSpecialOptions.length + tabIndex + 1)"
          [collapseStatus]="!currentGroup || optionals.toggled">
        </diy-single-option-tab>

        <diy-multi-option-tab id="sp{{ requiredSpecialOptions.length + tabIndex + 1 }}" *ngIf="optionals.multiple"
          [orderItem]="orderItem" [showError]="showError" [tabIndex]="requiredSpecialOptions.length + tabIndex + 1"
          [specialOption]="optionals" [collapseStatus]="!currentGroup || optionals.toggled">
        </diy-multi-option-tab>
      </div>
    </div>

    <p class="specialOptions__paragraph">Done selecting?</p>

    <div class="specialOptions__next">
      <button class="button button--green" [ngClass]="{'disabled': !isValid()}" (click)="nextStep()"
        *ngIf="!isSpecialAdmin">Continue</button>
      <button class="button button--green" [ngClass]="{'disabled': !isValid() || isLoading}"
        [disabled]="!isValid() || isLoading" (click)="saveDesign()" *ngIf="isSpecialAdmin">
        <span *ngIf="!isLoading">Save Design</span>
        <span *ngIf="isLoading">Loading</span>
      </button>
    </div>

    <ng-template #loading>
      <diy-spinner></diy-spinner>
    </ng-template>
  </div>

  <diy-notes></diy-notes>

  <diy-order-summary [currentStep]="currentStep"></diy-order-summary>

</div>

<ts-modal #saveSuccessModal>
  <diy-save-design-success (clickedClose)="closeSuccessModal()"></diy-save-design-success>
</ts-modal>
