<div [ngClass]="{'expandedSidebar' : toggleClass, 'toBottom': toBottom}" class="sidebar">
  <div class="side-container" [ngClass]="{'unlocked': fixedSidebar}">
    <div class="sidebar__wrap" [ngClass]="{'showMobileSidebar' : toggleMessage}" #sidebarRef id="sidebarWrap">
      <span class="sidebar__wrap--close" [ngClass]="{'sidebar__wrap--close-display' : toggleClass}"
        (click)="toggleSidebar()">&#215;</span>
      <div class="sidebar__mobile" (click)="toggleSidebar()"
        [ngClass]="{'hideMobile' : toggleClass, '' : !toggleClass}">
        <!-- Toggle message in case of mobile version -->
        <span>{{ toggleMessage }}</span>
        <button type="button" class="button button--green">View Details</button>
      </div>
      <!-- Dynamic content -->
      <ng-content></ng-content>
    </div>
  </div>
</div>
