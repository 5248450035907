import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

import { SessionService } from '../../utility/session.service';
import { DataTransformerService } from './data-transformer.service';

import { ICategory } from '../../types/category/ICategory';
import { IProduct } from '../../types/product/IProduct';
import { ISearchResult } from '../../types/search/ISearchResult';
import { ITurnaroundEstimationRequest } from '../../types/turnaround/ITurnaroundEstimationRequest';
import { BaseApi } from './baseApi';

/**
 * The requests made through this api
 * should hit cached json files
 */
@Injectable()
export class ProductFlowApi extends BaseApi {

  constructor(
    private _http: HttpClient,
    protected _sessionService: SessionService,
  ) {
    super(_sessionService);
  }

  private routes = {
    structuredProducts: environment.baseUrl + environment.cacheDir + 'structured_products.json',
    categoryList: environment.baseUrl + environment.cacheDir + 'categories.json',
    searchable: environment.baseUrl + environment.cacheDir + 'searchable.json',
    category: environment.baseUrl + environment.cacheDir + 'categories/',
    product: environment.baseUrl + environment.cacheDir + 'products/',
    turnaround: environment.baseUrl + 'api/deadline-estimation/',
    survey: environment.baseUrl + 'api/diy/customer/purchase-survey'
  };

  async sendSurvey(survey) {
    return await this._http.post(this.routes.survey, survey).toPromise();
  }
  async getStructuredProducts(): Promise<any> {
    const categories = [];
    const response = await this._http.get(this.routes.structuredProducts).toPromise();

    for (const category in response) {
      // why sent a list of items, when you can send a fucking object of useless key values. #justPHPThings.
      // categories response: { id: {categoryObj}}
      if (response.hasOwnProperty(category)) {
        categories.push(response[category]);
      }
    }
    return categories;
  }

  getCategoryList(): Observable<ICategory[]> {
    try {
      // Return observable of sorted entries by key
      return this._http.get(this.routes.categoryList)
        .pipe(map(categories => Object.keys(categories).sort().map(key => {
          return { ...categories[key], order: key };
        })));
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Get Product Info (with variations / options / turnarounds
   */
  async getProduct(productId: number): Promise<IProduct> {
    try {
      const res = await this._http.get(this.routes.product + productId + '.json?v=6').toPromise();
      return DataTransformerService.createProduct(res);
    } catch (err) {
      console.error(err);
    }
  }

  async getTurnaroundTime(payload: ITurnaroundEstimationRequest): Promise<any> {
    try {
      const res = await this._http.post(this.routes.turnaround, payload).toPromise();
      return res;
    } catch (err) {
      console.error(err);
    }
  }

  async getSearchable(): Promise<ISearchResult[]> {

    try {
      const items = await this._http.get(this.routes.searchable).toPromise();
      return DataTransformerService.createSearchResults(items);
    } catch (err) {
      console.error(err);
    }
  }
}
