/**
 * @author Liviu Dima
 */

export interface IStep {
  number: number;
  name: string;
  url: string;
  logStep?: number;
}

export enum StepTypes {
  PRODUCT_ORDER_STEPS,
  PACKAGING_ORDER_STEPS
}

export enum ProductOrderSteps {
  product = 1,
  quote = 2.25,
  draft = 2.5,
  customize = 2,
  specialOptions = 3,
  artwork = 4,
  packagingPrompt = 4.5,
  turnaround = 5,
}

export enum PackagingOrderSteps {
  size = 1,
  type = 2,
  option = 3,
  artwork = 4,
  review = 5,
}
