import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'diy-summary-info',
  templateUrl: './summary-info.component.html',
  styleUrls: ['./summary-info.component.scss']
})
export class SummaryInfoComponent implements OnInit {

  loadedInfo = false;
  title = '';
  description = '';

  @Input() currentStep: number;

  ngOnInit() {
    this.loadedInfo = true;
    this._updateInfoCopy(this.currentStep);
  }

  private _updateInfoCopy(stepNr: number) {
    switch (stepNr) {
      case 2:
        this.title = 'Product Selection';
        this.description = 'Need help to decide which product is right for you?';
          // + this._getPhoneLink('Call us at ' + environment.salesTelShown);
        break;
      case 3:
      case 4:
      case 5:
      case 6:
        this.title = 'We\'re here to help!';
        this.description = `Chat with us live`;
        // +` or call us at <br/>
        // <b>${this._getPhoneLink(environment.salesTelShown)}</b>`;
        break;
    }
  }

  private _getPhoneLink(text: string): string {
    return `<a href="tel:${environment.salesTel}">${text}</a>`;
  }
}
