import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { take } from 'rxjs/operators';

import { SetQuoteId } from '../../../state-manager/shopping-cart/shopping-cart.actions';

import { QuoteService } from '../../../core/services/quote.service';
import { SessionService } from '../../../utility/session.service';
import { TrackingService } from '../../../core/services/tracking';

import { IShoppingCart } from '../../../types/shopping-cart/IShoppingCart';
import { ICustomerInfo } from '../../../types/quote/IQuote';
import { IAppState } from '../../../types/app-state/IAppState';

@Component({
  templateUrl: './payment-login.component.html',
  styleUrls: ['./payment-login.component.scss']
})
export class PaymentLoginComponent implements OnInit {

  title = `Verify it's you`;
  subtitle = 'This helps us confirm your identify for a speedy checkout.';

  constructor(
    private _tracking: TrackingService,
    private _router: Router,
    private _quoteService: QuoteService,
    private _sessionService: SessionService,
    private _store: Store<IAppState>,
  ) { }

  ngOnInit() {
    this._tracking.sendAltoCloudData(['pageview', {
      location: location.pathname,
      title: 'Payment Login'
    }]);
  }

  authCompleted(differentUser: boolean) {
    // create new quotes if different user
    if (differentUser) {
      const user = this._sessionService.user;
      const customerInfo: ICustomerInfo = {
        firstname: user.firstName,
        lastname: user.lastName,
        email: user.email,
        phone: ''
      };
      let shoppingCart: IShoppingCart;

      this._store.pipe(select('shoppingCart'), take(1))
        .subscribe((data: IShoppingCart) => {
          shoppingCart = data;
        });
      if (shoppingCart && shoppingCart.cartItems && shoppingCart.cartItems.length) {
        shoppingCart.cartItems.forEach(cartItem => {
          cartItem.cartItemOrders.forEach(async cartItemOrder => {
            const quote = await this._quoteService.createQuote(customerInfo, cartItemOrder.orderItem.product.id);
            if (quote && quote.quoteId) {
              this._store.dispatch(new SetQuoteId({ uid: cartItemOrder.uid, quoteId: quote.quoteId, customerEmail: customerInfo.email }));
            }
          });
        });
      }
    }
    this._router.navigate(['shop/cart']);
  }
}
