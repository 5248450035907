import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class StaticContentApi {

  private _productRoutes = {
    footerData: '/assets/static-content/footer.json'
  };

  /**
   * @author Liviu Dima
   */
  constructor(
    private _http: HttpClient
  ) { }


  /**
   * Get Footer Content
   */
  async getFooterData(): Promise<any> {
    try {
      const res = await this._http.get(this._productRoutes.footerData).toPromise();
      return res;
    } catch (err) {
      console.error(err);
    }
  }
}
