import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';

import { PackagingResolver } from '../../packaging/core/resolvers/packaging.resolver';

import { IOrderItem } from '../../types/app-state/IOrderItem';
import { IAppState } from '../../types/app-state/IAppState';

@Injectable()
export class TurnaroundResolver implements Resolve<Observable<IOrderItem>> {

  constructor(
    private _store: Store<IAppState>,
    private _packagingResolver: PackagingResolver,
  ) { }

  async resolve(route: ActivatedRouteSnapshot): Promise<Observable<IOrderItem>> {
    await this._packagingResolver.resolve(route);

    return this._store.pipe(select('orderItem'));
  }

}
