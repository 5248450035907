import { Component } from '@angular/core';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'diy-tat-announcement',
  templateUrl: './tat-announcement.component.html',
  styleUrls: ['./tat-announcement.component.scss']
})
export class TatAnnouncementComponent {
  enabled = false;

  constructor() {
      this.enabled = environment.tatAnnouncement;
  }
}
