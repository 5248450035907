import { Component, Input, Output, EventEmitter } from '@angular/core';

/**
 * Component used for rendering a switch button.
 *
 * @author Liviu Dima
 */
@Component({
  selector: 'diy-switch-button',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss']
})
export class SwitchButtonComponent {
  @Input() public active = false;
  @Output() public activeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Class constructor.
   */
  constructor() { }

  /**
   * Toggle state.
   */
  toggle(active: boolean) {
    this.activeChange.emit(active);
  }
}
