import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../../environments/environment';
import { IShippingData, IBillingData, ICardData } from '../../../types/payment/IFormState';
import { SessionService } from '../../../utility/session.service';
import { ISalesTaxRequest, ISalesTaxResponse, ILicenseCheckResponse } from '../../../types/api/ISalesTax';

/**
 * Customer related api provider
 */
@Injectable()
export class CustomerDataProvider {

  constructor(private _http: HttpClient, private _ss: SessionService) { }

  private routes = {
    getCustomerData: environment.baseUrl + 'api/diy/customer/details',
    saveShippingAddress: environment.baseUrl + 'api/diy/customer/shipping/new',
    savebillingAddress: environment.baseUrl + 'api/diy/customer/billing/new',
    saveCard: environment.baseUrl + 'api/diy/customer/credit-card/new',
    getCustomerDataAsRep: environment.baseUrl + 'api/diy/sales/{customerId}/details',
    saveShippingAddressAsRep: environment.baseUrl + 'api/diy/sales/{customerId}/shipping/new',
    savebillingAddressAsRep: environment.baseUrl + 'api/diy/sales/{customerId}/billing/new',
    saveCardAsRep: environment.baseUrl + 'api/diy/sales/{customerId}/credit-card/new',
    checkResellerLicense: environment.baseUrl + 'api/diy/customer/check-licence',
    getTaxRate: environment.baseUrl + 'api/diy/sales/invoice/get-tax',
  };

  async getCustomerData(): Promise<any> {
    try {
      const headers = this._createAuthorizationHeader();
      return await this._http.get(this.routes.getCustomerData, { headers: headers }).toPromise();
    } catch (err) { }
  }


  async saveShipping(address: IShippingData): Promise<any> {
    try {
      const headers = this._createAuthorizationHeader();
      return await this._http.post(this.routes.saveShippingAddress, address, { headers: headers }).toPromise();
    } catch (err) { }
  }

  async saveBilling(address: IBillingData): Promise<any> {
    try {
      const headers = this._createAuthorizationHeader();
      return await this._http.post(this.routes.savebillingAddress, address, { headers: headers }).toPromise();
    } catch (err) { }
  }

  async saveCard(card: ICardData): Promise<any> {
    try {
      const headers = this._createAuthorizationHeader();
      const cardPayload = {
        cardHolder: card.cardHolder,
        cardNumber: card.cardNumber,
        cardCCV: card.cardCCV,
        cardExpireMonth: card.cardExpireMo,
        cardExpireYear: card.cardExpireYr,
      };

      return await this._http.post(this.routes.saveCard, cardPayload, { headers: headers }).toPromise();
    } catch (err) {
      return err.error;
    }
  }

  async getCustomerDataAsRep(customerId: string): Promise<any> {
    try {
      const headers = this._createAuthorizationHeader();
      const route = this._getRepRoute(this.routes.getCustomerDataAsRep, customerId);
      return await this._http.get(route, { headers: headers }).toPromise();
    } catch (err) { }
  }

  async saveBillingAsRep(customerId: string, address: IBillingData): Promise<any> {
    try {
      const headers = this._createAuthorizationHeader();

      delete address.id;
      delete address.sameAsShipping;
      const route = this._getRepRoute(this.routes.savebillingAddressAsRep, customerId);
      return await this._http.post(route, address, { headers: headers }).toPromise();
    } catch (err) { }
  }

  async saveShippingAsRep(customerId: string, address: IShippingData): Promise<any> {
    try {
      const headers = this._createAuthorizationHeader();
      const route = this._getRepRoute(this.routes.saveShippingAddressAsRep, customerId);
      return await this._http.post(route, address, { headers: headers }).toPromise();
    } catch (err) { }
  }

  async saveCardAsRep(customerId: string, card: ICardData): Promise<any> {
    try {
      const headers = this._createAuthorizationHeader();
      const cardPayload = {
        cardHolder: card.cardHolder,
        cardNumber: card.cardNumber,
        cardCCV: card.cardCCV,
        cardExpireMonth: card.cardExpireMo,
        cardExpireYear: card.cardExpireYr,
      };

      const route = this._getRepRoute(this.routes.saveCardAsRep, customerId);
      return await this._http.post(route, cardPayload, { headers: headers }).toPromise();
    } catch (err) {
      return err.error;
    }
  }

  async checkResellerLicense(customerEmail: string): Promise<ILicenseCheckResponse> {
    try {
      const reqBody = {
        email: customerEmail
      };
      return await this._http.post(this.routes.checkResellerLicense, reqBody).toPromise() as ILicenseCheckResponse;
    } catch (err) {
      console.error(err);
    }
  }

  async getTaxRate(taxRateRequest: ISalesTaxRequest): Promise<ISalesTaxResponse> {
    try {
      return await this._http.post(this.routes.getTaxRate, taxRateRequest).toPromise() as ISalesTaxResponse;
    } catch (err) {
      console.error(err);
    }
  }

  private _createAuthorizationHeader(): HttpHeaders {
    const headers = new HttpHeaders();
    return headers.append('Authorization', 'Bearer ' + this._ss.token);
  }

  /**
   * Replaces the customerId param with an actual id
   * @param route
   * @param customerId
   */
  private _getRepRoute(route: string, customerId: string): string {

    // check if we have a customer_no instead of id
    if (customerId.charAt(0).toLowerCase() === 'c') {
      return route.replace('{customerId}', customerId.slice(1));
    }

    return route.replace('{customerId}', customerId);
  }
}
