import { Utils } from './utils';

import { IVariationType, AvailableVariationTypeTypes } from '../../types/product/variations/IVariationType';
import { ISelectedVariation } from '../../types/app-state/ISelectedVariation';
import { ICustomQuantity } from '../../types/product/variations/ICustomQuantity';
import { ICustomSize } from '../../types/product/variations/ICustomSize';
import { IVariation } from '../../types/product/variations/IVariation';
import { IBundleQuantity } from '../../types/product/variations/IBundleQuantity';
import { IBundleItem } from '../../types/bundle/IBundle';

export class VariationManager {

  /**
   * Map IVariation to ISelectedVariation if is compatible
   */
  public static getSelectedVariation(
    variation: IVariation,
    variationType: IVariationType,
    incompatibilities: number[]
  ): ISelectedVariation {
    if (!this.checkVariationCompatibility(variation, incompatibilities)) {
      throw new Error(
        `Tried to select an incompatible variation.
        Selected Variation Id: ${variation.id}.`
      );
    }

    const selectedVariation: ISelectedVariation = {
      multiple: variationType.multiple,
      variation: variation,
      vtID: variationType.id,
      vtName: variationType.name,
      vtDBName: variationType.dbname,
      vtCategory: variationType.category,
    };

    return selectedVariation;
  }

  /**
   * Check if provided variation is compatible
   */
  public static checkVariationCompatibility(variation: IVariation, incompatibilities: number[]) {
    return incompatibilities.length ? !~incompatibilities.indexOf(variation.id) : true;
  }

  static createSelectedCustomQty(qty: number, variationType: IVariationType): ISelectedVariation {

    // first try to see if the "custom" quantity actually exists but is not shown. Yes this is a case
    const existingQtyVar = variationType.variations.filter(avt => avt.showInDIY)
      .find(avt => {
        return Number(avt.name) === qty;
      });

    const incompatibilities: number[] = [];

    const variation: ICustomQuantity = {
      name: 'Custom Quantity',
      customName: '(' + qty + ')',
      quantityValue: qty,
      customType: 'qty',
      incompatibilities: incompatibilities,
      showInDIY: false
    };

    const selectedVariation: ISelectedVariation = {
      variation: existingQtyVar ? existingQtyVar : variation,
      multiple: false,
      vtID: variationType.id,
      vtDBName: variationType.dbname,
      vtName: variationType.name,
      vtCategory: variationType.category
    };

    return selectedVariation;
  }

  static createSelectedCustomSize(width: number, height: number, variationType: IVariationType): ISelectedVariation {

    // first create a number array representing all sizes
    const sizeOptions = Utils.getSortedVariationValues(variationType.variations);

    const closestSizeInPT = Utils.getClosestSize(width, height, sizeOptions);
    const closestVariation = Utils.getVariationByNumberValue(closestSizeInPT, variationType.variations);

    const incompatibilities: number[] = [];
    const customSizeVariation: ICustomSize = {
      id: closestVariation.id,
      name: 'Custom Size',
      customName: '(' + width + '" x ' + height + '")',
      height: height,
      width: width,
      customType: 'size',
      incompatibilities: incompatibilities,
      showInDIY: false
    };

    const selectedVariation: ISelectedVariation = {
      variation: customSizeVariation,
      multiple: false,
      vtID: variationType.id,
      vtDBName: variationType.dbname,
      vtName: variationType.name,
      vtCategory: variationType.category
    };

    return selectedVariation;
  }

  static getQuantityFromStack(selectedVariations: ISelectedVariation[]) {
    if (!selectedVariations.length) {
      return 0;
    }
    const quantityVariation = selectedVariations
      .find(chosenVariation => chosenVariation.vtCategory === AvailableVariationTypeTypes.quantity);

    if (!quantityVariation) {
      return 0;
    }

    return VariationManager.getQuantityValue(quantityVariation.variation);
  }

  /**
  * @param variation ICustomQuantity | IBundleQuantity | IVariation
  */
  static getQuantityValue(variation) {

    if ('customType' in variation) {
      if (variation.customType === 'qty') {

        // custom quantity
        return (variation as ICustomQuantity).quantityValue;
      } else if (variation.customType === 'bundle') {

        // bundle quantity
        return (variation as IBundleQuantity).unitsSelected;
      }
    }

    // regular quantity variation
    return Utils.convertToNumber(variation.name);
  }

  static createBundleQty(bundleItem: IBundleItem, variationType: IVariationType, quantity = 0): ISelectedVariation {
    let credits = quantity;

    if (credits <= 0) {
      credits = bundleItem.quantity;
    }

    const incompatibilities: number[] = [];

    const variation: IBundleQuantity = {
      bundleId: String(bundleItem.bundleId),
      name: 'Bundle Quantity',
      customName: credits + 'QTY x ' + credits + ' Credits',
      unitsAvailable: Number(bundleItem.quantity),
      unitsSelected: Number(credits),
      customType: 'bundle',
      incompatibilities: incompatibilities,
      showInDIY: false
    };

    const selectedVariation: ISelectedVariation = {
      variation: variation,
      multiple: false,
      vtID: variationType.id,
      vtDBName: variationType.dbname,
      vtName: variationType.name,
      vtCategory: variationType.category
    };

    return selectedVariation;
  }
}
