import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { CookieService } from './cookie.service';

import { IAppState } from '../../types/app-state/IAppState';
import { IOrderItem } from '../../types/app-state/IOrderItem';
import { ArtworkCookieType, IArtwork, IArtworkCookie } from '../../types/artwork/IArtwork';

/**
 *
 * @author Andrei Diaconu
 */
@Injectable()
export class ArtworkService {

  private _orderItem: IOrderItem;

  constructor(
    private _store: Store<IAppState>,
    private _cookieService: CookieService
  ) {
    this._store.pipe(select('orderItem')).subscribe((data: IOrderItem) => {
      this._orderItem = { ...data };
    });
  }

  setArtworkCookie() {
    if (this._orderItem.product && this._orderItem.product.id) {
      const artworkData: IArtworkCookie = {
        productId: this._orderItem.product.id,
        data: this._orderItem.artwork
      };
      this._cookieService.setJson('artworkData', artworkData, 1);
    }
  }

  setPackagingArtworkCookie(artwork: IArtwork, packageId: number) {
    const artworkData: IArtworkCookie = {
      data: artwork,
      packageId: packageId
    };
    this._cookieService.setJson(ArtworkCookieType.PACKAGING, artworkData, 1);
  }

  removeArtworkCookie(type: ArtworkCookieType = ArtworkCookieType.ORDER) {
    this._cookieService.delete(type);
  }

}
