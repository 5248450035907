import { Directive, Input, HostListener } from '@angular/core';
import { BsModalService, ModalOptions } from 'ngx-bootstrap';

import { GalleryComponent } from './gallery.component';

import { IImage } from '../../types/images/IImage';

@Directive({
  selector: '[diyGallery]'
})
export class DiyGalleryDirective {
  @Input() images: IImage[] = [];

  constructor(private _modalService: BsModalService) { }

  @HostListener('click', ['$event']) onClick($event) {
    const options = new ModalOptions();
    options.class = 'modal-lg';
    options.initialState = {
      currentImage: this.images[0],
      imageList: this.images
    };

    this._modalService.show(GalleryComponent, options);
  }
}
