<div class="gallery-modal">
  <div class="gallery-content">
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>

    <div class="gallery-main">
      <diy-zoomable [image]="currentImage"></diy-zoomable>
    </div>

    <div *ngIf="imageList.length > 1" class="gallery-other">
      <div *ngFor="let item of imageList; let i = index;" class="gallery-item" (click)="chooseImage(item)" [ngClass]="{
          'selected-item': (currentImage?.large === item?.large)
        }">
        <img src="{{item.small}}" />
      </div>
    </div>
  </div>
</div>
