import { Directive, ElementRef, Input, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[diyMatchHeight]'
})
export class MatchHeightDirective implements AfterViewInit {

  @Input() diyMatchHeight: any;

  constructor(
    private _el: ElementRef
  ) { }

  ngAfterViewInit() {
    if (!this._isMobile()) {
      this.matchHeight(this._el.nativeElement, this.diyMatchHeight);
    }
  }

  matchHeight(parent: HTMLElement, className: string) {
    if (!parent) { return; }
    const children = parent.getElementsByClassName(className);

    if (!children) { return; }

    Array.from(children).map((x: HTMLElement) => {
      x.style.height = 'initial';
    });

    const itemHeights = Array.from(children)
      .map(x => x.getBoundingClientRect().height);

    const maxHeight = itemHeights.reduce((prev, curr) => {
      return curr > prev ? curr : prev;
    }, 0);

    Array.from(children).map((x: HTMLElement) => x.style.height = `${maxHeight}px`);
  }

  private _isMobile(): boolean {
    return window.screen.width > 790 ? false : true;
  }
}
