import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AmplitudeService } from '../../core/services/amplitude';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Meta } from '@angular/platform-browser';

// Base component
import { IdleBaseComponent } from '../../core/components/idleTracking/idle.component';

// Services
import { Utils } from '../../core/services/utils';
import { TrackingService } from '../../core/services/tracking';
import { BundleProvider } from '../../core/api/BundleProvider';
import { AuthGuard } from '../../core/guards/auth-guard.service';
import { ComStr } from '../../core/services/ComStr';
import { QuoteService } from '../../core/services/quote.service';
import { CookieService } from '../../core/services/cookie.service';

// types
import { ProductOrderSteps } from '../../types/step/IStep';
import { IOrderItem } from '../../types/app-state/IOrderItem';
import { ICustomerInfo, IQuote } from '../../types/quote/IQuote';
import { AvailableVariationTypeTypes } from '../../types/product/variations/IVariationType';
import { SessionService } from '../../utility/session.service';

@Component({
  selector: 'diy-quote-form',
  templateUrl: './quote-form.component.html',
  styleUrls: ['./quote-form.component.scss']
})
export class QuoteFormComponent extends IdleBaseComponent implements OnInit, OnDestroy {
  loading = false;
  draft: {
    productName: string,
    elapsedTime: string,
    returnUrl: string
  } | undefined;

  selectedCountryPrefix: string;

  // Define current step for step-tracker
  currentStep = ProductOrderSteps.quote;

  availableQuantities: number[] = [];

  availableReasons: string [] = ['Product resale', 'Promotional items', 'Organization/Corporate gifts', 'Event/Celebration', 'Team/Group Uniforms', 'Embellishment/Trim'];

  productName: string;

  customQuantityFlag = false;
  customReasonFlag = false;
  minQty = 0;
  maxQty = 0;
  quoteForm = this._formBuilder.group({
    firstname: ['', Validators.compose([
      Validators.required,
      Validators.minLength(2)
    ])],
    lastname: ['', Validators.compose([
      Validators.required,
      Validators.minLength(2)
    ])],
    email: ['', Validators.compose([
      Validators.required,
      Validators.pattern(ComStr.EMAIL_REGEX)
    ])],
    phone: ['', this._customPhoneValidator],
    reasonForPurchase: [''],
    customReasonForPurchase: [''],
    quantity: ['100', Validators.required],
    customQuantity: [''],
    // New field for combined name
    fullName: [''],
  });

  private _orderItem$: Observable<IOrderItem> = this._activatedRoute.snapshot.data.orderItem;
  private _subscriptions$: Subscription[] = [];

  constructor(
    private _formBuilder: FormBuilder,
    private _meta: Meta,
    private _activatedRoute: ActivatedRoute,
    private _amp: AmplitudeService,
    private _quoteService: QuoteService,
    private _bundleProvider: BundleProvider,
    protected cookies: CookieService,
    protected session: SessionService,
    protected router: Router,
    protected tracking: TrackingService,
    protected authGuard: AuthGuard,
  ) {
    super(tracking, router, session, cookies);

    this._meta.addTag({ name: 'robots', content: 'noindex' });

    // custom quantity validation based on quantity selected
    const subscription$ = this.quoteForm.controls['quantity'].valueChanges
      .subscribe(value => this._toggleCustomQuantityValidation(value));

    const reasonSubscription$ = this.quoteForm.controls['reasonForPurchase'].valueChanges
      .subscribe(value => this._toggleCustomReasonForPurchaseValidation(value));

    this._subscriptions$.push(subscription$);
    this._subscriptions$.push(reasonSubscription$);
  }

  async ngOnInit() {

    const orderItem = await this._orderItem$.pipe(take(1)).toPromise();

    this._initQuantities(orderItem);

    this.productName = orderItem.product.name;

    this._amp.logEvent('Navigation', {
      page: 'lead capture',
      navigationStep: 2.25,
      category: orderItem.category.name,
      category_id: orderItem.category.id,
      productName: orderItem.product.name,
      product_id: orderItem.product.id
    });

    this.tracking.sendAltoCloudData(['pageview', {
      location: location.pathname,
      title: 'Lead Capture'
    }]);

    this.quoteForm.get('fullName').valueChanges.subscribe(() => {
      this.handleNameChange();
    });

    try {
      const currentQuote: IQuote = this._quoteService.getCurrentQuote();

      if (currentQuote && currentQuote.quoteId) {
        return this.router.navigate(
          ['/category', orderItem.category.id, 'product', orderItem.product.id, 'draft'],
          { queryParams: { 'quoteId': currentQuote.quoteId } });
      }
    } catch (err) { }

    this.updateHyrosTrackingCode("https://182992.t.hyros.com/v1/lst/universal-script?ph=d7cb2d3b87ddc384cfc78b93c3a41292a992ed961977270eb515da16baa3f0fd&tag=!Step1");
  }

  handleNameChange() {
    const nameParts = this.quoteForm.get('fullName').value.split(' ');
    this.quoteForm.get('firstname').setValue(nameParts.shift() || ''); // Set first part as first name
  
    // Check if there are remaining parts for the last name, if not use 'Customer' as default
    if (nameParts.length > 0) {
      this.quoteForm.get('lastname').setValue(nameParts.join(' ')); // Join the rest as last name
    } else {
      this.quoteForm.get('lastname').setValue('Customer'); // Default last name
    }
  }

  updateHyrosTrackingCode(src) {
    const hyros = document.querySelector("#hyros-scripts") as HTMLScriptElement;
    if(this.session.isSalesLoggedIn()) {
      hyros.src = '';
    }else{
      hyros.src = src;
    }
  }

  async continue() {
    this._validateForm();

    if (!this.quoteForm.valid) {
      return;
    }

    this._quoteService.deleteQuotes();

    const orderItem = await this._orderItem$.pipe(take(1)).toPromise();

    const quantity = (this.quoteForm.controls.quantity.value !== 'other') ?
      this.quoteForm.controls.quantity.value : this.quoteForm.controls.customQuantity.value;

    const reasonForPurchase = (this.quoteForm.controls.reasonForPurchase.value !== 'other') ?
      this.quoteForm.controls.reasonForPurchase.value : this.quoteForm.controls.customReasonForPurchase.value;

    const formData = this.quoteForm.value;
    const customerInfo: ICustomerInfo = {
      firstname: formData.firstname,
      lastname: formData.lastname,
      email: formData.email,
      phone: formData.phone ? `${this.selectedCountryPrefix}${formData.phone.replace(/\D/g, '')}` : ''
    };

    const response = await this._quoteService.createSegmentationQuote(
      customerInfo,
      orderItem.product.id,
      Number(quantity),
      this.session.segmentation,
      String(reasonForPurchase),
    );

    const quote = response.quote;

    // if we can't get a quote id, try again
    if (!quote.quoteId) {
      this.loading = false;
      return;
    }

    if (quote.customerId) {
      this._amp.setUserId(quote.customerId);
    }

    this._amp.logEvent('Lead Capture', {
      page: 'Lead capture',
      navigationStep: 2.25,
      category: orderItem.category.name,
      category_id: orderItem.category.id,
      productName: orderItem.product.name,
      product_id: orderItem.product.id,
      quoteId: quote.quoteId,
      userId: quote.customerId,
    });

    this.tracking.sendAltoCloudData(['identify', {
      givenName: formData.firstname,
      familyName: formData.lastname,
      email: formData.email,
    }]);

    this.tracking.sendAltoCloudData(['record', 'customer.type', quote.customerType]);
    this._sendTracking(quote.leadType, quote.customerId);

    const queryParams = this._getQueryParams(orderItem, String(quote.quoteId), quantity);

    // redirect priority -1: thank you page
    if (response.showThankYou) {
      this.router.navigate(['/thank-you'], { queryParams: queryParams });
      return;
    }

    const hasBundle = await this._bundleProvider.checkBundle(orderItem.product.id, customerInfo.email);
    // redirect priority 0: bundle login
    if (hasBundle) {
      this.router.navigate(['/shop/bundle-login']);
      return;
    }


    // redirect priority 1: draft resume
    if (quote.existingDraft) {
      this.router.navigate(
        ['/category', orderItem.category.id, 'product', orderItem.product.id, 'draft'],
        { queryParams: queryParams }
      );
      return;
    }

    // redirect priority 2: return Url
    if (this.authGuard.returnUrl) {
      const currentUrl = this._updateCurrentUrl(this.authGuard.returnUrl, quote.quoteId);
      this.authGuard.returnUrl = undefined;

      this.router.navigateByUrl(currentUrl);
      return;
    }

    // redirect default: artwork
    this.router.navigate(
      ['/category', orderItem.category.id, 'product', orderItem.product.id, 'customize'],
      { queryParams: queryParams }
    );
    return;
  }

  countrySelected(prefix: string) {
    this.selectedCountryPrefix = prefix;
  }

  showError(formName: string): boolean {
    const formControl = this.quoteForm.get(formName);
    return formControl.dirty && formControl.invalid;
  }

  private _validateForm() {
    for (const key of Object.keys(this.quoteForm.controls)) {
      this.quoteForm.controls[key].markAsDirty();
      this.quoteForm.controls[key].markAsTouched();
      this.quoteForm.controls[key].updateValueAndValidity();
    }
  }

  // get query params for customise step (quantity id/ custom qty and quoteId)
  private _getQueryParams(orderItem: IOrderItem, quoteId: string, quantity: number) {

    const quantityVT = orderItem.product.variationTypes.find(vt => vt.category === AvailableVariationTypeTypes.quantity);
    const queryParams = {
      quoteId: quoteId
    };

    const quantityVariation = quantityVT.variations.find(variation => Number(variation.name) === quantity);

    if (quantityVariation) {
      queryParams['selections_vt'] = quantityVariation.id;
    } else {
      queryParams['c_qty'] = quantity;
    }
    return queryParams;
  }

  // grab product quantities and set validations min/max
  private _initQuantities(orderItem: IOrderItem) {
    const quantityVT = orderItem.product.variationTypes.find(vt => vt.category === AvailableVariationTypeTypes.quantity);


    this.availableQuantities = quantityVT.variations
      .filter(variation => variation.showInDIY)
      .map(variation => Utils.convertToNumber(variation.name));

    const allQuantities = quantityVT.variations
      .map(variation => Utils.convertToNumber(variation.name))
      .sort((n1, n2) => n1 - n2);

    this.minQty = allQuantities[0];
    this.maxQty = allQuantities[allQuantities.length - 1];
  }

  private _toggleCustomQuantityValidation(value: string) {
    const customQty = this.quoteForm.controls.customQuantity;
    if (value === 'other') {
      customQty.setValidators([
        Validators.required,
        Validators.min(this.minQty),
        Validators.max(this.maxQty)
      ]);

      this.customQuantityFlag = true;
    } else {
      customQty.clearValidators();
      this.customQuantityFlag = false;
    }
    customQty.updateValueAndValidity();
  }

  private _toggleCustomReasonForPurchaseValidation(value: string) {
    const customReason = this.quoteForm.controls.customReasonForPurchase;
    if (value === 'other') {
      customReason.setValidators([
        // Validators.required,
      ]);

      this.customReasonFlag = true;
    } else {
      customReason.clearValidators();
      this.customReasonFlag = false;
    }
    customReason.updateValueAndValidity();
  }

  private _customPhoneValidator(control: FormControl) {
    const phoneNumber = control.value.replace(/[^0-9]/g, '');
    return !phoneNumber || ComStr.PHONE_REGEX.test(phoneNumber) ? null : { phone: true };
  }

  /**
   * Send tracking data
   */
  private _sendTracking(customerType?: string, customerId?: string) {
    this.tracking.gtagReportConversion({
      'send_to': 'AW-1069576120/Rjo3CL7u9IgBELjfgf4D',
      'event_callback': () => { }
    });
    if(customerId) {
      window.dataLayer.push({
        event: "dataLayer-initialized",
        loggedIn: "y",
        userId: customerId
      });
      window.dataLayer.push({
        event: "login",
        loggedIn: "y",
        userId: customerId
      })
      
    }
    window.dataLayer.push({ 
      'event': 'sign-up',
      'stepNumber': 2.25,
      'stepName': 'Lead Capture'
    });
    if(customerType === 'new') {
      window.dataLayer.push({
        'event': 'lead_form_new',
        'stepNumber': 2.25,
        'stepName': 'Lead Capture'
      });
    }else{
      window.dataLayer.push({
        'event': 'lead_form_existing',
        'stepNumber': 2.25,
        'stepName': 'Lead Capture'
      });
    }
    this.tracking.sendFacebookData('track', 'Lead');
    this.tracking.sendBingData('click', { event_category: 'form', event_label: 'completion' });
  }

  private _updateCurrentUrl(currentUrl: string, quoteId: number) {
    let newUrl = currentUrl;
    if (!newUrl.includes('quoteId')) {
      newUrl += newUrl.includes('?') ? `&quoteId=${quoteId}` : `?quoteId=${quoteId}`;
    }

    return newUrl;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscriptions$.map(subscription$ => subscription$.unsubscribe());

    this.updateHyrosTrackingCode("https://182992.t.hyros.com/v1/lst/universal-script?ph=d7cb2d3b87ddc384cfc78b93c3a41292a992ed961977270eb515da16baa3f0fd&tag=!clicked");
  }
}
