import { HostListener, OnDestroy, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

// Services
import { TrackingService } from '../../services/tracking';
import { SessionService, IDLE_ONE, IDLE_TWO, IDLE_HALF } from '../../../utility/session.service';
import { CookieService } from '../../services/cookie.service';
import { ICustomerInfo } from '../../../types/quote/IQuote';

declare const customPlugin: any;

export abstract class IdleBaseComponent implements OnDestroy, AfterViewInit {

  private _subscriptions: Subscription[] = [];
  private _lastId: number;
  private _pastUrls: string[];

  private _halfMinuteTimer = setTimeout(() => {
    const currentTime = Math.floor((new Date()).getTime() / 1000);
    const idleTriggerHalf = this.session.getItem(IDLE_HALF);
    if (!idleTriggerHalf || (idleTriggerHalf && currentTime - Number(idleTriggerHalf) > 120)) {
      this.session.setItem(IDLE_HALF, String(Math.floor((new Date()).getTime() / 1000)));
      this.tracking.sendAltoCloudData(['record', 'userIdle30sec']);
    }
  }, 30000);

  private _oneMinuteTimer = setTimeout(() => {
    const currentTime = Math.floor((new Date()).getTime() / 1000);
    const idleTriggerOne = this.session.getItem(IDLE_ONE);
    if (!idleTriggerOne || (idleTriggerOne && currentTime - Number(idleTriggerOne) > 120)) {
      this.session.setItem(IDLE_ONE, String(Math.floor((new Date()).getTime() / 1000)));
      this.tracking.sendAltoCloudData(['record', 'userIdle1min']);
    }
  }, 60000);

  private _twoMinutesTimer = setTimeout(() => {
    const currentTime = Math.floor((new Date()).getTime() / 1000);
    const idleTriggerTwo = this.session.getItem(IDLE_TWO);
    if (!idleTriggerTwo || (idleTriggerTwo && currentTime - Number(idleTriggerTwo) > 120)) {
      this.session.setItem(IDLE_TWO, String(Math.floor((new Date()).getTime() / 1000)));
      this.tracking.sendAltoCloudData(['record', 'userIdle1min']);
    }
  }, 120000);

  constructor(
    protected tracking: TrackingService,
    protected router: Router,
    protected session: SessionService,
    protected cookies: CookieService,
  ) {
    const routerSub = router.events.subscribe(value => {
      if (value instanceof NavigationEnd) {
        this.checkBackEvent(value);
      }
    });

    this._subscriptions.push(routerSub);
  }

  @HostListener('click', ['$event'])
  async clickEventHandler(event) {
    this.resetCountDown();
  }

  @HostListener('window:scroll', ['$event'])
  async scrollEventHandler(event) {
    this.resetCountDown();
  }
  @HostListener('window:keyup', ['$event'])
  async keyupEventHandler(event) {
    this.resetCountDown();
  }

  resetCountDown() {
    clearTimeout(this._halfMinuteTimer);
    clearTimeout(this._twoMinutesTimer);
    clearTimeout(this._twoMinutesTimer);

    this._halfMinuteTimer = setTimeout(() => {
      const currentTime = Math.floor((new Date()).getTime() / 1000);
      const idleTriggerHalf = this.session.getItem(IDLE_HALF);
      if (!idleTriggerHalf || (idleTriggerHalf && currentTime - Number(idleTriggerHalf) > 120)) {
        this.session.setItem(IDLE_HALF, String(Math.floor((new Date()).getTime() / 1000)));
        this.tracking.sendAltoCloudData(['record', 'userIdle30sec']);
      }
    }, 30000);

    this._oneMinuteTimer = setTimeout(() => {
      const currentTime = Math.floor((new Date()).getTime() / 1000);
      const idleTriggerOne = this.session.getItem(IDLE_ONE);
      if (!idleTriggerOne || (idleTriggerOne && currentTime - Number(idleTriggerOne) > 120)) {
        this.session.setItem(IDLE_ONE, String(Math.floor((new Date()).getTime() / 1000)));
        this.tracking.sendAltoCloudData(['record', 'userIdle1min']);
      }
    }, 60000);

    this._twoMinutesTimer = setTimeout(() => {
      const currentTime = Math.floor((new Date()).getTime() / 1000);
      const idleTriggerTwo = this.session.getItem(IDLE_TWO);
      if (!idleTriggerTwo || (idleTriggerTwo && currentTime - Number(idleTriggerTwo) > 120)) {
        this.session.setItem(IDLE_TWO, String(Math.floor((new Date()).getTime() / 1000)));
        this.tracking.sendAltoCloudData(['record', 'userIdle1min']);
      }
    }, 120000);
  }

  checkBackEvent(value: NavigationEnd) {
    const currentTime = Math.floor((new Date()).getTime() / 1000);
    if (this.session.backTrigger && currentTime - Number(this.session.backTrigger) > 120) {
      this.session.turnedBack = undefined;
    }

    const sessionUrls = JSON.parse(this.session.pastUrls);
    this._pastUrls = sessionUrls ? sessionUrls : [];

    if (value.id !== this._lastId && (!this.session.turnedBack || this.session.turnedBack !== 'true')) {
      this._lastId = value.id;


      if (
        value.url === this._pastUrls[this._pastUrls.length - 2] &&
        value.url !== this._pastUrls[this._pastUrls.length - 1]
      ) {
        this.session.turnedBack = 'true';
        this.session.backTrigger = String(Math.floor((new Date()).getTime() / 1000));
        this.tracking.sendAltoCloudData(['record', 'navigationBack']);
      }
    }

    if (value.url !== this._pastUrls[this._pastUrls.length - 1]) {
      this._pastUrls.push(value.url);
      this.session.pastUrls = JSON.stringify(this._pastUrls.slice(this._pastUrls.length - 2));
    }
  }

  // ng LifeCycle hooks

  ngAfterViewInit(): void {

    if (!!customPlugin) {
      const customerInfo: ICustomerInfo = this.cookies.getJson('customerInfo');

      const subscription = customPlugin.subscribe('WebChat.opened', function (e) {
        if (!customerInfo) {
          return;
        }

        const inputs = document.getElementsByClassName('cx-input');

        const form = document.getElementsByClassName('cx-form-wrapper');

        let preFilled = false;
        if (form && form.length) {
          preFilled = form[0].getAttribute('style').includes('display: none;');
        }

        for (const input of Object.values(inputs)) {
          const inpt = input as HTMLInputElement;
          switch (input.getAttribute('name')) {
            case 'firstname':
              inpt.value = customerInfo.firstname;
              break;
            case 'lastname':
              inpt.value = customerInfo.lastname;
              break;
            case 'email':
              inpt.value = customerInfo.email;
              break;
          }
        }

        const btns = document.getElementsByClassName('cx-submit');
        if (btns && btns.length && !preFilled) {
          (btns[0] as HTMLElement).click();
        }
      });
    }
  }

  ngOnDestroy() {
    clearTimeout(this._oneMinuteTimer);
    clearTimeout(this._twoMinutesTimer);
    customPlugin.unsubscribe();
    this._subscriptions.map(subscription => subscription.unsubscribe());
  }

}
