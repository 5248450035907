<div class="gallery-modal shipping-prompt__modal">
  <div class="gallery-main">
  </div>
  <h1 class="shipping-prompt__title">Success</h1>
  <p class="shipping-prompt__description">
    The Design has been saved
  </p>
  <div class="shipping-prompt__actions">
    <button class="shipping-prompt__btn shipping-prompt__btn-combine" (click)="close()">Close</button>
  </div>
</div>
