import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, AfterViewInit } from '@angular/core';

// Types
import { ICartItem, ICartItemOrder } from '../../../../types/shopping-cart/ICartItem';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'diy-cart-item',
  templateUrl: './cart-item.component.html',
  styleUrls: ['../shopping-cart.component.scss']
})
export class CartItemComponent implements OnInit, OnDestroy {

  @Input() cartItem: ICartItem;
  @Input() cartIndex: number;
  @Input() itemChecked$: Subject<boolean>;

  @Output() checkedItem = new EventEmitter();
  @Output() clearChecked = new EventEmitter();

  deliveryDateType: string;
  deliveryDate: string;
  itemChecked = false;

  private _subscriptions$: Subscription[] = [];

  constructor() { }

  ngOnInit() {
    if (this.itemChecked$) {
      const subscription$ = this.itemChecked$.subscribe(state => {
        this.itemChecked = state;
      });
      this._subscriptions$.push(subscription$);
    }

    this.deliveryDate = this._getDeliveryDate();
  }

  ngOnDestroy() {
    this._subscriptions$.forEach(subscription$ => subscription$.unsubscribe());
  }

  checkItem() {
    this.checkedItem.emit(this.cartIndex);
  }

  clearCheckedItems() {
    this.clearChecked.emit();
  }

  private _getDeliveryDate(): string {
    const formatter = Intl.DateTimeFormat('en-us', { month: 'short' });
    const cartItemOrder = this._getMaxTurnaround();
    this.deliveryDateType = cartItemOrder.orderItem.turnaround.selectedOption.isGuaranteed ? 'Guaranteed Date: ' : 'Expected Date: ';
    const dates = this._getDeliveryDates(cartItemOrder);

    if (!cartItemOrder.orderItem.turnaround.selectedOption.isGuaranteed) {
      return `${formatter.format(dates.min)} ${dates.min.getDate()} - ${formatter.format(dates.max)}
      ${dates.max.getDate()}, ${dates.max.getFullYear()}`;
    } else {
      return `${formatter.format(dates.guaranteedDate)} ${dates.guaranteedDate.getDate()}, ${dates.guaranteedDate.getFullYear()}`;
    }
  }

  private _getDeliveryDates(orderItem: ICartItemOrder) {
    if (!orderItem.orderItem.turnaround.selectedOption.isGuaranteed) {
      let min = new Date(orderItem.orderItem.turnaround.selectedOption.datesInterval.normalStart);
      let max = new Date(orderItem.orderItem.turnaround.selectedOption.datesInterval.normalEnd);
      if (orderItem.orderItem.turnaround.skipSample) {
        min = new Date(orderItem.orderItem.turnaround.selectedOption.datesInterval.ssStart);
        max = new Date(orderItem.orderItem.turnaround.selectedOption.datesInterval.ssEnd);
      }
      return {
        min: min,
        max: max
      };
    } else {
      return {
        guaranteedDate: new Date(orderItem.orderItem.turnaround.selectedOption.guaranteedDate)
      };
    }
  }

  private _getMaxTurnaround(): ICartItemOrder {
    let cartItemOrder: ICartItemOrder;
    let maxDate: Date = new Date();
    this.cartItem.cartItemOrders.map(_orderItem => {
      let date;
      if (_orderItem.initialTurnaround.isGuaranteed) {
        date = _orderItem.initialTurnaround.guaranteedDate;
      } else if (_orderItem.orderItem.turnaround.skipSample) {
        date = _orderItem.initialTurnaround.datesInterval.ssEnd;
      } else {
        date = _orderItem.initialTurnaround.datesInterval.normalEnd;
      }
      date = new Date(date);
      if (maxDate.getTime() < date.getTime()) {
        maxDate = date;
        cartItemOrder = _orderItem;
      }
    });
    return cartItemOrder;
  }

}
