import { BundleActionUnion, BundleActionTypes } from './bundle.actions';
import { IBundle } from '../../types/bundle/IBundle';

const initialState: IBundle = {
  id: 0,
  name: '',
  price: 0,
  file: '',
  dateAdded: '',
  items: [],
  invoiceId: ''
};

export function reducer(state = initialState, action: BundleActionUnion) {

  switch (action.type) {
    case BundleActionTypes.SetBundleData: {
      return action.payload;
    }

    case BundleActionTypes.ClearBundleData: {
      return {
        id: 0,
        name: '',
        price: 0,
        file: '',
        dateAdded: '',
        items: [],
        invoiceId: ''
      };
    }

    case BundleActionTypes.SetBundleInvoiceId: {
      return { ...state, invoiceId: action.payload };
    }

  }

}
