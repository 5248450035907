<div class="shopping-cart__item-body">
  <div class="shopping-cart__item-avatar">
    <img [src]="artwork" alt="">
  </div>
  <div class="shopping-cart__item-products">
    <label>{{cartItemOrder?.orderItem?.product?.name}}</label>
    <ng-container *ngFor="let vt of cartItemOrder?.orderItem?.variationTypes">
      <p [ngSwitch]="vt.vtName">
        <ng-container *ngSwitchCase="'Quantity'">
          <b>QTY:</b>
          <ng-container *ngIf="vt.variation?.name === 'Bundle Quantity'; else defaultQty">
            {{vt.variation?.unitsAvailable}} x {{vt.variation?.unitsAvailable}} Credits
            at $0.00
            <p *ngIf="vt.variation.unitsSelected > vt.variation.unitsAvailable">
              {{ vt.variation?.unitsSelected - vt.variation?.unitsAvailable }} x
              {{cartItemOrder?.orderItem?.price?.pricePerUnit | currency:'USD'}}
              at
              {{cartItemOrder?.orderItem?.price?.pricePerUnit * (vt.variation?.unitsSelected - vt.variation?.unitsAvailable) | currency:'USD'}}
            </p>
          </ng-container>
          <ng-template #defaultQty>
            {{vt.variation?.customName || vt.variation?.name}}
            x {{cartItemOrder?.orderItem?.price?.pricePerUnit | currency:'USD'}}
            at
            {{ cartItemOrder?.orderItem?.price?.customizeTotal | currency:'USD':'symbol':'1.0-2'}}
          </ng-template>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <b>{{vt.vtName}}:</b> {{ vt?.variation?.customName || vt?.variation?.name}}
        </ng-container>
      </p>
    </ng-container>
  </div>

  <div class="shopping-cart__item-offset"></div>
  <div class="shopping-cart__item-options">
    <label>Customizations</label>
    <p
      *ngFor="let spOpt of (expanded ? cartItemOrder?.orderItem?.specialOptions : cartItemOrder?.orderItem?.specialOptions.slice(0,5))">
      <b>[{{spOpt?.vtName}}]</b> - {{spOpt?.variation?.name}}
      (<span *ngIf="getSpecialOptionPrice(spOpt?.variation, cartItemOrder?.orderItem?.price) > 0">+ </span>{{
          getSpecialOptionPrice(spOpt?.variation, cartItemOrder?.orderItem?.price) | currency:'USD':'symbol':'1.0-2'}})
    </p>
    <div *ngIf="cartItemOrder?.orderItem?.specialOptions?.length > 5" class="see-more-wrap">
      <button class="button button-noFill" (click)="toggleContainer()">
        <span *ngIf="expanded">Hide Customizations</span>
        <span *ngIf="!expanded">View All Customizations</span>
      </button>
    </div>
  </div>
  <div class="shopping-cart__item-offset"></div>

  <div class="shopping-cart__item-delivery">
    <label>Delivery Method</label>
    <p>{{cartItemOrder?.orderItem?.turnaround?.selectedOption?.name | titlecase}}: {{cartItemOrder?.orderItem?.price?.shippingTotal |
        currency:'USD'}}</p>
    <p *ngIf="cartItemOrder?.orderItem?.price?.guaranteedFee > 0">
      Guaranteed Date: +{{cartItemOrder?.orderItem?.price?.guaranteedFee | currency:'USD'}}
    </p>
  </div>

  <div class="shopping-cart__item-offset"></div>

  <div class="shopping-cart__item-price">
    <label>Total price</label>
    <diy-price-display [price]="cartItemOrder?.orderItem?.price"></diy-price-display>
  </div>
  <div class="shopping-cart__item-offset"></div>
  <div class="shopping-cart__item-actions">
    <button (click)="editDeliveryMethod()" class="shopping-cart__btn-edit">edit shipping
      method</button>
    <button (click)="deleteCartItemOrder()">delete order</button>
    <button *ngIf="isGrouped" (click)="ungroupShipping(cartItemOrderIndex)" class="shopping-cart__btn-ungroup">Ungroup
      Shipping</button>
  </div>
</div>

<diy-packaging-details *ngIf="cartItemOrder?.packagingOrder?.packagingSelections?.length"
  [cartItemOrder]="cartItemOrder" [cartIndex]="cartIndex" (packagingEdit)="ungroupShipping()"></diy-packaging-details>

<ts-confirm #confirmModal className="confirm-modal" title="Confirm deletion"
  subtitle="Are you sure you want to delete this order? This cannot be undone." confirmText="Delete"
  declineText="Nevermind">
</ts-confirm>
