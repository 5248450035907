import { IArtwork } from '../artwork/IArtwork';

/**
 * @author Liviu Dima
 */
export interface IDraft {
  urlParams: any;
  creationDate: number;
  productName: string;
  productId: number;
  deadline?: string;
  stepChange?: boolean;
  artwork: IArtwork | undefined;
}

export enum DraftSource {
  cart = 'cart'
}
