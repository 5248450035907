import { Action } from '@ngrx/store';

import { IProduct } from '../../types/product/IProduct';
import { ISelectedVariation } from '../../types/app-state/ISelectedVariation';
import { IArtwork } from '../../types/artwork/IArtwork';
import { IPackagingOrder } from '../../types/app-state/IPackagingOrder';

export enum PackagingOrderActionTypes {
  SelectProduct = '[Packaging] Select Product',
  SetPackagingOrder = '[Packaging] Set Order',
  ResetPackagingOrder = '[Packaging] Reset Order',
  AddPackagingArtwork = '[Packaging] Add Artwork',
  RemovePackagingArtwork = '[Packaging] Remove Artwork',
  AddPackagingVariation = '[Packaging] Add Variation',
  RemovePackagingVariation = '[Packaging] Remove Variation',
  ReplacePackagingVariations = '[Packaging] Replace Variation',
}

export class SelectProduct implements Action {
  readonly type = PackagingOrderActionTypes.SelectProduct;
  constructor(public payload: IProduct) { }
}

export class SetPackagingOrder implements Action {
  readonly type = PackagingOrderActionTypes.SetPackagingOrder;
  constructor(public payload: IPackagingOrder) { }
}

export class ResetPackagingOrder implements Action {
  readonly type = PackagingOrderActionTypes.ResetPackagingOrder;
  constructor() { }
}

export class AddPackagingArtwork implements Action {
  readonly type = PackagingOrderActionTypes.AddPackagingArtwork;
  constructor(public payload: IArtwork) { }
}
export class RemovePackagingArtwork implements Action {
  readonly type = PackagingOrderActionTypes.RemovePackagingArtwork;
  constructor() { }
}
export class AddPackagingVariation implements Action {
  readonly type = PackagingOrderActionTypes.AddPackagingVariation;
  constructor(public payload: ISelectedVariation) { }
}
export class RemovePackagingVariation implements Action {
  readonly type = PackagingOrderActionTypes.RemovePackagingVariation;
  constructor(public payload: ISelectedVariation) { }
}
export class ReplacePackagingVariations implements Action {
  readonly type = PackagingOrderActionTypes.ReplacePackagingVariations;
  constructor(public payload: ISelectedVariation[]) { }
}

export type PackagingOrderActionsUnion = SelectProduct | SetPackagingOrder | ResetPackagingOrder | AddPackagingArtwork |
  RemovePackagingArtwork | AddPackagingVariation | RemovePackagingVariation | ReplacePackagingVariations;
