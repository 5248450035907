import { select, Store } from '@ngrx/store';
import { IAppState } from '../../types/app-state/IAppState';
import { Subscription } from 'rxjs';
import { IShoppingCart } from '../../types/shopping-cart/IShoppingCart';
import { Injectable, OnDestroy } from '@angular/core';
import { IBundleItem, IAppliedBundle } from '../../types/bundle/IBundle';

@Injectable()
export class BundleIntegrityService implements OnDestroy {

  // keeps track of bundleItem ids applied to current cart
  appliedBundleItemIds: IAppliedBundle[] = [];

  private _subscriptions$: Subscription[] = [];

  constructor(private _store: Store<IAppState>) {

    const subscription$ = this._store.pipe(select('shoppingCart'))
      .subscribe((cart: IShoppingCart) => this.appliedBundleItemIds = this._reduceBundleIds(cart));

    this._subscriptions$.push(subscription$);
  }

  /**
   * Return array of matching bundle items (not used in cart)
   * Uid is passed only when editing cart items
   */
  getValidBundleItem(bundleItems: IBundleItem[], productId: string, uid: string = ''): IBundleItem[] {
    if (bundleItems.length <= 0 || !productId) {
      return [];
    }

    // product id must match and bundleItem id must not be already used
    return bundleItems.filter(item => {
      const appliedBundle = this.appliedBundleItemIds.find(_appliedBundle => _appliedBundle.bundleId === item.id);
      if (String(item.productId) === productId && (!appliedBundle || appliedBundle.uid === uid)) {
        return item;
      }
    });
  }

  ngOnDestroy() {
    this._subscriptions$.map(subscription$ => subscription$.unsubscribe());
  }

  // get an array of used bundleIds;
  private _reduceBundleIds(cart: IShoppingCart): IAppliedBundle[] {
    const ids = [];
    // iterate over cart to save bundleitemIds
    cart.cartItems.map(cartItem => {
      cartItem.cartItemOrders.map(cartItemOrder => {
        // if we have a bundle item push it
        if (cartItemOrder.orderItem.bundleItem && cartItemOrder.orderItem.bundleItem.id) {

          ids.push({
            uid: cartItemOrder.uid,
            bundleId: cartItemOrder.orderItem.bundleItem.id
          } as IAppliedBundle);
        }
      });
    });

    return ids;
  }

}
