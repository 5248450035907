import { Routes } from '@angular/router';

// Resolvers
import { OrderItemResolver } from './core/resolvers/order-item.resolver';

// Pages
import { HomeComponent } from './pages/home/home.component';
import { DraftComponent } from './pages/draft/draft.component';
import { ArtworkComponent } from './pages/artwork/artwork.component';
import { CustomizeComponent } from './pages/customize/customize.component';
import { SpecialOptionsComponent } from './pages/special-options/special-options.component';
import { TurnaroundComponent } from './pages/turnaround/turnaround.component';
import { AuthorizationComponent } from './pages/authorization/authorization.component';
import { IdentifyCustomerComponent } from './pages/identify-customer/identify-customer.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';

// Guards
import { AuthGuard } from './core/guards/auth-guard.service';
import { IdentifyCustomerGuard } from './core/guards/identify-customer-guard.service';
import { SalesRepGuard } from './core/guards/sales-rep-guard.service';

// Resolvers
import { CategoryComponent } from './pages/category/category.component';
import { CategoryResolver } from './core/resolvers/category.resolver';
import { TurnaroundResolver } from './core/resolvers/turnaround.resolver';
import { QuoteFormComponent } from './pages/quote-form/quote-form.component';
import { ThankYouComponent } from './pages/thank-you/thank-you.component';
import {PaymentGuard} from './shop/core/guards/payment-guard.service';

export const routes: Routes = [
  // Categories
  { path: '', component: HomeComponent },
  // Products
  {
    path: 'category/:categoryId',
    component: CategoryComponent,
    resolve: { category: CategoryResolver }
  },
  // Quote
  {
    path: 'category/:categoryId/product/:productId/welcome',
    component: QuoteFormComponent,
    resolve: { orderItem: OrderItemResolver },
  },
  { path: 'thank-you', component: ThankYouComponent },
  // Draft
  {
    path: 'category/:categoryId/product/:productId/draft',
    component: DraftComponent,
    canActivate: [AuthGuard],
    resolve: { orderItem: OrderItemResolver },
  },
  // Artwork
  {
    path: 'category/:categoryId/product/:productId/artwork',
    component: ArtworkComponent,
    canActivate: [AuthGuard],
    resolve: { orderItem: OrderItemResolver },
  },
  // Customize
  {
    path: 'category/:categoryId/product/:productId/customize',
    component: CustomizeComponent,
    canActivate: [AuthGuard],
    resolve: { orderItem: OrderItemResolver }
  },
  {
    path: 'category/:categoryId/product/:productId/customise',
    redirectTo: 'category/:categoryId/product/:productId/customize'
  },
  // Special Options
  {
    path: 'category/:categoryId/product/:productId/specialOptions',
    component: SpecialOptionsComponent,
    canActivate: [AuthGuard],
    resolve: { orderItem: OrderItemResolver },
  },
  // Turnaround
  {
    path: 'category/:categoryId/product/:productId/turnaround',
    component: TurnaroundComponent,
    canActivate: [PaymentGuard],
    resolve: { orderItem: TurnaroundResolver },
  },
  // Authorization -> used for authentication of sales rep
  {
    path: 'authorization/:token',
    component: AuthorizationComponent
  },
  // Identify Customer
  {
    path: 'customer',
    component: IdentifyCustomerComponent,
    canActivate: [SalesRepGuard],
    canDeactivate: [IdentifyCustomerGuard]
  },
  // Packaging module
  {
    path: 'category/:categoryId/product/:productId/packaging',
    loadChildren: () => import('./packaging/packaging.module').then(m => m.PackagingModule)
  },
  // Shop module
  {
    path: 'shop',
    loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule)
  },
  // 404 Not Found
  {
    path: '**',
    component: NotFoundComponent
  }
];
