<div class="form-identify-customer">
  <h1 class="form-control-title">Please identify your customer</h1>
  <div class="form-identify-customer-inner">
    <form [formGroup]="oldCustomerForm">
      <h1 class="form-control-title">Old Customer</h1>
      <h2 class="form-control-welcome-message">Type your customer's email address</h2>
      <p *ngIf="findError" class="error">{{findError}}</p>
      <div class="form-control-wrapper">
        <label class="form-control-label">
          <span>Email:</span><br />
          <input type="email" formControlName="email" class="form-control" autocomplete="doNotAutoCompleteMeChrome" />
        </label>
      </div>
      <div class="form-control-wrapper">
        <label class="form-control-label">
          <span>Quote Number:</span><br />
          <input type="text" formControlName="quoteNumber" class="form-control"
            autocomplete="doNotAutoCompleteMeChrome" />
        </label>
      </div>
      <button class="form-control-btn" (click)="continueOldCustomer()"
        [disabled]="(!oldCustomerForm.valid || !isValid()) || loading">
        <span *ngIf="!loading">Continue</span>
        <span *ngIf="loading">Loading</span>
      </button>
    </form>

    <form [formGroup]="newCustomerForm">
      <h1 class="form-control-title">New Customer</h1>
      <p *ngIf="createError" class="error">{{createError}}</p>
      <div class="form-control-wrapper">
        <label class="form-control-label">
          <span>First Name:</span><br />
          <input type="text" formControlName="firstname" [ngClass]="{'form--input-required': showError('firstname')}"
            class="form-control" autocomplete="doNotAutoCompleteMeChrome" />
        </label>
      </div>
      <div class="form-control-wrapper">
        <label class="form-control-label">
          <span>Last Name:</span><br />
          <input type="text" formControlName="lastname" [ngClass]="{'form--input-required': showError('lastname')}"
            class="form-control" autocomplete="doNotAutoCompleteMeChrome" />
        </label>
      </div>
      <div class="form-control-wrapper">
        <label class="form-control-label">
          <span>Email:</span>
          <span *ngIf="showError('email')" class="form--error">Please enter a valid e-mail.</span>
          <br />
          <input type="email" formControlName="email" [ngClass]="{'form--input-required': showError('email')}"
            class="form-control" autocomplete="doNotAutoCompleteMeChrome" />
        </label>
      </div>
      <div class="form-control-wrapper">
        <diy-phone-selector (countrySelected)="countrySelected($event)"> </diy-phone-selector>
        <label class="form-control-label">
          <span>Phone:</span>
          <span *ngIf="showError('phone')" class="form--error">Please enter a valid phone number.</span>
          <br />
          <input formControlName="phone" class="form-control phone-input"
            [ngClass]="{'form--input-required': showError('phone')}" autocomplete="doNotAutoCompleteMeChrome"
            phoneMask />
        </label>
      </div>
      <button class="form-control-btn" (click)="continueNewCustomer()" [disabled]="!newCustomerForm.valid || loading">
        <span *ngIf="!loading">Continue</span>
        <span *ngIf="loading">Loading</span>
      </button>
    </form>
  </div>
</div>
