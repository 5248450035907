import { Component } from '@angular/core';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'diy-promo-banner',
  templateUrl: './promo-banner.component.html',
  styleUrls: ['./promo-banner.component.scss']
})
export class PromoBannerComponent {
  enabled = false;
  discountAmount = 0;

  constructor() {
    if (!sessionStorage.getItem('promoBannerClosed')) {
      this.discountAmount = environment.alwaysSaleValue;
      this.enabled = environment.alwaysSaleBanner;
    }
  }

  close() {
    this.enabled = false;
    sessionStorage.setItem('promoBannerClosed', 'true');
  }
}
