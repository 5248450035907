<div id='trustmarks' class="trustmarks">
  <div class="trustmarks__trustpilot">
    <!-- TrustBox widget - Mini -->
    <div id='53d69c44000064000579285f' class="trustpilot-widget" data-locale="en-US"
      data-template-id="53aa8807dec7e10d38f59f32" data-businessunit-id="53d69c44000064000579285f"
      data-style-height="150px" data-style-width="100%" data-theme="light">
      <a href="https://www.trustpilot.com/review/thestudio.com" target="_blank" rel="noopener">Trustpilot</a>
    </div>
    <!-- End TrustBox widget -->
  </div>

  <!-- <div class="trustmarks__norton">
    <table width="135" border="0" cellpadding="2" cellspacing="0"
      title="Click to Verify - This site chose Symantec SSL for secure e-commerce and confidential communications.">
      <tr>
        <td id="seal" width="135" align="center" valign="top"></td>
      </tr>
    </table>
  </div> -->

  <div class="trustmarks__bbb">
    <a href="https://www.bbb.org/us/ca/san-francisco/profile/manufacturer-representative/the-studio-technologies-1116-914729/#sealclick"
      target="_blank" rel="nofollow"><img src="https://seal-goldengate.bbb.org/seals/blue-seal-280-80-bbb-914729.png"
        style="border: 0;" alt="The/Studio Technologies BBB Business Review" /></a>
  </div>
</div>
