import { Component, Input } from '@angular/core';

import { IPaymentItem } from '../../../../../types/payment/IPaymentSummary';
import { DiscountTypes } from '../../../../../types/voucher/IVoucherResponse';

@Component({
  selector: 'diy-payment-summary-item',
  templateUrl: './payment-summary-item.component.html',
  styleUrls: ['./payment-summary-item.component.scss']
})
export class PaymentSummaryItemComponent {

  @Input() summaryItem: IPaymentItem;

  discountTypes = DiscountTypes;
  isCollapsed = true;

  constructor() { }

  toggle(): void {
    this.isCollapsed = !this.isCollapsed;
  }

}
