import { ShoppingCartActionUnion, ShoppingCartActionTypes } from './shopping-cart.actions';

import { environment } from '../../../environments/environment';

import { CombineShippingService } from '../../shop/core/services/combine-shipping.service';
import { PriceCalculatorService } from '../../core/services/price-calculator';

import { IShoppingCart } from '../../types/shopping-cart/IShoppingCart';
import { ICartItem, ICartItemOrder } from '../../types/shopping-cart/ICartItem';
import { DiscountTypes } from '../../types/voucher/IVoucherResponse';
import { IVoucher } from '../../types/voucher/IVoucher';
import { IOrderItem } from '../../types/app-state/IOrderItem';

const initialState: IShoppingCart = {
  cartItems: [],
  orderTotal: 0,
  invoiceId: '',
  taxFee: 0,
  taxRate: 0,
  resellerLicense: undefined,
};

export function  reducer(state: IShoppingCart = initialState, action: ShoppingCartActionUnion): IShoppingCart {

  switch (action.type) {
    case ShoppingCartActionTypes.AddCartItem: {
      let cartItems;

      if (action.payload.index !== undefined) {
        cartItems = [...state.cartItems];
        cartItems.splice(action.payload.index, 0, action.payload.cartItem);
      } else {
        cartItems = [...state.cartItems, action.payload.cartItem];
      }

      let newCartItems = cartItems.map((cartItem: ICartItem) => {
        const newCartItemOrders = cartItem.cartItemOrders.map((cartItemOrder: ICartItemOrder) => {
          const newPrice = PriceCalculatorService.redoPrice(
            cartItemOrder.orderItem.product,
            { ...cartItemOrder.orderItem.price, voucher: undefined },
            cartItemOrder.orderItem.variationTypes,
            cartItemOrder.orderItem.specialOptions,
            cartItemOrder.orderItem.turnaround,
            cartItemOrder.orderItem.bundleItem
          );

          return { ...cartItemOrder, orderItem: { ...cartItemOrder.orderItem, price: newPrice } };
        });
        return { ...cartItem, cartItemOrders: newCartItemOrders };
      });

      newCartItems = redoDiscounts(newCartItems);

      const orderTotal = sumTotal(newCartItems);

      return {
        cartItems: newCartItems,
        orderTotal: orderTotal,
        invoiceId: state.invoiceId,
      };
    }

    case ShoppingCartActionTypes.RemoveCartItem: {
      let cartItems = [...state.cartItems].filter((val, idx) => idx !== action.index);

      cartItems = redoDiscounts(cartItems);

      const orderTotal = sumTotal(cartItems);

      return {
        cartItems: cartItems,
        orderTotal: orderTotal,
        invoiceId: state.invoiceId,
      };
    }

    case ShoppingCartActionTypes.RemoveCartItems: {
      let cartItems = [...state.cartItems].filter((val, idx) => !action.indexes.includes(idx));
      cartItems = redoDiscounts(cartItems);
      const orderTotal = sumTotal(cartItems);

      return {
        cartItems: cartItems,
        orderTotal: orderTotal,
        invoiceId: state.invoiceId,
      };
    }

    case ShoppingCartActionTypes.SetShoppingCart: {
      const cart = {
        ...action.payload,
        cartItems: redoDiscounts(action.payload.cartItems),
        orderTotal: sumTotal(action.payload.cartItems),
      };
      return cart;
    }

    case ShoppingCartActionTypes.ClearShoppingCart: {
      return {
        cartItems: [],
        orderTotal: 0,
        invoiceId: state.invoiceId,
      };
    }

    case ShoppingCartActionTypes.SetInvoiceId: {
      return { ...state, invoiceId: action.payload };
    }

    case ShoppingCartActionTypes.UpdateCartItemOrder: {
      const selectedItemIndex =
        state.cartItems[action.payload.index].cartItemOrders.findIndex(
          _cartItemOrder => _cartItemOrder.uid === action.payload.cartItemOrder.uid
        );

      // state.cartItems[action.payload.index].cartItemOrders[selectedItemIndex] = action.payload.cartItemOrder;

      const newCartItemOrders = [...state.cartItems[action.payload.index].cartItemOrders];

      const newPrice = PriceCalculatorService.redoPrice(
        newCartItemOrders[selectedItemIndex].orderItem.product,
        { ...newCartItemOrders[selectedItemIndex].orderItem.price, voucher: undefined },
        newCartItemOrders[selectedItemIndex].orderItem.variationTypes,
        newCartItemOrders[selectedItemIndex].orderItem.specialOptions,
        action.payload.cartItemOrder.orderItem.turnaround,
        newCartItemOrders[selectedItemIndex].orderItem.bundleItem
      );

      newCartItemOrders[selectedItemIndex] = {...action.payload.cartItemOrder,
        orderItem: {
          ...action.payload.cartItemOrder.orderItem,
          price: newPrice,
          // turnaround: {
          //   ...action.payload.cartItemOrder.orderItem.turnaround,
          //   skipSample: !action.payload.cartItemOrder.orderItem.turnaround.skipSample
          // }
        }
      };


      let newCartItems = [...state.cartItems];
      newCartItems[action.payload.index] = { ...newCartItemOrders[action.payload.index], cartItemOrders: newCartItemOrders };

      newCartItems = redoDiscounts(newCartItems);
      return {
        cartItems: newCartItems,
        orderTotal: sumTotal(newCartItems),
        invoiceId: state.invoiceId,
      };
    }

    case ShoppingCartActionTypes.RemoveCartItemOrder: {
      const combinedCartItem = Object.assign({}, state.cartItems[action.payload.cartIndex].cartItemOrders[action.payload.itemIndex]);
      let cartItems = [...state.cartItems];

      const newCartItemOrders = [...cartItems[action.payload.cartIndex].cartItemOrders];
      newCartItemOrders.splice(action.payload.itemIndex, 1);
      cartItems[action.payload.cartIndex] = { ...cartItems[action.payload.cartIndex], cartItemOrders: newCartItemOrders };

      if (!combinedCartItem.combinedWith) {
        const remainingCombinedItems = Object.assign({}, cartItems[action.payload.cartIndex]);
        const updatedRemainingItem = CombineShippingService.updateRemainingCombinedItems(remainingCombinedItems);
        cartItems.splice(action.payload.cartIndex, 1);
        if (!!updatedRemainingItem && !!updatedRemainingItem.cartItemOrders && !!updatedRemainingItem.cartItemOrders.length) {
          cartItems.splice(action.payload.cartIndex, 0, updatedRemainingItem);
        }
      }

      cartItems = redoDiscounts(cartItems);
      const orderTotal = sumTotal(cartItems);

      return {
        cartItems: cartItems,
        orderTotal: orderTotal,
        invoiceId: state.invoiceId,
      };
    }

    case ShoppingCartActionTypes.CombineCartItems: {
      let cartItemOrders: ICartItemOrder[] = [];
      let cartItems = state.cartItems.filter((val, idx) => !action.payload.includes(idx));
      for (const index of action.payload) {
        cartItemOrders = cartItemOrders.concat(state.cartItems[index].cartItemOrders);
      }

      const minIndex = action.payload[0];
      const combinedItems = CombineShippingService.combineCartItems(cartItemOrders);
      cartItems.splice(minIndex, 0, { cartItemOrders: combinedItems });

      cartItems = redoDiscounts(cartItems);

      const orderTotal = sumTotal(cartItems);

      return {
        cartItems: cartItems,
        orderTotal: orderTotal,
        invoiceId: state.invoiceId,
      };
    }

    case ShoppingCartActionTypes.UngroupCartItemOrder: {
      let cartItems = [...state.cartItems];
      const combinedCartItem = Object.assign({}, state.cartItems[action.payload.cartIndex].cartItemOrders[action.payload.itemIndex]);
      const newCartItemOrders = [...cartItems[action.payload.cartIndex].cartItemOrders];
      newCartItemOrders.splice(action.payload.itemIndex, 1);
      cartItems[action.payload.cartIndex] = { ...cartItems[action.payload.cartIndex], cartItemOrders: newCartItemOrders };

      const ungroupedCartItemOrder = CombineShippingService.ungroupCartItemOrder(Object.assign({}, combinedCartItem));
      const newItemIndex = action.payload.itemIndex === 0 ? action.payload.cartIndex : action.payload.cartIndex + 1;

      if (!combinedCartItem.combinedWith) {
        const remainingCombinedItems = Object.assign({}, cartItems[action.payload.cartIndex]);
        const updatedRemainingItem = CombineShippingService.updateRemainingCombinedItems(remainingCombinedItems);
        cartItems.splice(action.payload.cartIndex, 1);
        cartItems.splice(newItemIndex, 0, updatedRemainingItem);
      }

      const newCartItem = { cartItemOrders: [ungroupedCartItemOrder] };
      cartItems.splice(newItemIndex, 0, newCartItem);

      cartItems = redoDiscounts(cartItems);
      const orderTotal = sumTotal(cartItems);

      return {
        cartItems: cartItems,
        orderTotal: orderTotal,
        invoiceId: state.invoiceId,
      };
    }

    case ShoppingCartActionTypes.ApplyDiscountVoucher: {
      let cartItems = state.cartItems;
      const voucher = action.payload.voucher;
      const productIds = action.payload.productIds;

      if (voucher.type === DiscountTypes.AMOUNT) {
        // then search for the cartItemOrder with the max finalTotal and apply voucher on that
        const cartItemOrders = [];
        cartItems.forEach((cartItem) => {
          cartItem.cartItemOrders.forEach(cartItemOrder => {
            if (productIds.includes(Number(cartItemOrder.orderItem.product.id))) {
              cartItemOrders.push(cartItemOrder);
            }
          });
        });
        const maxOrderItem = cartItemOrders.reduce((prev: ICartItemOrder, current: ICartItemOrder) => {
          return (prev.orderItem.price.finalTotal > current.orderItem.price.finalTotal) ? prev : current;
        });
        cartItems.forEach(cartItem => {
          cartItem.cartItemOrders = cartItem.cartItemOrders.map(cartItemOrder => {
            if (maxOrderItem && (cartItemOrder === maxOrderItem)) {
              _applyVoucher(voucher, cartItemOrder);
            }
            return cartItemOrder;
          });
        });
      } else {
        // apply percentage voucher on all valid cartItemOrders
        cartItems.forEach(cartItem => {
          cartItem.cartItemOrders = cartItem.cartItemOrders.map(cartItemOrder => {
            if (productIds.includes(Number(cartItemOrder.orderItem.product.id))) {
              _applyVoucher(voucher, cartItemOrder);
            }
            return cartItemOrder;
          });
        });
      }

      cartItems = redoDiscounts(cartItems);
      const orderTotal = sumTotal(cartItems);

      return {
        cartItems: cartItems,
        orderTotal: orderTotal,
        invoiceId: state.invoiceId,
        taxFee: _redoTaxFee(state.taxRate, orderTotal),
        taxRate: state.taxRate,
      };
    }


    case ShoppingCartActionTypes.RemoveDiscountVoucher: {
      let cartItems = state.cartItems;

      cartItems.forEach(cartItem => {
        cartItem.cartItemOrders = cartItem.cartItemOrders.map(cartItemOrder => {
          cartItemOrder.orderItem.price.voucher = undefined;
          cartItemOrder.orderItem.price = Object.assign({}, PriceCalculatorService.redoPrice(
            cartItemOrder.orderItem.product,
            cartItemOrder.orderItem.price,
            cartItemOrder.orderItem.variationTypes,
            cartItemOrder.orderItem.specialOptions,
            cartItemOrder.orderItem.turnaround,
            cartItemOrder.orderItem.bundleItem
          ));
          return cartItemOrder;
        });
      });
      cartItems = redoDiscounts(cartItems);
      const orderTotal = sumTotal(cartItems);

      return {
        cartItems: cartItems,
        orderTotal: orderTotal,
        invoiceId: state.invoiceId,
        taxFee: _redoTaxFee(state.taxRate, orderTotal),
        taxRate: state.taxRate,
      };
    }

    case ShoppingCartActionTypes.RemoveQuoteIds: {
      let cartItems = [...state.cartItems].map((cartItem: ICartItem) => ({
        ...cartItem,
        cartItemOrders: [
          ...cartItem.cartItemOrders
        ].map((cartItemOrder: ICartItemOrder) => ({
          ...cartItemOrder,
          orderItem: { ...cartItemOrder.orderItem, quoteId: undefined } as IOrderItem
        }))
      }));

      cartItems = redoDiscounts(cartItems);
      const orderTotal = sumTotal(cartItems);

      return {
        cartItems: cartItems,
        orderTotal: orderTotal,
        invoiceId: state.invoiceId,
      };
    }

    case ShoppingCartActionTypes.RemovePackagingOption: {
      let cartItems = state.cartItems;

      cartItems.forEach(cartItem => {
        cartItem.cartItemOrders.forEach(cartItemOrder => {
          if (cartItemOrder.uid === action.payload) {
            cartItemOrder.packagingOrder = undefined;
          }
        });
      });

      cartItems = redoDiscounts(cartItems);
      const orderTotal = sumTotal(cartItems);

      return {
        cartItems: cartItems,
        orderTotal: orderTotal,
        invoiceId: state.invoiceId,
      };
    }

    case ShoppingCartActionTypes.SetTaxFee: {
      if (!environment.resellerTaxActive) {
        return state;
      }

      const taxRate = action.payload && action.payload.value ?
        action.payload.value : 0;
      const taxFee = _redoTaxFee(taxRate, state.orderTotal);

      return {
        ...state,
        taxFee: taxFee,
        taxRate: taxRate
      };
    }

    case ShoppingCartActionTypes.SetResellerLicense: {
      if (!environment.resellerTaxActive) {
        return state;
      }

      return {
        ...state,
        taxFee: action.payload ? 0 : state.taxFee,
        taxRate: action.payload ? 0 : state.taxRate,
        resellerLicense: action.payload
      };
    }

    case ShoppingCartActionTypes.SetQuoteId: {
      state.cartItems.forEach(cartItem => {
        const foundCartItemOrder = cartItem.cartItemOrders.find(cartItemOrder => cartItemOrder.uid === action.payload.uid);
        if (foundCartItemOrder) {
          foundCartItemOrder.orderItem.quoteId = action.payload.quoteId;
          foundCartItemOrder.orderItem.customerEmail = action.payload.customerEmail;
        }
      });

      return {
        ...state,
        cartItems: state.cartItems,
      };
    }

    default: {
      return state;
    }

  }
}

function sumTotal(cartItems: ICartItem[]): number {
  let total = 0;
  if (cartItems.length) {
    cartItems.forEach(cartItem => {
      if (!cartItem || !cartItem.cartItemOrders.length) {
        return 0;
      }

      total += cartItem.cartItemOrders.map(cartItemOrder => {
        return cartItemOrder.packagingOrder &&
          cartItemOrder.packagingOrder.price &&
          cartItemOrder.packagingOrder.price.finalTotal ?
          cartItemOrder.packagingOrder.price.finalTotal + cartItemOrder.orderItem.price.finalTotal :
          cartItemOrder.orderItem.price.finalTotal;
      }).reduce((prev, next) => prev + next);
    });
  }
  return total;
}

function redoDiscounts(cartItems: ICartItem[]): ICartItem[] {
  if (!cartItems.length) {
    return [];
  }

  if (!environment.promotionActive) {
    return cartItems;
  }

  const promoDiscountValue = environment.promotionValue;

  let orderItems: ICartItemOrder[] = [];

  cartItems.forEach(cartItem => {
    if (cartItem) {
      orderItems = [...orderItems, ...cartItem.cartItemOrders];
    }
  });

  if (!orderItems.length) {
    return [];
  }

  // reset previous discounts
  cartItems.forEach(cartItem => {
    cartItem.cartItemOrders.map(cartItemOrder => {
      const orderItem = cartItemOrder.orderItem;

      orderItem.price.promotionalDiscountValue = 0;
      orderItem.price.promotionalDiscount = 0;

      orderItem.price = PriceCalculatorService.redoPrice(
        orderItem.product,
        orderItem.price,
        orderItem.variationTypes,
        orderItem.specialOptions,
        orderItem.turnaround,
        orderItem.bundleItem
      );
    });
  });

  const maxOrderItem = orderItems.reduce((prev: ICartItemOrder, current: ICartItemOrder) => {
    return (prev.orderItem.price.finalTotal > current.orderItem.price.finalTotal) ? prev : current;
  });

  const duplicateConfigurationsUids = getDuplicateConfigurations(orderItems, maxOrderItem.uid);

  // apply discounts
  cartItems.forEach(cartItem => {
    cartItem.cartItemOrders.map(cartItemOrder => {

      const foundIndex = duplicateConfigurationsUids.findIndex(item => item === cartItemOrder.uid);

      // apply the discount to every item exept the highest value one
      if (cartItemOrder.uid !== maxOrderItem.uid && !~foundIndex) {
        cartItemOrder.orderItem.price.promotionalDiscount = promoDiscountValue;
      }
      cartItemOrder.orderItem.price = Object.assign({}, PriceCalculatorService.redoPrice(
        cartItemOrder.orderItem.product,
        cartItemOrder.orderItem.price,
        cartItemOrder.orderItem.variationTypes,
        cartItemOrder.orderItem.specialOptions,
        cartItemOrder.orderItem.turnaround,
        cartItemOrder.orderItem.bundleItem
      ));
    });
  });

  return cartItems;
}

/**
 * Return uids of duplicate configs
 * @param orderItems
 */
function getDuplicateConfigurations(orderItems: ICartItemOrder[], maxOrderItemUid: string): Array<string> {
  const orderItemConfigurations = orderItems.map((orderItem: ICartItemOrder) => {
    const variations = orderItem.orderItem.variationTypes.filter(vt => vt.vtName !== 'Quantity');

    // concat ids of variations and ids of special options
    const allVariations = variations.map(vt => vt.variation.id).concat(orderItem.orderItem.specialOptions.map(vt => vt.variation.id));

    return {
      uid: orderItem.uid,
      variations: allVariations.sort().join('-')
    };
  });

  const duplicateConfigs = [];

  orderItemConfigurations.forEach(orderItemConfig => {
    const index = orderItemConfigurations.findIndex(
      item =>
        (item.uid !== orderItemConfig.uid) &&
        (item.variations === orderItemConfig.variations) &&
        (item.uid === maxOrderItemUid || orderItemConfig.uid === maxOrderItemUid)
    );

    if (!!~index) {
      duplicateConfigs.push(orderItemConfig.uid);
    }
  });

  return duplicateConfigs;
}

function _applyVoucher(voucher: IVoucher, cartItemOrder: ICartItemOrder): ICartItemOrder {
  cartItemOrder.orderItem.price.voucher = voucher;
  cartItemOrder.orderItem.price = Object.assign({}, PriceCalculatorService.redoPrice(
    cartItemOrder.orderItem.product,
    cartItemOrder.orderItem.price,
    cartItemOrder.orderItem.variationTypes,
    cartItemOrder.orderItem.specialOptions,
    cartItemOrder.orderItem.turnaround,
    cartItemOrder.orderItem.bundleItem
  ));
  return cartItemOrder;
}

function _redoTaxFee(taxRate: number, orderTotal: number): number {
  if (!environment.resellerTaxActive) {
    return 0;
  }
  return taxRate ? orderTotal * (taxRate / 100) : 0;
}
