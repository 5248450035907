import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { TSConfirmComponent } from 'tscommon';

import { SetOrderItem } from '../../../../state-manager/order-item/order-item.actions';
import { SetPackagingOrder } from '../../../../state-manager/packaging-order/packaging-order.actions';
import { RemovePackagingOption } from '../../../../state-manager/shopping-cart/shopping-cart.actions';

import { ComStr } from '../../../../core/services/ComStr';
import { NavigatorService } from '../../../../core/services/navigator';
import { PriceCalculatorService } from '../../../../core/services/price-calculator';

import { ICartItemOrder } from '../../../../types/shopping-cart/ICartItem';
import { IAppState } from '../../../../types/app-state/IAppState';
import { ISelectedVariation } from '../../../../types/app-state/ISelectedVariation';
import { PackagingVariationTypeTypes } from '../../../../types/product/variations/IVariationType';
import { IVariation } from '../../../../types/product/variations/IVariation';

@Component({
  selector: 'diy-packaging-details',
  templateUrl: './packaging-details.component.html',
  styleUrls: [
    './packaging-details.component.scss',
    '../shopping-cart.component.scss'
  ]
})
export class PackagingDetailsComponent implements OnInit {

  @Input() cartItemOrder: ICartItemOrder;
  @Input() cartIndex: number;

  @Output() packagingEdit = new EventEmitter<boolean>();

  @ViewChild('confirmModal', { static: true }) confirm: TSConfirmComponent;

  artworkUrl: string;

  packageSize: ISelectedVariation;
  packageType: ISelectedVariation;
  packageOption: ISelectedVariation;

  constructor(
    private _store: Store<IAppState>,
    private _navigator: NavigatorService,
  ) { }

  ngOnInit(): void {
    this.artworkUrl = this._getArtwork();

    this.packageOption = this.cartItemOrder.packagingOrder.packagingSelections
      .find(selectedVariation => selectedVariation.vtCategory === PackagingVariationTypeTypes.options);

    this.packageType = this.cartItemOrder.packagingOrder.packagingSelections
      .find(selectedVariation => selectedVariation.vtCategory === PackagingVariationTypeTypes.type);

    this.packageSize = this.cartItemOrder.packagingOrder.packagingSelections
      .find(selectedVariation => selectedVariation.vtCategory === PackagingVariationTypeTypes.size);
  }

  async editPackaging() {
    this.packagingEdit.emit(true);
    this._store.dispatch(new SetOrderItem(this.cartItemOrder.orderItem));
    this._store.dispatch(new SetPackagingOrder(this.cartItemOrder.packagingOrder));

    const cartItemParams = { uid: this.cartItemOrder.uid, index: this.cartIndex };

    await this._navigator.navigate('packaging-review', cartItemParams);
  }

  getPrice(selection: IVariation) {
    const price = this.cartItemOrder.packagingOrder.price;
    return PriceCalculatorService.getVariationPrice(selection, price.quantity, price.pricePerUnit);
  }

  openDeletePackageModal() {
    this.confirm.show();
    this.confirm.onClose$.pipe(take(1)).subscribe(response => {
      if (response) {
        this._store.dispatch(new RemovePackagingOption(this.cartItemOrder.uid));
      }
    });
  }

  private _getArtwork(): string {
    let imageSrc: string;

    if (
      this.cartItemOrder.packagingOrder.artwork &&
      this.cartItemOrder.packagingOrder.artwork.files &&
      this.cartItemOrder.packagingOrder.artwork.files.length
    ) {
      return ComStr.getDisplayableImage(this.cartItemOrder.packagingOrder.artwork.files);
    } else {
      const productImages = this.cartItemOrder.packagingOrder.product.images;
      if (productImages.length) {
        let images = this.cartItemOrder.packagingOrder.product.images.find(img => img.main);
        if (!images) {
          images = this.cartItemOrder.packagingOrder.product.images[0];
        }
        imageSrc = images.small;
      } else {
        imageSrc = '/assets/img/packaging/packaging_placeholder.png';
      }
    }

    return ComStr.checkImageFormat(imageSrc);

  }

}
