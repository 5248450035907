import { Injectable } from '@angular/core';

declare const store: any;
const _localStorage = store;

/**
 * Service that uses store.js to CRUD the localStorage
 *
 * @author Andrei Diaconu
 */
@Injectable()
export class LocalStorageService {

  setItem(key: string, value: any) {
    _localStorage.set(key, value);
  }

  getItem(key: string) {
    return _localStorage.get(key);
  }

  removeItem(key: string) {
    _localStorage.remove(key);
  }
}
