import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';

import { CookieService } from '../../core/services/cookie.service';
import { OrderMessengerService } from '../../core/services/order-messenger';
import { ComStr } from '../../core/services/ComStr';
import { QuoteService } from '../../core/services/quote.service';

import { IQuote, ICustomerInfo } from '../../types/quote/IQuote';

@Component({
  templateUrl: './identify-customer.component.html',
  styleUrls: ['./identify-customer.component.scss']
})
export class IdentifyCustomerComponent implements OnInit {

  newCustomerForm: FormGroup;
  oldCustomerForm: FormGroup;
  loading = false;

  findError = '';
  createError = '';
  selectedCountryPrefix: string;

  constructor(
    private _formBuilder: FormBuilder,
    private _router: Router,
    private _cookieService: CookieService,
    private _quoteService: QuoteService,
    public _oms: OrderMessengerService
  ) {
    const quotes: IQuote[] = this._quoteService.getQuotes();
    if (quotes && quotes.length) {
      this._router.navigate(['/']);
      return;
    }
    this.newCustomerForm = _formBuilder.group({
      firstname: ['', Validators.compose([
        Validators.required,
        Validators.minLength(2)
      ])],
      lastname: ['', Validators.compose([
        Validators.required,
        Validators.minLength(2)
      ])],
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern(ComStr.EMAIL_REGEX)
      ])],
      phone: ['', this._customPhoneValidator]
    });

    this.oldCustomerForm = _formBuilder.group({
      email: ['', Validators.compose([
        Validators.pattern(ComStr.EMAIL_REGEX)
      ])],
      quoteNumber: ['', Validators.compose([
        Validators.minLength(3)
      ])]
    });
  }

  ngOnInit() {
    this._oms.updateStep(2.25);
  }

  async continueNewCustomer(): Promise<void> {
    this.loading = true;

    const customerInfo: ICustomerInfo = {
      firstname: this.newCustomerForm.value.firstname,
      lastname: this.newCustomerForm.value.lastname,
      email: this.newCustomerForm.value.email,
      phone: this.newCustomerForm.value.phone ?
        `${this.selectedCountryPrefix}${this.newCustomerForm.value.phone.replace(/\D/g, '')}` : ''
    };

    await this._quoteService.createQuote(customerInfo, 92);

    this.loading = false;
    this._cookieService.delete('paymentData');
    sessionStorage.removeItem('diy_quote');

    this._router.navigate(['/']);
  }

  async continueOldCustomer(): Promise<void> {
    this.loading = true;

    const email = this.oldCustomerForm.value.email ? encodeURI(this.oldCustomerForm.value.email) : undefined;
    const quoteNumber = this.oldCustomerForm.value.quoteNumber;

    const response = await this._quoteService.findQuote(email, quoteNumber);
    this.findError = response;

    this.loading = false;
    this._cookieService.delete('paymentData');
    sessionStorage.removeItem('diy_quote');

    if (this.findError === '') {
      this._router.navigate(['/']);
    }
  }

  isValid(): boolean {
    return this.oldCustomerForm.value.email || this.oldCustomerForm.value.quoteNumber;
  }

  showError(controlName: string): boolean {
    const formControl = this.newCustomerForm.get(controlName);
    return formControl.dirty && formControl.invalid;
  }

  countrySelected(prefix: string) {
    this.selectedCountryPrefix = prefix;
  }

  private _customPhoneValidator(control: FormControl) {
    const phoneNumber = control.value.replace(/[^0-9]/g, '');
    return !phoneNumber || ComStr.PHONE_REGEX.test(phoneNumber) ?
      null : {
        phone: true
      };
  }

}
