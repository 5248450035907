import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthGuard } from '../../../core/guards/auth-guard.service';
import { QuoteService } from '../../../core/services/quote.service';
import { TrackingService } from '../../../core/services/tracking';

@Component({
  templateUrl: './bundle-login.component.html',
  styleUrls: ['./bundle-login.component.scss']
})
export class BundleLoginComponent implements OnInit {

  title = 'Account Found';
  subtitle = 'Looks like you have an existing account with a pre-paid package already. Log in for faster checkout.';

  constructor(
    private _tracking: TrackingService,
    private _router: Router,
    private _authGuard: AuthGuard,
    private _quoteService: QuoteService,
  ) { }

  ngOnInit() {
    this._tracking.sendAltoCloudData(['pageview', {
      location: location.pathname,
      title: 'Bundle Login'
    }]);
  }

  authCompleted(differentUser: boolean) {
    if (differentUser) {
      // TODO handle different user login
    }

    if (this._authGuard.returnUrl) {
      const quote = this._quoteService.getCurrentQuote();
      let currentUrl = this._authGuard.returnUrl;

      if (quote && quote.quoteId) {
        currentUrl = this._updateCurrentUrl(this._authGuard.returnUrl, quote.quoteId);
      }

      this._authGuard.returnUrl = undefined;

      return this._router.navigateByUrl(currentUrl, { replaceUrl: true });
    }

    return this._router.navigate(['/'], { replaceUrl: true });
  }

  private _updateCurrentUrl(currentUrl: string, quoteId: number) {
    let newUrl = currentUrl;
    if (!newUrl.includes('quoteId')) {
      newUrl += newUrl.includes('?') ? `&quoteId=${quoteId}` : `?quoteId=${quoteId}`;
    }

    return newUrl;
  }

}
