<div class="payment__item">
  <p class="payment__item--title">Notice to California Residents:</p>
  <p class="payment__item--subtitle">All California residents will be charged a ‘CA Sales Tax’. Businesses with a
    California Resale Certificate may be waived from this fee.</p>

  <ng-container *ngIf="!validLicense; else licenseUploaded">
    <p class="payment__item--title">Upload License (Optional)</p>
    <label class="upload-file">
      <input #fileSelect class="init-file-field" (change)="handleFileInput($event)" type="file"
        accept=".png,.jpg,.jpeg,.pdf" [disabled]="uploadedTaxFile?.name" />
    </label>
    <div *ngIf="uploadedTaxFile">
      <p class="delete-uploaded-image">
        {{ uploadedTaxFile?.name }}
        <button (click)="deleteFile()">Delete</button>
      </p>

    </div>
    <p *ngIf="errorMessage" class="error">{{ errorMessage }}</p>
  </ng-container>
</div>

<ng-template #licenseUploaded>
  <br>
  <p>
    You already uploaded a reseller license. To manage your license, please contact customer service.
  </p>
</ng-template>
