<!-- Header -->
<div class="header-content">
  <diy-header></diy-header>
</div>

<!-- Page content -->
<div class="container">
  <div class="payment mt-5">
    <div class="heading-primary">
      <h1>Payment</h1>
      <p>Just a few more details, like who's paying and where we should put your credits.</p>
    </div>

    <div *ngIf="!isLogged()">
      <span><b>Already Have an Account?</b></span>
      <button class="simple-green-btn ml-3" (click)="showLoginModal()">Log in</button>
    </div>

    <!-- Shipping data -->
    <diy-shipping-address-form [customerShippingData]="customerData?.shipping" [formValidation$]="formValidation$"
      [error$]="shippingError$" [data]="shippingDraftData" (formState)="getShippingData($event)">
    </diy-shipping-address-form>

    <!-- Billing Data -->
    <diy-billing-address-form [customerBillingData]="customerData?.billing" [formValidation$]="formValidation$"
      [error$]="billingError$" [data]="billingDraftData" (formState)="getBillingData($event)">
    </diy-billing-address-form>

    <!-- Card data -->
    <diy-payment-form [customerCardData]="customerData?.card" [formValidation$]="formValidation$" [error$]="cardError$"
      (formState)="getPaymentData($event)">
    </diy-payment-form>

    <button class="button button--green d-block d-md-none" type="button" [disabled]="processing"
      (click)="triggerValidation()">
      <span *ngIf="!processing else processingTemplate">Confirm Payment</span>
    </button>

    <diy-payment-summary [summaryData]="summaryData"></diy-payment-summary>

    <button class="button button--green" type="button" [disabled]="processing" (click)="triggerValidation()">
      <span *ngIf="!processing else processingTemplate">Confirm Payment</span>
    </button>

  </div>

  <ng-template #processingTemplate>
    <span>Processing Order...</span>
  </ng-template>
</div>

<ts-modal #bundleLoginPrompt>
  <diy-login-prompt (authComplete)="onAuthComplete()"></diy-login-prompt>
</ts-modal>
