import { Component, ElementRef, OnInit, Output, EventEmitter, Input, SimpleChanges, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidationErrors } from '@angular/forms';
import { Subject, Subscription, BehaviorSubject } from 'rxjs';

import { PaymentItemBaseComponent } from '../payment-item-base.component';

import { CustomerDataProvider } from '../../../core/api/CustomerDataProvider';

import { ComStr } from '../../../../core/services/ComStr';

import { getExpirationDate, getCCThumb } from '../../../../utility/card.utils';

import { PaymentMethod } from '../../../../types/payment/PaymentMethod';
import { RemainingBalanceDue } from '../../../../types/payment/RemainingBalanceDue';
import { IPaymentError } from '../../../../types/payment/IFormState';
import { ICustomerCardData } from '../../../../types/api/ICustomerPaymentData';

// const for min year value validation
const CURRENT_YEAR = Number(String((new Date()).getFullYear()).substr(-2));

/**
 * @author Liviu Dima
 */
@Component({
  selector: 'diy-sales-payment-form',
  templateUrl: './sales-payment-form.component.html',
  styleUrls: ['./sales-payment-form.component.scss']
})
export class SalesPaymentFormComponent extends PaymentItemBaseComponent implements OnInit, OnChanges {

  @Input() orderTotal: number;
  @Input() isConfirmed$: Subject<boolean>;
  @Input() customerCardData: ICustomerCardData[];
  @Input() customerId: number;
  @Output() updatePaymentMethod$ = new EventEmitter<string>();


  form: FormGroup;
  prePaymentPercentages: number[] = [];

  paymentMethod = PaymentMethod;

  remainingBalanceDue = RemainingBalanceDue;

  enablePartialPayment = true;

  showCardForm = true;

  selectedCard: ICustomerCardData;

  hideAfterShipFolloup$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  hidePartialPaymentDays$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  hidePaymentAfterProductionReason$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  private _selectedPaymentMethod: PaymentMethod = PaymentMethod.Card;

  // card data validators definition
  private _cardDataValidators = {
    cardHolder: Validators.required,
    cardNumber: Validators.compose([
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(16),
      Validators.pattern(ComStr.NUMBER_ONLY_REGEX)
    ]),
    cardCCV: Validators.compose([
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(6),
      Validators.pattern(ComStr.NUMBER_ONLY_REGEX)
    ]),
    cardExpireMo: Validators.compose([
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(2),
      Validators.min(1),
      Validators.max(12),
      Validators.pattern(ComStr.NUMBER_ONLY_REGEX)
    ]),
    cardExpireYr: Validators.compose([
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(2),
      Validators.min(CURRENT_YEAR),
      Validators.pattern(ComStr.NUMBER_ONLY_REGEX)
    ])
  };

  // check data validators definition
  private _checkDataValidators = {
    initialInvoice: Validators.compose([
      Validators.maxLength(3),
      Validators.min(0),
      Validators.max(100),
      Validators.pattern(ComStr.NUMBER_ONLY_REGEX)
    ]),
    mockup: Validators.compose([
      Validators.maxLength(3),
      Validators.min(0),
      Validators.max(100),
      Validators.pattern(ComStr.NUMBER_ONLY_REGEX)
    ]),
    shipping: Validators.compose([
      Validators.maxLength(3),
      Validators.min(0),
      Validators.max(100),
      Validators.pattern(ComStr.NUMBER_ONLY_REGEX)
    ]),
    finalInvoice: Validators.compose([
      Validators.maxLength(3),
      Validators.min(0),
      Validators.max(100),
      Validators.pattern(ComStr.NUMBER_ONLY_REGEX)
    ]),
    sample: Validators.compose([
      Validators.maxLength(3),
      Validators.min(0),
      Validators.max(100),
      Validators.pattern(ComStr.NUMBER_ONLY_REGEX)
    ]),
  };

  /**
   * Class constructor
   * @param _formBuilder
   * @param el
   */
  constructor(
    private _formBuilder: FormBuilder,
    protected el: ElementRef,
    private _customerApi: CustomerDataProvider
  ) {
    super(el);

    this.form = this._formBuilder.group({
      partialPayment: ['No', Validators.required],
      prePaymentPercentage: [''],
      prePaymentAmount: [{ value: '', disabled: true }],
      totalInvoiceAmount: [{ value: '', disabled: true }],
      remainingBalance: [{ value: '', disabled: true }],
      partialPaymentDays: [0],
      remainingBalanceDue: [''],
      paymentMethod: [PaymentMethod.Card, Validators.required],
      cardData: this._formBuilder.group({
        cardHolder: ['', Validators.required],
        cardNumber: ['', Validators.compose([
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(16),
          Validators.pattern(ComStr.NUMBER_ONLY_REGEX)
        ])],
        cardCCV: ['', Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(6),
          Validators.pattern(ComStr.NUMBER_ONLY_REGEX)
        ])],
        cardExpireMo: ['', Validators.compose([
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(2),
          Validators.min(1),
          Validators.max(12),
          Validators.pattern(ComStr.NUMBER_ONLY_REGEX)
        ])],
        cardExpireYr: ['', Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(2),
          Validators.min(CURRENT_YEAR),
          Validators.pattern(ComStr.NUMBER_ONLY_REGEX)
        ])],
        id: ['']
      }),
      checkData: this._formBuilder.group({
        initialInvoice: [0],
        mockup: [0],
        sample: [0],
        shipping: [0],
        finalInvoice: [0],
        afterShipFollowup: [0],
        paymentAfterProductionReason: ['']
      })
    });
  }

  ngOnInit(): void {
    for (let i = 20; i <= 90; i += 5) {
      this.prePaymentPercentages.push(i);
    }

    let subscription$: Subscription;
    // trigger form validation
    if (this.formValidation$) {
      subscription$ = this.formValidation$.subscribe(formValidation => {
        if (formValidation.validate) {
          if (!formValidation.isConfirmed && this._selectedPaymentMethod === PaymentMethod.Card) {
            this._resetValidators();
          }

          this.isFormValid(this.form);
          this.formState.emit({
            formData: this.form.getRawValue(),
            isValid: this.form.valid
          });
        }
      });
      this._subscriptions$.push(subscription$);
    }

    // trigger errors
    if (this.error$) {
      subscription$ = this.error$.subscribe((error: IPaymentError) => {
        if (error.hasError) {
          this._scrollToFirstError();
          this.errorMessage = error.message;
        }
      });
      this._subscriptions$.push(subscription$);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['customerCardData'] && this.customerCardData) {
      this.toggleCardForm(false);
    }
  }

  /**
   * Update validators for payment method change
   * @param method
   */
  updatePaymentMethod(method) {
    this.updatePaymentMethod$.next(method);

    this._selectedPaymentMethod = method;

    switch (method) {
      case PaymentMethod.Card:
        this._resetValidators();
        this._updateCardValidation();
        this._updatePartialPayment(false);
        break;

      case PaymentMethod.Paypal:
        this._resetValidators();
        this._updatePartialPayment(false);
        break;

      case PaymentMethod.Check:
      case PaymentMethod.NET30:
      case PaymentMethod.NET60:
        this._resetValidators();
        this._updateCheckValidation();
        this._updatePartialPayment(true);
        break;
    }
  }

  /**
   * Calculate values for partial payment
   * @param percent
   */
  computePartialPayment(percent: string) {
    const percentMultiplier = Number(percent) / 100;

    const prePaymentAmount = this.orderTotal * percentMultiplier;
    const remainingBalance = this.orderTotal - prePaymentAmount;

    this.form.controls.prePaymentAmount.setValue(prePaymentAmount.toFixed(2));
    this.form.controls.totalInvoiceAmount.setValue(this.orderTotal.toFixed(2));
    this.form.controls.remainingBalance.setValue(remainingBalance.toFixed(2));
  }

  /**
   * Update Partial payment validation
   * @param partialPayment
   */
  updatePartialPaymentValidation(partialPayment: string) {
    if (partialPayment === 'Yes') {
      this.form.controls.prePaymentPercentage.setValidators(Validators.required);
      this.form.controls.remainingBalanceDue.setValidators(Validators.required);
      return;
    }

    this.form.controls.prePaymentPercentage.setValidators([]);
    this.form.controls.remainingBalanceDue.setValidators([]);
  }

  /**
   * Check shipping value from checkData form
   * and display After Ship Follow-up if is greater than 0
   * and update according validators
   * @param value
   */
  checkShippingValue(value: number) {
    if (value > 0) {
      this.hideAfterShipFolloup$.next(false);
      return this.form.controls.checkData['controls'].afterShipFollowup.setValidators(
        Validators.compose([
          Validators.required,
          Validators.min(1),
          Validators.pattern(ComStr.NUMBER_ONLY_REGEX)
        ])
      );
    }

    this.hideAfterShipFolloup$.next(true);
    this.form.controls.checkData['controls'].afterShipFollowup.setValidators([]);
    this.form.controls.checkData['controls'].afterShipFollowup.reset();
  }

  /**
   * Check inital invoice
   * and display Payment after production reason if is equal to 100
   * @param value
   */
  checkInitialInvoice(value: string) {
    return Number(value) === 100 ?
      this.hidePaymentAfterProductionReason$.next(false) :
      (
        this.hidePaymentAfterProductionReason$.next(true),
        this.form.controls.checkData['controls'].paymentAfterProductionReason.setValue('')
      );
  }

  /**
   * Check partial payment selection
   * and display Partial Payment Days only for After Shipping
   * @param value
   */
  checkRemainingBalanceDue(value: RemainingBalanceDue) {
    return value === RemainingBalanceDue.afterShipping ?
      this.hidePartialPaymentDays$.next(false) :
      this.hidePartialPaymentDays$.next(true);
  }

  async saveCard() {
    const cardForm = this.form.controls.cardData;
    if (!cardForm.valid) {
      Object.keys(cardForm['controls']).forEach(field => {
        const control = cardForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
      return;
    }
    this.isLoading = true;

    const cardData = cardForm.value;
    const resp = await this._customerApi.saveCardAsRep(String(this.customerId), cardData);

    if (resp && resp.id) {
      // update the card array
      this.customerCardData.push(resp);

      // hide the form
      this.toggleCardForm(false);

      // select the latest added card
      this.selectCard(resp);

      this.errorMessage = undefined;
      this.isLoading = false;
    } else {
      if (resp.message) {
        this.errorMessage = resp.message;
      } else {
        this.errorMessage = 'Unfortunately there was an error with your credit card info.';
      }
      this.isLoading = false;
    }
  }

  selectCard(card: ICustomerCardData) {
    // selection logic
    this.selectedCard = card;
    this.form.controls.cardData['controls'].id.patchValue(card.id);
    this.errorMessage = '';
  }

  toggleCardForm(flag: boolean) {
    this.showCardForm = flag;

    this._resetValidators();

    if (this.showCardForm) {
      this.selectedCard = undefined;
      this.form.controls.cardData['controls'].id.reset();
      return this._updateValidators(this.form.controls.cardData['controls'], this._cardDataValidators);
    } else {
      this._resetCardForm();
    }

    return this.form.controls.cardData['controls'].id.setValidators(Validators.required);
  }

  getExpirationDate(date: string): string {
    return getExpirationDate(date);
  }

  getCCThumb(type: string) {
    return getCCThumb(type);
  }

  private _resetCardForm() {
    const controls = this.form.controls.cardData['controls'];

    for (const key in controls) {
      if (controls.hasOwnProperty(key)) {
        controls[key].reset();
      }
    }
  }

  /**
   * Shitty implementation of denial partial payment for check method
   * because I could not disable or stop propagation for an option
   * @param isCheckSelected
   */
  private _updatePartialPayment(isCheckSelected) {
    if (isCheckSelected) {
      this.enablePartialPayment = false;
      this.form.controls.partialPayment.setValue('No');

      this.form.controls.prePaymentPercentage.setValidators([]);
      this.form.controls.remainingBalanceDue.setValidators([]);

      return;
    }

    this.enablePartialPayment = true;
  }

  /**
   * Set card form validators
   */
  private _updateCardValidation() {
    this._updateValidators(this.form.controls.cardData['controls'], this._cardDataValidators);
  }

  /**
   * Set check form validators
   */
  private _updateCheckValidation() {
    this.form.controls.checkData.setValidators(this._customCheckValidation);
    this._updateValidators(this.form.controls.checkData['controls'], this._checkDataValidators);
  }

  /**
   * Set validators
   * @param controls
   * @param validators
   */
  private _updateValidators(controls: Object, validators: Object) {
    for (const key in controls) {
      if (controls.hasOwnProperty(key)) {
        controls[key].setValidators(validators[key]);
      }
    }
  }

  /**
   * Reset validators
   */
  private _resetValidators() {
    this.form.controls.checkData.setValidators([]);

    const cardControls = this.form.controls.cardData['controls'];
    const checkControls = this.form.controls.checkData['controls'];
    const controls = { ...cardControls, ...checkControls };

    for (const key in controls) {
      if (controls.hasOwnProperty(key)) {
        controls[key].setValidators([]);
      }
    }
  }

  private _customCheckValidation(form: FormGroup): ValidationErrors | null {
    const checkControls = form.controls;

    let sum = 0;

    for (const key in checkControls) {
      if (checkControls.hasOwnProperty(key) && key !== 'paymentAfterProductionReason' && key !== 'afterShipFollowup') {
        sum += checkControls[key].value;
      }
    }

    return sum === 100 ? null : { 'wrongSumForCheck': `The sum for the split payment should be 100, now is ${sum}.` };
  }
}
