import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { TSConfirmComponent } from 'tscommon';

import { UngroupCartItemOrder, RemoveCartItemOrder } from '../../../../../state-manager/shopping-cart/shopping-cart.actions';
import { SetOrderItem } from '../../../../../state-manager/order-item/order-item.actions';
import { SetPackagingOrder } from '../../../../../state-manager/packaging-order/packaging-order.actions';

import { ComStr } from '../../../../../core/services/ComStr';
import { PriceCalculatorService } from '../../../../../core/services/price-calculator';
import { NavigatorService } from '../../../../../core/services/navigator';
import { TrackingService } from '../../../../../core/services/tracking';

import { IAppState } from '../../../../../types/app-state/IAppState';
import { ICartItemOrder } from '../../../../../types/shopping-cart/ICartItem';
import { IVariation } from '../../../../../types/product/variations/IVariation';
import { IPrice } from '../../../../../types/product/IPrice';
import { IDeliveryOption } from '../../../../../types/turnaround/IDeliveryOption';

@Component({
  selector: 'diy-cart-item-order',
  templateUrl: './cart-item-order.component.html',
  styleUrls: ['../../shopping-cart.component.scss']
})
export class CartItemOrderComponent implements OnInit {

  @Input() cartItemOrder: ICartItemOrder;
  @Input() cartIndex: number;
  @Input() cartItemOrderIndex: number;
  @Input() isGrouped: boolean;

  @Output() checkedItem = new EventEmitter();
  @Output() clearChecked = new EventEmitter();

  @ViewChild('confirmModal', { static: true }) confirm: TSConfirmComponent;

  artwork: string;
  expanded = false;

  constructor(
    private _store: Store<IAppState>,
    private _router: Router,
    private _navigator: NavigatorService,
    private _trackingService: TrackingService,
  ) { }

  ngOnInit(): void {
    this.artwork = this._getArtwork();
  }

  checkItem() {
    this.checkedItem.emit(this.cartItemOrderIndex);
  }

  getSpecialOptionPrice(variation: IVariation, price: IPrice): number {
    return PriceCalculatorService.getVariationPrice(variation, price.quantity, price.pricePerUnit);
  }

  ungroupShipping(): void {
    if (this.isGrouped) {
      this._store.dispatch(new UngroupCartItemOrder({ itemIndex: this.cartItemOrderIndex, cartIndex: this.cartIndex }));
      this.clearChecked.emit();
    }
  }

  deleteCartItemOrder(): void {
    this.confirm.show();
    this.confirm.onClose$.pipe(take(1)).subscribe(response => {
      if (response) {
        this._store.dispatch(new RemoveCartItemOrder({ itemIndex: this.cartItemOrderIndex, cartIndex: this.cartIndex }));
        this.clearChecked.emit();
        this._trackingService.gtagAction('remove_from_cart', { orderItem: this.cartItemOrder, cartIndex: this.cartIndex });

        this._trackingService.sendAltoCloudData(['record', 'product.removed', this.cartItemOrder.orderItem.product.name, {
          id: this.cartItemOrder.orderItem.product.id,
          name: this.cartItemOrder.orderItem.product.name,
          price: this.cartItemOrder.orderItem.price.finalTotal,
          category: this.cartItemOrder.orderItem.category.name,
          quantity: this.cartItemOrder.orderItem.price.quantity,
        }]);

        window.dataLayer.push({
          'event': 'remove-from-cart',
          'ecommerce': {
            'currencyCode': 'USD',
            'remove': {
              'products': [
                {
                  'name': this.cartItemOrder.orderItem.product.name,
                  'id': this.cartItemOrder.orderItem.product.id,
                  'price': this.cartItemOrder.orderItem.price.pricePerUnit,
                  'brand': 'The/Studio',
                  'category': this.cartItemOrder.orderItem.category.name,
                  'variant': this.cartItemOrder.orderItem.product.variationTypes,
                  'quantity': this.cartItemOrder.orderItem.price.quantity
                }
              ]
            }
          }
        });
      }
    });
  }

  async editDeliveryMethod() {
    this.ungroupShipping();
    this.clearChecked.emit();


    const newOrderItem = {
      ...this.cartItemOrder.orderItem,
      turnaround: { ...this.cartItemOrder.orderItem.turnaround, selectedOption: Object.assign({}, this.cartItemOrder.initialTurnaround) },
      price: { ...this.cartItemOrder.orderItem.price, promotionalDiscount: 0 }
    };

    const _packagingOrder = { ...this.cartItemOrder.packagingOrder };

    this._store.dispatch(new SetOrderItem(newOrderItem));

    if (_packagingOrder && _packagingOrder.packagingSelections &&
      _packagingOrder.packagingSelections.length) {
      this._store.dispatch(new SetPackagingOrder(_packagingOrder));
    }

    const index = this.cartItemOrderIndex === 0 ?
      this.cartIndex : this.cartIndex + 1;

    const turnaroundParams = await this._navigator.generateStateQueryParams(this.cartItemOrder.orderItem);
    const uidParam = { uid: this.cartItemOrder.uid, index };
    const quoteIdParam = { quoteId: this.cartItemOrder.orderItem.quoteId };

    this._router.navigate(
      ['/category', this.cartItemOrder.orderItem.category.id, 'product', this.cartItemOrder.orderItem.product.id, 'turnaround'],
      { queryParams: { ...turnaroundParams, ...uidParam, ...quoteIdParam } }
    );
  }

  toggleContainer() {
    this.expanded = !this.expanded;
  }

  private _getArtwork(): string {
    let image: string;

    if (
      this.cartItemOrder.orderItem.artwork &&
      this.cartItemOrder.orderItem.artwork.files &&
      this.cartItemOrder.orderItem.artwork.files.length
    ) {
      return ComStr.getDisplayableImage(this.cartItemOrder.orderItem.artwork.files);
    } else {
      let images = this.cartItemOrder.orderItem.product.images.find(img => img.main);
      if (!images) {
        images = this.cartItemOrder.orderItem.product.images[0];
      }
      image = images.small;
    }

    return ComStr.checkImageFormat(image);
  }
}
