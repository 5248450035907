<!--Subtotal-->
<div *ngIf="price.subTotal" class="price-group subTotal">
  <hr />
  <span>Subtotal: </span>
  <span>{{price.subTotal | currency:'USD'}}</span>
</div>

<!-- State Sales Tax for California -->
<!-- <div class="price-group tax" *ngIf="resellerTaxActive">
  <hr class="dont-show" />
  <span>Tax:
    <div class="tax-notice">
      <img src="/assets/img/icon-info.svg" alt="">
      <small>Tax fee is to be determined in checkout.</small>
    </div>
  </span>

  <span>TBD</span>
</div> -->

<!--Delivery total-->
<div *ngIf="price.shippingTotal" class="price-group">
  <hr class="dont-show" />
  <span>Expedite Fee: </span>
  <span>{{ price.shippingTotal | currency:'USD'}}</span>
</div>

<!--Guaranteed fee-->
<div *ngIf="price.guaranteedFee" class="price-group">
  <hr class="dont-show" />
  <span>Guaranteed Delivery: </span>
  <span>{{ price.guaranteedFee | currency:'USD'}}</span>
</div>

<!--Discount-->
<div *ngIf="price.discountAmount" class="price-group discount">
  <hr class="dont-show" />
  <span>Additional {{price.displayDiscount | number : '1.0-2'}}% Off:</span>
  <span>-{{price.discountAmount | currency:'USD'}}</span>
</div>

<!-- Bundle Discount-->
<div *ngIf="bundleItem" class="price-group discount">
  <span>{{bundleItem.quantity }} Credits x {{ price.pricePerUnit | currency: 'USD' }}/Unit</span>
  <span>-{{price.pricePerUnit * bundleItem.quantity | currency:'USD'}}</span>
</div>

<!--Final Total-->
<div *ngIf="price.pricePerUnit" class="price-group final-price">
  <hr />
  <span>Final Price: </span>
  <span>{{price.finalTotal | currency:'USD'}}</span>
</div>
