import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ShoppingCartStorageService } from '../../shop/core/services/shopping-cart-storage.service';
import { ArtworkService } from '../../core/services/artwork.service';

import { ShoppingCartActionTypes } from './shopping-cart.actions';
import { ArtworkCookieType } from '../../types/artwork/IArtwork';

@Injectable()
export class ShoppingCartEffects {

  constructor(
    private _actions: Actions,
    private _cartService: ShoppingCartStorageService,
    private _artworkService: ArtworkService,
  ) { }

  @Effect({ dispatch: false })
  syncShoppingCart(): Observable<Action> {
    return this._actions.pipe(
      ofType(
        ShoppingCartActionTypes.AddCartItem,
        ShoppingCartActionTypes.RemoveCartItem,
        ShoppingCartActionTypes.RemoveCartItems,
        ShoppingCartActionTypes.RemoveCartItemOrder,
        ShoppingCartActionTypes.CombineCartItems,
        ShoppingCartActionTypes.UngroupCartItemOrder,
        ShoppingCartActionTypes.UpdateCartItemOrder,
        ShoppingCartActionTypes.ApplyDiscountVoucher,
        ShoppingCartActionTypes.RemoveDiscountVoucher,
        ShoppingCartActionTypes.RemoveQuoteIds,
        ShoppingCartActionTypes.RemovePackagingOption,
        ShoppingCartActionTypes.SetResellerLicense,
        ShoppingCartActionTypes.SetTaxFee,
        ShoppingCartActionTypes.SetQuoteId,
      ),
      tap(() => {
        this._cartService.setCartData();
      })
    );
  }

  @Effect({ dispatch: false })
  clearShoppingCart(): Observable<Action> {
    return this._actions.pipe(
      ofType(
        ShoppingCartActionTypes.ClearShoppingCart
      ),
      tap(() => {
        this._cartService.clearCartData();
      })
    );
  }

  @Effect({ dispatch: false })
  clearArtworkCookie(): Observable<Action> {
    return this._actions.pipe(
      ofType(
        ShoppingCartActionTypes.AddCartItem,
      ),
      tap(() => {
        this._artworkService.removeArtworkCookie(ArtworkCookieType.ORDER);
        this._artworkService.removeArtworkCookie(ArtworkCookieType.PACKAGING);
      })
    );
  }
}
