<footer class="footer">
  <!-- <div class="footer__left"> -->
  <div class="footer__content">
    <img class="footer__logo" src="assets/img/The-Studio-Logo.svg" alt="The Studio Logo" />
    <p class="footer__address">
      <span>Contact Us:</span><br />
      The Studio Technologies, Inc. <br />
      1055 E. Colorado Blvd., Suite 500 <br />
      Pasadena, CA, 91106 <br />
      <!-- <a href="tel:(877) 647-6447">(877) 647-6447</a><br /> -->
      <a href="mailto:sales@thestudio.com">sales@thestudio.com</a><br />
    </p>
    <p class="footer__social">
      <a href="https://www.facebook.com/factorybythestudio" class="trackable" data-button="Facebook">
        <img src="/assets/img/social-icons/social-facebook-white.svg" alt="Facebook" />
      </a>
      <a href="https://www.instagram.com/factorybythestudio/" class="trackable" data-button="Instagram">
        <img src="assets/img/social-icons/social-instagram-white.svg" alt="Instagram" />
      </a>
      <a href="https://pinterest.com/factorybystudio/" class="trackable" data-button="Pinterest">
        <img src="assets/img/social-icons/social-pinterest-white.svg" alt="Pinterest" />
      </a>
    </p>
  </div>

  <div class="footer__copyright">
    <a href="https://www.thestudio.com/privacy/" class="trackable" data-button="PrivacyPolicy">Privacy Policy</a>
    <a href="https://www.thestudio.com/terms/" class="trackable" data-button="Terms&Conditions">Terms & Conditions</a>
    <p>Copyright &copy; {{ year }} The/Studio. All Rights Reserved.</p>
  </div>
</footer>
