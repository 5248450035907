import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import postscribe from 'postscribe';

import { OrderMessengerService } from "../../../core/services/order-messenger";
import { AmplitudeService } from "../../../core/services/amplitude";
import { TrackingService } from "../../../core/services/tracking";

import { environment } from "../../../../environments/environment";

@Component({
  selector: "diy-success",
  templateUrl: "./success.component.html",
  styleUrls: ["./success.component.scss"],
})
export class SuccessComponent implements OnInit {
  orderItems: Array<any> = [];
  orderItemsCount: number;
  invoiceId: number;
  customerDashURL: string;

  private _orderData: any = this._activatedRoute.snapshot.data.orderData;

  constructor(
    private _oms: OrderMessengerService,
    private _amp: AmplitudeService,
    private _activatedRoute: ActivatedRoute,
    private _tracking: TrackingService
  ) {
    this.customerDashURL = environment.customerDashURL;

    this._oms.updateStep(0);

    this.invoiceId = this._orderData.data.invoiceId;

    this.orderItems = Object.values(this._orderData.data.orders).map(
      (order: any) => ({
        productName: order.product,
        quantity: order.quantity,
        total: order.subtotal,
        pendingArtwork: order.pendingArtwork,
      })
    );

    const productsIds = Object.values(this._orderData.data.orders).map(
      (order: any) => order.productId
    );

    this._amp.logEvent("Navigation", {
      page: "payment",
      navigationStep: 8,
      product_ids: JSON.stringify(productsIds),
    });

    this._sendTrackingData(
      this._orderData.data.amounts.fullTotal,
      this.invoiceId
    );
  }

  async ngOnInit() {

    // Access the 'to' query parameter
    this._activatedRoute.queryParams.subscribe(params => {
      this.orderItemsCount = +params['to'] || 1; // '+' converts the string to a number
    });
    // add skipDraft param to header logo href so it redirects to the front page
    const headerLogo: HTMLAnchorElement = document.querySelector(
      ".header-content .bottom__logo"
    );
    headerLogo.href = headerLogo.getAttribute("href") + "?skipDraft=true";
    console.log("headerLogo", headerLogo);
    ////
      
    this._tracking.sendAltoCloudData([
      "pageview",
      {
        location: location.pathname,
        title: "Success",
      },
    ]);
    
    window.dataLayer.push({
      event: "transaction", // required
      totalOrders: this.orderItemsCount, // optional
      paymentType: this._orderData.data.method, // optional
      ecommerce: {
        currencyCode: this._orderData.data.currency, // technically optional, but always include it so GA can auto convert if needed
        purchase: {
          actionField: {
            id: this.invoiceId, // required
            affiliation: "thestudio.com",
            revenue: this._orderData.data.total, // usually includes shipping and tax but can also exclude it, just be consistent
            shipping: 0, // technically optional, always include it
            tax: this._orderData.data.amounts.chargedTax, // technically optional, always include it
            coupon: 0, // optional
          },
          products: this.getOrderProducts(),
        },
      },
    });
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "purchase", // required
      ecommerce: {
        transaction_id: this.invoiceId, // required
        currencyCode: this._orderData.data.currency,
        value: this._orderData.data.total,
        tax: this._orderData.data.amounts.chargedTax,
        shipping: 0,
        items: this.getOrderProductsGa4(),
      },
    });
  }

  private _sendTrackingData(total: number, invoiceId: number) {
    this._tracking.gtagReportConversion({
      send_to: "AW-1069576120/4It-CPaPrYkBELjfgf4D",
      value: total,
      currency: "USD",
      transaction_id: invoiceId,
    });
    this._tracking.sendFacebookData("track", "Purchase", {
      value: total,
      currency: "USD",
    });

    this._tracking.sendBingData("sale", {
      event_category: "cart",
      event_label: "order",
      event_value: String(total),
    });
  }

  private getOrderProducts() {
    return Object.values(this._orderData.data.orders).map((order: any) => ({
      name: order.product,
      id: order.productId,
      price: order.unitPrice,
      brand: "The/Studio",
      category: order.category,
      variant: order.specialOptions,
      quantity: order.quantity,
    }));
  }

  private getOrderProductsGa4() {
    return Object.values(this._orderData.data.orders).map((order: any) => ({
      item_name: order.product,
      item_id: order.productId,
      price: order.unitPrice,
      item_brand: "The/Studio",
      item_category: order.category,
      item_variant: order.specialOptions[0].name,
      quantity: order.quantity,
      item_list_id: order.category.id,
      item_list_name: order.category.name,
    }));
  }

  ngAfterViewInit() {
    /* tslint:disable:max-line-length */
    postscribe('#vwoTracking', 
      "<script>window.VWO = window.VWO || []; VWO.event = VWO.event || function () {VWO.push(['event', ...arguments])}; VWO.event('trackRevenue', { 'paymentSuccess':"+ this._orderData.data.total + " }) </script>"
    );
    /* tslint:enable:max-line-length */
  }
}
