// External
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

// Env vars
import { environment } from '../../../../environments/environment';
import { IBundlePayment } from '../../../types/bundle/IBundlePayment';

@Injectable()
export class BundlePaymentApi {

  private _routes = {
    getBundleData: environment.baseUrl + 'api/diy/sales/list-bundles',
    makeBundlePayment: environment.baseUrl + 'api/diy/sales/bundle-payment',
  };

  /**
   * @author Liviu Dima
   */
  constructor(
    private _http: HttpClient
  ) { }

  /**
   * Get Bundle Data by Id
   */
  async getBundleData(id: number): Promise<any> {
    try {
      const params = new HttpParams().set('id', String(id));
      return await this._http.get(this._routes.getBundleData, { params: params }).toPromise();
    } catch (err) {
      console.error(err);
    }
  }

  async submitBundlePayment(payload: IBundlePayment): Promise<any> {
    try {
      return await this._http.post(this._routes.makeBundlePayment, payload).toPromise();
    } catch (err) {
      console.error(err);
    }
  }
}
