import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

// API Provider
import { InvoiceApi } from '../api/invoiceApi';

@Injectable()
export class InvoiceResolver implements Resolve<any> {

  constructor(
    private _invoiceApi: InvoiceApi,
  ) { }

  async resolve(route: ActivatedRouteSnapshot): Promise<any> {
    const invoiceId = route.params['invoiceId'];
    if (!invoiceId) {
      throw new Error('Could not fetch products due to lacking categoryId.');
    }

    return this._invoiceApi.getInvoiceData(invoiceId);
  }
}
