import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { OrderMessengerService } from '../../core/services/order-messenger';
import { CampaignTrackingService } from '../../core/services/campaign-tracking.service';
import { TrackingService } from '../../core/services/tracking';

@Component({
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  constructor(
    private _tracking: TrackingService,
    private _oms: OrderMessengerService,
    private _activatedRoute: ActivatedRoute,
    private _campaignTrackingService: CampaignTrackingService,
  ) {
    this._oms.updateStep(0);
    this._campaignTrackingService.handleCampaignTracking(this._activatedRoute.snapshot.queryParams);
  }

  ngOnInit() {
    this._tracking.sendAltoCloudData(['pageview', {
      location: location.pathname,
      title: 'Not Found'
    }]);
  }

}
