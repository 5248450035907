<div *ngIf="selectedVariations.length" class="options-summary">

  <div *ngFor="let option of selectedVariations" class="option-info">
    <img class="thumb" alt="" src="{{getThumbnail(option.variation)}}" />
    <span class="name">{{option.variation.name}}</span>

    <div class="price">
      <span *ngIf="getSpecialOptionPrice(option.variation) > 0; else free">
        {{ getSpecialOptionPrice(option.variation) | currency:'USD'}}
      </span>

      <ng-template #free>
        <span>FREE</span>
      </ng-template>
    </div>
  </div>

</div>
