<div class="vt-summary vt-package" *ngIf="packageOptions">
  <span>Package Artwork</span>
  <button (click)="editOption('artwork')" class="link-btn">Edit</button>
</div>

<div class="vt-summary vt-package" *ngIf="packageOptions">
  <span>Package Customization - </span>
  <button (click)="editOption('PackageSize')" class="link-btn">Edit</button>
  <div *ngIf="packageSize">
    <span *ngIf="packageSize.variation.name === 'Custom Size'">
      {{packageSize.variation.width}}" x {{packageSize.variation.height}}"
    </span>
    <span *ngIf="packageSize.variation.name !== 'Custom Size'">
      {{packageSize.variation.name}}
    </span>
  </div>
</div>
<hr/>
<div class="vt-summary vt-package" *ngIf="packageOptions">
  <span>Package Options</span>
  <button (click)="editOption('PackageType')" class="link-btn">Edit</button>
  <div class="vt-package-item" *ngFor="let option of packageOptions">
    <span class="vt-desc">
      <span class="vt-variation">
        <img src="{{ getVariationIcon(option.variation) }}" class="vt-package-img" />
      </span>
      <span class="vt-name">{{option.variation.name}}</span>

      <span *ngIf="getPrice(option.variation) > 0; else free" class="option-price vt-price">
        {{getPrice(option.variation) | currency:'USD':'symbol':'1.0-2'}}
      </span>
    </span>
    <ng-template class="vt-free" #free><span class="option-price">FREE</span></ng-template>
  </div>
</div>
