import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { BaseApi } from '../../../core/api/baseApi';

import { environment } from '../../../../environments/environment';

import { SessionService } from '../../../utility/session.service';

// Types
import { IOrderPayload, IPaypalPayload } from '../../../types/api/IOrderPayload';
import { IVoucherResponse } from '../../../types/voucher/IVoucherResponse';

@Injectable()
export class InvoiceApi extends BaseApi {

  private routes = {
    invoiceSubmit: environment.baseUrl + 'api/diy/invoice/new',
    invoiceData: environment.baseUrl + 'api/diy/invoice/${id}/show',
    // sales
    invoiceSalesNew: environment.baseUrl + 'api/diy/sales/invoice/new',
    invoiceSalesSave: environment.baseUrl + 'api/diy/sales/invoice/save',
    checkPromoCode: environment.baseUrl + 'api/diy/sales/check-voucher',
    downloadInvoice: environment.baseUrl + 'api/diy/sales/invoice/${id}/download',
    emailCustomer: environment.baseUrl + 'api/diy/sales/invoice/${id}/email',
    paypalSubmit: environment.baseUrl + 'api/diy/paypal/',
    paypalSaveTransactionId: environment.baseUrl + 'api/diy/paypal/transaction'
  };

  constructor(
    private _http: HttpClient,
    protected _sessionService: SessionService,
  ) {
    super(_sessionService);
  }

  // Customer
  async submitInvoice(orderDetails: IOrderPayload): Promise<any> {
    try {
      const headers = this._createAuthorizationHeader();
      const resp = await this._http.post(this.routes.invoiceSubmit, orderDetails, { headers: headers }).toPromise();
      return resp;
    } catch (err) {
      console.error(err);
    }
  }

  async getInvoiceData(invoiceId: string): Promise<any> {
    try {
      const headers = this._createAuthorizationHeader();
      const resp = await this._http.get(this.routes.invoiceData.replace('${id}', invoiceId), { headers: headers }).toPromise();
      return resp;
    } catch (err) {
      console.error(err);
    }
  }

  // Sales Rep
  async saveSalesInvoice(orderDetails: IOrderPayload): Promise<any> {
    try {
      const headers = this._createAuthorizationHeader();
      const resp = await this._http.post(this.routes.invoiceSalesSave, orderDetails, { headers: headers }).toPromise();
      return resp;
    } catch (err) {
      console.error(err);
    }
  }

  async newSalesInvoice(orderDetails: IOrderPayload): Promise<any> {
    try {
      const headers = this._createAuthorizationHeader();
      const resp = await this._http.post(this.routes.invoiceSalesNew, orderDetails, { headers: headers }).toPromise();
      return resp;
    } catch (err) {
      console.error(err);
    }
  }

  async checkPromoCode(promoCode: string, productIds: number[], orderTotal: number, customerEmail: string): Promise<IVoucherResponse> {
    try {
      let params = new HttpParams()
        .set('voucherName', promoCode)
        .set('total', orderTotal.toFixed(2))
        .set('customer', encodeURIComponent(customerEmail));

      productIds.forEach(productId => {
        params = params.append('products[]', String(productId));
      });

      const res = await this._http.get(this.routes.checkPromoCode, { params: params }).toPromise();
      return res as IVoucherResponse;
    } catch (err) {
      return err.error;
    }
  }

  async downloadInvoice(invoiceId: string): Promise<any> {
    try {
      const headers = this._createAuthorizationHeader();
      const route = this.routes.downloadInvoice.replace('${id}', invoiceId);
      const res = await this._http.post(route, {}, { headers: headers }).toPromise();
      return res;
    } catch (err) { }
  }

  async emailCustomer(invoiceId: string): Promise<any> {
    try {
      const headers = this._createAuthorizationHeader();
      const route = this.routes.emailCustomer.replace('${id}', invoiceId);
      const res = await this._http.post(route, { headers: headers }).toPromise();
      return res;
    } catch (err) { }
  }

  async submitPaypal (paypalData: IPaypalPayload): Promise<any> {
    try {
      const headers = this._createAuthorizationHeader();
      const resp = await this._http.post(this.routes.paypalSubmit, paypalData, {headers: headers}).toPromise();
      return resp;
    } catch (err) {
      console.error(err);
    }
  }

  async savePaypalTransactionId (paypalData: IPaypalPayload): Promise<any> {
    try {
      const headers = this._createAuthorizationHeader();
      const resp = await this._http.post(this.routes.paypalSaveTransactionId, paypalData, {headers: headers}).toPromise();
      return resp;
    } catch (err) {
      console.error(err);
    }
  }
}
