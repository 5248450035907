import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ModalModule, PopoverModule } from 'ngx-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';

// App component
import { AppComponent } from './app.component';

// App routes
import { routes } from './app.routes';

// Modules
import { CommonComponentsModule } from './common/common-components.module';
import { TSCommonModule } from 'tscommon';

// API
import { ProductFlowApi } from './core/api/productFlowApi';
import { QuoteApi } from './core/api/quoteApi';
import { DataTransformerService } from './core/api/data-transformer.service';
import { AuthProvider } from './core/api/AuthProvider';
import { PackagingProvider } from './packaging/core/api/packaging.provider';

// Core
import { AuthGuard } from './core/guards/auth-guard.service';
import { DraftService } from './core/services/draft.service';
import { NavigationService } from './core/services/navigation.service';

// State Management ( ngrx store )
import { reducers, metaReducers } from './state-manager/reducers';
import { URLEffects } from './state-manager/url.effect';
import { ShoppingCartEffects } from './state-manager/shopping-cart/shopping-cart.effect';
import { OrderItemEffects } from './state-manager/order-item/order-item.effect';
import { StaticContentApi } from './core/api/staticContentApi';
import { BundleProvider } from './core/api/BundleProvider';

// Order Summary
import { OrderSummaryComponent } from './core/components/order-summary/order-summary.component';
import { SummaryInfoComponent } from './core/components/order-summary/summary-info/summary-info.component';
import { VariationTypesSummaryComponent } from './core/components/order-summary/variation-types-summary/variation-types-summary.component';
import { SpecialOptionsSummaryComponent } from './core/components/order-summary/special-options-summary/special-options-summary.component';
import { PriceInfoComponent } from './core/components/order-summary/price-info/price-info.component';
import { OrderDetailsComponent } from './core/components/order-details/order-details.component';

// Services
import { OrderMessengerService } from './core/services/order-messenger';
import { PriceCalculatorService } from './core/services/price-calculator';
import { AmplitudeService } from './core/services/amplitude';
import { TrackingService } from './core/services/tracking';
import { CookieService } from './core/services/cookie.service';
import { LocalStorageService } from './utility/localStorage.service';
import { ArtworkService } from './core/services/artwork.service';
import { SessionService } from './utility/session.service';
import { SalesRepGuard } from './core/guards/sales-rep-guard.service';
import { QuoteService } from './core/services/quote.service';
import { StepGenerator } from './core/services/step-generator.service';
import { NavigatorService } from './core/services/navigator';
import { BundleIntegrityService } from './core/services/bundle-integrity.service';
import { ShoppingCartStorageService } from './shop/core/services/shopping-cart-storage.service';
import { IdentifyCustomerGuard } from './core/guards/identify-customer-guard.service';

// Resolvers
import { CategoryResolver } from './core/resolvers/category.resolver';
import { OrderItemResolver } from './core/resolvers/order-item.resolver';
import { TurnaroundResolver } from './core/resolvers/turnaround.resolver';
import { PackagingResolver } from './packaging/core/resolvers/packaging.resolver';

// Utils
import { SafePipe } from './utility/pipes/safe';
import { ImageLoaderComponent } from './utility/image-loader.component';
import { GalleryComponent } from './utility/gallery/gallery.component';
import { ZoomableComponent } from './utility/zoomable/zoomable.component';
import { SpinnerComponent } from './utility/spinner/spinner.component';
import { PhoneMaskDirective } from './utility/phone-mask/PhoneMaskDirective';
import { MatchHeightDirective } from './utility/directives/match-height.directive';
import { PageHeightFixService } from './utility/page-height-fix.service';

// Pages
import { HomeComponent } from './pages/home/home.component';
import { ProductComponent } from './pages/home/product/product.component';

import { CategoryComponent } from './pages/category/category.component';
import { TurnaroundComponent } from './pages/turnaround/turnaround.component';
import { ArtworkComponent } from './pages/artwork/artwork.component';

import { CustomizeComponent } from './pages/customize/customize.component';
import { StyleComponent } from './pages/customize/variation-type-templates/style/style.component';
import { SizeComponent } from './pages/customize/variation-type-templates/size/size.component';
import { QuantityComponent } from './pages/customize/variation-type-templates/quantity/quantity.component';
import { BundleQuantityComponent } from './pages/customize/variation-type-templates/bundle-quantity/bundle-quantity.component';
import { CreditSelectorComponent } from './pages/customize/credit-selector/credit-selector.component';

import { SpecialOptionsComponent } from './pages/special-options/special-options.component';
import { DeliveryOptionComponent } from './pages/turnaround/delivery-option/delivery-option.component';
import { SingleOptionComponent } from './pages/special-options/options/single-option.component';
import { MultiOptionComponent } from './pages/special-options/options/multi-option.component';
import { DraftComponent } from './pages/draft/draft.component';
import { AuthorizationComponent } from './pages/authorization/authorization.component';
import { SaveSuccessComponent } from './pages/special-options/save-success/save-success.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';

import { IdentifyCustomerComponent } from './pages/identify-customer/identify-customer.component';
import { PackagingSummaryComponent } from './core/components/order-summary/packaging-summary/packaging-summary.component';
import { DraftApi } from './core/api/draftApi';
import { SalesRepProductFlowApi } from './core/api/salesRepProductFlowApi';
import { QuoteFormComponent } from './pages/quote-form/quote-form.component';
import { ThankYouComponent } from './pages/thank-you/thank-you.component';
import { CampaignTrackingService } from './core/services/campaign-tracking.service';
import { ShopModule } from './shop/shop.module';

@NgModule({
  declarations: [
    // App component
    AppComponent,
    // Pages & subComponents
    HomeComponent,
    CategoryComponent,
    ProductComponent,
    CategoryComponent,

    ArtworkComponent,

    CustomizeComponent,
    CreditSelectorComponent,
    StyleComponent,
    SizeComponent,
    QuantityComponent,
    BundleQuantityComponent,

    SpecialOptionsComponent,

    TurnaroundComponent,
    DeliveryOptionComponent,

    ImageLoaderComponent,
    SafePipe,
    GalleryComponent,
    ZoomableComponent,
    SingleOptionComponent,
    MultiOptionComponent,
    SpinnerComponent,
    PhoneMaskDirective,
    DraftComponent,
    MatchHeightDirective,
    AuthorizationComponent,
    SaveSuccessComponent,
    NotFoundComponent,
    // Order summaries
    OrderSummaryComponent,
    SummaryInfoComponent,
    VariationTypesSummaryComponent,
    SpecialOptionsSummaryComponent,
    PriceInfoComponent,
    OrderDetailsComponent,
    PackagingSummaryComponent,
    IdentifyCustomerComponent,
    QuoteFormComponent,
    ThankYouComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    CommonComponentsModule,
    CarouselModule,
    TSCommonModule,
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    RouterModule.forRoot(routes),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    EffectsModule.forRoot([URLEffects, ShoppingCartEffects, OrderItemEffects]),
    PopoverModule.forRoot(),
    ShopModule,
  ],
  providers: [
    ProductFlowApi,
    QuoteApi,
    DraftApi,
    SalesRepProductFlowApi,
    OrderMessengerService,
    PriceCalculatorService,
    DataTransformerService,
    URLEffects,
    CookieService,
    AmplitudeService,
    DraftService,
    NavigationService,
    TrackingService,
    CookieService,
    ArtworkService,
    LocalStorageService,
    SessionService,
    AuthProvider,
    QuoteService,
    StaticContentApi,
    PageHeightFixService,
    BundleProvider,
    BundleIntegrityService,
    CampaignTrackingService,

    // Guards
    AuthGuard,
    IdentifyCustomerGuard,
    SalesRepGuard,

    // Services
    NavigatorService,
    StepGenerator,
    ShoppingCartStorageService,
    // Resolvers
    CategoryResolver,
    OrderItemResolver,
    PackagingProvider,
    PackagingResolver,
    TurnaroundResolver,
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    GalleryComponent,
  ]
})
export class AppModule {
}
