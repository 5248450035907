<!-- Header -->
<div class="header-content">
  <diy-header></diy-header>
  <diy-order-details></diy-order-details>
  <diy-promo-banner></diy-promo-banner>
  <!-- we have the ngIf as we are setting currentStep after resolving route -->
  <diy-order-step-tracking *ngIf="currentStep" [currentStep]="currentStep">
  </diy-order-step-tracking>
</div>

<!-- Page Content-->
<div class="container">
  <div class="artwork">
    <div class="heading-primary">
      <h1>Step {{ currentStep }}: Upload Artwork for {{product?.name}}</h1>
      <p>Share your inspiration with us – we'll accept finished technical mocks, reference images or
        even a sketch on
        a napkin.</p>
    </div>
    <div class="content">
      <div class="files">
        <p class="title">1. upload file</p>

        <diy-file-upload title="Upload your artwork to be printed on your {{ product?.name }}." [multiple]="true"
          [allowedExtensions]="['png', 'jpg', 'jpeg', 'ai', 'eps', 'psd', 'tif', 'tiff', 'pdf']" [maxFileSizeMb]="12"
          [existingFiles$]="existingArtworkFiles$" (fileDeleted)="fileDeleted($event)"
          (fileUploaded)="fileUploaded($event)" (fileError)="fileError($event)">
        </diy-file-upload>

      </div>
      <div class="notes">
        <p class="title">2. Notes (optional)</p>
        <h2>
          Provide additional info about your artwork, such as specifications or how you want the artwork customized
        </h2>
        <textarea placeholder="Additional information" [formControl]="descriptionControl"></textarea>

        <button class="button button--green mr-medium" type="button" [ngClass]="{'disabled': !artwork?.files?.length}"
          (click)="nextStep()">
          Continue
        </button>
        <button class="button button--green" type="button" [ngClass]="{'disabled': artwork?.files?.length}"
          (click)="uploadArtworkLater()">
          Upload Artwork Later
        </button>

        <div *ngIf="showCopyQuoteArtworkBtn" class="my-normal">
          <button class="button button--green" type="button" [ngClass]="{'disabled': artwork?.files?.length}"
            (click)="copyQuoteArtwork()">
            Import Quote Artwork
          </button>
        </div>
      </div>

    </div>

    <!-- UST Notes -->
    <diy-notes></diy-notes>

    <!-- Siderbar -->
    <diy-order-summary [currentStep]="currentStep"></diy-order-summary>

  </div>
</div>
