import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

// Types
import { IStep, StepTypes, ProductOrderSteps, PackagingOrderSteps } from '../../types/step/IStep';
import { IAppState } from '../../types/app-state/IAppState';
import { IOrderItem } from '../../types/app-state/IOrderItem';
import { take } from 'rxjs/operators';


/**
 * @author Liviu Dima
 */
@Injectable()
export class StepGenerator {

  private _orderItem$: Observable<IOrderItem> = this._store.select('orderItem');

  constructor(
    private _router: Router,
    private _store: Store<IAppState>,
  ) { }

  /**
   * Get steps for specified
   * @param orderType flow
   */
  async getSteps(orderType: StepTypes): Promise<IStep[]> {
    switch (orderType) {
      case StepTypes.PRODUCT_ORDER_STEPS:
        return await this._getProductOrderSteps();
      case StepTypes.PACKAGING_ORDER_STEPS:
        return await this._getPackagingOrderSteps();
    }
  }

  /**
   * Returns all steps for specific order flow
   */
  private async _getProductOrderSteps(): Promise<IStep[]> {
    const orderItem = await this._orderItem$.pipe(take(1)).toPromise();

    const categoryId = orderItem.category && orderItem.category.id ? orderItem.category.id : 0;
    const productId = orderItem.product && orderItem.product.id ? orderItem.product.id : 0;

    return [
      {
        number: ProductOrderSteps.product,
        name: 'Product',
        url: this._router.createUrlTree(['']).toString()
      },
      {
        number: ProductOrderSteps.customize,
        name: 'Customize',
        url: this._router.createUrlTree([
          'category', categoryId,
          'product', productId,
          'customize'
        ], { queryParamsHandling: 'merge' }
        ).toString()
      },
      {
        number: ProductOrderSteps.specialOptions,
        name: 'Special Options',
        url: this._router.createUrlTree([
          'category', categoryId,
          'product', productId,
          'specialOptions'
        ], { queryParamsHandling: 'merge' }
        ).toString()
      },
      {
        number: ProductOrderSteps.artwork,
        name: 'Upload Artwork',
        url: this._router.createUrlTree([
          'category', categoryId,
          'product', productId,
          'artwork'
        ], { queryParamsHandling: 'merge' }
        ).toString()
      },
      {
        number: ProductOrderSteps.turnaround,
        name: 'Payment',
        url: this._router.createUrlTree([
          'category', categoryId,
          'product', productId,
          'turnaround'
        ], { queryParamsHandling: 'merge' }
        ).toString()
      },
    ];
  }

  /**
   * Returns all steps for specific order flow
   */
  private async _getPackagingOrderSteps(): Promise<IStep[]> {
    const orderItem = await this._orderItem$.pipe(take(1)).toPromise();

    const categoryId = orderItem.category && orderItem.category.id ? orderItem.category.id : 0;
    const productId = orderItem.product && orderItem.product.id ? orderItem.product.id : 0;

    return [
      {
        number: PackagingOrderSteps.size,
        name: 'Package Size',
        url: this._router.createUrlTree([
          'category', categoryId,
          'product', productId,
          'packaging', 'size'
        ], { queryParamsHandling: 'merge' }
        ).toString()
      },
      {
        number: PackagingOrderSteps.type,
        name: 'Package Type',
        url: this._router.createUrlTree([
          'category', categoryId,
          'product', productId,
          'packaging', 'type'
        ], { queryParamsHandling: 'merge' }
        ).toString()
      },
      {
        number: PackagingOrderSteps.option,
        name: 'Package Option',
        url: this._router.createUrlTree([
          'category', categoryId,
          'product', productId,
          'packaging', 'option'
        ], { queryParamsHandling: 'merge' }
        ).toString()
      },
      {
        number: PackagingOrderSteps.artwork,
        name: 'Upload Artwork',
        url: this._router.createUrlTree([
          'category', categoryId,
          'product', productId,
          'packaging', 'upload-artwork'
        ], { queryParamsHandling: 'merge' }
        ).toString()
      },
      {
        number: PackagingOrderSteps.review,
        name: 'Review',
        url: this._router.createUrlTree([
          'category', categoryId,
          'product', productId,
          'packaging', 'review'
        ], { queryParamsHandling: 'merge' }
        ).toString()
      },
    ];
  }
}
