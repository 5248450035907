import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { environment } from '../../../environments/environment';

import { SearchEngineService } from './search-engine.service';
import { AmplitudeService } from '../../core/services/amplitude';

import { ISearchResult } from '../../types/search/ISearchResult';

@Component({
  selector: 'diy-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {

  sourcingUrl: string;
  isSearching: boolean;
  showResultsFlag: boolean;
  searchForm: FormGroup;
  searchResults: ISearchResult[];
  isScrolled = false;

  private _subscriptions$: Subscription[] = [];

  constructor(
    private _amp: AmplitudeService,
    private _formBuilder: FormBuilder,
    private _searchService: SearchEngineService
  ) {
    this.searchResults = [];
    this.searchForm = this._formBuilder.group({
      searchField: ['']
    });
    this.isSearching = false;
    this.showResultsFlag = false;
    this.sourcingUrl = environment.sourcingUrl;

    const _subscription$ = this.searchForm.controls.searchField.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(term => this.searchIndexed(term));

    this._subscriptions$.push(_subscription$);
  }

  async ngOnInit() {
    await this._searchService.indexData();
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.isSearching = false;
      this.showResultsFlag = false;
    }
  }

  focusSearchBar() {
    this.isSearching = true;
    this._amp.logEvent('Search Section Clicked', {});
  }

  altLog(linkName: string) {
    this._amp.logEvent('Search Alternative', {
      clickItem: linkName
    });
    window.open(environment.sourcingUrl, '_blank');
  }

  showResults() {
    return (
      !this.searchForm.controls.searchField.pristine
      && this.searchForm.controls.searchField.value !== ''
      && this.showResultsFlag
    );
  }

  protected searchIndexed(term: string) {
    this.showResultsFlag = true;
    this._amp.logEvent('Term searched', { term: term });
    this.searchResults = this._searchService.find(term);
  }


  ngOnDestroy(): void {
    this._subscriptions$.map(subscription$ => subscription$.unsubscribe());
  }
}
