<!-- Header -->
<div class="header-content">
  <diy-header></diy-header>
</div>

<!-- Page content -->
<div class="container">
  <div class="not-found">

    <h4><b>We make a lot of great items everyday...</b></h4>

    <img class="not-found__image" src="../../../assets/img/404_icon.png" alt="Not Found">

    <h5 class="not-found__subtitle">
      but this page isn't one of them. Let's take you back
      <br>
      to our area of <a routerLink="/">expertise</a>.
    </h5>

  </div>
</div>
