<p class="price-display">
  <span *ngIf="price.promotionalDiscount === 0" class="default">
    {{price.finalTotal | currency:'USD'}}
  </span>

  <span *ngIf="price.promotionalDiscount" class="discounted">
    <span class="old-price">
      {{price.finalTotal + price.promotionalDiscountValue | currency:'USD'}}
    </span>

    <span class="offer">
      {{price.promotionalDiscount | number : '1.0-2' }}% off:

      {{price.finalTotal | currency:'USD' }}
    </span>
  </span>
</p>
