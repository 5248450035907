import { Component, Input } from '@angular/core';
import { IPrice } from '../../../../types/product/IPrice';

@Component({
  selector: 'diy-price-display',
  templateUrl: './price-display.component.html',
  styleUrls: ['./price-display.component.scss']
})
export class PriceDisplayComponent {

  @Input() price: IPrice;

  constructor() { }
}
