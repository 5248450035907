<!-- Header -->
<div class="header-content">
  <diy-header></diy-header>
</div>

<!-- Page content -->
<div class="container">
  <div class="order-confirmation">
    <div>
      <h1 class="order-confirmation__title">Order Successfully Placed!</h1>
      <p class="order-confirmation__info">Invoice Number <b>{{invoiceId}}</b></p>

      <div [hidden]="!(errors$ | async)" class="order-confirmation__danger">
        <p>{{ errors$ | async }}</p>
      </div>

      <div [hidden]="!(success$ | async)" class="order-confirmation__success">
        <p>{{ success$ | async }}</p>
      </div>

      <div class="order-confirmation__actions">
        <button class="button button--green button__download" type="button" (click)="downloadInvoice()">
          Download
        </button>
      </div>
    </div>
  </div>
</div>
