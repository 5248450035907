import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { BaseApi } from './baseApi';

import { SessionService } from '../../utility/session.service';

// Types
import { DraftRequestType, IDraftRequest } from '../../types/api/IDraftRequest';


@Injectable()
export class DraftApi extends BaseApi {

  private routes = {
    hasDrafts: environment.baseUrl + 'api/diy/quote/hasDrafts',
    discardDraft: environment.baseUrl + 'api/diy/quote/draft/discard',
    saveDrafts: environment.baseUrl + 'api/diy/quote/drafts/new',
    getDrafts: environment.baseUrl + 'api/diy/quote/drafts',
  };

  constructor(
    private _http: HttpClient,
    protected _sessionService: SessionService,
  ) {
    super(_sessionService);
  }

  /**
   * Check if specified email has drafts
   */
  async hasDrafts(email: string): Promise<any> {
    const params = new HttpParams()
      .set('email', encodeURIComponent(email))
      .set('type', DraftRequestType.diyDraft);

    try {
      const res = await this._http.get(
        this.routes.hasDrafts,
        { params: params }
      ).toPromise();
      return res;
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Discard all drafts for specified email
   */
  async discardDrafts(email: string): Promise<void> {
    try {
      await this._http.put(
        this.routes.discardDraft,
        {
          email: email
        },
        this.httpOptions
      ).toPromise();
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Send request to save draft
   */
  saveDraft(email: string, drafts: IDraftRequest[], logData: string, source?: string): void {
    const formData = new FormData();
    formData.append('drafts', JSON.stringify(drafts));
    formData.append('email', encodeURIComponent(email));
    formData.append('logData', logData);
    formData.append('source', source);

    try {
      navigator.sendBeacon(this.routes.saveDrafts, formData);
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Get all drafts for specified email
   */
  async getDrafts(email: string): Promise<any> {
    const params = new HttpParams()
      .set('email', encodeURIComponent(email))
      .set('type', DraftRequestType.diyDraft);

    try {
      const res = await this._http.get(
        this.routes.getDrafts,
        { params: params }
      ).toPromise();
      return res;
    } catch (err) {
      console.error(err);
    }
  }
}
