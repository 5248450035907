import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { BaseApi } from './baseApi';

import { SessionService } from '../../utility/session.service';

// Types
import { UserRole } from '../../types/user/IUser';

@Injectable()
export class SalesRepProductFlowApi extends BaseApi {

  private routes = {

    validateToken: environment.baseUrl + 'api/diy/sales/authorize',
    getSalesData: environment.baseUrl + 'api/diy/sales/data',
    saveDesign: environment.baseUrl + 'api/diy/product-config/new',
  };

  constructor(
    private _http: HttpClient,
    protected _sessionService: SessionService,
  ) {
    super(_sessionService);
  }


  async validateAuthToken(token: string): Promise<any> {
    try {
      const resp: any = await this._http.post(`${this.routes.validateToken}?cacheBuster=${Date.now()}`, { token: token }).toPromise();
      if (resp) {
        resp.userData.role = UserRole.SALES;
      }
      return resp;
    } catch (err) {
      console.error(err);
    }
  }

  async getSalesData(quoteId: number): Promise<any> {
    try {
      const headers = this._createAuthorizationHeader();
      const params = new HttpParams().set('quoteId', `${quoteId}`);
      const res = await this._http.get(this.routes.getSalesData, { params: params, headers: headers }).toPromise();
      return res;
    } catch (err) { }
  }

  async saveDesign(newDesign): Promise<any> {
    try {
      const headers = this._createAuthorizationHeader();
      const res = await this._http.post(this.routes.saveDesign, newDesign, { headers: headers }).toPromise();
      return res;
    } catch (err) {

    }
  }
}
