import { Component, Input, HostListener } from '@angular/core';
import { document } from 'ngx-bootstrap/utils';

/**
 * @author Liviu Dima
 * Render app sidebar with dynamic content
 */
@Component({
  selector: 'diy-sidebar',
  templateUrl: 'sidebar.component.html',
  styleUrls: ['sidebar.component.scss']
})
export class SidebarComponent {

  @Input() toggleMessage: string;

  toggleClass = false;
  fixedSidebar = false;
  toBottom = false;

  toggleSidebar() {
    this.toggleClass = !this.toggleClass;
  }


  @HostListener('window:scroll', ['$event'])
  onScrollEvent($event): void {
    if (window.innerWidth > 1008) {
      const padding = (window.innerHeight <= 700) ? 230 : 500;
      const breakpointTop = 240 + window.innerHeight;
      const distanceScrolled = window.innerHeight + window.scrollY;
      const sidebarWrap = document.getElementsByClassName('sidebar__wrap')[0];
      const docHeight = (document.height !== undefined) ? document.height : document.body.offsetHeight;
      let sidebarHeight = 0;
      if (typeof sidebarWrap !== 'undefined') {
        sidebarHeight = sidebarWrap.offsetHeight;
      }
      this.toBottom = (docHeight - distanceScrolled + padding <= sidebarHeight);
      this.fixedSidebar = (distanceScrolled > breakpointTop);
    }
  }
}
