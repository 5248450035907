import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
  ViewChild,
} from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { HttpEvent, HttpProgressEvent, HttpEventType } from '@angular/common/http';

import { FileApi } from '../../core/api/FileApi';

// Types
import { IFileUpload } from './IFile';
import { IUploadedFile } from '../../types/artwork/IUploadedFile';

@Component({
  selector: 'diy-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit, OnDestroy, OnChanges {

  @Input() title: string;
  @Input() multiple = false;
  @Input() allowedExtensions: string[];
  @Input() disabled = false;
  @Input() maxFileSizeMb = 0;

  @Input() existingFiles$: Subject<IUploadedFile[]>;
  @Input() triggerUpload$: Subject<boolean>;
  @Input() triggerReset$: Subject<boolean>;

  @Output() fileUploaded = new EventEmitter<IUploadedFile>();
  @Output() fileDeleted = new EventEmitter<string>();
  @Output() fileError = new EventEmitter<any>();

  uploadingFiles = new Map<string, IFileUpload>();
  errorMessage: string;
  acceptTypes = '';

  private _subscriptions$: Subscription[] = [];
  private _acceptedTypes = {
    jpg: ['image/jpeg'],
    png: ['image/png'],
    pdf: ['application/pdf'],
    xls: ['application/vnd.ms-excel'],
    xlsx: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
    eps: ['application/postscript'],
    ai: ['application/illustrator', 'application/postscript'],
    psd: ['image/vnd.adobe.photoshop'],
    tif: ['image/tiff'],
    csv: ['text/csv']
  };
  private _acceptedTypesArray = [];

  @ViewChild('fileSelect', { static: true }) fileSelect;

  constructor(
    private _fileHandler: FileApi,
    private _changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    if (this.allowedExtensions) {
      this.allowedExtensions.forEach(ext => {
        if (this._acceptedTypes.hasOwnProperty(ext)) {
          this._acceptedTypesArray.push(...this._acceptedTypes[ext]);
        }
      });
      this.acceptTypes = this._acceptedTypesArray.join(', ');
      // workaround for files without type
      this._acceptedTypesArray.push('');
    }

    if (this.existingFiles$) {
      const subscription$ = this.existingFiles$.subscribe(existingFiles => {
        if (existingFiles) {
          existingFiles.forEach(file => {
            if (file) {
              this.uploadingFiles.set(
                file.name,
                {
                  percentDone: 100,
                  id: Date.now()
                }
              );
            }
          });
        }
        this._changeDetectorRef.detectChanges();
      });
      this._subscriptions$.push(subscription$);
    }

    if (this.triggerUpload$) {
      const subscription$ = this.triggerUpload$.subscribe(triggerUpload => {
        if (triggerUpload) {
          this.fileSelect.nativeElement.click();
        }
      });
      this._subscriptions$.push(subscription$);
    }

    if (this.triggerReset$) {
      const subscription$ = this.triggerReset$.subscribe(triggerReset => {
        if (triggerReset) {
          this.uploadingFiles.forEach((_value, key) => {
            this.delete(key);
          });
          this.fileSelect.nativeElement.value = '';
        }
      });
      this._subscriptions$.push(subscription$);
    }
  }

  ngOnDestroy() {
    this._subscriptions$.forEach(_subscription$ => _subscription$.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.disabled) {
      this.uploadingFiles.forEach((_value, key) => {
        this.delete(key);
      });
      this.fileSelect.nativeElement.value = '';
    }
  }

  async handleUpload(event) {
    this.errorMessage = '';
    const files: File[] = Array.from(event.target.files);

    if (!this.multiple) {
      this.uploadingFiles.forEach((_value, key) => {
        this.delete(key);
      });
      this.fileSelect.nativeElement.value = '';
    }

    files.forEach(async file => {
      if (this.uploadingFiles.has(file.name)) {
        return;
      }
      if (this._acceptedTypesArray.length && !this._acceptedTypesArray.includes(file.type)) {
        return this.errorMessage = `Please upload an image that is ${this.allowedExtensions.join(', ')} (${file.name})`;
      }

      if (this.maxFileSizeMb && file.size > (this.maxFileSizeMb * 1000000)) {
        return this.errorMessage = `Please upload an image that is smaller than ${this.maxFileSizeMb}MB (${file.name})`;
      }

      const subscription$ = this._fileHandler.upload(file).subscribe(
        (status: HttpEvent<HttpProgressEvent>) => {

          switch (status.type) {

            case HttpEventType.Sent: {
              this.uploadingFiles.set(file.name, { percentDone: 0 });
              break;
            }

            case HttpEventType.UploadProgress: {
              const currentProgress = Math.round((status.loaded / status.total) * 100);
              if (this.uploadingFiles.get(file.name).percentDone < currentProgress) {
                this.uploadingFiles.get(file.name).percentDone = currentProgress;
              }
              break;
            }

            case HttpEventType.Response: {
              if (status.body && status.body['newName'] && status.body['url']) {
                this.fileUploaded.emit({
                  name: file.name,
                  newName: status.body['newName'],
                  url: status.body['url']
                });
                this.uploadingFiles.get(file.name).id = status.body['newName'];
              } else {
                this.uploadingFiles.delete(file.name);
                this.errorMessage = status.body['message'] ? `${status.body['message']} (${file.name})` : this.errorMessage;
              }
              subscription$.unsubscribe();
              break;
            }

            default:
              break;
          }

        },
        error => {
          console.error(error);
          this.fileError.emit(error);
          subscription$.unsubscribe();
          this.uploadingFiles.delete(file.name);
        }
      );
      this._subscriptions$.push(subscription$);
    });

    this.fileSelect.nativeElement.value = '';
  }

  delete(fileName: string) {
    const file = this.uploadingFiles.get(fileName);
    this.uploadingFiles.delete(fileName);
    this.fileDeleted.emit(fileName);
    this._fileHandler.delete(`${file.id}`);
  }
}
