import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { SessionService } from '../../utility/session.service';

/**
 * Service used to check if an user is a authenticated sales rep to access certain routes.
 *
 * @author Andrei Diaconu
 */
@Injectable()
export class SalesRepGuard implements CanActivate {

  constructor(
    private _router: Router,
    private _sessionService: SessionService
  ) { }

  canActivate() {
    if (this._sessionService.isSalesLoggedIn()) {
      return true;
    }

    this._router.navigate(['/']);
    return false;
  }
}
