import { Injectable } from '@angular/core';

import { ProductFlowApi } from '../../core/api/productFlowApi';
import { ISearchResult } from '../../types/search/ISearchResult';

@Injectable({
  providedIn: 'root'
})
export class SearchEngineService {

  constructor(private _productFlowApi: ProductFlowApi) {
    this.indexedEntries = [];
  }

  indexedEntries: ISearchResult[];

  readonly resultCount: number = 6;

  async indexData() {
    this.indexedEntries = await this._productFlowApi.getSearchable();
  }

  find(input: string): ISearchResult[] {
    // if the json hasn't loaded yet
    if (this.indexedEntries.length <= 0) {
      return [];
    }

    // basic sanitising (remove dots and commas)
    const query = input.replace(/[.,]+/g, ' ').toLowerCase().trim();

    for (let i = 0; i < this.indexedEntries.length; i++) {

      const entryName = this.indexedEntries[i].name;

      if (entryName.indexOf(query) !== -1) {

        const strDiff = entryName.split(query).join('');

        this.indexedEntries[i].weight = strDiff.length;

        // patches have higher priority
        if (strDiff.length < 15 && entryName === 'embroidered patches') {
          this.indexedEntries[i].weight = 1;
        }

      } else {
        this.indexedEntries[i].weight = 9999;
      }

    }

    // sort by weight, keep only 4, remove all completely incompatible (hight weight)
    return this.indexedEntries
      .sort((res1, res2) => {
        return res1.weight - res2.weight;
      })
      .slice(0, this.resultCount)
      .filter(el => {
        return el.weight < 9999;
      });
  }

}
