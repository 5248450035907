import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CollapseModule } from 'ngx-bootstrap';

// Common
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { StepTrackingComponent } from './step-tracking/step-tracking.component';
import { SearchComponent } from './search/search.component';
import { SearchResultComponent } from './search/search-result/search-result.component';
import { NotesComponent } from './notes/notes.component';
import { CarouselComponent } from './carousel/carousel.component';
import { LoginComponent } from './login/login.component';
import { PromoBannerComponent } from './promo-banner/promo-banner.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SimpleSidebarComponent } from './simple-sidebar/simple-sidebar.component';
import { OrderStepTrackingComponent } from './step-tracking/order-step-tracking/order-step-tracking.component';
import { PackagingStepTrackingComponent } from './step-tracking/packaging-step-tracking/packaging-step-tracking.component';
import { DiyGalleryDirective } from '../utility/gallery/gallery.directive';
import { PhoneSelectorComponent } from './phone-selector/phone-selector.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FileApi } from '../core/api/FileApi';
import { SwitchButtonComponent } from './switch-button/switch.component';
import { TrustmarksComponent } from './trustmarks/trustmarks.component';
import { TatAnnouncementComponent } from './tat-announcement/tat-announcement.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    StepTrackingComponent,
    OrderStepTrackingComponent,
    PackagingStepTrackingComponent,
    SearchComponent,
    SearchResultComponent,
    NotesComponent,
    CarouselComponent,
    LoginComponent,
    PromoBannerComponent,
    SidebarComponent,
    SimpleSidebarComponent,
    DiyGalleryDirective,
    PhoneSelectorComponent,
    FileUploadComponent,
    SwitchButtonComponent,
    TrustmarksComponent,
    TatAnnouncementComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    CollapseModule.forRoot(),
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    StepTrackingComponent,
    OrderStepTrackingComponent,
    PackagingStepTrackingComponent,
    SearchComponent,
    SearchResultComponent,
    NotesComponent,
    CarouselComponent,
    LoginComponent,
    PromoBannerComponent,
    SidebarComponent,
    SimpleSidebarComponent,
    DiyGalleryDirective,
    PhoneSelectorComponent,
    FileUploadComponent,
    SwitchButtonComponent,
    TrustmarksComponent,
    TatAnnouncementComponent
  ],
  providers: [
    FileApi,
  ],
  entryComponents: []
})
export class CommonComponentsModule { }
