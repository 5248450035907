<div *ngIf="enabled; else promotionPlaceholder" class="promo-banner-wrap">
  <div class="content">
    <span>
      <!-- <div>
        Get 
      </div> -->
      <!-- <div class="discount">&nbsp;50% off&nbsp;</div>
      <div> all products. Add pins get </div>
      <div class="discount">&nbsp;odiod60% off&nbsp;</div>
      <div> w/ special code in email </div> -->
      
      <div>Limited time offer! Get up to</div>
      <div class="discount">&nbsp;{{ discountAmount }}% OFF&nbsp;</div>
      <div>on all products!</div>
      <!-- <div>
        Don't miss out! Use code
      </div>
      <div class="discount">&nbsp;DECEMBER45 for 45% OFF&nbsp;</div>
      <div>
        your order for a limited time!
      </div> -->
    </span>
    <!-- <a href="https://www.thestudio.com/about/2018-promo-details/">Details</a> -->
    <button class="close" (click)="close()">&times;</button>
  </div>
</div>

<ng-template #promotionPlaceholder>
  <div class="promo-banner-wrap"></div>
</ng-template>
