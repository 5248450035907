<div class="order-details-wrap" *ngIf="quote">
  <div class="order-details-item">
    <div>
      <p>Customer Name</p>
      <p>Phone Number</p>
      <p>Quote Number</p>
      <p>Type</p>
      <p>Product</p>
      <p>Request Date</p>
    </div>
    <div>
      <p>{{quote?.customerData?.customerName}}</p>
      <p>{{quote?.customerData?.phoneNumber || '-'}}</p>
      <p>{{quote?.customerData?.quoteNumber}}</p>
      <p>{{quote?.customerData?.type}}</p>
      <p>{{quote?.customerData?.product}}</p>
      <p>{{quote?.customerData?.requestDate}}</p>
    </div>
  </div>

  <div class="order-details-item">
    <div>
      <p>Customer ID</p>
      <p>Score</p>
      <p>LTV</p>
      <p>Reference</p>
      <p>Amount Spent</p>
      <p *ngIf="quote?.customerData?.acceptedSample">Free Artwork</p>
    </div>
    <div>
      <p>{{quote?.customerData?.customerId}}</p>
      <p>{{quote?.customerData?.score}}</p>
      <p>{{quote?.customerData?.ltv}}</p>
      <p>{{quote?.customerData?.reference}}</p>
      <p>{{quote?.customerData?.amountSpent}}</p>
      <p *ngIf="quote?.customerData?.acceptedSample">Accepted</p>
    </div>
  </div>

  <div class="order-details-item">
    <diy-carousel style="width: 100%" [quotes]="quote?.openQuotes"></diy-carousel>
  </div>

</div>
