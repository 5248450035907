<div class="header-wrap">
  <div class="bottom">
    <div class="bottom__container">
      <a class="bottom__logo" href="/">
        <img src="assets\img\The-Studio-Logo-white.svg" />
      </a>
      <input type="checkbox" id="showMobileNav" />
      <label for="showMobileNav" class="bottom__mobileIcon"
        ><span>&nbsp;</span></label
      >
      <ul class="bottom__navigation">
        <!-- <li class="bottom__item">
          <button type="button" id="chat-button" onclick="customPlugin.command('WebChat.open', getAdvancedConfig());">Start Chat</button>
        </li> -->
        <li class="bottom__item">
          <!-- <a href="tel:{{ telNumber }}" class="bottom__link">{{ telShown }}</a> -->
        </li>
        <li *ngIf="customerDashboardOrigin">
          <a href="{{ customerDashURL }}" class="user">
            <img src="assets\img\user.svg" />
          </a>
        </li>
        <li class="bottom__item">
          <a routerLink="/shop/cart" class="cart">
            <div *ngIf="totalCartItems" class="cart__notification">
              {{ totalCartItems }}
            </div>
            <img src="assets\img\shopping-cart.svg" />
          </a>
        </li>
        <li class="bottom__logo__user" *ngIf="isLoggedIn()">
          <button class="bottom__logo__user-logout" (click)="logout()">
            Logout
          </button>
        </li>
      </ul>
    </div>
  </div>
</div>
