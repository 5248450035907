<!-- Page content -->
<div class="container survey">
  <h1> Thank you for your interest in creating custom products!</h1>
  <h2>To better service you, please provide us with more information about your purchase.</h2>

  <form [formGroup]="surveryForm">
    <div class="form-group">
      <h3>Are you purchasing as an individual or a business?</h3>
      <label>
        <input type="radio" formControlName="type" value="individual" />
        Individual
      </label>
      <label>
        <input type="radio" formControlName="type" value="business" />
        Business
        <input *ngIf="showCompany" class="form-control" type="text" formControlName="company"
          placeholder="Company Name" />
      </label>
    </div>

    <div class="form-group">
      <h3>What is your primary reason for creating custom products?</h3>
      <label>
        <input type="radio" formControlName="reason" value="'To sell'" />
        To sell
      </label>
      <label>
        <input type="radio" formControlName="reason" value="'Part or embellishment for your product'" />
        Part or embellishment for your product
      </label>
      <label>
        <input type="radio" formControlName="reason" value="'As promotional giveaways'" />
        As promotional giveaways
      </label>
      <label>
        <input type="radio" formControlName="reason" value="'For employees'" />
        For employees
      </label>
      <label>
        <input type="radio" formControlName="reason" value="'For your customers' event/project'" />
        For your customers' event/project
      </label>
      <label>
        <input type="radio" formControlName="reason" value="'Personal Use'" />
        Personal Use
      </label>
      <label>
        <input type="radio" formControlName="reason" value="custom" />
        Other
        <input *ngIf="showCustomReason" type="text" class="form-control" formControlName="customReason" />
      </label>
    </div>


    <div class="form-group">
      <h3>How often do you manufacture custom products?</h3>
      <label>
        <input type="radio" formControlName="frequency" value="'Just once'" />
        Just once
      </label>
      <label>
        <input type="radio" formControlName="frequency" value="'Once a year'" />
        Once a year
      </label>
      <label>
        <input type="radio" formControlName="frequency" value="'2-3 times per year'" />
        2-3 times per year
      </label>
      <label>
        <input type="radio" formControlName="frequency" value="'4-6 times per year'" />
        4-6 times per year
      </label>
      <label>
        <input type="radio" formControlName="frequency" value="'6+ times per year'" />
        6+ times per year
      </label>
    </div>
  </form>
</div>
