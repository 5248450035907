<div id='trustmarks-padding' class="main-content" [ngClass]="{'no-pad': thankYouPage, 'lead-capture': leadCapture}">
  <router-outlet (activate)="onActivate($event)"></router-outlet>
  <div class="chat-btn" id="pc-chat-btn" onclick="customPlugin.command('WebChat.open', customfields());">
    <img src="../assets/img/chat-icn.svg" alt="">
    <span>Chat with us</span>
  </div>
</div>

<div class="footer-content">
  <diy-footer></diy-footer>
</div>
