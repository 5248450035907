import { Component, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'diy-login-prompt',
  templateUrl: './login-prompt.component.html',
  styleUrls: [
    './login-prompt.component.scss'
  ]
})
export class LoginPromptComponent {

  title = 'Login';
  continueText = 'Log In';

  @Output() authComplete = new EventEmitter();

  constructor() { }

  onAuthComplete(success) {
    if (success) {
      this.authComplete.emit();
    }
  }

}
