import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { VTBaseComponent } from '../VTBase.component';

import { AddVariation } from '../../../../state-manager/order-item/order-item.actions';

import { AmplitudeService } from '../../../../core/services/amplitude';
import { VariationManager } from '../../../../core/services/variation-manager.service';

import { IAppState } from '../../../../types/app-state/IAppState';
import { IOrderItem } from '../../../../types/app-state/IOrderItem';
import { Utils } from '../../../../core/services/utils';
import { ICustomSize } from '../../../../types/product/variations/ICustomSize';
import { IBundleItem } from '../../../../types/bundle/IBundle';
import { AvailableVariationTypeTypes } from '../../../../types/product/variations/IVariationType';

@Component({
  selector: 'diy-size',
  templateUrl: 'size.component.html',
  styleUrls: ['../../customize.component.scss']
})
export class SizeComponent extends VTBaseComponent implements OnInit {

  customSizeEnabled = true;
  hasCustomSize = false;
  d1 = this._formBuilder.group({
    size: ['', Validators.required]
  });
  d2 = this._formBuilder.group({
    width: ['', Validators.compose([
      Validators.required,
      Validators.pattern(/^((?!(Select)).)*$/)
    ])],
    height: ['', Validators.compose([
      Validators.required,
      Validators.pattern(/^((?!(Select)).)*$/)
    ])],
  });
  sizeOptions: Array<{ name: string, value: number }> = [];

  constructor(
    private _formBuilder: FormBuilder,
    protected _amp: AmplitudeService,
    protected _store: Store<IAppState>,
  ) {
    super(_amp, _store);
  }

  ngOnInit() {
    const subscription$: Subscription = this._store.pipe(select('orderItem'))
      .subscribe((orderItem: IOrderItem) => {
        this.orderItem = orderItem;
        this._handleBundleItem(orderItem.bundleItem);
        this.currentlySelected = orderItem.variationTypes.find(selection => selection.vtCategory === AvailableVariationTypeTypes.size);
      });


    this._initSizeOptions();

    if (this.variationType.dimensions === 1) {
      this.d1.controls.size.setValue(this.currentlySelected ? this.currentlySelected.variation.id : '');
    }

    this._subscriptions$.push(subscription$);


    // edit values
    if (this.orderItem.variationTypes) {
      const customSize = this.orderItem.variationTypes.find(element => {
        return (element.vtID === this.variationType.id && (element.variation as ICustomSize).customType === 'size');
      });

      if (customSize) {
        this.d2.controls.width.setValue((customSize.variation as ICustomSize).width);
        this.d2.controls.height.setValue((customSize.variation as ICustomSize).height);
      } else if (this.currentlySelected) {
        const sizeValue = Utils.convertToNumber(this.currentlySelected.variation.name);
        this.d2.controls.width.setValue(sizeValue);
        this.d2.controls.height.setValue(sizeValue);
      }
    }
  }

  /**
   * Handle variation selection for 1 dimension Size
   */
  handle1DSize() {
    if (this.d1.controls.size.valid
    ) {
      const chosenVariation = this.variationType.variations.find(variation => variation.id === Number(this.d1.controls.size.value));
      console.log(chosenVariation);
      this.chooseVariation(chosenVariation);
    }
  }

  /**
   * Handle variation selection for 2 dimensions Size
   */
  handle2DSize() {
    if (this.d2.controls.height.valid
      && this.d2.controls.width.valid
    ) {
      const width = Number(this.d2.controls.width.value);
      const height = Number(this.d2.controls.height.value);

      let selectedSize;
      if (width === height) {
        selectedSize = this.variationType.variations.find(variation => Utils.convertToNumber(variation.name) === Number(width));
      }

      if (selectedSize) {
        return this.chooseVariation(selectedSize);
      }

      selectedSize = VariationManager.createSelectedCustomSize(width, height, this.variationType);
      this._store.dispatch(new AddVariation(selectedSize));
      this.variationSelected.emit();
    } else {
      this.removeVariationType();
    }
  }

  /**
   * Init DropDown values
   */
  private _initSizeOptions() {

    let sizeOptions;

    if (this.variationType.typeSize !== 'contiguous') {
      if (this.variationType.dimensions === 1) {
        return this.sizeOptions = this.variationType.variations
          .filter(variation => VariationManager.checkVariationCompatibility(variation, this.orderItem.incompatibilities))
          .map(variation => ({ name: variation.name, value: variation.id }));
      } else if (this.variationType.dimensions === 2) {
        // Get sorted values for variations
        sizeOptions = Utils.getSortedVariationValues(this.variationType.variations.filter(variation => variation.showInDIY)
          .filter(variation => VariationManager.checkVariationCompatibility(variation, this.orderItem.incompatibilities)));
        return this.sizeOptions = sizeOptions.map(option => ({ name: String(option), value: option }));
      }
    }

    // Re-get sorted variation without filtering for show in DIY as we are gonna generate them between min and max values
    sizeOptions = Utils.getSortedVariationValues(this.variationType.variations);

    if (this.variationType.typeSize === 'contiguous') {
      // enable custom size validation only for contiguous
      this.hasCustomSize = true;

      const minSize = sizeOptions[0];
      const maxSize = sizeOptions[sizeOptions.length - 1];

      const customSizeOptions = [];
      for (let i = minSize; i <= maxSize; i += .5) {
        customSizeOptions.push({ name: i, value: i });
      }

      return this.sizeOptions = customSizeOptions;
    }
  }

  private _handleBundleItem(newBundleItemState: IBundleItem) {

    // only execute when changes to bundleItem appear
    if (this.bundleItem !== newBundleItemState) {

      this.removeVariationType();
      this.bundleItem = newBundleItemState;

      if (this.bundleItem) {
        this.bundleVariation = this.variationType.variations
          .find(variation => !!~newBundleItemState.variations.indexOf(variation.id));

        this.customSizeEnabled = false;
        this.d2.disable();
        this.chooseVariation(this.bundleVariation);
      } else {
        this.d2.enable();
        this.customSizeEnabled = true;
      }
    }
  }

}
