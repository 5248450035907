import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';

import { VTBaseComponent } from '../VTBase.component';

import { AmplitudeService } from '../../../../core/services/amplitude';
import { PriceCalculatorService } from '../../../../core/services/price-calculator';
import { VariationManager } from '../../../../core/services/variation-manager.service';
import { Utils } from '../../../../core/services/utils';

import { AddVariation } from '../../../../state-manager/order-item/order-item.actions';

import { IAppState } from '../../../../types/app-state/IAppState';
import { IOrderItem } from '../../../../types/app-state/IOrderItem';
import { IVariation } from '../../../../types/product/variations/IVariation';
import { AvailableVariationTypeTypes } from '../../../../types/product/variations/IVariationType';

@Component({
  selector: 'diy-quantity',
  templateUrl: 'quantity.component.html',
  styleUrls: ['../../customize.component.scss']
})
export class QuantityComponent extends VTBaseComponent implements OnInit {

  customQtyEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  customQtyGroup: FormGroup;
  minQty: number;
  maxQty: number;
  popUpState = false;
  remaining: string[] = [];

  constructor(
    protected _amp: AmplitudeService,
    private _formBuilder: FormBuilder,
    protected _store: Store<IAppState>
  ) {
    super(_amp, _store);

    this.customQtyGroup = this._formBuilder.group({
      qty: ['']
    });
  }

  ngOnInit() {

    const sortedVariations = [...this.variationType.variations]
      .sort((a: IVariation, b: IVariation) => {
        return Utils.convertToNumber(a.name) - Utils.convertToNumber(b.name);
      });

    this.minQty = Utils.convertToNumber(sortedVariations[0].name);
    this.maxQty = Utils.convertToNumber(sortedVariations[sortedVariations.length - 1].name);

    const storeSub = this._store.pipe(select('orderItem'))
      .subscribe((orderItem: IOrderItem) => {

        this.orderItem = orderItem;
        const selected = orderItem.variationTypes;

        const selectedVariation = selected.find(
          sel => {
            if (sel.vtCategory === AvailableVariationTypeTypes.quantity) {
              this.customQtyEnabled.next(sel.variation.hasOwnProperty('customType'));
              return true;
            }
          });

        if (selectedVariation) {
          this.currentlySelected = selectedVariation;
        }
        this._updateRemaining();
      });

    this._subscriptions$.push(storeSub);
    this._subscriptions$.push(this._customQtyValidation$());


    if (this.orderItem && this.orderItem.variationTypes) {

      const customQt = this.orderItem.variationTypes.find(element => {
        if ('customType' in element.variation) {
          return (element.vtID === this.variationType.id && element.variation.customType === 'qty');
        }
      });

      // edit custom qty
      if (customQt) {
        this.customQtyEnabled.next(true);
        this.selectedVariation = customQt.variation;

        if ('quantityValue' in customQt.variation) {
          this.customQtyGroup.controls['qty'].setValue(customQt.variation.quantityValue);
        }
      }
    }
  }


  getVariationPrice(variation: IVariation, applyDiscount = true): number {
    const qty = Utils.convertToNumber(variation.name);
    return PriceCalculatorService.getQuantityVariationPrice(variation, qty, this.orderItem, this.variationType, applyDiscount);
  }

  getCustomQuantityPrice(applyDiscount = true): number {
    const qty = Number(this.customQtyGroup.controls['qty'].value);
    return PriceCalculatorService.getCustomQuantityPrice(this.minQty, qty, this.orderItem, this.variationType, applyDiscount);
  }

  getVariationPricePerUnit(variation: IVariation, applyDiscount = true): number {
    const qty = Utils.convertToNumber(variation.name);
    const total = PriceCalculatorService.getQuantityVariationPrice(variation, qty, this.orderItem, this.variationType, applyDiscount);
    return total / qty;
  }

  getCustomQuantityPerUnit(applyDiscount = true): number {
    const qty = Number(this.customQtyGroup.controls['qty'].value);
    const total = PriceCalculatorService.getCustomQuantityPrice(this.minQty, qty, this.orderItem, this.variationType, applyDiscount);
    return total / qty;
  }

  selectQuantity(variation: IVariation) {
    this.customQtyEnabled.next(false);
    this.customQtyGroup.reset();
    this.chooseVariation(variation);
  }

  selectCustomQuantity() {

    // Enable min/max validators for qty
    this.customQtyEnabled.next(true);

    const customQuantity = Number(this.customQtyGroup.controls['qty'].value);

    if (this.customQtyGroup.controls['qty'].valid && customQuantity > 0) {

      // custom quantity chosen variation
      const ccv = VariationManager.createSelectedCustomQty(customQuantity, this.variationType);

      this._logQty(ccv);
      this._store.dispatch(new AddVariation(ccv));
    } else {
      this.removeVariationType();
    }
  }

  isChecked(variation: IVariation): boolean {

    if (!this.currentlySelected) {
      return false;
    }

    if (this.currentlySelected.variation.hasOwnProperty('customType')) {
      this.customQtyEnabled.next(true);
    } else {
      this.customQtyEnabled.next(false);
      return this.currentlySelected.variation.id === variation.id;
    }
  }


  showCustomSizeError() {
    if (this.customQtyEnabled) {
      return !this.customQtyGroup.controls['qty'].valid && this.customQtyGroup.controls['qty'].dirty;
    }
    return false;
  }

  private _updateRemaining() {

    // available variation types
    const nonQtyVar = this.orderItem.product.variationTypes.filter(vt => vt.category !== 'qty');

    this.remaining = [];

    if (this.orderItem && this.orderItem.variationTypes && this.orderItem.variationTypes.length) {
      for (let i = 0; i < nonQtyVar.length; i++) {
        // match against selected variation types
        const match = this.orderItem.variationTypes.find(arrElem => {
          return arrElem.vtID === nonQtyVar[i].id;
        });

        if (!match) {
          this.remaining.push(nonQtyVar[i].name);
        }
      }
    } else {

      for (let i = 0; i < nonQtyVar.length; i++) {
        this.remaining.push(nonQtyVar[i].name);
      }
    }

    if (this.remaining.length > 0) {
      this.customQtyGroup.disable();
    } else {
      this.customQtyGroup.enable();
    }
  }

  private _customQtyValidation$(): Subscription {
    return this.customQtyEnabled.subscribe(value => {

      this.customQtyGroup.controls['qty'].clearValidators();
      if (value) {
        this.customQtyGroup.controls['qty'].setValidators([
          Validators.min(this.minQty),
          Validators.max(this.maxQty),
          Validators.required
        ]);
        this.customQtyGroup.enable();
      } else {
        this.customQtyGroup.controls['qty'].setValidators([
          Validators.min(this.minQty),
          Validators.max(this.maxQty)
        ]);
      }
      this.customQtyGroup.controls['qty'].updateValueAndValidity();
    });
  }

}
