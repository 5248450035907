import { Action } from '@ngrx/store';
import { ICategory } from '../../types/category/ICategory';

export enum CategoriesActions {
  UpdateCategories = '[AppState] Add Categories',
}

export class UpdateCategories implements Action {
  readonly type = CategoriesActions.UpdateCategories;
  constructor(public payload: ICategory[]) { }
}


export type CategoriesActionsUnion = UpdateCategories;
