<div class="variation">
  <div class="variation__title">
    <p>Select {{variationType?.name}}*</p>
    <div class="variation__info">
      <button>i</button>
      <span>{{variationType?.info}} </span>
    </div>
  </div>
  <p class="variation__alert">Oops! You forgot to select size. This step is required.</p>

  <!-- 1 dimension form -->
  <div *ngIf="variationType?.dimensions === 1" class="variation__item" [formGroup]="d1">
    <select formControlName="size" (change)="handle1DSize()">
      <option>Select</option>
      <option *ngFor="let option of sizeOptions" [value]="option.value">
        {{option.name}}</option>
    </select>
  </div>

  <!-- 2 dimensions form -->
  <div *ngIf="variationType?.dimensions === 2" class="variation__item" [formGroup]="d2">
    <select formControlName="width" (change)="handle2DSize()">
      <option>Select</option>
      <option *ngFor="let option of sizeOptions" [value]="option.value">
        {{option.name}}</option>
    </select>
    <span>inches wide</span>

    <select formControlName="height" (change)="handle2DSize()">
      <option>Select</option>
      <option *ngFor="let option of sizeOptions" [value]="option.value">
        {{option.name}}</option>
    </select>
    <span>inches long</span>
  </div>

</div>
