import { Component, OnInit } from '@angular/core';

import { IImage } from '../../types/images/IImage';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'diy-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

  currentImage: IImage;
  imageList: IImage[] = [];

  constructor(public bsModalRef: BsModalRef) {
  }

  ngOnInit() {
    this.imageList = [...this.imageList];
    let currentImage = this.imageList.find(image => image.main);
    if (!currentImage) {
      currentImage = this.imageList[0];
    }

    this.imageList.sort((a) => a.main ? -1 : 0);

    this.currentImage = currentImage;
  }

  /**
   * @param galleryItem
   */
  chooseImage(galleryItem: IImage) {
    this.currentImage = galleryItem;
  }

}
