import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';

// Services
import { ArtworkService } from '../../core/services/artwork.service';

// Actions
import { OrderItemActionTypes } from './order-item.actions';

/**
 *
 * @author Andrei Diaconu
 */
@Injectable()
export class OrderItemEffects {

  constructor(
    private _actions: Actions,
    private _artworkService: ArtworkService,
  ) { }

  @Effect({ dispatch: false })
  addArtwork(): Observable<Action> {
    return this._actions.pipe(
      ofType(
        OrderItemActionTypes.AddArtwork,
        OrderItemActionTypes.SetOrderItem,
      ),
      tap(() => {
        this._artworkService.setArtworkCookie();
      })
    );
  }

}
