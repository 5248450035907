import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class OrderMessengerService {

  private _stepChange: BehaviorSubject<number>;

  constructor() {
    this._stepChange = new BehaviorSubject(0);
  }

  updateStep(step: number) {
    this._stepChange.next(step);
  }

  getStep() {
    return this._stepChange.asObservable();
  }
}
