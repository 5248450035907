import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

import { AuthProvider } from '../../../core/api/AuthProvider';

// Services
import { SessionService } from '../../../utility/session.service';
import { CookieService } from '../../../core/services/cookie.service';

// Types
import { ICustomerInfo } from '../../../types/quote/IQuote';
import { UserRole } from '../../../types/user/IUser';

/**
 * Service used to check if an user can access the payment page
 */
@Injectable()
export class PaymentGuard implements CanActivate {

  constructor(
    private _router: Router,
    private _sessionService: SessionService,
    private _auth: AuthProvider,
    private _cookieService: CookieService,
  ) { }

  async canActivate(): Promise<boolean> {

    // first check if we have a logged in user
    if (this._sessionService.isLoggedIn()) {
      return true;
    }

    const user = this._sessionService.user;

    let email = '';

    // first try to grab the email from the session
    if (user && this._sessionService.isGranted(UserRole.CUSTOMER)) {
      email = user.email;
    } else {
      // then if there isn't any, check the current quote for an email
      const customerInfo: ICustomerInfo = this._cookieService.getJson('customerInfo');
      if (customerInfo) {
        email = customerInfo.email;
      }
    }

    if (email) {
      const resp = await this._auth.checkCustomerEmail(email);
      if (resp.data) {
        this._router.navigate(['/shop/login']);
      }

    }
    return true;
  }
}
