// External
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { of, EMPTY } from 'rxjs';

// Api provider
import { BundlePaymentApi } from '../../shop/core/api/bundlePaymentApi';

import { SetBundleData } from '../../state-manager/bundle/bundle.actions';

import { IAppState } from '../../types/app-state/IAppState';

/**
 * @author Liviu Dima
 */
@Injectable()
export class BundlePaymentResolver implements Resolve<any> {

  constructor(
    private _bundlePaymentApi: BundlePaymentApi,
    private _store: Store<IAppState>,
    private _router: Router,
  ) { }


  async resolve(route: ActivatedRouteSnapshot, rstate: RouterStateSnapshot): Promise<any> {
    const bundleId = route.paramMap.get('bundleId');

    if (bundleId) {
      const res = await this._bundlePaymentApi.getBundleData(Number(bundleId));
      if (res && res.success && res.bundles && res.bundles.length) {
        this._store.dispatch(new SetBundleData(res.bundles[0]));
        return of(res.bundles[0]);
      }
    }

    this._router.navigate(['/']);
    return EMPTY;
  }

}
