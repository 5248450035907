import { ActionReducerMap, MetaReducer, ActionReducer } from '@ngrx/store';
import { environment } from '../../environments/environment';

import { reducer as CategoriesReducer } from './categories/categories.reducer';
import { reducer as OrderItemReducer } from './order-item/order-item.reducer';
import { reducer as PackagingOrderReducer } from './packaging-order/packaging-order.reducer';
import { reducer as ShoppingCartReducer } from './shopping-cart/shopping-cart.reducer';
import { reducer as BundleReducer } from './bundle/bundle.reducer';

import { IAppState } from '../types/app-state/IAppState';


export const reducers: ActionReducerMap<IAppState> = {
  orderItem: OrderItemReducer,
  shoppingCart: ShoppingCartReducer,
  bundleData: BundleReducer,
  packagingOrder: PackagingOrderReducer,
  categories: CategoriesReducer,
};

export function logger(reducer: ActionReducer<IAppState>): ActionReducer<IAppState> {
  return function (state: IAppState, action: any): IAppState {
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<IAppState>[] = !environment.production
  ? [logger]
  : [];
