export enum DiscountTypes {
  PERCENT = 'percentage',
  AMOUNT = 'amount'
}

export interface IVoucherResponse {
  success: boolean;
  valid: boolean;
  type: DiscountTypes;
  discount: number;
  products: number[];
  reason?: string;
}
