<div class="shopping-cart__item mb-4">

  <div class="shopping-cart__item-date">
    <div>
      <input type="checkbox" name="shopping-cart__item-date--checkbox" id="{{cartIndex}}"
        class="shopping-cart__item-date--checkbox" (change)="checkItem()" [(ngModel)]="itemChecked">
      <label for="{{cartIndex}}" class="shopping-cart__item-date--checkbox-label"></label>
    </div>
    <p>
      <strong>{{deliveryDateType}}</strong> {{deliveryDate}}
    </p>
  </div>

  <diy-cart-item-order *ngFor="let cartItemOrder of cartItem?.cartItemOrders; let i = index"
    [cartItemOrder]="cartItemOrder" [cartIndex]="cartIndex" [cartItemOrderIndex]="i"
    [isGrouped]="cartItem?.cartItemOrders?.length > 1" (clearChecked)="clearCheckedItems()">
  </diy-cart-item-order>

</div>
