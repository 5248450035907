import { Action } from '@ngrx/store';

import { IArtwork } from '../../types/artwork/IArtwork';
import { ICategory } from '../../types/category/ICategory';
import { ISelectedVariation } from '../../types/app-state/ISelectedVariation';
import { IProduct } from '../../types/product/IProduct';
import { ITurnaround } from '../../types/turnaround/ITurnaround';
import { IPrice } from '../../types/product/IPrice';
import { IOrderItem } from '../../types/app-state/IOrderItem';
import { IBundleItem } from '../../types/bundle/IBundle';

export enum OrderItemActionTypes {
  UpdateCategory = '[Order] Update Category',
  ClearCategory = '[Order] Clear Category',
  UpdateProduct = '[Order] Update Product',
  ClearProduct = '[Order] Clear Product',
  UpdateQuoteId = '[Order] Update Quote Id',
  ResetPrice = '[Order] Reset Price',
  AddArtwork = '[Order] Add Artwork',
  RemoveArtwork = '[Order] Remove Artwork',
  UpdatePrice = '[Order] Update Price',
  AddVariation = '[Order] Add Variation',
  RemoveVariation = '[Order] Remove Variation',
  ReplaceVariations = '[Order] Replace Variations',
  RemoveVariations = '[Order] Remove Variations',
  AddSpecialOption = '[Order] Add Special Options',
  RemoveSpecialOption = '[Order] Remove Special Option',
  RemoveSpecialOptions = '[Order] Remove Special Options',
  ReplaceSpecialOptions = '[Order] Replace Special Options',
  UpdateTurnaround = '[Order] Turnaround Change',
  RemoveTurnaround = '[Order] Remove Turnaround',
  UpdateReorderId = '[Order] Update Reorder Id',
  UpdateFirstInquiry = '[Order] Update First Inquiry',
  SetOrderItem = '[Order] Set Order Item',
  ResetOrderItem = '[Order] Reset Order Item',
  AddBundleItem = '[Order] Add Bundle Item',
  RemoveBundleItem = '[Order] Remove Bundle Item',
  SetCopyQuoteArtwork = '[Order] Set Copy Quote Artwork',
}

export class UpdateCategory implements Action {
  readonly type = OrderItemActionTypes.UpdateCategory;
  constructor(public payload: ICategory) { }
}

export class ClearCategory implements Action {
  readonly type = OrderItemActionTypes.ClearCategory;
  constructor() { }
}

export class UpdateProduct implements Action {
  readonly type = OrderItemActionTypes.UpdateProduct;
  constructor(public payload: IProduct) { }
}

export class ClearProduct implements Action {
  readonly type = OrderItemActionTypes.ClearProduct;
  constructor() { }
}

export class UpdateQuoteId implements Action {
  readonly type = OrderItemActionTypes.UpdateQuoteId;
  constructor(public payload: { quoteId: number, customerEmail: string }) { }
}

export class ResetPrice implements Action {
  readonly type = OrderItemActionTypes.ResetPrice;
  constructor() { }
}

export class AddArtwork implements Action {
  readonly type = OrderItemActionTypes.AddArtwork;
  constructor(public payload: IArtwork) { }
}

export class RemoveArtwork implements Action {
  readonly type = OrderItemActionTypes.RemoveArtwork;
  constructor() { }
}

export class UpdatePrice implements Action {
  readonly type = OrderItemActionTypes.UpdatePrice;
  constructor(public payload: IPrice) { }
}

export class AddVariation implements Action {
  readonly type = OrderItemActionTypes.AddVariation;
  constructor(public payload: ISelectedVariation) { }
}

export class ReplaceVariations implements Action {
  readonly type = OrderItemActionTypes.ReplaceVariations;
  constructor(public payload: ISelectedVariation[]) { }
}

export class RemoveVariation implements Action {
  readonly type = OrderItemActionTypes.RemoveVariation;
  constructor(public payload: ISelectedVariation) { }
}

export class RemoveVariations implements Action {
  readonly type = OrderItemActionTypes.RemoveVariations;
  constructor() { }
}

export class RemoveSpecialOption implements Action {
  readonly type = OrderItemActionTypes.RemoveSpecialOption;
  constructor(public payload: ISelectedVariation) { }
}

export class RemoveSpecialOptions implements Action {
  readonly type = OrderItemActionTypes.RemoveSpecialOptions;
  constructor() { }
}

export class AddSpecialOption implements Action {
  readonly type = OrderItemActionTypes.AddSpecialOption;
  constructor(public payload: ISelectedVariation) { }
}

export class ReplaceSpecialOptions implements Action {
  readonly type = OrderItemActionTypes.ReplaceSpecialOptions;
  constructor(public payload: ISelectedVariation[]) { }
}

export class UpdateTurnaround implements Action {
  readonly type = OrderItemActionTypes.UpdateTurnaround;
  constructor(public payload: ITurnaround) { }
}

export class RemoveTurnaround implements Action {
  readonly type = OrderItemActionTypes.RemoveTurnaround;
  constructor() { }
}

export class UpdateReorderId implements Action {
  readonly type = OrderItemActionTypes.UpdateReorderId;
  constructor(public payload: number) { }
}

export class UpdateFirstInquiry implements Action {
  readonly type = OrderItemActionTypes.UpdateFirstInquiry;
  constructor(public payload: string) { }
}

export class ResetOrderItem implements Action {
  readonly type = OrderItemActionTypes.ResetOrderItem;
  constructor() { }
}

export class AddBundleItem implements Action {
  readonly type = OrderItemActionTypes.AddBundleItem;
  constructor(public payload: IBundleItem) { }
}
export class RemoveBundleItem implements Action {
  readonly type = OrderItemActionTypes.RemoveBundleItem;
  constructor() { }
}
export class SetOrderItem implements Action {
  readonly type = OrderItemActionTypes.SetOrderItem;
  constructor(public payload: IOrderItem) { }
}

export class SetCopyQuoteArtwork implements Action {
  readonly type = OrderItemActionTypes.SetCopyQuoteArtwork;
  constructor(public payload: boolean) { }
}

export type OrderItemsActionsUnion = UpdateCategory | ClearCategory | UpdateProduct | ClearProduct | UpdateQuoteId |
  ResetPrice | AddArtwork | RemoveArtwork | UpdateTurnaround | AddSpecialOption |
  AddVariation | UpdatePrice | UpdateReorderId | UpdateFirstInquiry |
  RemoveVariation | RemoveSpecialOption | RemoveVariations | RemoveSpecialOptions | ResetOrderItem | SetOrderItem
  | ReplaceVariations | ReplaceSpecialOptions | RemoveTurnaround | AddBundleItem | RemoveBundleItem | SetCopyQuoteArtwork;
