import { Injectable } from '@angular/core';
import { CanDeactivate, Router } from '@angular/router';
import { NavigationService } from '../../../core/services/navigation.service';

@Injectable()
export class NavigationGuardService implements CanDeactivate<boolean> {

  constructor(
    private _router: Router,
    private _navService: NavigationService
  ) { }

  canDeactivate(component: any) {
    if (this._navService.clickedBack) {
      this._navService.clickedBack = false;
      this._router.navigate(['/'], { replaceUrl: true });
      return false;
    }
    return true;
  }

}
