import { Input, OnDestroy, Output, EventEmitter, Directive } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { AmplitudeService } from '../../../core/services/amplitude';
import { VariationManager } from '../../../core/services/variation-manager.service';

import { AddVariation, RemoveVariation } from '../../../state-manager/order-item/order-item.actions';

import { IVariationType } from '../../../types/product/variations/IVariationType';
import { IVariation } from '../../../types/product/variations/IVariation';
import { ISelectedVariation } from '../../../types/app-state/ISelectedVariation';
import { IAppState } from '../../../types/app-state/IAppState';
import { IOrderItem } from '../../../types/app-state/IOrderItem';
import { ICustomQuantity } from '../../../types/product/variations/ICustomQuantity';
import { ICustomSize } from '../../../types/product/variations/ICustomSize';
import { IBundleItem } from '../../../types/bundle/IBundle';
import { IBundleQuantity } from '../../../types/product/variations/IBundleQuantity';

/**
 * Is extended by variation type classes
 */
@Directive()
export abstract class VTBaseComponent implements OnDestroy {

  selectedVariation: IVariation | ICustomQuantity | ICustomSize | IBundleQuantity;

  currentlySelected: ISelectedVariation;
  orderItem: IOrderItem;

  bundleVariation: IVariation;
  bundleItem: IBundleItem;

  protected _subscriptions$: Subscription[] = [];

  @Input() variationType: IVariationType;

  @Output() variationSelected = new EventEmitter();

  constructor(protected _amp: AmplitudeService, protected _store: Store<IAppState>) {

  }

  public chooseVariation(variation: IVariation) {

    if (!this.isCompatible(variation)) {
      return false;
    }

    const selectedVariation: ISelectedVariation = {
      variation: variation,
      multiple: false,
      vtID: this.variationType.id,
      vtDBName: this.variationType.dbname,
      vtName: this.variationType.name,
      vtCategory: this.variationType.category
    };

    if (this.variationType.category === 'qty') {
      this._logQty(selectedVariation);
    }

    this._store.dispatch(new AddVariation(selectedVariation));
    this.variationSelected.emit();
  }

  public removeVariationType() {

    this.currentlySelected = undefined;

    const selectedVariation: ISelectedVariation = {
      multiple: false,
      vtID: this.variationType.id,
      vtDBName: this.variationType.dbname,
      vtName: this.variationType.name,
      vtCategory: this.variationType.category
    };

    this._store.dispatch(new RemoveVariation(selectedVariation));
  }

  /**
   * Check variation against all other selected incompatibilities
   * @param variation
   */
  public isCompatible(variation: IVariation): boolean {

    if (!variation) {
      return false;
    }

    // if a bundle is selected, all variations outside those in the bundle should be disabled
    if (this.bundleItem && this.bundleVariation) {
      return this.bundleVariation.id === variation.id;
    }

    if (!this.orderItem || !this.orderItem.incompatibilities) {
      return true;
    }
    return !(this.orderItem.incompatibilities.indexOf(variation.id) > -1);
  }

  /**
   * Find the incompatible selectedVariations
   * and create an error message out of it
   */
  public getIncompatibility(variation: IVariation): string {
    if (!this.orderItem || !this.orderItem.variationTypes) {
      return '';
    }
    const incompatibles = this.orderItem.variationTypes.filter(arrEl => {
      if (arrEl.variation.incompatibilities.length) {
        const inc: number[] = arrEl.variation.incompatibilities;
        return inc.indexOf(variation.id) > -1;
      }
    });

    let error = '';

    if (incompatibles.length > 0) {

      error = 'This option is incompatible with: ';

      for (let i = 0; i < incompatibles.length; i++) {
        error += ' ' + incompatibles[i].vtName + ': ' + incompatibles[i].variation.name;

        if (i + 1 < incompatibles.length) {
          error += ', ';
        }
      }
    }

    return error;
  }


  // todo use a placeholder
  public getThumbnail(variation: IVariation) {

    if (variation.images.length < 1) {
      return '';
    }

    return variation.images[0].small;
  }


  protected _logQty(selectedVariation: ISelectedVariation) {

    let qType = 'from list';
    const quantity = VariationManager.getQuantityValue(selectedVariation.variation);

    if ('customType' in selectedVariation.variation) {
      qType = 'custom';
    }

    this._amp.logEvent('Quantity', {
      type: qType,
      quantity: quantity,
      product_id: this.orderItem.product.id,
    });
  }

  ngOnDestroy() {
    this._subscriptions$.map(el => el.unsubscribe());
  }
}

