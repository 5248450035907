<diy-sidebar *ngIf="showSummary" [toggleMessage]="currentOrderItem?.price?.finalTotal | currency:'USD'">
  <div class="summary__image">
    <diy-image-loader [src]="artworkSrc"></diy-image-loader>
  </div>

  <p class="summary__title">Order Summary</p>
  <div class="summary__scroll-wrap">

    <div class="summary__scrollable">
      <div class="summary__content">
        <div class="collapsible" [ngClass]="{'collapsed': productCollapsed}">
          <h3 class="collapsible-header" (click)="toggleProduct()">
            Product
            <span class="accordion-arrow">&rsaquo;</span>
          </h3>

          <div class="collapsible-content">

            <!--Category-->
            <div *ngIf="currentOrderItem?.category">
              Category - {{currentOrderItem?.category?.name}}
              <button [routerLink]="['']" type="button" class="link-btn">Edit
              </button>
            </div>

            <!--Product-->
            <div *ngIf="currentOrderItem?.product">
              Product - {{currentOrderItem?.product?.name}}
              <button [routerLink]="['/category', currentOrderItem?.category?.id ]" (click)="toggleClass = false"
                type="button" class="link-btn">
                Edit
              </button>
            </div>

            <!--Artwork-->
            <div *ngIf="currentOrderItem?.artwork && currentOrderItem.artwork.file">
              Artwork
              <button (click)="toggleClass = false" type="button" class="link-btn"
                [routerLink]="['/category', currentOrderItem?.category?.id, 'product', currentOrderItem?.product?.id, 'artwork' ]">
                Edit
              </button>
            </div>


            <!--Variation Types (in the pricing table)-->
            <div *ngIf="currentOrderItem?.variationTypes?.length">
              <hr /> {{currentOrderItem?.product?.name}} Customization -
              <button (click)="toggleClass = false" queryParamsHandling="merge" type="button" class="link-btn"
                [routerLink]="['/category', currentOrderItem?.category?.id, 'product', currentOrderItem?.product?.id, 'customize' ]">
                Edit
              </button>

              <diy-variation-types-summary [price]="currentOrderItem.price"
                [selectedVariations]="currentOrderItem.variationTypes" [bundleItem]="currentOrderItem.bundleItem">
              </diy-variation-types-summary>
            </div>


            <!--Special Options-->
            <div *ngIf="currentOrderItem?.specialOptions?.length" class="summary__content--specialOptions">
              <hr /> {{currentOrderItem?.product?.name}} Special Options -
              <button queryParamsHandling="merge" type="button" class="link-btn"
                [routerLink]="['/category', currentOrderItem?.category?.id, 'product', currentOrderItem?.product?.id, 'specialOptions']">
                Edit
              </button>

              <diy-special-options-summary [price]="currentOrderItem.price"
                [selectedVariations]="currentOrderItem.specialOptions">
              </diy-special-options-summary>

            </div>
          </div>
        </div>
        <hr />
        <div class="collapsible" *ngIf="currentStep >= 5 && packagingOrderItem.packagingSelections.length > 0"
          [ngClass]="{'collapsed': packagingCollapsed}">

          <h3 class="collapsible-header" (click)="togglePackaging()">
            Packaging Added
            <span class="accordion-arrow">&rsaquo;</span>
          </h3>

          <div class="collapsible-content">
            <diy-packaging-summary [packagingOrderItem]="packagingOrderItem"></diy-packaging-summary>
          </div>
        </div>
        <!--Totals-->
        <diy-price-info [bundleItem]="currentOrderItem.bundleItem" [price]="currentOrderItem.price"></diy-price-info>

      </div>

      <!--Turnaround-->
      <div class="summary__turnaround-info"
        *ngIf="currentOrderItem?.turnaround && currentOrderItem?.turnaround.selectedOption">
        Your order will arrive
        <br />
        {{
            formatTatDate(
            currentOrderItem.turnaround.selectedOption,
            currentOrderItem.turnaround.skipSample
            )
            }}
      </div>

      <diy-summary-info [currentStep]="currentStep"></diy-summary-info>
    </div>
  </div>
</diy-sidebar>
