import { IVariation } from './IVariation';
import { IImage } from '../../images/IImage';

export interface IVariationType {
  id: string;
  name: string;
  info: string;
  required: boolean;
  multiple: boolean;
  category: AvailableVariationTypeTypes | PackagingVariationTypeTypes;
  dbname: string;
  typeSize: string;
  variations: IVariation[];
  image: IImage;
  dimensions?: number;
  toggled: boolean;
}

export interface IVariationTypeListItem {
  variationTypeName: string;
  variationName: string;
}
export enum VariationTypes {
  customize = 'selections_vt',
  specialOptions = 'selections_sp'
}

export enum AvailableVariationTypeTypes {
  quantity = 'qty',
  size = 'size',
  style = 'style',
}

export enum PackagingVariationTypeTypes {
  type = 'packageType',
  size = 'packageSize',
  options = 'packageOptions',
  quantity = 'packageQuantity',
}

