<div class="payment__block" [formGroup]="form">
  <div *ngIf="errorMessage" class="card-form__danger">
    <p>{{ errorMessage }}</p>
  </div>

  <p class="payment__item--title">
    Payment

    <button *ngIf="showCardForm && !paypal && customerCardData?.length" class="simple-green-btn cancel-form"
      (click)="toggleCardForm(false)">
      Cancel
    </button>
  </p>

  <p class="paypal-button-wrapper">
    <input formControlName="paypal" (change)="payWithPaypal();" type="checkbox" id="paypalCheckbox" />
    <label for="paypalCheckbox" class="paypal-button">
      Pay with &nbsp;<span class="pp-pay">Pay</span><span class="pp-pal">Pal</span>
    </label>
  </p>

  <p class="payment-separator">OR</p>
  <div>
    <div *ngIf="!showCardForm">
      <p class="payment__item--subtitle">Select your payment:</p>

      <div *ngFor="let cardData of customerCardData" class="existing-card"
        [ngClass]="{ active: selectedCard === cardData }" (click)="selectCard(cardData)">
        <div class="cc-thumb-wrap">
          <img src="{{ getCCThumb(cardData.type) }}" width="100%" />
        </div>
        <div class="cc-data">
          {{ cardData.type }} ending in {{ cardData.number }}<br />
          {{ cardData.holder }} {{ getExpirationDate(cardData.expire) }}
        </div>
      </div>

      <button class="simple-green-btn" (click)="toggleCardForm(true)">
        + Add Credit Card
      </button>

    </div>

    <div *ngIf="showCardForm" id="payment-block">
      <div class="paymentOptions">
        <p class="paymentOptions__subtitle">We accept the following:</p>
        <ul class="paymentOptions__list">
          <li>
            <img src="../../../../assets/img/payment/american.png" alt="American Express"
              class="paymentOptions__list--icon" />
          </li>
          <li>
            <img src="../../../../assets/img/payment/visa.png" alt="Visa" class="paymentOptions__list--icon" />
          </li>
          <li>
            <img src="../../../../assets/img/payment/discover.png" alt="Discover" class="paymentOptions__list--icon" />
          </li>
          <li>
            <img src="../../../../assets/img/payment/mastercard.png" alt="MasterCard"
              class="paymentOptions__list--icon" />
          </li>
          <li>
            <img src="../../../../assets/img/payment/paypal.png" alt="PayPal" class="paymentOptions__list--icon" />
          </li>
        </ul>
      </div>

      <div class="form-row">
        <input type="text" placeholder="Cardholder Name" formControlName="cardHolder"
          class="form-input card-holder-input" [ngClass]="getStateClass(form.get(['cardHolder']))" />

        <input type="text" placeholder="Card Number" formControlName="cardNumber" class="form-input"
          [ngClass]="getStateClass(form.get(['cardNumber']))" />
      </div>
      <div class="form-row">
        <label for="ccv">Card Security Code (CCV/CVV)</label>
        <input type="text" formControlName="cardCCV" id="ccv" class="form-input ccv" placeholder="CCV" minlength="3"
          maxlength="6" [ngClass]="getStateClass(form.get(['cardCCV']))" />
      </div>
      <div class="form-row">
        <label for="ced-month">Card Expiration Date</label>
        <input type="text" placeholder="MM" formControlName="cardExpireMo" id="ced-month" minlength="1" maxlength="2"
          class="form-input date" [ngClass]="getStateClass(form.get(['cardExpireMo']))" />

        <span class="form-row__separator">/</span>
        <input type="text" placeholder="YY" formControlName="cardExpireYr" id="ced-year" minlength="2" maxlength="2"
          class="form-input date" [ngClass]="getStateClass(form.get(['cardExpireYr']))" />
      </div>

      <div *ngIf="!isLoggedIn" class="checkbox-wrap">
        <input formControlName="saveCard" type="checkbox" id="saveCardCheckbox" value="false" />
        <label for="saveCardCheckbox"><span class="checkbox-icon"></span> Save payment info</label>
      </div>

      <p class="saveCardMessage">
        By selecting Save, you provide consent to save your card account that can
        be used to process purchases from this site/application
      </p>

      <button *ngIf="isLoggedIn" class="save-btn" [disabled]="!form.valid || isLoading" (click)="saveCard()">
        <span *ngIf="!isLoading; else loading">Save Card</span>
      </button>
    </div>
  </div>

  <ts-modal #paypalModal title="" class="pay-with-paypal-modal">
    <div>
      <p *ngIf="!isLoading else paypalLoading;">
        Click the button below to pay with Paypal
      </p>
      <div #paypalButton class="modal-paypal-button"></div>
    </div>
  </ts-modal>
</div>

<ng-template #loading>
  <span>Loading</span>
</ng-template>

<ng-template #paypalLoading class="pay-with-paypal-modal">
  <div>
    <p>Initiating PayPal Payment...</p>
    <div class="spinner-modal-paypal">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
  </div>
</ng-template>
