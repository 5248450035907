<!-- Header -->
<div class="header-content">
  <diy-header></diy-header>
</div>

<!-- Page content -->
<div class="container">
  <form *ngIf="!emailSent" [formGroup]="pwdForm" class="form-forgot">
    <h1 class="form-control-title">Forgot Password?</h1>
    <h2 class="form-control-subtitle">Enter your email address and we’ll send you instructions to reset your
      password.
    </h2>

    <div class="form-control-wrapper">
      <label class="form-control-label">
        <span>Email:</span><br />
        <input type="email" formControlName="email" class="form-control">
      </label>
    </div>

    <button class="form-control-btn" (click)="sendResetEmail()">
      Send instructions
    </button>
  </form>

  <div *ngIf="emailSent" class="form-forgot">
    <div class="email-sent">

      <h1>Email sent!</h1>
      <p>Password reset instructions sent to {{ sendTo }}.</p>

      <button class="form-control-btn" [routerLink]="[ '/shop/login' ]">
        Continue to Log In
      </button>

    </div>
  </div>
</div>
