import { Component, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription, BehaviorSubject } from 'rxjs';

// Services
import { ComStr } from '../../core/services/ComStr';
import { SessionService } from '../../utility/session.service';
import { AuthProvider } from '../../core/api/AuthProvider';
import { QuoteService } from '../../core/services/quote.service';

// Types
import { IUser, UserRole } from '../../types/user/IUser';
import { ICustomerInfo } from '../../types/quote/IQuote';

@Component({
  selector: 'diy-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  @Input() title = 'Login';
  @Input() continueText = 'Continue to payment';
  @Input() subtitle = '';

  // If logged in user is different emit true
  @Output() authComplete = new EventEmitter<boolean>();

  loginForm: FormGroup;
  showPassword = true;
  hasEmail = false;
  user: IUser;

  showEmailInput: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  invalidCredentialsError = false;
  invalidLoginForm = false;
  isLoading = false;

  private _subscriptions$: Subscription[] = [];

  constructor(
    private _sessionService: SessionService,
    private _formBuilder: FormBuilder,
    private _authProvider: AuthProvider,
    private _quoteService: QuoteService,
  ) {

    this.loginForm = this._formBuilder.group({
      email: ['', Validators.compose([
        Validators.pattern(ComStr.EMAIL_REGEX)
      ])],
      password: ['', Validators.compose([
        Validators.required,
        Validators.minLength(3)
      ])],
      rememberMe: [false]
    });

    const subs$ = this._loginValidation$();

    this._subscriptions$.push(subs$);

    const customerInfo: ICustomerInfo = this._quoteService.getCustomerInfo();

    if (customerInfo) {
      this.user = {
        firstName: customerInfo.firstname,
        lastName: customerInfo.lastname,
        email: customerInfo.email,
        role: UserRole.CUSTOMER
      };

      this.showEmailInput.next(false);
      this.hasEmail = true;
    }
  }

  private _loginValidation$(): Subscription {
    return this.showEmailInput.subscribe(value => {

      this.loginForm.controls['email'].clearValidators();

      if (value) {
        this.hasEmail = false;
        this.loginForm.controls['email'].setValidators([
          Validators.required,
          Validators.pattern(ComStr.EMAIL_REGEX)
        ]);
        this.loginForm.controls['email'].enable();
      } else {
        this.hasEmail = true;
        this.loginForm.controls['email'].setValidators([]);
      }

      this.loginForm.controls['email'].updateValueAndValidity();
    });
  }

  changeEmail() {
    this._quoteService.deleteCustomerInfo();
    this._quoteService.deleteQuotes();
    this.showEmailInput.next(true);
  }

  async login() {
    this.loginForm.updateValueAndValidity();
    if (this.loginForm.valid) {
      this.isLoading = true;
      this.invalidCredentialsError = false;
      this.invalidLoginForm = false;

      const form = this.loginForm.value;
      const email = (!form.email) ? this.user.email : form.email;

      const resp = await this._authProvider.login(email, form.password);

      if (!resp) {
        this.isLoading = false;
        return this.invalidCredentialsError = true;
      }

      const customer = {
        firstName: resp.customerData.firstName,
        lastName: resp.customerData.lastName,
        email: resp.customerData.email,
        role: UserRole.CUSTOMER
      } as IUser;

      this._sessionService.setSession(resp.token, customer, form.rememberMe);

      this.isLoading = false;
      window.dataLayer.push({
        'event': 'login-complete',
        'loginType': 'email', // facebook, google, or email (based on method of authentication)
        'loggedIn': 'y',
        'userId': resp.customerData.id
      });
      return this.authComplete.emit(this.user.email !== customer.email);
    }
    this.invalidLoginForm = true;
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }
}
