import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { BaseApi } from './baseApi';

import { SessionService } from '../../utility/session.service';

// Types
import { IQuoteRequest } from '../../types/api/IQuoteRequest';
import { IQuoteSearch } from '../../types/quote/IQuote';

@Injectable()
export class QuoteApi extends BaseApi {

  private routes = {
    getQuote: environment.baseUrl + 'api/diy/quote/new',
    searchQuote: environment.baseUrl + 'api/diy/quote/',
  };

  constructor(
    private _http: HttpClient,
    protected _sessionService: SessionService,
  ) {
    super(_sessionService);
  }

  async getQuote(quote: IQuoteRequest): Promise<any> {
    try {
      const res = await this._http.post(this.routes.getQuote, quote, this.httpOptions).toPromise();
      return res;
    } catch (err) {
      console.error(err);
    }
  }

  async searchQuote(quoteDetails: IQuoteSearch): Promise<any> {
    try {
      const headers = this._createAuthorizationHeader();
      let params = new HttpParams();
      /* tslint:disable:no-unused-expression */
      quoteDetails.email ? params = params.set('email', encodeURIComponent(quoteDetails.email)) : '';
      quoteDetails.quoteNumber ? params = params.set('quoteNumber', encodeURIComponent(quoteDetails.quoteNumber)) : '';
      /* tslint:enable:no-unused-expression */
      const res = await this._http.get(this.routes.searchQuote, { params: params, headers: headers }).toPromise();
      return res;
    } catch (err) {
      console.error(err);
    }
  }
}
