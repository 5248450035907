import { Injectable } from '@angular/core';

import { PriceCalculatorService } from '../../../core/services/price-calculator';

import { ICartItemOrder, ICartItem } from '../../../types/shopping-cart/ICartItem';
import { IDeliveryOption } from '../../../types/turnaround/IDeliveryOption';

@Injectable()
export class CombineShippingService {

  static ungroupCartItemOrder(cartItemOrder: ICartItemOrder): ICartItemOrder {
    let _cartItemOrder: ICartItemOrder = {
      ...cartItemOrder,
      combinedWith: undefined,
      orderItem: {
        ...cartItemOrder.orderItem,
        turnaround: { ...cartItemOrder.orderItem.turnaround, selectedOption: Object.assign({}, cartItemOrder.initialTurnaround) },
      },
    };

    const updatedPrice = PriceCalculatorService.redoPrice(
      _cartItemOrder.orderItem.product,
      _cartItemOrder.orderItem.price,
      _cartItemOrder.orderItem.variationTypes,
      _cartItemOrder.orderItem.specialOptions,
      _cartItemOrder.orderItem.turnaround,
      _cartItemOrder.orderItem.bundleItem
    );
    const orderItem = {
      ..._cartItemOrder.orderItem,
      price: updatedPrice,
    };
    _cartItemOrder = { ..._cartItemOrder, orderItem: orderItem };

    return _cartItemOrder;
  }

  static updateRemainingCombinedItems(cartItem: ICartItem): ICartItem {
    if (!cartItem.cartItemOrders || !cartItem.cartItemOrders.length) {
      return;
    }
    const _cartItem = Object.assign({}, cartItem);
    _cartItem.cartItemOrders = cartItem.cartItemOrders.map(_leftCartItemOrder => {

      _leftCartItemOrder = {
        ..._leftCartItemOrder,
        combinedWith: undefined,
        orderItem: {
          ..._leftCartItemOrder.orderItem,
          turnaround: {
            ..._leftCartItemOrder.orderItem.turnaround,
            selectedOption: Object.assign({}, _leftCartItemOrder.initialTurnaround)
          },
        },
      };

      const _updatedPrice = PriceCalculatorService.redoPrice(
        _leftCartItemOrder.orderItem.product,
        _leftCartItemOrder.orderItem.price,
        _leftCartItemOrder.orderItem.variationTypes,
        _leftCartItemOrder.orderItem.specialOptions,
        _leftCartItemOrder.orderItem.turnaround,
        _leftCartItemOrder.orderItem.bundleItem
      );
      const orderItem = {
        ..._leftCartItemOrder.orderItem,
        price: _updatedPrice
      };
      _leftCartItemOrder = { ..._leftCartItemOrder, orderItem: orderItem };

      return _leftCartItemOrder;
    });

    // combine remaining items
    if (_cartItem.cartItemOrders.length > 1) {
      _cartItem.cartItemOrders = this.combineCartItems(_cartItem.cartItemOrders);
    }
    return _cartItem;
  }

  static combineCartItems(cartItemOrders: ICartItemOrder[]): ICartItemOrder[] {
    cartItemOrders = cartItemOrders.map(cartItemOrder => {
      return {
        ...cartItemOrder,
        orderItem: {
          ...cartItemOrder.orderItem,
          turnaround: { ...cartItemOrder.orderItem.turnaround, selectedOption: Object.assign({}, cartItemOrder.initialTurnaround) }
        }
      };
    });

    const maxTurnaroundOrderItem = this.getMaxTurnaround(cartItemOrders);

    if (!maxTurnaroundOrderItem) {
      return;
    }

    const combinedCartItemOrders = cartItemOrders.filter(cartItemOrder => cartItemOrder.uid !== maxTurnaroundOrderItem.uid)
      .map(orderItem => {
        return this._updateCombinedItem(orderItem, maxTurnaroundOrderItem);
      });

    const maxTurnaroundIndex = cartItemOrders.findIndex(cartItemOrder => cartItemOrder.uid === maxTurnaroundOrderItem.uid);
    combinedCartItemOrders.splice(maxTurnaroundIndex, 0, maxTurnaroundOrderItem);

    return combinedCartItemOrders;
  }

  static getMaxTurnaround(cartItemOrders: ICartItemOrder[]): ICartItemOrder {
    let cartItemOrder: ICartItemOrder;
    let maxDate: Date = new Date();

    cartItemOrders.map(_orderItem => {
      let date;
      if (_orderItem.orderItem.turnaround.selectedOption.isGuaranteed) {
        date = _orderItem.orderItem.turnaround.selectedOption.guaranteedDate;
      } else if (_orderItem.orderItem.turnaround.skipSample) {
        date = _orderItem.orderItem.turnaround.selectedOption.datesInterval.ssEnd;
      } else {
        date = _orderItem.orderItem.turnaround.selectedOption.datesInterval.normalEnd;
      }
      date = new Date(date);
      if (maxDate.getTime() < date.getTime()) {
        maxDate = date;
        cartItemOrder = _orderItem;
      }
    });

    return cartItemOrder;
  }

  private static _updateCombinedItem(cartItemOrder: ICartItemOrder, maxTurnaroundOrderItem: ICartItemOrder): ICartItemOrder {
    const maxTurnaround: IDeliveryOption = maxTurnaroundOrderItem.orderItem.turnaround.selectedOption;
    const turnaroundName = cartItemOrder.orderItem.turnaround.selectedOption.name;
    cartItemOrder.combinedWith = maxTurnaroundOrderItem.uid;

    const maxTurnaroundEndDate = this._getTurnaroundEndDate(maxTurnaround, maxTurnaroundOrderItem.orderItem.turnaround.skipSample);

    if (turnaroundName !== 'standard') {
      const standardTurnaround: IDeliveryOption = cartItemOrder.orderItem.turnaround.availableOptions.filter(
        turnaround => turnaround.name === 'standard'
      )[0];

      const rushTurnaround: IDeliveryOption = cartItemOrder.orderItem.turnaround.availableOptions.filter(
        turnaround => turnaround.name === 'rush'
      )[0];

      if (standardTurnaround
        && (this._getTurnaroundEndDate(standardTurnaround, cartItemOrder.orderItem.turnaround.skipSample) <= maxTurnaroundEndDate)
      ) {
        cartItemOrder.orderItem.turnaround.selectedOption = Object.assign({}, standardTurnaround);
      } else if (rushTurnaround
        && (this._getTurnaroundEndDate(rushTurnaround, cartItemOrder.orderItem.turnaround.skipSample) <= maxTurnaroundEndDate)
      ) {
        cartItemOrder.orderItem.turnaround.selectedOption = Object.assign({}, rushTurnaround);
      }

    }

    cartItemOrder.orderItem.turnaround.selectedOption.datesInterval = Object.assign({}, maxTurnaround.datesInterval);
    cartItemOrder.orderItem.turnaround.selectedOption.isGuaranteed = maxTurnaround.isGuaranteed;
    cartItemOrder.orderItem.turnaround.selectedOption.guaranteedDate = maxTurnaround.guaranteedDate;

    const updatedPrice = PriceCalculatorService.redoPrice(
      cartItemOrder.orderItem.product,
      cartItemOrder.orderItem.price,
      cartItemOrder.orderItem.variationTypes,
      cartItemOrder.orderItem.specialOptions,
      cartItemOrder.orderItem.turnaround,
      cartItemOrder.orderItem.bundleItem
    );


    cartItemOrder = { ...cartItemOrder, orderItem: { ...cartItemOrder.orderItem, price: updatedPrice } };

    return cartItemOrder;
  }

  private static _getTurnaroundEndDate(deliveryOption: IDeliveryOption, skipSample = false): Date {
    if (skipSample) {
      return new Date(deliveryOption.datesInterval.ssEnd);
    }

    return new Date(deliveryOption.datesInterval.normalEnd);
  }

}
