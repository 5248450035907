import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { format } from 'date-fns';

import { environment } from '../../../environments/environment';

// Services
import { QuoteApi } from '../api/quoteApi';
import { CookieService } from './cookie.service';

// Actions
import { UpdateQuoteId } from '../../state-manager/order-item/order-item.actions';
import { RemoveQuoteIds } from '../../state-manager/shopping-cart/shopping-cart.actions';

// Types
import { IQuote, ICustomerInfo, IQuoteSearch } from '../../types/quote/IQuote';
import { IAppState } from '../../types/app-state/IAppState';
import { IQuoteRequest } from '../../types/api/IQuoteRequest';
import { SessionService } from '../../utility/session.service';
import {IUser, UserRole} from '../../types/user/IUser';

const QUOTES = 'quotes';
const CUSTOMER_INFO = 'customerInfo';
const USER = 'diy_user';

declare var window: any;
declare var browser: any;

/**
 * @author Liviu Dima
 */
@Injectable()
export class QuoteService {

  constructor(
    private _cookieService: CookieService,
    private _sessionService: SessionService,
    private _quoteApi: QuoteApi,
    private _store: Store<IAppState>,
  ) { }

  /**
   * Create new quote and update quotes array in cookies
   * @param quoteBase // quote base which we'll send to CRM to get quoteId
   */
  async createQuote(customerInfo: ICustomerInfo, productId: number): Promise<IQuote> {

    const _campaignTracking = this._cookieService.get('campaignTracking');
    const _optimizelyTracking = this._cookieService.get('optimizely_thestudio');
    const _firstInquiry = this._cookieService.get('firstInquiry');

    let quoteRequest: IQuoteRequest = {
      productId: productId,
      firstname: customerInfo.firstname,
      lastname: customerInfo.lastname,
      email: customerInfo.email,
      phone: customerInfo.phone,
      campaignTracking: _campaignTracking,
      optimizelyTracking: _optimizelyTracking,
      firstInquiry: _firstInquiry,
      salesRepId: customerInfo.salesRepId,
      quoteUrl: window.location.href,
      localTime: this._getLocalTime()
    };

    quoteRequest = this.addLogData(quoteRequest);

    const response = await this._quoteApi.getQuote(quoteRequest);

    if (response.success) {
      return this.saveQuote(response, productId, customerInfo);
    }
  }

  // this should be the only one used from now on (unless we need the welcome component back)
  async createSegmentationQuote(customerInfo: ICustomerInfo, productId: number, quantity: number, segmentation: boolean = false, reasonForPurchase: string):
    Promise<{ quote: IQuote, showThankYou: boolean }> {

    const _campaignTracking = this._cookieService.get('campaignTracking');
    const _optimizelyTracking = this._cookieService.get('optimizely_thestudio');
    const _firstInquiry = this._cookieService.get('firstInquiry');

    let quoteRequest: IQuoteRequest = {
      productId: productId,
      firstname: customerInfo.firstname,
      lastname: customerInfo.lastname,
      email: customerInfo.email,
      phone: customerInfo.phone,
      campaignTracking: _campaignTracking,
      optimizelyTracking: _optimizelyTracking,
      firstInquiry: _firstInquiry,
      salesRepId: customerInfo.salesRepId,
      quoteUrl: window.location.href,
      quantity: quantity,
      reasonForPurchase: reasonForPurchase,
      localTime: this._getLocalTime(),
    };

    if (segmentation) {
      quoteRequest.ref = 'segmentation';
    }
    quoteRequest = this.addLogData(quoteRequest);

    const response = await this._quoteApi.getQuote(quoteRequest);

    if (response.success) {
      return {
        quote: this.saveQuote(response, productId, customerInfo),
        showThankYou: response.showThankYou
      };
    }
  }

  saveQuote(response, productId: number, customerInfo: ICustomerInfo): IQuote {
    const newQuote: IQuote = {
      current: true,
      productId: productId,
      quoteId: Number(response.quoteId),
      customerId: response.customerId,
      existingDraft: response.existingDraft,
      customerType: response.customerType,
      leadType: response.leadType,
    };

    // Trigger event when quote is create by declaring a global variable
    window.thestudioData = window.thestudioData ? window.thestudioData : {};
    window.thestudioData.isLead = true;

    const quoteInfo = {
      quoteId: Number(newQuote.quoteId),
      customerEmail: customerInfo.email
    };
    this._store.dispatch(new UpdateQuoteId(quoteInfo));

    let quotes: IQuote[] = this.getQuotes();

    const foundQuote = quotes.find(quote => quote.quoteId === newQuote.quoteId);
    if (foundQuote) {
      return foundQuote;
    }

    quotes = quotes.map(quote => Object.assign(quote, { current: false }));
    quotes.unshift(newQuote);

    if(!this._sessionService.user){
      const userData = {
        firstName: customerInfo.firstname,
        lastName: customerInfo.lastname,
        email: customerInfo.email,
        role: UserRole.CUSTOMER
      } as IUser;
      this._sessionService.setItem(USER, JSON.stringify(userData));
    }
    this._cookieService.setJson(QUOTES, quotes, 14);
    this._cookieService.setJson(CUSTOMER_INFO, customerInfo, 14);

    return newQuote;
  }

  /**
   * Returns current quote
   */
  getCurrentQuote(): IQuote {
    const quotes: IQuote[] = this._cookieService.getJson(QUOTES);

    // if there are no quotes returns void
    if (!quotes || !quotes.length) {
      return;
    }

    return quotes.find(quote => quote.current);
  }

  /**
   * Returns quote by id
   */
  getQuoteById(id: number): IQuote {
    const quotes: IQuote[] = this._cookieService.getJson(QUOTES);

    // if there are no quotes returns void
    if (!quotes || !quotes.length) {
      return;
    }

    return quotes.find(quote => quote.quoteId === id);
  }

  /**
   * Return all quotes from cookies
   */
  getQuotes(): IQuote[] {
    const quotes = this._cookieService.getJson(QUOTES);

    return quotes && quotes.length ? quotes : [];
  }

  /**
   * Get customer info for saved quote(s)
   */
  getCustomerInfo(): ICustomerInfo {
    const customerInfo = this._cookieService.getJson(CUSTOMER_INFO);

    return customerInfo ? customerInfo : undefined;
  }

  deleteCustomerInfo() {
    this._cookieService.delete(CUSTOMER_INFO, '/', environment.cookieDomainName);
  }

  async findQuote(email: string, quoteNumber: string): Promise<string> {
    try {

      const quoteQuery: IQuoteSearch = {
        email: email,
        quoteNumber: quoteNumber
      };

      const response = await this._quoteApi.searchQuote(quoteQuery);

      if (response && response.success) {
        const newQuote: IQuote = {
          current: true,
          productId: response.productId,
          quoteId: Number(response.quoteId),
          customerId: response.customerId,
          existingDraft: response.existingDraft,
          customerType: response.customerType,
        };

        const quoteInfo = {
          quoteId: Number(newQuote.quoteId),
          customerEmail: email
        };
        this._store.dispatch(new UpdateQuoteId(quoteInfo));

        let quotes: IQuote[] = this.getQuotes();

        const foundQuote = quotes.find(quote => quote.quoteId === newQuote.quoteId);
        if (foundQuote) {
          return '';
        }

        quotes = quotes.map(quote => Object.assign(quote, { current: false }));
        quotes.unshift(newQuote);

        this._cookieService.setJson(QUOTES, quotes, 14);

        const customerInfo = {
          firstname: response.firstname,
          lastname: response.lastname,
          email: response.email,
          phone: response.phone,
        };
        this._cookieService.setJson(CUSTOMER_INFO, customerInfo, 14);

        return '';
      }

      return (response && response.message) ? response.message : 'An error has occurred';

    } catch (error) {
      return error;
    }
  }

  deleteQuotes() {
    this._store.dispatch(new RemoveQuoteIds());
    this._cookieService.delete(QUOTES);
  }

  private _getLocalTime(): string {
    return format(new Date(), 'YYYY-MM-DD H:mm:ss');
  }

  addLogData(quoteRequest: IQuoteRequest) {
    let logData = '';

    try {
      const loggedUser = this._sessionService.user;
      const cookieQuotes = this.getQuotes();
      const cookieCustomerInfo = this.getCustomerInfo();


      logData = JSON.stringify({
        loggedUser: loggedUser,
        cookieCustomerInfo: cookieCustomerInfo,
        cookieQuotes: cookieQuotes,
      });
    } catch (err) {
      logData += JSON.stringify(err);
    }

    quoteRequest.logData = logData;

    return quoteRequest;
  }
}
