<div class="qty-banner">
  UP TO {{orderItem.product.productDiscount }}% OFF • LIMITED TIME ONLY
</div>

<div class="variation">

  <div class="variation__title">
    <p>Select {{variationType.name}}*</p>
  </div>

  <ul>
    <ng-container *ngFor="let variation of variationType.variations">
      <li *ngIf="variation.showInDIY" (click)="selectQuantity(variation)">
        <p><small [hidden]="!variation.popular">Most Popular</small></p>
        <label for="quantity-{{variation.name}}" class="toggleFullPrice">
          {{variation.name}}
          <span class="price-strike">
            {{getVariationPrice(variation, false) | currency}}
          </span>
          <span>
            {{getVariationPrice(variation) | currency}}
          </span>
        </label>
        <label for="quantity-{{variation.name}}" class="toggleUnitPrice">
          {{variation.name}}
          <span class="price-strike">
            {{getVariationPricePerUnit(variation, false) | currency}}
          </span>
          <span>
            {{getVariationPricePerUnit(variation) | currency}} each
          </span>
        </label>
        <input type="radio" [checked]="isChecked(variation)" (change)="selectQuantity(variation)"
          [disabled]="!isCompatible(variation)" name="{{variationType.dbname}}" id="quantity-{{variation.name}}" />
        <span>&nbsp;</span>
      </li>
    </ng-container>
  </ul>

  <p class="variation__alert">Oops! You forgot to select quantity. This step is required.</p>


  <div class="variation__item">
    <label>
      <input type="radio" placeholder="EnterUnits" [checked]="customQtyEnabled | async"
        (change)="selectCustomQuantity()" name="{{variationType.dbname}}" [disabled]="remaining.length > 0" />

      <span *ngIf="!remaining.length; else showRemaining">Custom Quantity</span>
      <ng-template #showRemaining>
        <span>
          You must first select
          <ng-container *ngFor="let vtRemaining of remaining">{{vtRemaining}}, </ng-container>
          in order to set a custom quantity
        </span>
      </ng-template>
    </label>

    <div class="variation__custom--quantity" [formGroup]="customQtyGroup">
      <input id="customQty" type="text" formControlName="qty" (focus)="selectCustomQuantity()"
        (input)="selectCustomQuantity()" />
      <label for="customQty">Units</label>
      <span class="price-display toggleFullPrice" *ngIf="getCustomQuantityPrice() > 0">
        <span class="price-strike">{{getCustomQuantityPrice(false) | currency}}</span>
        <span>{{getCustomQuantityPrice() | currency}}</span>
      </span>
      <span class="price-display toggleUnitPrice" *ngIf="getCustomQuantityPrice() > 0">
        <span class="price-strike">{{getCustomQuantityPerUnit(false) | currency}}</span>
        <span>{{getCustomQuantityPerUnit() | currency}} each</span>
      </span>
    </div>
  </div>

  <div [hidden]="!showCustomSizeError()" class="danger">
    Minimum order quantity is {{ minQty }} and maximum order quantity is {{ maxQty }}.
    <br />
    For order quantity over {{ maxQty }}, please reach out to us at <a
      href="mailto:expert@thestudio.com">expert@thestudio.com</a>. Thank you!
  </div>

  <div class="overlay" *ngIf="popUpState">
    <div class="overlay__container">
      <div class="overlay__title">Large Orders?</div>
      <div class="overlay__paragraph">For inquiries over 10,000 units, e-mail us at
        <br><span>assistance@thestudio.com</span></div>
      <div class="overlay__button"><a href="mailto:assistance@thestudio.com">Email Us</a></div>
    </div>
  </div>

</div>
