import { ElementRef, HostListener, Input, ViewChild, OnInit, Directive } from '@angular/core';

import { PriceCalculatorService } from '../../../core/services/price-calculator';
import { VariationManager } from '../../../core/services/variation-manager.service';

import { IVariationType } from '../../../types/product/variations/IVariationType';
import { IVariation } from '../../../types/product/variations/IVariation';
import { IOrderItem } from '../../../types/app-state/IOrderItem';

import { environment } from '../../../../environments/environment';

@Directive()
export abstract class OptionBase implements OnInit {

  readonly DISPLAY_COUNT_BP: number = 540;

  expanded = false;
  hasSelection = false;
  displayCount: number;

  @Input() showError = false;
  @Input() orderItem: IOrderItem;
  @Input() specialOption: IVariationType;
  @Input() tabIndex: number;
  @Input() collapseStatus;

  @ViewChild('optionAnchor', { static: true }) optionEl: ElementRef;


  constructor() {
    this.displayCount = (window.screen.width < this.DISPLAY_COUNT_BP) ? 4 : 3;
  }

  ngOnInit() {
    this.collapseStatus = this.specialOption.required ? false : this.specialOption.toggled;
  }

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.displayCount = (event.target.innerWidth < this.DISPLAY_COUNT_BP) ? 4 : 3;
  }


  getVariations() {
    return this.expanded ? this.specialOption.variations : this.specialOption.variations.slice(0, this.displayCount);
  }

  getSpecialOptionPrice(variation: IVariation) {
    return PriceCalculatorService.getVariationPrice(variation,
      this.orderItem.price.quantity,
      this.orderItem.price.pricePerUnit
    );
  }

  expandOption() {

    this.expanded = !this.expanded;

    if (!this.expanded) {
      this.optionEl.nativeElement.scrollIntoView({ behavior: 'instant', block: 'start' });
    }
  }

  /**
   * Check variation against all other selected incompatibilities
   */
  isCompatible(variation: IVariation): boolean {
    const orderItemQty = VariationManager.getQuantityFromStack(this.orderItem.variationTypes);
    return !~this.orderItem.incompatibilities.indexOf(variation.id) && orderItemQty >= variation.moq;
  }

  isChecked(variation: IVariation): boolean {
    if (!this.orderItem.specialOptions) {
      return false;
    }

    const match = this.orderItem.specialOptions.find(sel => sel.variation.id === variation.id);
    return !!(match);
  }

  getThumbnail(variation: IVariation) {
    if (variation.images.length < 1) {
      return environment.placeholderImageURL;
    }

    return variation.images[0].small;
  }

  collapseOption() {
    if (this.specialOption.required) {
      return;
    }
    this.collapseStatus = !this.collapseStatus;
  }
}
