import { Component, OnDestroy, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { ProductFlowApi } from '../../../core/api/productFlowApi';

import { TrackingService } from '../../../core/services/tracking';

@Component({
  selector: 'diy-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})
export class SurveyComponent implements OnInit, OnDestroy {

  surveryForm: FormGroup;
  showCompany = false;
  showCustomReason = false;
  protected _subscriptions$: Subscription[] = [];
  loading = false;

  @Input() invoiceId: string;

  constructor(
    private _tracking: TrackingService,
    fb: FormBuilder,
    private _api: ProductFlowApi,
  ) {
    this.surveryForm = fb.group({
      type: ['', Validators.required],
      reason: ['', Validators.required],
      company: ['', Validators.required],
      customReason: [''],
      frequency: ['', Validators.required],
    });

    let sub$ = this.surveryForm.controls['reason'].valueChanges
      .subscribe(value => this._toggleCustomReasonValidation(value));
    this._subscriptions$.push(sub$);

    sub$ = this.surveryForm.controls['type'].valueChanges
      .subscribe(value => this._toggleCompanyValidation(value));

    this._subscriptions$.push(sub$);


    sub$ = this.surveryForm.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(() => this._sendSurvey());

    this._subscriptions$.push(sub$);
  }

  async ngOnInit() {
    this._tracking.sendAltoCloudData(['pageview', {
      location: location.pathname,
      title: 'Survey'
    }]);
  }

  private _sendSurvey() {
    const formData = this.surveryForm.value;
    formData.invoiceId = this.invoiceId;
    this._api.sendSurvey(this.surveryForm.value);
  }

  private _toggleCompanyValidation(value: string) {
    const company = this.surveryForm.controls['company'];
    if (value === 'business') {
      company.setValidators([
        Validators.required
      ]);
      this.showCompany = true;
    } else {
      company.clearValidators();
      this.showCompany = false;
    }
    company.updateValueAndValidity();
  }

  private _toggleCustomReasonValidation(value: string) {
    const customReason = this.surveryForm.controls['customReason'];
    if (value === 'custom') {
      customReason.setValidators([
        Validators.required
      ]);
      this.showCustomReason = true;
    } else {
      customReason.clearValidators();
      this.showCustomReason = false;
    }
    customReason.updateValueAndValidity();
  }

  ngOnDestroy() {
    this._subscriptions$.map(el => el.unsubscribe());
  }
}
