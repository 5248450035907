import { HttpHeaders } from '@angular/common/http';

import { SessionService } from '../../utility/session.service';

export class BaseApi {

  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(
    protected _sessionService: SessionService,
  ) { }

  protected _createAuthorizationHeader(): HttpHeaders {
    const headers = new HttpHeaders();
    return headers.append('Authorization', `Bearer ${this._sessionService.token}`);
  }

}
