import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

// Utils
import { DateUtils } from '../../../utility/DateUtils';
import { Utils } from '../../../core/services/utils';

// Service
import { PriceCalculatorService } from '../../../core/services/price-calculator';
import { AmplitudeService } from '../../../core/services/amplitude';

// Types
import { IPrice } from '../../../types/product/IPrice';
import { IDeliveryOption } from '../../../types/turnaround/IDeliveryOption';
import { IAppState } from '../../../types/app-state/IAppState';
import { IOrderItem } from '../../../types/app-state/IOrderItem';

const MINIMUM_GUARANTEE_VALUE = 300;

@Component({
  selector: 'diy-delivery-option',
  templateUrl: './delivery-option.component.html',
  styleUrls: ['./delivery-option.component.scss']
})
export class DeliveryOptionComponent implements OnInit {

  @Input() isSelected: boolean;
  @Input() deliveryOption: IDeliveryOption;

  @Input() skipSample: boolean;

  @Input() price: IPrice;

  @Output() deliveryOptionChange = new EventEmitter();

  isUnavailableGuarantee = false;

  private _orderItem: Observable<IOrderItem> = this._store.select('orderItem');

  constructor(
    private _amp: AmplitudeService,
    private _store: Store<IAppState>,
  ) { }

  getValue() {
    const value = (!this.deliveryOption || !this.deliveryOption.guaranteedDate) ?
      '' :
      DateUtils.tatGuaranteedDateFormat(new Date(this.deliveryOption.guaranteedDate));

    return value;
  }

  async chooseDeliveryOption() {
    const orderItem = await this._orderItem.pipe(take(1)).toPromise();

    this._amp.logEvent('Turnaround Selection', {
      turnaround: this.deliveryOption.name,
      product_id: orderItem.product.id,
    });

    this.deliveryOptionChange.emit(this.deliveryOption);
  }

  async guaranteedCheckBoxToggle() {
    this.deliveryOption.isGuaranteed = !this.deliveryOption.isGuaranteed;
    this.deliveryOption.guaranteedDate = this.deliveryOption.isGuaranteed ? this.deliveryOption.guaranteedDate : null;

    const orderItem = await this._orderItem.pipe(take(1)).toPromise();

    this._amp.logEvent('Turnaround Guaranteed', {
      turnaround: this.deliveryOption.name,
      checked: this.deliveryOption.isGuaranteed,
      product_id: orderItem.product.id,
    });

    this.deliveryOptionChange.emit(this.deliveryOption);
  }

  async guaranteedDateChange(newDate: Date) {

    if (!this.isValidDate(newDate)) {
      this.deliveryOption.guaranteedDate = null;
      this.deliveryOption.isGuaranteed = false;
      return;
    }

    const orderItem = await this._orderItem.pipe(take(1)).toPromise();

    this._amp.logEvent('Turnaround Guaranteed', {
      turnaround: this.deliveryOption.name,
      checked: this.deliveryOption.isGuaranteed,
      product_id: orderItem.product.id,
    });
    this.deliveryOption.isGuaranteed = true;
    this.deliveryOption.guaranteedDate = newDate.getTime();
    this.deliveryOptionChange.emit(this.deliveryOption);

    // recheck final total because skip sample might not be applicable (over n where n env.skipSampleMinimum)
    if (this.skipSample) {
      if (!this.isValidDate(newDate)) {

        this.deliveryOption.guaranteedDate = null;
        this.deliveryOptionChange.emit(this.deliveryOption);
      }
    }
  }

  isValidDate(date: Date): boolean {
    return (this.getMinDate().getTime() <= date.getTime() && date.getTime() <= this.getMaxDate().getTime());
  }

  getMinDate() {
    if (this.skipSample) {
      return new Date(this.deliveryOption.datesInterval.ssStart);
    } else {
      return new Date(this.deliveryOption.datesInterval.normalStart);
    }
  }

  getMaxDate() {
    if (this.skipSample) {
      return new Date(this.deliveryOption.datesInterval.ssEnd);
    } else {
      return new Date(this.deliveryOption.datesInterval.normalEnd);
    }
  }

  estimateInterval(): string {
    return DateUtils.estimateInterval(this.deliveryOption, this.skipSample);
  }

  formatDeliveryCost(): string {

    const subTotal = (this.price.quantity * this.price.pricePerUnit) + this.price.specialOptionsTotal;

    const deliveryCost = PriceCalculatorService.getShippingOptionCost(this.deliveryOption.name, subTotal);

    const deliveryString = (deliveryCost > 0) ? '$' + Utils.round(deliveryCost) : 'Free';

    if (this.deliveryOption.isGuaranteed) {
      const guaranteedCost = PriceCalculatorService.getGuaranteedFee(subTotal, deliveryCost);
      return deliveryString + ' + $' + Utils.round(guaranteedCost);
    }

    return deliveryString;
  }

  /**
   * Lifecyle hooks
   */
  ngOnInit() {
    this.isUnavailableGuarantee = this.price.finalTotal < MINIMUM_GUARANTEE_VALUE;
  }

}
