import {Component,  ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { PaymentItemBaseComponent } from '../payment-item-base.component';

import { CustomerDataProvider } from '../../../core/api/CustomerDataProvider';

import { ComStr } from '../../../../core/services/ComStr';
import { SessionService } from '../../../../utility/session.service';
import { getExpirationDate, getCCThumb } from '../../../../utility/card.utils';

import { ICustomerCardData } from '../../../../types/api/ICustomerPaymentData';

import {TSModalComponent} from 'tscommon';

// const for min year value validation
const CURRENT_YEAR = Number(String((new Date()).getFullYear()).substr(-2));

/**
 * @author Liviu Dima
 */
@Component({
  selector: 'diy-payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.scss']
})
export class PaymentFormComponent extends PaymentItemBaseComponent implements OnChanges {
  form: FormGroup;

  @Input() customerCardData: ICustomerCardData[];

  @ViewChild('paypalButton', {static: true}) paypalButton: ElementRef;

  @ViewChild('paypalModal', { static: true }) paypalModal: TSModalComponent;

  selectedCard: ICustomerCardData;

  showCardForm = true;

  isLoggedIn = false;

  paypal = false;

  // @Output() triggerValidation$ = new EventEmitter<boolean>();
  @Output() submitForm$: EventEmitter<any> = new EventEmitter();

  // card data validators definition
  private _cardDataValidators = {
    cardHolder: Validators.required,
    cardNumber: Validators.compose([
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(16),
      Validators.pattern(ComStr.NUMBER_ONLY_REGEX)
    ]),
    cardCCV: Validators.compose([
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(6),
      Validators.pattern(ComStr.NUMBER_ONLY_REGEX)
    ]),
    cardExpireMo: Validators.compose([
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(2),
      Validators.min(1),
      Validators.max(12),
      Validators.pattern(ComStr.NUMBER_ONLY_REGEX)
    ]),
    cardExpireYr: Validators.compose([
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(2),
      Validators.min(CURRENT_YEAR),
      Validators.pattern(ComStr.NUMBER_ONLY_REGEX)
    ])
  };

  /**
   * Class constructor
   * @param _formBuilder
   * @param el
   */
  constructor(
    private _formBuilder: FormBuilder,
    protected el: ElementRef,
    private _customerApi: CustomerDataProvider,
    private _sessionService: SessionService
  ) {
    super(el);

    const currentYear = Number(String((new Date()).getFullYear()).substr(-2));

    this.form = this._formBuilder.group({
      cardHolder: ['', Validators.required],
      cardNumber: ['', Validators.compose([
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(16),
        Validators.pattern(ComStr.NUMBER_ONLY_REGEX)
      ])],
      cardCCV: ['', Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(6),
        Validators.pattern(ComStr.NUMBER_ONLY_REGEX)
      ])],
      cardExpireMo: ['', Validators.compose([
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(2),
        Validators.min(1),
        Validators.max(12),
        Validators.pattern(ComStr.NUMBER_ONLY_REGEX)
      ])],
      cardExpireYr: ['', Validators.compose([
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(2),
        Validators.min(currentYear),
        Validators.pattern(ComStr.NUMBER_ONLY_REGEX)
      ])],
      id: [''],
      saveCard: [''],
      paypal: [false],
    });

    this.isLoggedIn = this._sessionService.isLoggedIn();
  }

  selectCard(card: ICustomerCardData) {
    // selection logic
    this.selectedCard = card;
    this.form.controls.id.patchValue(card.id);
    this._resetValidators();
    this.errorMessage = '';

    // reset paypal
    this.paypal = false;
    this.form.get('paypal').setValue(false);
  }

  toggleCardForm(flag: boolean) {
    this.showCardForm = flag;

    this._resetValidators();

    if (this.showCardForm) {
      this.selectedCard = undefined;
      this.form.controls.id.reset();
      return this._updateValidators(this.form.controls, this._cardDataValidators);
    } else {
      this._resetCardForm();
    }

    return this.form.controls.id.setValidators(Validators.required);
  }

  updatePaymentMethod() {
    this.paypal = !this.paypal;
    this.selectedCard = undefined;
    this.form.controls.id.reset();
    if (this.paypal) {
      this._resetValidators();
    } else {
      this._updateValidators(this.form.controls, this._cardDataValidators);
    }
  }

  async saveCard() {
    if (!this.form.valid) {
      // iterate through inputs and mark them as touched for validation
      Object.keys(this.form.controls).forEach(field => {
        const control = this.form.get(field);
        control.markAsTouched({ onlySelf: true });
      });
      return;
    }
    this.isLoading = true;

    const cardData = this.form.value;
    const resp = await this._customerApi.saveCard(cardData);

    if (resp && resp.id) {
      // update the card array
      this.customerCardData.push(resp);

      // hide the form
      this.toggleCardForm(false);

      // select the latest added card
      this.selectCard(resp);

      this.errorMessage = undefined;
      this.isLoading = false;
    } else {
      if (resp.message) {
        this.errorMessage = resp.message;
      }
      this.isLoading = false;
    }
  }


  /**
   * Reset validators
   */
  private _resetValidators() {

    const controls = this.form.controls;

    for (const key in controls) {
      if (controls.hasOwnProperty(key)) {
        controls[key].setValidators([]);
      }
    }
  }

  /**
   * Set validators
   * @param controls
   * @param validators
   */
  private _updateValidators(controls: Object, validators: Object) {
    for (const key in controls) {
      if (controls.hasOwnProperty(key)) {
        controls[key].setValidators(validators[key]);
      }
    }
  }

  /**
   * Reset the form values
   */
  private _resetCardForm() {
    const controls = this.form.controls;

    for (const key in controls) {
      if (controls.hasOwnProperty(key)) {
        controls[key].reset();
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['customerCardData'] && this.customerCardData) {
      this.toggleCardForm(false);
    }
  }

  getExpirationDate(date: string): string {
    return getExpirationDate(date);
  }

  getCCThumb(type: string) {
    return getCCThumb(type);
  }

  showPaypalModal() {
    this.paypalModal.show();
  }

  payWithPaypal() {
    this.paypal = false;
    this.form.get('paypal').setValue(true);
    this.updatePaymentMethod();
    this.submitForm$.emit();
  }
}
