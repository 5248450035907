<div class="vt-summary">
  <div *ngFor="let vt of selectedVariations">

    <span *ngIf="vt.vtName !== 'Quantity' && vt.variation.name !== 'Custom Size'">
      {{vt.variation.name}}
    </span>

    <span *ngIf="vt.variation.name === 'Custom Size'">
      Custom Size {{vt.variation.width}} x {{vt.variation.height}}
    </span>
  </div>
  <div class="unit-price">
    <span>{{price.quantity}} QTY x {{price.pricePerUnit | currency: 'USD'}}</span>
    <span>{{price.quantity * price.pricePerUnit | currency: 'USD'}}</span>
  </div>
</div>
