<!-- Header -->
<div class="header-content">
  <diy-header></diy-header>
  <diy-order-details></diy-order-details>
  <diy-promo-banner></diy-promo-banner>
  <!-- we have the ngIf as we are setting currentStep after resolving route -->
  <diy-order-step-tracking *ngIf="currentStep" [currentStep]="currentStep">
  </diy-order-step-tracking>
</div>

<!-- Page content -->
<div class="container home-container">
  <div class="inner-wrapper heading-wrapper">
    <div class="heading-primary">
      <h1>Step 1: Select a Product</h1>
      <p>Select a product and begin customizing.</p>
    </div>
    <div class="search-primary">
      <diy-search></diy-search>
    </div>
  </div>

  <h1>
    {{category?.name}}
    <a [routerLink]="['']"> &lt; Go Back</a>
  </h1>

  <ng-container *ngFor="let product of category?.products">
    <diy-product [product]="product" [category]="category"></diy-product>
  </ng-container>
</div>
