import { Injectable, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { environment } from '../../../environments/environment';

// Services
import { DraftApi } from '../api/draftApi';
import { CookieService } from './cookie.service';
import { QuoteService } from './quote.service';
import { SessionService } from '../../utility/session.service';
import { NavigatorService } from './navigator';
import { VariationManager } from './variation-manager.service';


// Actions
import { AddArtwork } from '../../state-manager/order-item/order-item.actions';

// Types
import { IDraft, DraftSource } from '../../types/draft/IDraft';
import { IAppState } from '../../types/app-state/IAppState';
import { IOrderItem } from '../../types/app-state/IOrderItem';
import { ICustomerInfo } from '../../types/quote/IQuote';
import { IShoppingCart } from '../../types/shopping-cart/IShoppingCart';
import { IDraftRequest, DraftRequestType } from '../../types/api/IDraftRequest';
import { IArtwork, IArtworkCookie } from '../../types/artwork/IArtwork';

const CUSTOMIZE_PAGE = 'customize';
const SPECIAL_OPTIONS_PAGE = 'specialOptions';

/**
 * @author Liviu Dima
 */
@Injectable()
export class DraftService implements OnDestroy {

  orderItem: IOrderItem;

  shoppingCart: IShoppingCart;

  private _subscriptions$: Subscription[] = [];
  private _navigationId;

  constructor(
    private _draftApi: DraftApi,
    private _cookieService: CookieService,
    private _store: Store<IAppState>,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _navigator: NavigatorService,
    private _quoteService: QuoteService,
    private _sessionService: SessionService,
  ) {

    let subscription$ = this._store.pipe(select('orderItem')).subscribe((data: IOrderItem) => {
      this.orderItem = { ...data };
      const orderProductId = this.orderItem.product && this.orderItem.product.id ? this.orderItem.product.id : undefined;
      if (orderProductId && (!this.orderItem.artwork || !this.orderItem.artwork.files || !this.orderItem.artwork.files.length)) {
        this.loadArtworkFromCookie(Number(orderProductId));
      }
    });
    this._subscriptions$.push(subscription$);

    subscription$ = this._store.pipe(select('shoppingCart')).subscribe((cart: IShoppingCart) => {
      this.shoppingCart = { ...cart };
    });
    this._subscriptions$.push(subscription$);
  }

  ngOnDestroy() {
    this._subscriptions$.map(subscription$ => subscription$.unsubscribe());
  }

  /**
   * Update draft cookie and send request to save draft
   */
  async saveDraft(stepChange = false, navigationId?: number): Promise<void> {
    if (this._sessionService.isSalesLoggedIn()) {
      return;
    }
    // do not send multiple drafts on same navigationId
    if (navigationId === this._navigationId) {
      return;
    }
    this._navigationId = navigationId;

    const quote = this._quoteService.getCurrentQuote();
    const customerInfo: ICustomerInfo = this._cookieService.getJson('customerInfo');

    const packagingOrder = await this._store.select('packagingOrder').pipe(take(1)).toPromise();

    const queryParams = await this._navigator.generateStateQueryParams(this.orderItem, packagingOrder);
    const cartOrderParams = this._navigator.getCartOrderParams(this._activatedRoute.snapshot.queryParams);

    const urlData = this._parseUrl(this._router.url);

    const deadline = this.orderItem.turnaround &&
      this.orderItem.turnaround.selectedOption &&
      this.orderItem.turnaround.selectedOption.isGuaranteed &&
      this.orderItem.turnaround.selectedOption.guaranteedDate ? this.orderItem.turnaround.selectedOption.guaranteedDate.toString() : '';

    // Custom rule for customize page.
    // If customer has selected quantity then save draft as it was on specialOptions page
    if (urlData[4] === CUSTOMIZE_PAGE) {
      const quantity = VariationManager.getQuantityFromStack(this.orderItem.variationTypes);

      if (!!quantity) {
        urlData[4] = SPECIAL_OPTIONS_PAGE;
      }
    }
    // End of custom rule logic

    const draft: IDraft = {
      urlParams: {
        urlData: urlData,
        queryParams: { ...queryParams, ...cartOrderParams }
      },
      creationDate: Math.round((new Date()).getTime() / 1000),
      productName: this.orderItem.product.name,
      productId: this.orderItem.product.id,
      deadline: deadline,
      artwork: this.orderItem.artwork,
    };

    if (stepChange) {
      draft.stepChange = stepChange;
    }

    // save cookie only when discardedDraft is not set
    const discardedDraft = sessionStorage.getItem('discardedDraft');
    if (!discardedDraft) {
      this._cookieService.setJson('hasDraft', true, 14);
    }

    const draftRequest: IDraftRequest = {
      quoteId: this.orderItem.quoteId ? this.orderItem.quoteId : quote.quoteId,
      draft: draft,
      type: DraftRequestType.diyDraft
    };

    // Data used for debug purposes
    // @todo remove after found issue with quote not found
    let logData = '';
    try {
      const loggedUser = this._sessionService.user;
      const quotes = this._quoteService.getQuotes();

      logData = JSON.stringify({
        loggedUser: loggedUser,
        customerInfo: customerInfo,
        quotes: quotes,
      });
    } catch (err) {
      logData += JSON.stringify(err);
    }

    try {
      this._draftApi.saveDraft(customerInfo.email, [draftRequest], logData);
    } catch (err) { }
  }

  /**
   * Send request to save drafts
   * @todo update to save all orders
   */
  async saveCartOrderDrafts(stepChange = false, navigationId?: number): Promise<void> {
    if (this._sessionService.isSalesLoggedIn()) {
      return;
    }
    // do not send multiple drafts on same navigationId
    if (navigationId === this._navigationId) {
      return;
    }
    this._navigationId = navigationId;

    try {

      if (!this.shoppingCart.cartItems.length) {
        return;
      }

      const draftRequests: IDraftRequest[] = [];
      // for (const cartItem of this.shoppingCart.cartItems) {
      for (let i = 0; i < this.shoppingCart.cartItems.length; i++) {
        const cartItem = this.shoppingCart.cartItems[i];
        for (let j = 0; j < cartItem.cartItemOrders.length; j++) {
          const cartItemOrder = cartItem.cartItemOrders[j];
          const deadline = cartItemOrder.orderItem.turnaround &&
            cartItemOrder.orderItem.turnaround.selectedOption &&
            cartItemOrder.orderItem.turnaround.selectedOption.isGuaranteed &&
            cartItemOrder.orderItem.turnaround.selectedOption.guaranteedDate ?
            cartItemOrder.orderItem.turnaround.selectedOption.guaranteedDate.toString() : '';


          const urlData = ['category', String(cartItemOrder.orderItem.category.id),
            'product', String(cartItemOrder.orderItem.product.id), 'turnaround'];

          const cartOrderParams = this._navigator.getCartOrderParams({
            uid: cartItemOrder.uid,
            index: i,
          });

          const stateQueryParams = await this._navigator.generateStateQueryParams(cartItemOrder.orderItem, cartItemOrder.packagingOrder);

          const queryParams = { ...stateQueryParams, ...cartOrderParams };

          const draft: IDraft = {
            urlParams: {
              urlData: urlData,
              queryParams: queryParams
            },
            creationDate: Math.round((new Date()).getTime() / 1000),
            productName: cartItemOrder.orderItem.product.name,
            productId: cartItemOrder.orderItem.product.id,
            deadline: deadline,
            artwork: cartItemOrder.orderItem.artwork,
          };

          if (stepChange) {
            draft.stepChange = stepChange;
          }

          draftRequests.push({
            quoteId: cartItemOrder.orderItem.quoteId,
            draft: draft,
            type: DraftRequestType.diyDraft
          });
        }
      }

      // save cookie only when discardedDraft is not set
      const discardedDraft = sessionStorage.getItem('discardedDraft');
      if (!discardedDraft) {
        this._cookieService.setJson('hasDraft', true, 14);
      }

      const customerInfo: ICustomerInfo = this._cookieService.getJson('customerInfo');

      // Data used for debug purposes
      // @todo remove after found issue with quote not found
      let logData = '';
      try {
        const loggedUser = this._sessionService.user;
        const quotes = this._quoteService.getQuotes();

        logData = JSON.stringify({
          loggedUser: loggedUser,
          customerInfo: customerInfo,
          quotes: quotes,
        });
      } catch (err) {
        logData += JSON.stringify(err);
      }

      this._draftApi.saveDraft(customerInfo.email, draftRequests, logData, DraftSource.cart);
    } catch (err) { }
  }

  /**
   * Check if user has draft
   * @param email // email used to identify the drafts check
   */
  async hasDrafts(email: string) {
    // stop execution if discarded draft is set
    const discardedDraft = sessionStorage.getItem('discardedDraft');
    if (discardedDraft) {
      return false;
    }

    const hasDrafts = this._cookieService.getJson('hasDraft');

    if (hasDrafts) {
      return true;
    }

    const res = await this._draftApi.hasDrafts(email);
    this._cookieService.setJson('hasDraft', res.hasDrafts, 14);

    return res.hasDrafts;
  }

  /**
   * Get all drafts for specific customer
   * @param email // email used to identify for what customer to get the drafts
   */
  async getDrafts(email: string): Promise<IDraft[]> {
    // stop execution if discarded draft is set
    const discardedDraft = sessionStorage.getItem('discardedDraft');
    if (discardedDraft) {
      return [];
    }

    if (email) {

      const res = await this._draftApi.getDrafts(email);

      const draftOrders = res && res.drafts ? res.drafts.map(draftResponse => draftResponse.draft) : false;

      if (draftOrders) {
        // update new data in cookies
        this._cookieService.setJson('hasDraft', true, 14);

        return draftOrders;
      }

      sessionStorage.setItem('discardedDraft', 'true');
    }

    return [];
  }

  /**
   * Load the artwork from the draft cookie
   */
  loadArtworkFromDraft(draft: IDraft) {
    const discardedDraft = sessionStorage.getItem('discardedDraft');
    if (discardedDraft) {
      return;
    }

    if (draft) {
      if ((!this.orderItem.artwork || !this.orderItem.artwork.files || !this.orderItem.artwork.files.length) &&
        draft.artwork && draft.artwork.files && draft.artwork.files.length
      ) {
        this._store.dispatch(new AddArtwork(draft.artwork));
      }
    }
  }

  /**
   * Load the artwork from the artworkData cookie
   */
  loadArtworkFromCookie(orderProductId: number) {
    const artworkData = this._parseArtworkCookie();
    if (artworkData && artworkData.data && artworkData.data.files) {
      if (orderProductId === Number(artworkData.productId)) {
        this.orderItem.artwork = artworkData.data;
      } else {
        this._cookieService.delete('artworkData', '/', environment.cookieDomainName);
      }
    }
  }

  /**
   * Parse url and return angular routing config like params
   * @param url
   */
  private _parseUrl(url: string): Array<any> {
    const data = url.split('/');

    data[data.length - 1] = data[data.length - 1].split('?')[0];

    data.shift(); // remove first empty element

    return data;
  }

  private _parseArtworkCookie(): IArtworkCookie {
    const artworkData = this._cookieService.getJson('artworkData');
    if (artworkData && artworkData.data) {
      if (artworkData.data.files) {
        return artworkData;
      } else if (artworkData.data.file) {
        return {
          ...artworkData,
          data: {
            description: artworkData.data.description,
            files: [artworkData.data.file]
          }
        };
      }
    }
  }

}
