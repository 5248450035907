import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safeHtml'
})
export class SafePipe implements PipeTransform {

  /**
   * @param sanitizer
   */
  constructor(protected sanitizer: DomSanitizer) {
  }

  /**
   * @param htmlString
   * @return {SafeHtml}
   */
  transform(htmlString: string): any {
    return this.sanitizer.bypassSecurityTrustHtml(htmlString);
  }

}
