<form class="credit-selector">
  <h2>Credits Available for the Following Customizations:</h2>
  <ul>
    <li *ngFor="let item of bundleCustomisationList">
      {{item.variationTypeName}}: {{item.variationName}}
    </li>
  </ul>
  <p>Would you like to use your credits for this order?</p>

  <div class="form-group" [formGroup]="creditForm">
    <label>
      <input type="radio" formControlName="bundleRadio" value="enabled" />
      Yes, use my credits and specified customizations for this order
    </label>

    <label>
      <input type="radio" formControlName="bundleRadio" value="disabled" />
      No, I want to choose my own customizations
    </label>
  </div>
</form>
