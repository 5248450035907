<!-- Header -->
<div class="header-content">
  <diy-header></diy-header>
</div>

<!-- Page content -->
<div class="container">
  <div class="shopping-cart" *ngIf="shoppingCartData?.cartItems?.length else emptyCart">
    <br><br>
    <div class="shopping-cart__form">
      <h1 class="shopping-cart__form-title">Shopping Cart</h1>
      <div class="shopping-cart__form-desc" *ngIf="totalItems > 1">
        <p>
          <strong>Combined Shipping</strong> - Save money by combining shipping.
        </p>
        <p>Select the items you want to combine and then click on Combined Shipping button.</p>
        <button class="shopping-cart__form-btn shopping-cart__form-btn-modal" [disabled]="selectedIndexes?.length < 2"
          (click)="openCombinedShippingModal()">Combined
          Shipping</button>
      </div>

      <diy-cart-item *ngFor="let cartItem of shoppingCartData?.cartItems; let i = index" [cartItem]="cartItem"
        [cartIndex]="i" [itemChecked$]="itemChecked$" (checkedItem)="select($event)"
        (clearChecked)="clearCheckedIndexes(cartItem?.cartItemOrders?.length, i)">
      </diy-cart-item>

      <a routerLink="/" [queryParams]="{skipDraft: true}" class="new-btn">&#43; Add another item</a>

      <div *ngIf="showPromoBanner" class="promo-banner">
        Get {{bannerDiscountValue}}% off additional items - add more products to your order to save!
      </div>

      <div class="shopping-cart__totals">
        <div>
          <p>Total Items:&nbsp;</p><span> {{totalItems}}</span>
          <p>Order Total: <span>{{shoppingCartData?.orderTotal | currency:'USD'}}</span></p>
        </div>
        <div class="shopping-cart__actions">
          <button (click)="navigateToTurnaround()">Checkout</button>
        </div>
      </div>
    </div>
  </div>

  <ng-template #emptyCart>
    <div class="shopping-cart__empty">
      <h1 class="shopping-cart__form-title">Your Shopping Cart is currently empty.</h1>
      <a routerLink="/" class="new-btn">&#43; Add an item</a>
    </div>
  </ng-template>

</div>

<ts-confirm #confirmModal className="confirm-modal">
</ts-confirm>
