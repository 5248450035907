import { IUploadedFile } from '../../types/artwork/IUploadedFile';

/**
 * Created by petre on 27.08.2018.
 */
export class ComStr {

  /* tslint:disable */
  // New REGEX
  public static EMAIL_REGEX = /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;
  // Old REGEX
  // public static EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  /* tslint:enable */
  // public static PHONE_REGEX = /^[+]?[()\s./-\d]+$/;
  public static PHONE_REGEX = /^\+?[1-9]\d{1,14}$/;
  public static NUMBER_ONLY_REGEX = '[0-9 ]+';
  public static VARIATION_NAME_REGEX = /[^\d.-]/g;
  public static UTM_REGEX = /utm_\w+/;

  static checkEmail(str: string) {
    const re = new RegExp(this.EMAIL_REGEX);
    return re.test(str);
  }

  public static castNameToNumber(variationName: string) {
    return Number(variationName.replace(this.VARIATION_NAME_REGEX, ''));
  }

  public static checkImageFormat(imageSrc: string) {
    if (imageSrc) {
      const ext = ComStr.getImageExtension(imageSrc);

      switch (ext) {
        case 'ai':
          return '/assets/img/uploaded-files-icons/File_Placeholder_AI.png';
        case 'eps':
          return '/assets/img/uploaded-files-icons/File_Placeholder_EPS.png';
        case 'pdf':
          return '/assets/img/uploaded-files-icons/File_Placeholder_PDF.png';
        case 'psd':
          return '/assets/img/uploaded-files-icons/File_Placeholder_PSD.png';
        case 'tif':
        case 'tiff':
          return '/assets/img/uploaded-files-icons/File_Placeholder_TIFF.png';
        default:
          return imageSrc;
      }
    }
  }

  public static getDisplayableImage(files: IUploadedFile[]): string {
    if (files && files.length) {
      const nonDisplayableExt = ['ai', 'eps', 'pdf', 'psd', 'tif', 'tiff'];

      const displayableImage = files.find(file => !nonDisplayableExt.includes(ComStr.getImageExtension(file.url)));
      if (displayableImage) {
        return ComStr.checkImageFormat(displayableImage.url);
      }
      return ComStr.checkImageFormat(files[0].url);
    }
  }

  public static getImageExtension(imageSrc: string): string {
    if (imageSrc) {
      const formatted = imageSrc.split('.');
      const ext = formatted[formatted.length - 1];
      return ext.toLocaleLowerCase();
    }
  }

}
