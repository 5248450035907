<div class="search-wrapper">
  <div class="search">
    <form [formGroup]="searchForm">

      <div class="form-group input-wrap">
        <span class="search-icon"></span>
        <input formControlName="searchField" placeholder="Product Name" type="text" (focus)="focusSearchBar()"
          (blur)="isSearching = false" />
      </div>

      <div class="search-results-wrap" *ngIf="showResults()">
        <div class="search-results">
          <div *ngFor="let sResult of searchResults">
            <diy-search-result [result]="sResult"></diy-search-result>
          </div>

          <div *ngIf="searchResults.length <= 0" class="no-results">
            No results found
          </div>

          <div class="fake-search-result">
            Looking for something else? <a (click)="altLog('Source it through us')" href="javascript:void(0);">
              Source it through us</a>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
