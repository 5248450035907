import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { AmplitudeService } from '../../core/services/amplitude';
import { TrackingService } from '../../core/services/tracking';

import { IAppState } from '../../types/app-state/IAppState';
import { IOrderItem } from '../../types/app-state/IOrderItem';

@Component({
  selector: 'diy-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})
export class ThankYouComponent implements OnInit, OnDestroy {

  orderItem: IOrderItem;

  private _subscriptions$: Subscription[] = [];
  constructor(
    protected _store: Store<IAppState>,
    private _tracking: TrackingService,
    private _router: Router,
    private _amp: AmplitudeService,
  ) {
    const subscription$ = this._store.pipe(select('orderItem'))
      .subscribe((orderItem: IOrderItem) => this.orderItem = orderItem);

    this._subscriptions$.push(subscription$);
  }

  ngOnInit() {
    this._tracking.sendAltoCloudData(['pageview', {
      location: location.pathname,
      title: 'Not Found'
    }]);
  }

  ngOnDestroy() {
    this._subscriptions$.forEach(subscription$ => subscription$.unsubscribe());
  }

  goToCustomize() {
    this._amp.logEvent('Navigation', {
      page: 'thank-you-customize',
      category: this.orderItem.category.name,
      category_id: this.orderItem.category.id,
      productName: this.orderItem.product.name,
      product_id: this.orderItem.product.id,
      quoteId: this.orderItem.quoteId,
    });

    return this._router.navigate(
      ['/category', this.orderItem.category.id, 'product', this.orderItem.product.id, 'customize'],
      { queryParamsHandling: 'preserve' }
    );
  }

}
