import { Component, OnInit, Input } from '@angular/core';

import { IOpenQuote } from '../../types/api/IToken';

@Component({
  selector: 'diy-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {

  @Input() quotes: IOpenQuote[];

  currentQuote: IOpenQuote;
  currentIndex: number;
  shownIndexes: number[];

  private _currentBatch = 1;
  private _indexes: number[] = [];

  constructor() { }

  ngOnInit() {
    if (this.quotes && this.quotes.length) {
      for (let i = 0; i < this.quotes.length; i++) {
        this._indexes.push(i);
      }
      this.currentQuote = this.quotes[0];
      this.currentIndex = 0;
      this.shownIndexes = this._indexes.slice(0, 5);
    }
  }

  select(index: number) {
    if (this.quotes.length && index !== this.currentIndex) {
      this.currentQuote = this.quotes[index];
      this.currentIndex = index;
    }
  }

  next() {
    if (this.currentIndex === this._indexes[this._indexes.length - 1]) { return; }

    if (this.currentIndex === this.shownIndexes[this.shownIndexes.length - 1]) {
      this._nextBatch();
    }
    this.currentIndex += 1;
    this.currentQuote = this.quotes[this.currentIndex];
  }

  prev() {
    if (this.currentIndex === this._indexes[0]) { return; }

    if (this.currentIndex === this.shownIndexes[0]) {
      this._prevBatch();
    }
    this.currentIndex -= 1;
    this.currentQuote = this.quotes[this.currentIndex];
  }

  private _nextBatch() {
    if (this.shownIndexes[this.shownIndexes.length - 1] < this.quotes.length - 1) {
      this._currentBatch++;
      const offset: number = (this._currentBatch - 1) * 5;
      this.shownIndexes = this._indexes.slice(offset, offset + 5);
    }
  }

  private _prevBatch() {
    if (this.shownIndexes[0] !== 0) {
      this._currentBatch--;
      const offset: number = (this._currentBatch - 1) * 5;
      this.shownIndexes = this._indexes.slice(offset, offset + 5);
    }
  }

}
