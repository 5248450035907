import { Component, OnInit, Renderer2, Inject, AfterViewInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import postscribe from 'postscribe';

@Component({
  selector: 'diy-trustmarks',
  templateUrl: './trustmarks.component.html',
  styleUrls: ['./trustmarks.component.scss']
})
export class TrustmarksComponent implements OnInit, AfterViewInit {

  constructor(private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document) {
  }

  ngOnInit() {
    const script = this._renderer2.createElement('script');
    script.type = `text/javascript`;
    script.src = `//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js`;
    script.text = `
            {
      "@context": ""
            }
                  `;

    script.async = 'true';

    this._renderer2.appendChild(this._document.head, script);

    script.onload = function () {
      const trustboxRef = document.getElementById('53d69c44000064000579285f');
      (window as any).Trustpilot.loadFromElement(trustboxRef);
    }



    // Get elements
    const trustmarks = document.getElementById('trustmarks');
    const hasHide = trustmarks.classList.contains('hide');
    const wrapper = document.getElementsByClassName('main-content');

    // Add padding for payment page
    if (!hasHide && window.location.href.indexOf('payment') > -1) {
      wrapper[0].classList.add('trustmarks-padding');
      wrapper[0].classList.remove('trustmarks-padding__welcome');
    }

    // Add padding for welcome page
    if (!hasHide && window.location.href.indexOf('welcome') > -1) {
      wrapper[0].classList.add('trustmarks-padding__welcome');
    }

    if (window.location.href.indexOf('welcome') > -1) {
      trustmarks.classList.add('trustmarks__welcome');
    }

    if (window.location.href.indexOf('payment') > -1) {
      trustmarks.classList.add('trustmarks__payment');
    }
  }

  ngAfterViewInit() {
    /* tslint:disable:max-line-length */
    // postscribe('#seal', '<script type="text/javascript" src="https://seal.websecurity.norton.com/getseal?host_name=create.thestudio.com&size=L&use_flash=NO&use_transparent=Yes&lang=en"></script><br />');
    /* tslint:enable:max-line-length */
  }

}
