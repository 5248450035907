export function getExpirationDate(date: string): string {
  if (!date) {
    return '';
  }

  return 'EXP: ' + date.substr(0, 2) + '-20' + date.substr(2, 4);
}

export function getCCThumb(type: string) {
  type = type.trim().toLowerCase();
  switch (type) {
    case 'visa': return '../../../../assets/img/payment/visa.png';
    case 'mastercard': return '../../../../assets/img/payment/mastercard.png';
    case 'paypal': return '../../../../assets/img/payment/paypal.png';
    case 'american': return '../../../../assets/img/payment/american.png';
    case 'american express': return '../../../../assets/img/payment/american.png';
    case 'discover': return '../../../../assets/img/payment/discover.png';
  }
}
