<div class="carousel" *ngIf="quotes?.length">
  <ol class="carousel-indicators">
    <li *ngFor="let index of shownIndexes" (click)="select(index)" [ngClass]="{'active': currentIndex === index}">
      {{index + 1}}
    </li>
  </ol>

  <div class="slide">
    <div class="slide-content-wrapper">

      <div class="slide-content-heading">
        <p>Quote Number</p>
        <p>Product</p>
        <p>Type</p>
        <p>Quantity</p>
        <p>Request Date</p>
      </div>

      <div class="slide-content-description">
        <p>{{currentQuote?.quoteNumber || '-'}}</p>
        <p>{{currentQuote?.product || '-'}}</p>
        <p>{{currentQuote?.type || '-'}}</p>
        <p>{{(currentQuote?.quantity | number) || '-'}}</p>
        <p>{{currentQuote?.requestDate || '-'}}</p>
      </div>

    </div>
    <a class="left carousel-control carousel-control-prev" (click)="prev()">
      <span aria-hidden="true" class="icon-prev carousel-control-prev-icon"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="right carousel-control carousel-control-next" (click)="next()">
      <span aria-hidden="true" class="icon-next carousel-control-next-icon"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</div>
