<!-- Header -->
<div class="header-content">
  <diy-header></diy-header>
  <diy-order-details></diy-order-details>
  <diy-promo-banner></diy-promo-banner>
  <!-- we have the ngIf as we are setting currentStep after resolving route -->
  <diy-order-step-tracking *ngIf="currentStep" [currentStep]="currentStep">
  </diy-order-step-tracking>
</div>

<!-- Page content -->
<div class="container">
  <div class="customize">
    <div class="heading-primary">
      <h1>Step {{ currentStep }}: Customize</h1>
      <p>Customize by selecting the options that best fit your needs. As you make selections, the
        Order Summary on
        the right will update and reflect each choice – any upgrades that add additional cost to
        your order will
        also be reflected.</p>
    </div>

    <diy-credit-selector *ngIf="bundleItem" [bundleItem]="bundleItem"
      [bundleCustomisationList]="bundleCustomisationList">
    </diy-credit-selector>

    <div *ngFor="let vt of orderItem?.product?.variationTypes; let i = index" class="customize__container">
      <div [ngSwitch]="vt.category">

        <diy-style (variationSelected)="scrollToNextSection(i)" id="section{{ i }}" *ngSwitchCase="'style'"
          [variationType]="vt"></diy-style>

        <diy-size (variationSelected)="scrollToNextSection(i)" id="section{{ i }}" *ngSwitchCase="'size'"
          [variationType]="vt"></diy-size>

        <div id="section{{ i }}" *ngSwitchCase="'qty'">
          <!-- Regular Quantity Component -->
          <diy-quantity (variationSelected)="scrollToNextSection(i)" *ngIf="!customisationLock" [variationType]="vt">
          </diy-quantity>

          <!-- Bundle Quantity Component -->
          <diy-bundle-quantity (variationSelected)="scrollToNextSection(i)" *ngIf="customisationLock"
            [variationType]="vt">
          </diy-bundle-quantity>
        </div>

      </div>
    </div>

    <button class="button button--green" [ngClass]="{'disabled': disableNextStep()}" id="continue" (click)="nextStep()">
      Continue
    </button>
  </div>

  <diy-notes></diy-notes>

  <diy-order-summary [currentStep]="currentStep"></diy-order-summary>
  <ts-confirm #confirmModal title="{{getUpsellTitle()}}" subtitle="{{getUpsellSubTitle()}}" confirmText="Yes, Upgrade"
    declineText="No, Thanks" className="upgrade-quantity-modal-confirm">

    <div class="choices">
      <div class="choice">
        <small>Your choice</small>
        <p>
          {{priceUpgradeInfo.currentQty}}
          <span class="price-strike">{{priceUpgradeInfo.currentPrice | currency}}</span>
          <span>{{priceUpgradeInfo.currentDiscountedPrice | currency}}</span>
        </p>
      </div>
      <img src="assets/img/arrows-upgrade.jpg" alt="">
      <div class="choice">
        <small class="color-green">Smart Buy</small>
        <p>
          {{priceUpgradeInfo.upgradeQty}}
          <span class="price-strike">{{priceUpgradeInfo.upgradePrice | currency}}</span>
          <span>{{priceUpgradeInfo.upgradeDiscountedPrice | currency}}</span>
        </p>
      </div>

    </div>
  </ts-confirm>

</div>
