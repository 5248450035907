import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';

import { OptionBase } from './option-base.component';
import { AddSpecialOption, RemoveSpecialOption } from '../../../state-manager/order-item/order-item.actions';

import { IAppState } from '../../../types/app-state/IAppState';
import { ISelectedVariation } from '../../../types/app-state/ISelectedVariation';
import { IVariation } from '../../../types/product/variations/IVariation';

@Component({
  selector: 'diy-multi-option-tab',
  templateUrl: './multi-option.component.html',
  styleUrls: ['./options-common.component.scss']
})
export class MultiOptionComponent extends OptionBase implements OnInit {

  @Output() optionSelected: EventEmitter<ISelectedVariation> = new EventEmitter<ISelectedVariation>();
  // array of selected ids
  selectedIds: number[] = [];

  constructor(private _store: Store<IAppState>) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.selectedIds = this.orderItem.specialOptions.map(selectedOption => Number(selectedOption.variation.id));

    if (this.tabIndex === 1) {
      this.collapseStatus = false;
    }
  }

  isSelected(variationId: number): boolean {
    return this.selectedIds.indexOf(variationId) > -1;
  }

  chooseOption(variation: IVariation) {

    if (!this.isCompatible(variation)) {
      return;
    }

    const selectedVariation: ISelectedVariation = {
      variation: variation,
      multiple: true,
      vtID: this.specialOption.id,
      vtDBName: this.specialOption.dbname,
      vtName: this.specialOption.name,
      vtCategory: this.specialOption.category
    };

    // handle multiple select
    if (!this.isSelected(variation.id)) {
      this._addOption(selectedVariation);
      this.optionSelected.emit(selectedVariation);
    } else {
      this._removeOption(selectedVariation);
    }
  }

  private _addOption(iSelectedVariation: ISelectedVariation) {
    // update selected ids array
    this.selectedIds.push(Number(iSelectedVariation.variation.id));
    this._store.dispatch(new AddSpecialOption(iSelectedVariation));
    this.hasSelection = true;
    this.showError = false;
  }

  private _removeOption(iSelectedVariation: ISelectedVariation) {
    // update selected ids array
    const oldPosition = this.selectedIds.indexOf(Number(iSelectedVariation.variation.id));
    this.selectedIds.splice(oldPosition, 1);
    if (this.selectedIds.length <= 0) {
      this.hasSelection = false;
    }
    this._store.dispatch(new RemoveSpecialOption(iSelectedVariation));
  }

}
