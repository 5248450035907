import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { take } from 'rxjs/operators';

import { LocalStorageService } from '../../../utility/localStorage.service';
import { ProductFlowApi } from '../../../core/api/productFlowApi';
import { CombineShippingService } from './combine-shipping.service';
import { DataTransformerService } from '../../../core/api/data-transformer.service';

import { IAppState } from '../../../types/app-state/IAppState';
import { IShoppingCart } from '../../../types/shopping-cart/IShoppingCart';
import { SetShoppingCart } from '../../../state-manager/shopping-cart/shopping-cart.actions';
import { ICartItemOrder } from '../../../types/shopping-cart/ICartItem';
import { ITurnaround } from '../../../types/turnaround/ITurnaround';
import { IDeliveryOption } from '../../../types/turnaround/IDeliveryOption';
import { ITurnaroundEstimationRequest, ITurnaroundEstimationBase } from '../../../types/turnaround/ITurnaroundEstimationRequest';

const SHOPPING_CART = 'shopping_cart';

@Injectable()
export class ShoppingCartStorageService {

  constructor(
    private _store: Store<IAppState>,
    private _productFlowApi: ProductFlowApi,
    private _localStorage: LocalStorageService
  ) { }

  async setCartData() {
    const shoppingCartData = await this._store.pipe(select('shoppingCart'), take(1)).toPromise();
    this._localStorage.setItem(SHOPPING_CART, JSON.stringify({ cart: shoppingCartData, timestamp: String(Date.now()) }));
  }

  clearCartData() {
    this._localStorage.removeItem(SHOPPING_CART);
  }

  async retrieveCartData() {
    const localStorageCartString = this._localStorage.getItem(SHOPPING_CART);
    let localStorageCart: IShoppingCart;

    if (localStorageCartString) {

      const data = JSON.parse(localStorageCartString);
      localStorageCart = data.cart;
      const timestamp = parseInt(data.timestamp, 10);

      const cartAge = (Date.now() - timestamp) / (1000 * 60 * 60 * 24);
      // Remove local storage data after 10 days
      if (cartAge > 10) {
        this.clearCartData();
        return;
      }

      if (localStorageCart && localStorageCart.cartItems && localStorageCart.cartItems.length) {
        if ((new Date(timestamp).getDate() !== new Date().getDate()) && localStorageCart.cartItems.length) {
          await Promise.all(localStorageCart.cartItems.map(async cartItem => {
            cartItem.cartItemOrders = await Promise.all(cartItem.cartItemOrders.map(async (cartItemOrder) => {
              cartItemOrder = Object.assign({}, await this._updateTurnaroundTime(cartItemOrder));
              return cartItemOrder;
            }));
            if (cartItem.cartItemOrders.length > 1) {
              cartItem.cartItemOrders = CombineShippingService.combineCartItems(cartItem.cartItemOrders);
            }
          }));
          this._localStorage.setItem(SHOPPING_CART, JSON.stringify({ cart: localStorageCart, timestamp: String(Date.now()) }));
        }

        // remove cartItemOrders that use the old structure
        // should remove these following 3 lines on the next release
        localStorageCart.cartItems.forEach(cartItem => {
          cartItem.cartItemOrders = cartItem.cartItemOrders.filter(cartItemOrder => !!cartItemOrder.orderItem);
        });

        this._store.dispatch(new SetShoppingCart(localStorageCart));

        // remove this, too
        this.setCartData();
      }
    }
  }

  private async _updateTurnaroundTime(cartItemOrder: ICartItemOrder): Promise<ICartItemOrder> {
    const variationIds: number[] = [];
    const sizeVT = cartItemOrder.orderItem.variationTypes.find(chosenElement => chosenElement.vtName === 'Size');
    if (sizeVT) {
      variationIds.push(Number(sizeVT.variation.id));
    }
    const otherVariations = cartItemOrder.orderItem.variationTypes.filter(chosenElement => {
      return (chosenElement.vtName !== 'Quantity' && chosenElement.vtName !== 'Size');
    });
    if (otherVariations.length) {
      otherVariations.forEach(otherVariation => {
        variationIds.push(Number(otherVariation.variation.id));
      });
    }
    const specialOptionsIds: number[] = cartItemOrder.orderItem.specialOptions.map(specialOption => Number(specialOption.variation.id));

    const artwork = !!cartItemOrder.orderItem.artwork &&
      !!cartItemOrder.orderItem.artwork.files &&
      !!cartItemOrder.orderItem.artwork.files.length;

    const packagingOrder = cartItemOrder.packagingOrder;
    let packagingEstimationPayload: ITurnaroundEstimationBase;
    if (packagingOrder && packagingOrder.packagingSelections && packagingOrder.packagingSelections.length) {
      packagingEstimationPayload = {
        product: packagingOrder.product.id,
        variations: packagingOrder.packagingSelections
          .filter(packagingSelection => !packagingSelection.variation.hasOwnProperty('customType'))
          .map(packagingSelection => Number(packagingSelection.variation.id)),
        artwork: !!(packagingOrder.artwork && packagingOrder.artwork.files && packagingOrder.artwork.files.length),
        quantity: packagingOrder.price.quantity
      };
    }

    const turnaroundEstimatesPayload: ITurnaroundEstimationRequest = {
      product: Number(cartItemOrder.orderItem.product.id),
      quantity: cartItemOrder.orderItem.price.quantity,
      variations: [...variationIds, ...specialOptionsIds],
      artwork: artwork,
      packaging: packagingEstimationPayload
    };

    const updatedTAT = await this._productFlowApi.getTurnaroundTime(turnaroundEstimatesPayload);

    if (updatedTAT) {
      const availableOptions = DataTransformerService.createDeliveryOptions(updatedTAT.estimations);
      const oldSelectedOption = Object.assign({}, cartItemOrder.initialTurnaround);
      const newSelectedOption = Object.assign({}, availableOptions.find(option => option.name === oldSelectedOption.name));
      newSelectedOption.isGuaranteed = oldSelectedOption.isGuaranteed;
      if (oldSelectedOption.isGuaranteed) {
        newSelectedOption.guaranteedDate = this._getGuaranteedDate(
          oldSelectedOption,
          newSelectedOption,
          cartItemOrder.orderItem.turnaround.skipSample
        );
      }
      const turnaround: ITurnaround = {
        availableOptions: availableOptions,
        skipSample: cartItemOrder.orderItem.turnaround.skipSample,
        selectedOption: newSelectedOption
      };
      cartItemOrder.orderItem.turnaround = Object.assign({}, turnaround);
      cartItemOrder.initialTurnaround = Object.assign({}, newSelectedOption);
    }
    return cartItemOrder;
  }

  private _getGuaranteedDate(oldSelected: IDeliveryOption, newSelected: IDeliveryOption, skipSample: boolean): number {
    const oldGuaranteed = Number(oldSelected.guaranteedDate);
    let newStart: number;
    let newEnd: number;
    if (skipSample) {
      newStart = Number(newSelected.datesInterval.ssStart);
      newEnd = Number(newSelected.datesInterval.ssEnd);
    } else {
      newStart = Number(newSelected.datesInterval.normalStart);
      newEnd = Number(newSelected.datesInterval.normalEnd);
    }


    return oldGuaranteed > newStart && oldGuaranteed < newEnd ? oldSelected.guaranteedDate : newStart;
  }

}
