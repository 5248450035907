export const environment = {
  production: true,
  enableLeadCapture: true,
  baseUrl: 'http://crm.devback.thestudio.com/',
  customerDashURL: 'http://customer.devback.thestudio.com/',
  uploadUrl: 'http://create.devfront.thestudio.com/upload/',
  stagingUrl: 'http://crm.devback.thestudio.com/',
  cacheDir: 'app/custom_cache/diy_data/',
  amplitudeApiKey: '606df8d216e2d4c7c5b3df0429fbcd37',
  salesTel: '+18447078700',
  salesTelShown: '(877) 647-6447',
  sourcingUrl: 'http://thestudio.com/enterprise/custom-t-shirts',
  cookieDomainName: '.thestudio.com',
  promotionActive: false,
  alwaysSaleBanner: true,
  promotionValue: 40,
  alwaysSaleValue: 40,
  specialAdminEmails: ['recdesignsbymerch@thestudio.com', 'freedesigns@thestudio.com'],
  resellerTaxActive: true,
  showUpgradeModal: true,
  skipSampleDefault: 1000,
  skipSampleMandatory: 5000,
  tatAnnouncement: false,
  placeholderImageURL: "https://s3-us-west-2.amazonaws.com/cdn.thestudio.com/create/placeholder.png",
};

