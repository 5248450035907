<!-- Header -->
<div class="header-content">
  <diy-header></diy-header>
  <diy-order-details></diy-order-details>
  <!-- <diy-promo-banner></diy-promo-banner> -->
  <!-- we have the ngIf as we are setting currentStep after resolving route -->
  <diy-order-step-tracking
    *ngIf="currentStep"
    [currentStep]="currentStep"
  ></diy-order-step-tracking>
</div>

<!-- Page content -->
<div class="container quoteFormClass">
  <form class="welcome__form" [formGroup]="quoteForm">
    <h1 class="welcome__form--title">Design your {{ productName }}</h1>
    <h2 class="welcome__form--subtitle">
      Create an account to save your designs and progress.
    </h2>

    <!-- <div class="welcome__form--image">
      <img src="../../../assets/img/welcome-form.png" alt="welcome" />
    </div> -->

    <div class="welcome__form--control">
      <label for="welcome__form--fullname" class="welcome__form--label">Name
        <span *ngIf="showError('firstname')" class="form--error"
            >Please enter your name.</span
          >
      </label>
      <input
        type="text"
        id="welcome__form--fullname"
        class="form--input"
        formControlName="fullName"
      />
    </div>

    <div class="welcome__form--controls">
      <div class="welcome__form--control" style="display: none;">
        <label for="welcome__form--name" class="welcome__form--label">
          First Name
          <span *ngIf="showError('firstname')" class="form--error"
            >Please enter your first name.</span
          >
        </label>
        <input
          type="text"
          id="welcome__form--name"
          class="form--input"
          formControlName="firstname"
          [ngClass]="{ 'form--input-required': showError('firstname') }"
        />
      </div>

      <div class="welcome__form--control" style="display: none;">
        <label for="welcome__form--lname" class="welcome__form--label">
          Last Name
          <span *ngIf="showError('lastname')" class="form--error"
            >Please enter your last name.</span
          >
        </label>
        <input
          id="welcome__form--lname"
          class="form--input"
          formControlName="lastname"
          [ngClass]="{ 'form--input-required': showError('lastname') }"
        />
      </div>

      <div class="welcome__form--control">
        <label for="welcome__form--email" class="welcome__form--label">
          Email
          <span *ngIf="showError('email')" class="form--error"
            >Please enter a valid e-mail.</span
          >
        </label>
        <input
          id="welcome__form--email"
          class="form--input"
          type="text"
          formControlName="email"
          [ngClass]="{ 'form--input-required': showError('email') }"
        />
      </div>

      <div class="welcome__form--control">
        <label for="welcome__form--phone" class="welcome__form--label">
          Phone
          <span *ngIf="showError('phone')" class="form--error"
            >Please enter a valid phone number.</span
          >
        </label>

        <diy-phone-selector
          (countrySelected)="countrySelected($event)"
        ></diy-phone-selector>

        <input
          formControlName="phone"
          id="welcome__form--phone"
          class="form--input phone-input"
          [ngClass]="{ 'form--input-required': showError('phone') }"
          phoneMask
        />
      </div>

      <div class="welcome__form--control" style="display: none;">
        <label for="welcome__form--phone" class="welcome__form--label">
          Reason for Purchase
          <span *ngIf="showError('reasonForPurchase')" class="form--error"
            >Please select a reason for your purchase.</span
          >
        </label>
        <select
          formControlName="reasonForPurchase"
          [ngClass]="{ 'form--input-required': showError('reasonForPurchase') }"
          class="form--input"
        >
          <option value="">Select Reason for Purchase</option>
          <option
            *ngFor="let reasonForPurchase of availableReasons"
            [value]="reasonForPurchase"
          >
            {{ reasonForPurchase }}
          </option>
          <option value="other">Other</option>
        </select>
      </div>

      <div class="welcome__form--control" *ngIf="customReasonFlag">
        <label for="welcome__form--phone" class="welcome__form--label">
          Specify Reason
          <span
            *ngIf="showError('customReasonForPurchase')"
            class="form--error"
          >
            Please enter your reason for purchase.
          </span>
        </label>

        <input
          formControlName="customReasonForPurchase"
          class="form--input"
          [ngClass]="{
            'form--input-required': showError('customReasonForPurchase')
          }"
        />
      </div>

      <div class="welcome__form--control" style="display: none;">
        <label for="welcome__form--phone" class="welcome__form--label">
          Quantity
          <span *ngIf="showError('quantity')" class="form--error"
            >Please select a quantity.</span
          >
        </label>
        <select
          formControlName="quantity"
          [ngClass]="{ 'form--input-required': showError('quantity') }"
          class="form--input"
        >
          <option value="">Select a quantity</option>
          <option
            *ngFor="let quantity of availableQuantities"
            [value]="quantity"
          >
            {{ quantity }}
          </option>
          <option value="other">Other</option>
        </select>
      </div>

      <div class="welcome__form--control" *ngIf="customQuantityFlag">
        <label for="welcome__form--phone" class="welcome__form--label">
          Specify Desired Quantity
          <span *ngIf="showError('customQuantity')" class="form--error">
            Please select a quantity between {{ minQty }} and {{ maxQty }}
          </span>
        </label>

        <input
          formControlName="customQuantity"
          class="form--input"
          [ngClass]="{ 'form--input-required': showError('customQuantity') }"
        />
      </div>

      <button
        class="welcome__form--submit btn-info"
        [disabled]="loading"
        (click)="continue()"
      >
        <span *ngIf="!loading">Start Designing!</span>
        <span *ngIf="loading">Loading</span>
      </button>
      
      <div class="welcome__form--agreement">
        By signing up, I confirm that I have read and agreed to The/Studio's
        User Agreement & Terms of Service. I also agree to signing up via text,
        you consent to receive marketing text messages from The/Studio at the
        number provided, including messages sent by autodialer. Consent is not a
        condition of any purchase. You can unsubscribe at any time by replying
        STOP.
        <!--
        By clicking this button, you agree to The/Studio’s <a href="https://www.thestudio.com/privacy/">Privacy Policy</a>
        & <a href="https://www.thestudio.com/terms/">Terms and Conditions</a>. -->
      </div>
    </div>
  </form>
  <diy-trustmarks></diy-trustmarks>
</div>
