<form [formGroup]="voucherForm" class="promo-code">
  <hr>
  <p for="promoCode">Promo Code</p>

  <label for="promoCode">
    <input type="text" formControlName="promoCode" placeholder="XYZBE">

    <span *ngIf="errorMessage" class="promo-code__danger">{{errorMessage}}</span>
    <span *ngIf="showSuccess" class="promo-code__succes">Promo code {{promoCode}} was successfully added!</span>

  </label>

  <div class="cta">
    <button type="submit" class="button button--green button-noFill" (click)="applyVoucher()"
      [disabled]="!voucherForm.valid || isLoading">
      <span *ngIf="!isLoading; else loading">Apply</span>
    </button>
    <span *ngIf="showSuccess" class="promo-code__remove" (click)="removeVoucher()">Remove</span>
  </div>
</form>

<ng-template #loading>
  <span>Loading...</span>
</ng-template>
