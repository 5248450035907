// counter.reducer.ts
import { OrderItemActionTypes, OrderItemsActionsUnion } from './order-item.actions';
import { IOrderItem } from '../../types/app-state/IOrderItem';

import { PriceCalculatorService } from '../../core/services/price-calculator';
import { IPrice } from '../../types/product/IPrice';
import { IArtwork } from '../../types/artwork/IArtwork';
import { OrderManagerService } from '../../core/services/order-manager.service';

const initialArtwork: IArtwork = {
  files: undefined,
  description: ''
};

const initialState: IOrderItem = {
  quoteId: undefined,
  category: undefined,
  product: undefined,
  artwork: initialArtwork,
  turnaround: undefined,
  price: {
    pricePerUnit: 0,
    units: 0,
    discountedTotal: 0,
    setupFee: 0,
    shippingTotal: 0,
    customizeTotal: 0,
    guaranteedFee: 0,
    specialOptionsTotal: 0,
    subTotal: 0,
    finalTotal: 0,
    displayDiscount: 0,
    discountAmount: 0,
    quantity: 0,
    productDiscount: 0,
    promotionalDiscount: 0,
    promotionalDiscountValue: 0
  },
  reorderId: undefined,
  firstInquiry: undefined,
  specialOptions: [],
  variationTypes: [],
  incompatibilities: [],
  bundleItem: undefined,
};

const initialPrice: IPrice = {
  pricePerUnit: 0,
  units: 0,
  discountedTotal: 0,
  setupFee: 0,
  shippingTotal: 0,
  customizeTotal: 0,
  guaranteedFee: 0,
  specialOptionsTotal: 0,
  subTotal: 0,
  finalTotal: 0,
  displayDiscount: 0,
  discountAmount: 0,
  quantity: 0,
  productDiscount: 0,
  promotionalDiscount: 0,
  promotionalDiscountValue: 0
};

export function reducer(state: IOrderItem = initialState, action: OrderItemsActionsUnion): IOrderItem {
  switch (action.type) {

    case OrderItemActionTypes.UpdateCategory: {
      return { ...state, category: action.payload };
    }

    case OrderItemActionTypes.ClearCategory: {
      return { ...state, category: undefined };
    }

    case OrderItemActionTypes.UpdateProduct: {
      return { ...state, product: action.payload };
    }

    case OrderItemActionTypes.ClearProduct: {
      return { ...state, product: undefined };
    }

    case OrderItemActionTypes.UpdateQuoteId: {
      return { ...state, ...action.payload };
    }

    case OrderItemActionTypes.ResetPrice: {
      return {
        ...state,
        price: {
          pricePerUnit: 0,
          units: 0,
          discountedTotal: 0,
          setupFee: 0,
          shippingTotal: 0,
          customizeTotal: 0,
          guaranteedFee: 0,
          specialOptionsTotal: 0,
          subTotal: 0,
          finalTotal: 0,
          displayDiscount: 0,
          discountAmount: 0,
          quantity: 0,
          productDiscount: 0,
          promotionalDiscountValue: 0,
          promotionalDiscount: 0,
        }
      };
    }

    case OrderItemActionTypes.AddArtwork: {
      return { ...state, artwork: action.payload };
    }

    case OrderItemActionTypes.RemoveArtwork: {
      const newArtwork: IArtwork = {
        files: undefined,
        description: (state.artwork) ? state.artwork.description : ''
      };

      return { ...state, artwork: newArtwork };
    }

    case OrderItemActionTypes.UpdateReorderId: {
      return { ...state, reorderId: action.payload };
    }

    case OrderItemActionTypes.UpdateFirstInquiry: {
      return { ...state, firstInquiry: action.payload };
    }

    case OrderItemActionTypes.ResetOrderItem: {
      return {
        quoteId: undefined,
        category: undefined,
        product: undefined,
        artwork: {
          files: undefined,
          description: (state.artwork) ? state.artwork.description : ''
        },
        turnaround: undefined,
        price: {
          pricePerUnit: 0,
          units: 0,
          discountedTotal: 0,
          setupFee: 0,
          shippingTotal: 0,
          customizeTotal: 0,
          guaranteedFee: 0,
          specialOptionsTotal: 0,
          subTotal: 0,
          finalTotal: 0,
          displayDiscount: 0,
          discountAmount: 0,
          quantity: 0,
          productDiscount: 0,
          promotionalDiscountValue: 0,
          promotionalDiscount: 0,
        },
        reorderId: undefined,
        firstInquiry: undefined,
        specialOptions: [],
        variationTypes: [],
        incompatibilities: [],
        bundleItem: undefined
      };
    }

    case OrderItemActionTypes.SetOrderItem: {
      const orderItem: IOrderItem = {
        ...action.payload,
        price: PriceCalculatorService.redoPrice(
          action.payload.product,
          action.payload.price,
          action.payload.variationTypes,
          action.payload.specialOptions,
          action.payload.turnaround,
          action.payload.bundleItem
        )
      };
      return orderItem;
    }

    case OrderItemActionTypes.ReplaceVariations: {

      const newState = {
        ...state,
        variationTypes: action.payload
      };

      newState.incompatibilities = OrderManagerService.getIncompatibilities([newState.variationTypes, newState.specialOptions]);
      try {
        newState.price = PriceCalculatorService.redoPrice(
          newState.product,
          newState.price,
          newState.variationTypes,
          newState.specialOptions,
          newState.turnaround,
          newState.bundleItem
        );
      } catch (err) {
        newState.price = Object.assign({}, initialPrice);
      }

      return newState;
    }

    case OrderItemActionTypes.AddBundleItem: {

      const newState = {
        ...state,
        bundleItem: action.payload
      };

      try {
        newState.price = PriceCalculatorService.redoPrice(
          newState.product,
          newState.price,
          newState.variationTypes,
          newState.specialOptions,
          newState.turnaround,
          newState.bundleItem
        );
      } catch (err) {
        newState.price = Object.assign({}, initialPrice);
      }

      return newState;
    }

    case OrderItemActionTypes.RemoveBundleItem: {

      return {
        ...state,
        variationTypes: [],
        specialOptions: [],
        price: Object.assign({}, initialPrice),
        bundleItem: undefined
      };
    }
    case OrderItemActionTypes.AddVariation: {

      const newState = {
        ...state,
        variationTypes: OrderManagerService.addVariation(state.variationTypes, action.payload)
      };

      newState.incompatibilities = OrderManagerService.getIncompatibilities([newState.variationTypes, newState.specialOptions]);
      try {
        newState.price = PriceCalculatorService.redoPrice(
          newState.product,
          newState.price,
          newState.variationTypes,
          newState.specialOptions,
          newState.turnaround,
          newState.bundleItem
        );
      } catch (err) {
        newState.price = Object.assign({}, initialPrice);
      }

      return newState;
    }

    case OrderItemActionTypes.RemoveVariation: {

      const newState = {
        ...state,
        variationTypes: OrderManagerService.removeVariation(state.variationTypes, action.payload)
      };

      newState.incompatibilities = OrderManagerService.getIncompatibilities([newState.variationTypes, newState.specialOptions]);
      try {
        newState.price = PriceCalculatorService.redoPrice(
          newState.product,
          newState.price,
          newState.variationTypes,
          newState.specialOptions,
          newState.turnaround,
          newState.bundleItem
        );
      } catch (err) {
        newState.price = Object.assign({}, initialPrice);
      }

      return newState;
    }

    case OrderItemActionTypes.RemoveVariations: {

      const newState = { ...state, variationTypes: [], incompatibilities: [] };

      try {
        newState.price = PriceCalculatorService.redoPrice(
          newState.product,
          newState.price,
          newState.variationTypes,
          newState.specialOptions,
          newState.turnaround,
          newState.bundleItem
        );
      } catch (err) {
        newState.price = Object.assign({}, initialPrice);
      }

      return newState;
    }

    case OrderItemActionTypes.AddSpecialOption: {

      const newState = {
        ...state,
        specialOptions: OrderManagerService.addSpecialOption(state.specialOptions, action.payload)
      };

      newState.incompatibilities = OrderManagerService.getIncompatibilities([newState.variationTypes, newState.specialOptions]);
      try {
        newState.price = PriceCalculatorService.redoPrice(
          newState.product,
          newState.price,
          newState.variationTypes,
          newState.specialOptions,
          newState.turnaround,
          newState.bundleItem
        );
      } catch (err) {
        newState.price = Object.assign({}, initialPrice);
      }

      return newState;
    }

    case OrderItemActionTypes.RemoveSpecialOption: {

      const newState = {
        ...state,
        specialOptions: OrderManagerService.removeSpecialOption(state.specialOptions, action.payload)
      };

      newState.incompatibilities = OrderManagerService.getIncompatibilities([newState.variationTypes, newState.specialOptions]);
      try {
        newState.price = PriceCalculatorService.redoPrice(
          newState.product,
          newState.price,
          newState.variationTypes,
          newState.specialOptions,
          newState.turnaround,
          newState.bundleItem
        );
      } catch (err) {
        newState.price = Object.assign({}, initialPrice);
      }

      return newState;
    }

    case OrderItemActionTypes.ReplaceSpecialOptions: {

      const newState = {
        ...state,
        specialOptions: action.payload
      };

      newState.incompatibilities = OrderManagerService.getIncompatibilities([newState.variationTypes, newState.specialOptions]);
      try {
        newState.price = PriceCalculatorService.redoPrice(
          newState.product,
          newState.price,
          newState.variationTypes,
          newState.specialOptions,
          newState.turnaround,
          newState.bundleItem
        );
      } catch (err) {
        newState.price = Object.assign({}, initialPrice);
      }

      return newState;
    }

    case OrderItemActionTypes.RemoveSpecialOptions: {

      const newState = { ...state, specialOptions: [] };

      newState.incompatibilities = OrderManagerService.getIncompatibilities([newState.variationTypes, newState.specialOptions]);
      try {
        newState.price = PriceCalculatorService.redoPrice(
          newState.product,
          newState.price,
          newState.variationTypes,
          newState.specialOptions,
          newState.turnaround,
          newState.bundleItem
        );
      } catch (err) {
        newState.price = Object.assign({}, initialPrice);
      }

      return newState;
    }

    case OrderItemActionTypes.UpdateTurnaround: {
      const newState = {
        ...state,
        turnaround: action.payload
      };

      try {
        newState.price = PriceCalculatorService.redoPrice(
          newState.product,
          newState.price,
          newState.variationTypes,
          newState.specialOptions,
          newState.turnaround,
          newState.bundleItem
        );
      } catch (err) {
        newState.price = Object.assign({}, initialPrice);
      }

      return newState;
    }

    case OrderItemActionTypes.RemoveTurnaround: {
      const newState = {
        ...state,
        turnaround: undefined
      };
      try {
        newState.price = PriceCalculatorService.redoPrice(
          newState.product,
          newState.price,
          newState.variationTypes,
          newState.specialOptions,
          newState.turnaround,
          newState.bundleItem
        );
      } catch (err) {
        newState.price = Object.assign({}, initialPrice);
      }

      return newState;
    }

    case OrderItemActionTypes.SetCopyQuoteArtwork: {
      return { ...state, copyQuoteArtwork: action.payload };
    }

    default: {
      return state;
    }
  }
}
