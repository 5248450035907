import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

import { AuthProvider } from '../../../core/api/AuthProvider';
import { ComStr } from '../../../core/services/ComStr';
import { TrackingService } from '../../../core/services/tracking';

@Component({
  selector: 'diy-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  pwdForm: FormGroup;

  emailSent = false;

  sendTo: string;

  constructor(
    private _tracking: TrackingService,
    private _formBuilder: FormBuilder,
    private _authProvider: AuthProvider
  ) {

    this.pwdForm = this._formBuilder.group({
      email: ['', Validators.compose([
        Validators.pattern(ComStr.EMAIL_REGEX)
      ])],
    });
  }

  ngOnInit() {
    this._tracking.sendAltoCloudData(['pageview', {
      location: location.pathname,
      title: 'Forgot Password'
    }]);
  }

  sendResetEmail() {
    if (this.pwdForm.valid) {
      this.sendTo = this.pwdForm.get('email').value;
      this._authProvider.forgotPassword(this.sendTo);
      this.emailSent = true;
    }

    return false;
  }
}
