<!-- Header -->
<div class="header-content">
  <diy-header></diy-header>
</div>

<!-- Page content -->

<div class="thank-you">
  <div class="image">
    <img src="/assets/img/thank-you/thank-you.png" alt="thank-you-img">
  </div>
  <div class="details">
    <h1>Thank you for your interest in {{orderItem?.product?.name}}</h1>
    <div class="call-you">
      <h3>we'll call you shortly!</h3>
      <p>Our Creative Team will assist you with the customization and the pricing.</p>

      <div class="image">
        <img src="/assets/img/thank-you/thank-you-phone.png" alt="thank-you-phone">
      </div>
    </div>
    <p>
      Have a design and specifications ready? <br>
      You can proceed to our guided design platform.
    </p>

    <div class="my-normal">
      <button class="button-link" (click)="goToCustomize()">Customize></button>
    </div>

    <small>* Our service hours are 7:00 AM to 7:00 PM PST, Monday to Friday.</small>
  </div>
</div>
