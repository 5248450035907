import { Injectable } from '@angular/core';

import { IVariation } from '../../types/product/variations/IVariation';

@Injectable()
export class Utils {

  static round(num: number): string {
    return (Math.round(num * 100) / 100).toFixed(2);
  }

  /**
   * @param height
   * @param width
   * @param sizeOptions
   * @return {number}
   */
  static getClosestSize(height: number, width: number, sizeOptions: number[]) {
    let customSizeValue = (height + width) / 2;
    customSizeValue = Math.round(customSizeValue * 2 ) / 2;

    return sizeOptions.reduce(function (prev, curr, i) {

      // first, find the closest element
      if ((Math.abs(curr - customSizeValue) >= Math.abs(prev - customSizeValue))) {
        return prev;
      }
      // then Aproximate UP ALYWAS ( for [1,5,10] a value of 3.5 turns into 1, 5.1 is 5 etc.
      return ((curr - customSizeValue) >= 0) ? curr : sizeOptions[i + 1] || curr;
    });
  }

  /**
   * @param needle
   * @param haystack
   */
  static getClosestQty(needle: number, haystack: number[]) {

    return haystack.reduce(function (prev, curr) {
      return (Math.abs(curr - needle) < Math.abs(prev - needle) ? curr : prev);
    });
  }


  // Strip alphanumeric characters,whitespace and convert to number
  static convertToNumber(theString: string): number {
    const converted = theString.replace(/[^\d.-\/]/g, '');

    if ((/\//).test(converted)) {
      const splitFraction = converted.split('/');
      return Number(splitFraction[0]) / Number(splitFraction[Number(splitFraction.length - 1)]);
    }

    return Number(converted);
  }

  /**
   * Generate a number array by casting "1.5 inches" to 1.5
   * or "1000" to 1000 (quantity) basically strip the numbers
   * and then sorting all values in the array asc
   */
  static getSortedVariationValues(variations: IVariation[]): number[] {
    const options = [];

    for (let i = 0; i < variations.length; i++) {
      options.push(Utils.convertToNumber(variations[i].name));
    }

    options.sort((n1, n2) => n1 - n2);
    return options;
  }


  // Grab a variation CLONE by casting "1.5 inches" to 1.5
  static getVariationByNumberValue(value: number, variations: IVariation[]): IVariation {

    for (let i = 0; i < variations.length; i++) {
      if (Utils.convertToNumber(variations[i].name) === value) {
        // return CLONE
        return Object.create(variations[i]);
      }
    }
  }

  static generateUid(): string {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }
}
