import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

// API Provider
import { ProductFlowApi } from '../api/productFlowApi';

// Types
import { ICategory } from '../../types/category/ICategory';
import { Store, select } from '@ngrx/store';
import { IAppState } from '../../types/app-state/IAppState';
import { take } from 'rxjs/operators';

@Injectable()
export class CategoryResolver implements Resolve<ICategory> {

  constructor(
    private _crmProvider: ProductFlowApi,
    private _store: Store<IAppState>,
  ) { }

  async resolve(route: ActivatedRouteSnapshot): Promise<ICategory> {
    const categoryId = Number(route.params['categoryId']);
    if (!categoryId) {
      throw new Error('Could not fetch products due to lacking categoryId.');
    }
    let categories = [];

    // first try to get the category from store, save a request
    this._store.pipe(select('categories'), take(1))
      .subscribe(storeCategories => categories = storeCategories);

    if (categories.length < 1) {
      // so that the route works on refresh as well
      categories = await this._crmProvider.getStructuredProducts();
    }

    return categories.find((cat) => cat.id === categoryId);
  }
}
