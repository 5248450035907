import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Subscription, Observable, Subject } from 'rxjs';
import {debounceTime, take} from 'rxjs/operators';
import { ProductFlowApi } from '../../core/api/productFlowApi';

// Services
import { AmplitudeService } from '../../core/services/amplitude';
import { DraftService } from '../../core/services/draft.service';
import { NavigatorService } from '../../core/services/navigator';
import { ArtworkService } from '../../core/services/artwork.service';
import { CampaignTrackingService } from '../../core/services/campaign-tracking.service';
import { SessionService } from '../../utility/session.service';
import { TrackingService } from '../../core/services/tracking';
import { CookieService } from '../../core/services/cookie.service';
import { Utils } from '../../core/services/utils';

import { DraftBaseComponent } from '../../core/components/draft/draftBase.component';

// State Management
import {
  AddArtwork,
  RemoveArtwork, RemoveTurnaround,
  SetCopyQuoteArtwork,
  UpdateTurnaround
} from '../../state-manager/order-item/order-item.actions';
import {AddCartItem, UpdateCartItemOrder} from '../../state-manager/shopping-cart/shopping-cart.actions';

// Types
import { IUploadedFile } from '../../types/artwork/IUploadedFile';
import { IArtwork } from '../../types/artwork/IArtwork';
import { ICategory } from '../../types/category/ICategory';
import { IProduct } from '../../types/product/IProduct';
import { IOrderItem } from '../../types/app-state/IOrderItem';
import { IAppState } from '../../types/app-state/IAppState';
import { ProductOrderSteps } from '../../types/step/IStep';
import { IQuoteData } from '../../types/api/IToken';
import { ICartItem, ICartItemOrder } from '../../types/shopping-cart/ICartItem';
import { IPackagingOrder } from '../../types/app-state/IPackagingOrder';
import { ITurnaround } from '../../types/turnaround/ITurnaround';
import { IDeliveryOption } from '../../types/turnaround/IDeliveryOption';
import { IShoppingCart } from '../../types/shopping-cart/IShoppingCart';
import {ITurnaroundEstimationBase, ITurnaroundEstimationRequest} from '../../types/turnaround/ITurnaroundEstimationRequest';
import {DataTransformerService} from '../../core/api/data-transformer.service';
import {environment} from '../../../environments/environment';


@Component({
  selector: 'diy-app-artwork',
  templateUrl: './artwork.component.html',
  styleUrls: ['./artwork.component.scss']
})
export class ArtworkComponent extends DraftBaseComponent implements OnInit, AfterViewInit, OnDestroy {

  currentStep: number = ProductOrderSteps.artwork;

  errorMessage = '';
  skipArtwork = false;
  artwork: IArtwork = {
    files: [],
    description: ''
  };
  product: IProduct;
  category: ICategory;
  existingArtworkFiles$: Subject<IUploadedFile[]> = new Subject();

  descriptionControl = new FormControl();
  descriptionSubscription: Subscription;

  showCopyQuoteArtworkBtn = false;

  selectedOption: IDeliveryOption;
  orderItem: IOrderItem;
  cartItems: ICartItemOrder[] = [];
  turnaround: ITurnaround;

  private _subscriptions$: Subscription[] = [];
  private _orderItem$: Observable<IOrderItem> = this._activatedRoute.snapshot.data.orderItem;
  private _uploadedFiles: IUploadedFile[] = [];
  private _packagingOrder: IPackagingOrder;
  private _editParams: Params;

  constructor(
    protected draftService: DraftService,
    protected router: Router,
    protected tracking: TrackingService,
    protected _sessionService: SessionService,
    protected cookies: CookieService,
    private _activatedRoute: ActivatedRoute,
    private _navigator: NavigatorService,
    private _amp: AmplitudeService,
    private _store: Store<IAppState>,
    private _artworkService: ArtworkService,
    private _campaignTrackingService: CampaignTrackingService,
    private _productFlowApi: ProductFlowApi,
  ) {
    super(draftService, router, tracking, _sessionService, cookies);
    this._campaignTrackingService.handleCampaignTracking(this._activatedRoute.snapshot.queryParams);
  }

  // lifecycle hooks

  async ngOnInit() {
    let subscription$ = this._orderItem$.subscribe((data: IOrderItem) => {
      this.orderItem = data;
      this.category = data.category;
      this.product = data.product;

      if (data.artwork) {
        this.artwork = {...data.artwork};
        this.descriptionControl.setValue(this.artwork.description);
      }

      this.showCopyQuoteArtworkBtn = this._showCopyQuoteArtworkBtn();
    });
    this._subscriptions$.push(subscription$);

    // update description when value changes
    this.descriptionSubscription = this.descriptionControl.valueChanges
      .pipe(
        debounceTime(250)
      )
      .subscribe(newValue => {

        this.artwork.description = newValue;
        this._store.dispatch(new AddArtwork(this.artwork));

      });

    this._subscriptions$.push(this.descriptionSubscription);

    this._amp.logEvent('Navigation', {
      page: 'artwork',
      navigationStep: 3,
      category: this.category.name,
      category_id: this.category.id,
      productName: this.product.name,
      product_id: this.product.id
    });

    this.tracking.sendAltoCloudData(['pageview', {
      location: location.pathname,
      title: 'Artwork'
    }]);

    subscription$ = this._activatedRoute.queryParams.subscribe(params => {
      if (params && params['uid'] && params['index']) {
        this._editParams = params;
      }
    });
    this._subscriptions$.push(subscription$);

    subscription$ = this._store.pipe(select('shoppingCart')).subscribe((data: IShoppingCart) => {
      data.cartItems.forEach((cartItem) => {
        this.cartItems = this.cartItems.concat(cartItem.cartItemOrders);
      });
    });
    this._subscriptions$.push(subscription$);

    subscription$ = this._store.pipe(select('packagingOrder')).subscribe((data: IPackagingOrder) => {
      this._packagingOrder = data;
    });
    this._subscriptions$.push(subscription$);

  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    if (this.artwork && this.artwork.files && this.artwork.files.length) {
      this.existingArtworkFiles$.next(this.artwork.files);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscriptions$.map(subscription$ => subscription$.unsubscribe());
  }

  // end of lifecycle hooks

  fileUploaded(file: IUploadedFile) {
    this._uploadedFiles.push(file);
    this.artwork.files = this._uploadedFiles;
    this._store.dispatch(new AddArtwork(this.artwork));
  }

  fileDeleted(_fileName: string) {
    this._uploadedFiles = this._uploadedFiles.filter(file => file.name !== _fileName);
    this.artwork.files = this._uploadedFiles;
    if (this.artwork.files.length) {
      this._store.dispatch(new AddArtwork(this.artwork));
    } else {
      this._artworkService.removeArtworkCookie();
      this._store.dispatch(new RemoveArtwork());
    }
  }

  fileError(error: any) {
    this.tracking.sendAltoCloudData(['record', 'artworkUploadError']);
  }

  async nextStep() {
    const hasArtwork = Boolean(this.artwork.files && this.artwork.files.length);

    if (!hasArtwork) {
      this.tracking.sendAltoCloudData(['record', 'userStuck']);
      return;
    }

    this._amp.logEvent('Artwork Next', { state: 'enable' });
    this._store.dispatch(new SetCopyQuoteArtwork(false));

    this.tracking.sendAltoCloudData(['record', 'artwork.selected', hasArtwork]);

    this._store.dispatch(new AddArtwork(this.artwork));

    if (this.product.packageId) {
      await this._navigator.navigate('packaging', this._activatedRoute.snapshot.queryParams);
    } else {
      await this._addToCart();
      await this._navigator.navigate('turnaround', this._activatedRoute.snapshot.queryParams);
    }
  }

  async uploadArtworkLater() {
    this._amp.logEvent('Artwork Next', { state: 'disable' });

    this.tracking.sendAltoCloudData(['record', 'artwork.selected', false]);

    this._store.dispatch(new AddArtwork(this.artwork));

    if (this.product.packageId) {
      await this._navigator.navigate('packaging', this._activatedRoute.snapshot.queryParams);
    } else {
      const hasArtwork = Boolean(this.artwork.files && this.artwork.files.length);

      if (hasArtwork) {
        this.tracking.sendAltoCloudData(['record', 'userStuck']);
        return;
      }
      await this._addToCart();
      await this._navigator.navigate('turnaround', this._activatedRoute.snapshot.queryParams);
    }
  }

  async copyQuoteArtwork() {
    this._store.dispatch(new SetCopyQuoteArtwork(true));
    await this.uploadArtworkLater();
  }

  private _showCopyQuoteArtworkBtn(): boolean {
    if (this._sessionService.isSalesLoggedIn()) {
      try {
        const quoteDataString = sessionStorage.getItem('diy_quote');
        if (quoteDataString) {
          const quoteData: IQuoteData = JSON.parse(quoteDataString);
          if (quoteData && quoteData.customerData && quoteData.customerData) {
            return quoteData.customerData.acceptedSample && (quoteData.customerData.productId === this.product.id);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  nextDisabled() {
    return this.turnaround.selectedOption === undefined ||
      (this.turnaround.selectedOption.isGuaranteed && !this.turnaround.selectedOption.guaranteedDate);
  }

  checkItemInCart(): boolean {
    if (this._editParams && this._editParams['uid']) {
      const index = this.cartItems.findIndex(cartItem => cartItem.uid === this._editParams['uid']);
      return !!~index;
    }
    return false;
  }

  private async _addToCart() {

    const estimatedTurnaround = await this.checkItemHasTurnaround();

    if (this.checkItemInCart()) {
      const cartItemOrder: ICartItemOrder = {
        uid: this._editParams['uid'],
        initialTurnaround: Object.assign(
          {}, this.orderItem.turnaround ?
            this.orderItem.turnaround.selectedOption : estimatedTurnaround.selectedOption
        ),
        orderItem: Object.assign({}, this.orderItem),
        packagingOrder: Object.assign({}, this._packagingOrder)
      };

      this._store.dispatch(new UpdateCartItemOrder({ cartItemOrder, index: this._editParams['index'] }));

      this.tracking.sendAltoCloudData(['record', 'product.updated', this.orderItem.product.name, {
        id: this.orderItem.product.id,
        name: this.orderItem.product.name,
        price: this.orderItem.price.finalTotal,
        category: this.orderItem.category.name,
        quantity: this.orderItem.price.quantity,
      }]);
    } else {
      const generatedUid = Utils.generateUid();
      let instanceOfCombinedShipping = 0;

      this.cartItems.forEach(item => {
        if (item.combinedWith) {
          instanceOfCombinedShipping++;
        }
      });

      this._activatedRoute.snapshot.queryParams = {
        ...this._activatedRoute.snapshot.queryParams,
        uid: generatedUid,
        index: this.cartItems.length !== 0 ? this.cartItems.length - instanceOfCombinedShipping : 0
      };

      const cartItemOrder: ICartItemOrder = {
        uid: generatedUid,
        initialTurnaround: Object.assign(
          {}, this.orderItem.turnaround ?
            this.orderItem.turnaround.selectedOption : estimatedTurnaround.selectedOption
        ),
        orderItem: Object.assign({}, this.orderItem),
        packagingOrder: Object.assign({}, this._packagingOrder)
      };
      const cartItem: ICartItem = {
        cartItemOrders: [cartItemOrder]
      };
      this._store.dispatch(new AddCartItem({ cartItem: cartItem }));
      this.tracking.gtagAction('add_to_cart', { cartItem: cartItem });

      this.tracking.sendAltoCloudData(['record', 'product.added', this.orderItem.product.name, {
        id: this.orderItem.product.id,
        name: this.orderItem.product.name,
        price: this.orderItem.price.finalTotal,
        category: this.orderItem.category.name,
        quantity: this.orderItem.price.quantity,
      }]);
    }
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      'event': 'add_to_cart',
      'ecommerce': {
        'currencyCode': 'USD',
        'value': this.orderItem.price.finalTotal,
        'items': [
          {
            'item_name': this.orderItem.product.name,
            'item_id': this.orderItem.product.name,
            'price': this.orderItem.price.pricePerUnit,
            'item_brand': 'The/Studio',
            'item_category': this.orderItem.category.name,
            'item_variant': this.orderItem.product.variationTypes[0].name,
            'quantity': this.orderItem.price.quantity, // delta not total
            'item_list_id': 'none',
            'item_list_name': 'none',
          }
        ]
        
      }
    });

    this._subscriptions$.map(subscription$ => subscription$.unsubscribe());
  }

  async checkItemHasTurnaround() {

    const artwork = !!this.orderItem.artwork &&
      !!this.orderItem.artwork.files &&
      !!this.orderItem.artwork.files.length;

    const variationsIds = [...this.orderItem.variationTypes, ...this.orderItem.specialOptions]
      .filter(selectedVariation => !!selectedVariation.variation.id)
      .map(selectedVariation => Number(selectedVariation.variation.id));

    const turnaroundEstimatesPayload: ITurnaroundEstimationRequest = {
      product: Number(this.orderItem.product.id),
      quantity: this.orderItem.price.quantity,
      variations: variationsIds,
      artwork: artwork,
    };

    const updatedTAT = await this._productFlowApi.getTurnaroundTime(turnaroundEstimatesPayload);
    const availableOptions = DataTransformerService.createDeliveryOptions(updatedTAT.estimations);

    const skipSampleDefault = environment.skipSampleDefault ? environment.skipSampleDefault : 1000;
    const skipSampleMandatory = environment.skipSampleMandatory ? environment.skipSampleMandatory : 5000;

    let skipSample = false;
    if (this.orderItem.price.finalTotal < skipSampleDefault) {
      skipSample = true;
    }

    if (this.orderItem.price.finalTotal > skipSampleDefault && this.orderItem.price.finalTotal < skipSampleMandatory) {
      skipSample = false;
    }

    if (this.orderItem.price.finalTotal > skipSampleMandatory) {
      skipSample = false;
    }

    const turnaround: ITurnaround = {
      availableOptions: availableOptions,
      skipSample: skipSample,
      selectedOption: availableOptions[0]
    };

    this._store.dispatch(new UpdateTurnaround(turnaround));

    return turnaround;
    // this._store.dispatch(new UpdateTurnaround({
    //   availableOptions: [
    //     {
    //       name: 'standard',
    //       description: 'Standard Turnaround with Standard Shipping',
    //       isGuaranteed: false,
    //       guaranteedDate: undefined,
    //       iconPath: '/assets/img/delivery-standard.svg',
    //       datesInterval: {
    //         normalStart: 1630886400000,
    //         normalEnd: 1631145600000,
    //         ssStart: 1629244800000,
    //         ssEnd: 1629504000000
    //       }
    //     },
    //     {
    //       name: 'rush',
    //       description: 'Rush Production with Upgraded Overnight Shipping',
    //       isGuaranteed: false,
    //       guaranteedDate: undefined,
    //       iconPath: '/assets/img/delivery-rush.svg',
    //       datesInterval: {
    //         normalStart: 1629849600000,
    //         normalEnd: 1630108800000,
    //         ssStart: 1629158400000,
    //         ssEnd: 1629417600000
    //       }
    //     },
    //     {
    //       name: 'urgent',
    //       description: 'Hand-On, Rush Production with Upgraded Urgent Shipping',
    //       isGuaranteed: false,
    //       guaranteedDate: undefined,
    //       iconPath: '/assets/img/delivery-urgent.svg',
    //       datesInterval: {
    //         normalStart: 1629331200000,
    //         normalEnd: 1629590400000,
    //         ssStart: 1628726400000,
    //         ssEnd: 1628985600000
    //       }
    //     }
    //   ],
    //   skipSample: false,
    //   selectedOption: {
    //     name: 'standard',
    //     description: 'Standard Turnaround with Standard Shipping',
    //     isGuaranteed: false,
    //     guaranteedDate: undefined,
    //     iconPath: '/assets/img/delivery-standard.svg',
    //     datesInterval: {
    //       normalStart: 1630886400000,
    //       normalEnd: 1631145600000,
    //       ssStart: 1629244800000,
    //       ssEnd: 1629504000000
    //     }
    //   }
    // }));

    if (this.cartItems.find(item => item.orderItem.quoteId === this.orderItem.quoteId)) {

      this.orderItem = {
        ...this.orderItem,
        turnaround: this.cartItems.find(item => item.orderItem.quoteId === this.orderItem.quoteId).orderItem.turnaround
      };
    }
  }
}
