import { IDraft } from '../draft/IDraft';

export interface IDraftRequest {
  quoteId: number;
  draft: IDraft;
  type: DraftRequestType;
}

export enum DraftRequestType {
  diyDraft = 'diy',
  pdpDraft = 'pdp'
}
