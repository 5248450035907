<div *ngIf="isLoggedIn()" class="notes">
  <div class="notes__header" (click)="toggle()">
    <h6 *ngIf="isCollapsed; else expanded"><img src="assets/img/up-chevron.svg" /></h6>
    <h6>Script</h6>
  </div>
  <div class="notes__body" [hidden]="isCollapsed">
    <div class="notes__text">
      {{ notes }}
    </div>
  </div>
</div>

<ng-template #expanded>
  <img src="assets/img/down-chevron.svg" />
</ng-template>
