<diy-simple-sidebar title="Invoice Summary">
  <button class="continue-shopping" routerLink="/"> << Continue Shopping</button>
  <diy-payment-summary-item *ngFor="let summaryItem of summaryData?.items" [summaryItem]="summaryItem">
  </diy-payment-summary-item>

  <div class="flex-row tax" *ngIf="resellerTaxActive && summaryData?.taxFee">
    <span>Tax:
      <div class="tax-notice">
        <img src="/assets/img/icon-info.svg" alt="">
        <small>Tax fee is to be determined in checkout.</small>
      </div>
    </span>
    <span>{{ (summaryData?.taxFee | currency:'USD') || 'TBD' }}</span>
  </div>

  <div class="flex-row final-price">
    <span>Final Price:</span>
    <span
      *ngIf="summaryData?.taxFee && resellerTaxActive; else showTotalOnly">{{ (summaryData?.total + summaryData?.taxFee) | currency:'USD' }}</span>
  </div>

  <div class="payment_trustmarks">
    <diy-trustmarks></diy-trustmarks>
  </div>
  <button class="edit" routerLink="/shop/cart">
    <img src="../assets/img/pen.svg" alt="">
    Edit order</button>
</diy-simple-sidebar>

<diy-trustmarks></diy-trustmarks>


<ng-template #showTotalOnly>
  <span>{{ summaryData?.total | currency:'USD' }}</span>
</ng-template>
