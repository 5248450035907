import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { Store, select } from '@ngrx/store';

import { OrderMessengerService } from '../../../../core/services/order-messenger';

import {
  ClearShoppingCart,
  SetInvoiceId,
  SetTaxFee,
  SetResellerLicense
} from '../../../../state-manager/shopping-cart/shopping-cart.actions';

import { InvoiceApi } from '../../../core/api/invoiceApi';

import { IShoppingCart } from '../../../../types/shopping-cart/IShoppingCart';
import { IAppState } from '../../../../types/app-state/IAppState';

@Component({
  templateUrl: './sales-success.component.html',
  styleUrls: ['./sales-success.component.scss']
})
export class SalesSuccessComponent implements OnInit, OnDestroy {

  errors$: Subject<string> = new Subject();

  success$: Subject<string> = new Subject();

  invoiceId: string;

  private _subscription$: Subscription;

  constructor(
    private _store: Store<IAppState>,
    private _oms: OrderMessengerService,
    private _invoiceApi: InvoiceApi,
  ) {
    this._oms.updateStep(0);
  }

  ngOnInit(): void {
    this._subscription$ = this._store.pipe(select('shoppingCart'))
      .subscribe((data: IShoppingCart) => {
        this.invoiceId = data.invoiceId;
      });

    if (this.invoiceId) {
      this._subscription$.unsubscribe();
      this._store.dispatch(new ClearShoppingCart());
    }
  }

  ngOnDestroy(): void {
    this._subscription$.unsubscribe();
    this._store.dispatch(new ClearShoppingCart());
    this._store.dispatch(new SetInvoiceId(''));
    this._store.dispatch(new SetTaxFee(undefined));
    this._store.dispatch(new SetResellerLicense(undefined));
  }

  async downloadInvoice() {
    const resp = await this._invoiceApi.downloadInvoice(this.invoiceId);

    if (!resp.success) {
      return this.errors$.next(resp.message);
    }

    this._showPdf(resp.fileName, resp.pdfOutput);
  }

  private _showPdf(fileName, data) {
    const linkSource = 'data:application/pdf;base64,' + data;
    const downloadLink = document.createElement('a');

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }
}
