import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { AmplitudeService } from '../../../core/services/amplitude';

import { ISearchResult } from '../../../types/search/ISearchResult';

@Component({
  selector: 'diy-search-result',
  template: '<div class="search-result" (click)="selectResult()">{{result.name}}</div>',
  styleUrls: ['./search-result.component.scss']
})
export class SearchResultComponent {

  @Input() result: ISearchResult;

  constructor(private _amp: AmplitudeService, private _router: Router) {
  }

  selectResult() {
    this._amp.logEvent('Search Type', {
      selectedResult: this.result.id
    });

    window.dataLayer.push({
      'event': 'search-complete',
      'searchTerm': this.result.name
    });

    switch (this.result.type) {
      case 'product':
        this._router.navigate(['/category', this.result.categoryId, 'product', this.result.id, 'customize']);
        break;

      case 'category':
      default:
        this._router.navigate(['/category', this.result.id]);
        break;
    }

  }
}
