<div class="shopping-cart__item-body package-option">
  <img class="shopping-cart__item-icon" src="../../../../assets/img/icon-24-packaging-black.png" alt="">
  <div class="shopping-cart__item-avatar">
    <img [src]="artworkUrl" alt="">
  </div>
  <div class="shopping-cart__item-products full-width">
    <label>Packaging Added</label>


    <p *ngIf="packageSize" class="package-type">
      <span>Size: </span>
      <span *ngIf="packageSize.variation?.name !== 'Custom Size'; else customSize">
        {{ packageSize.variation?.name }}
      </span>

      <ng-template #customSize>
        <span>
          {{packageSize.variation?.width}}" x {{packageSize.variation?.height}}"
        </span>
      </ng-template>
    </p>

    <br />

    <p *ngIf="packageType" class="package-type">
      <span>Package Type - </span>
      <span>
        {{packageType.variation.name}}
        <span *ngIf="getPrice(packageType.variation) > 0; else free">
          ({{getPrice(packageType.variation) | currency:'USD'}})
        </span>
      </span>
    </p>

    <p *ngIf="packageOption" class="package-option">
      <span>Package Options - </span>
      <span>
        {{packageOption.variation.name}}
        <span *ngIf="getPrice(packageOption.variation) > 0; else free">
          ({{getPrice(packageOption.variation) | currency:'USD'}})
        </span>
      </span>
    </p>
  </div>
  <div class="shopping-cart__item-actions">
    <button (click)="editPackaging()" class="shopping-cart__btn-edit">Edit Packaging Options</button>
    <button (click)="openDeletePackageModal()">Delete Packaging Option</button>
  </div>
</div>

<ng-template #free>
  (FREE)
</ng-template>

<ts-confirm #confirmModal className="confirm-modal" title="Confirm Removal"
  subtitle="You'll lose the selections you've created for your package. Are you sure you want to remove?"
  confirmText="Remove">
</ts-confirm>
