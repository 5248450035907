<!-- Header -->
<div class="header-content">
  <diy-header></diy-header>
</div>

<!-- Page content -->
<div class="container">
  <div class="order-confirmation">

    <div class="image">
      <img src="/assets/img/Shoppingcarticon.svg" alt="Shopping Cart Icon">
    </div>

    <h1 class="order-confirmation__title">Order successfully placed.</h1>

    <div class="order-confirmation__informations">
      <p>Thank you. Your order of {{bundle?.name}} has been confirmed and has been added to your account. Start using
        your package by creating a project now.</p>
      <p>Invoice ID: <span class="invoice-id">{{bundle?.invoiceId}}</span> </p>
    </div>

    <div class="actions">
      <button class="button button--green cta cta-new" type="button" routerLink="/">Start a Project</button>
      <a class="button button--green cta cta-dash" [href]="dashboardUrl" target="_blank">Go to Dashboard</a>
    </div>

  </div>
</div>
