import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";

// Base component
import { IdleBaseComponent } from "../../core/components/idleTracking/idle.component";

// Provider
import { ProductFlowApi } from "../../core/api/productFlowApi";

// Services
import { AmplitudeService } from "../../core/services/amplitude";
import { DraftService } from "../../core/services/draft.service";
import { CookieService } from "../../core/services/cookie.service";
import { CampaignTrackingService } from "../../core/services/campaign-tracking.service";
import { SessionService } from "../../utility/session.service";
import { TrackingService } from "../../core/services/tracking";

// State management
import { UpdateCategories } from "../../state-manager/categories/categories.actions";

// Types
import { ICustomerInfo } from "../../types/quote/IQuote";
import { ProductOrderSteps } from "../../types/step/IStep";
import { ICategory } from "../../types/category/ICategory";
import { IProduct } from "../../types/product/IProduct";
import { IAppState } from "../../types/app-state/IAppState";

declare global {
  interface Window {
    dataLayer: any;
  }
}

@Component({
  selector: "diy-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent extends IdleBaseComponent implements OnInit {
  categories: ICategory[] = [];
  featuredProducts: IProduct[] = [];

  // Define current step for step-tracker
  currentStep = ProductOrderSteps.product;
  innerWidth: number;

  carouselOptions: any = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    nav: true,
    autoplay: false,
    autoplayTimeout: 999999999,
    autoWidth: true,
    margin: 28,
    items: 3,
    navText: [
      '<i class="fa fa-long-arrow-left"></i>',
      '<i class="fa fa-long-arrow-right"></i>',
    ],
  };

  readonly WPREF = "ref";

  constructor(
    protected tracking: TrackingService,
    protected router: Router,
    protected session: SessionService,
    protected cookies: CookieService,
    private _amp: AmplitudeService,
    private _draftService: DraftService,
    private _route: ActivatedRoute,
    private _productFlowApi: ProductFlowApi,
    private _store: Store<IAppState>,
    private _campaignTrackingService: CampaignTrackingService
  ) {
    super(tracking, router, session, cookies);

    this._campaignTrackingService.handleCampaignTracking(
      this._route.snapshot.queryParams
    );
  }

  async ngOnInit() {
    (window as any).dataLayer = window.dataLayer || [];

    this._amp.logEvent("Navigation", {
      page: "homepage",
      navigationStep: 1,
    });

    this.tracking.sendAltoCloudData([
      "pageview",
      {
        location: location.pathname,
        title: "Homepage",
      },
    ]);

    await this._getProducts();

    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 1280) {
      this.carouselOptions.items = 4;
      this.carouselOptions.margin = 28;
    }

    await this._checkDrafts();
    const wpRef = this._route.snapshot.queryParams[this.WPREF];
    // set segmentation flag;
    this.session.segmentation = !!wpRef;

    window.dataLayer.push({
      event: "product-impression",
      ecommerce: {
        currencyCode: "USD", // optional, but including this allows GA to auto convert to USD
        impressions: this.getNumberedProducts(),
      },
    });

    this.checkUserIsLoggedIn();
  }

  goToCategory(id: number, name: string) {
    this.tracking.sendAltoCloudData([
      "record",
      "category.selected",
      id,
      {
        id,
        name,
      },
    ]);

    return this.router.navigate(["/category", id]);
  }

  async _getProducts() {
    this.categories = await this._productFlowApi.getStructuredProducts();

    // update store with categories data to save requests for view page
    this._store.dispatch(new UpdateCategories(this.categories));

    this.categories.forEach((category) => {
      const categoryFeaturedProducts = category.products.filter(
        (product) => product.featured && product.id !== 198
      ); //Exclude face masks

      if (categoryFeaturedProducts.length > 0) {
        this.featuredProducts = this.featuredProducts.concat(
          categoryFeaturedProducts
        );
      }
    });

    if (this.featuredProducts.length > 0) {
      this.featuredProducts.sort(
        (a, b) => Number(a.featuredOrder) - Number(b.featuredOrder)
      );
    }
  }

  private async _checkDrafts() {
    if (this._route.snapshot.queryParams.skipDraft) {
      return;
    }

    // Check for drafts
    const customerInfo: ICustomerInfo = this.cookies.getJson("customerInfo");
    if (customerInfo && customerInfo.email) {
      let hasDrafts = false;

      hasDrafts = await this._draftService.hasDrafts(customerInfo.email);

      // if there was no draft in cookies or from server data go to artwork page
      if (hasDrafts) {
        // hard-coded values for category and product
        return this.router.navigate(["/category", 3, "product", 92, "draft"], {
          queryParams: { skip: true },
        });
      }
    }
  }

  getProductCategory(product: IProduct): ICategory {
    return this.categories.find(
      (category) =>
        !!category.products.find((catProduct) => catProduct.id === product.id)
    );
  }

  getNumberedProducts() {
    const numberedProducts = [];
    let position = 1;

    for (const category of this.categories) {
      for (const product of category.products) {
        numberedProducts.push({
          name: product.name, // required
          id: product.id, // required
          price: 0, // required
          brand: "The/Studio", // likely The/Studio
          category: category.name, // product category
          variant: product.variationTypes, // either new/renewal or sub time or both
          position: position, // position on the page, ensure each page tracks position the same way
        });
        position++;
      }
    }

    return numberedProducts;
  }

  checkUserIsLoggedIn() {
    const user = this.cookies.getJson("quotes")
      ? this.cookies.getJson("quotes")[0].customerId
      : undefined;

    if (user) {
      window.dataLayer.find(
        (element) => element.event === "dataLayer-initialized"
      ).userId = user;
      window.dataLayer.find(
        (element) => element.event === "dataLayer-initialized"
      ).loggedIn = "y";
      sessionStorage.setItem("userId", user);
    }
  }
}
