<div class="steps-container" [class.isMinimized]="minimizeSteps">
  <ul>
    <li *ngFor="let step of steps;" [ngClass]="{
      'step-ready': activeStep.number > step.number,
      'step-reached': activeStep.number === step.number,
      'partial-step': isPartialStep(step)
    }" (click)="navigateToStep(step)" >
      <span class="step-number">{{ step.number }}</span>
      <span class="step-name">{{ step.name }}</span>
      <div class="step-bar">&nbsp;</div>
    </li>
  </ul>
</div>
