import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Params } from '@angular/router';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { take } from 'rxjs/operators';

// Env
import { environment } from '../../../../environments/environment';

// API Providers
import { PackagingProvider } from '../api/packaging.provider';

// Services
import { VariationManager } from '../../../core/services/variation-manager.service';
// Use order item resolver as a service to pre-resolve data
import { OrderItemResolver } from '../../../core/resolvers/order-item.resolver';

// State Management Actions
import {
  SelectProduct,
  ReplacePackagingVariations,
  AddPackagingArtwork,
} from '../../../state-manager/packaging-order/packaging-order.actions';

// Types
import { IAppState } from '../../../types/app-state/IAppState';
import { IPackagingOrder } from '../../../types/app-state/IPackagingOrder';
import { ISelectedVariation } from '../../../types/app-state/ISelectedVariation';
import { CookieService } from '../../../core/services/cookie.service';
import { IArtwork, ArtworkCookieType } from '../../../types/artwork/IArtwork';
import { PackagingVariationTypeTypes } from '../../../types/product/variations/IVariationType';

@Injectable()
export class PackagingResolver implements Resolve<Observable<IPackagingOrder>> {

  private _packagingOrder$: Observable<IPackagingOrder> = this._store.select('packagingOrder');

  constructor(
    private _store: Store<IAppState>,
    private _orderItemResolver: OrderItemResolver,
    private _packagingProvider: PackagingProvider,
    private _cookieService: CookieService,
  ) { }


  async resolve(route: ActivatedRouteSnapshot): Promise<Observable<IPackagingOrder>> {
    const orderItem$ = await this._orderItemResolver.resolve(route);

    const orderItem = await orderItem$.pipe(take(1)).toPromise();

    if (!orderItem.product.packageId) {
      return;
    }

    // Wait to solve state and return app state from store
    const orderItemQty = VariationManager.getQuantityFromStack(orderItem.variationTypes);
    await this._updateState(route.queryParams, orderItem.product.packageId, orderItemQty);

    return this._store.pipe(select('packagingOrder'));
  }


  /**
   * Update state of the app from URL params
   */
  private async _updateState(
    queryParams: Params,
    packageId: number,
    quantity: number
  ): Promise<void> {
    return new Promise<void>(async (resolve) => {

      let packagingOrder: IPackagingOrder;

      this._packagingOrder$.subscribe(currentPackagingOrder => { packagingOrder = currentPackagingOrder; });

      // Update product in state
      if (!packagingOrder.product || packagingOrder.product.id !== packageId) {
        const product = await this._packagingProvider.getProduct(packageId);
        if (product && product.id) {
          const artwork = this._loadArtworkFromCookie(product.id);
          this._store.dispatch(new AddPackagingArtwork(artwork));
        }
        this._store.dispatch(new SelectProduct(product));
      }

      // Packaging Selctions
      if (packagingOrder.product && packagingOrder.product.variationTypes.length) {
        let selectedVariations: ISelectedVariation[] = [];

        let updated = false;
        if (queryParams['packagingSelections']) {
          updated = true;

          // fetch selected variations and reconstruct state
          selectedVariations = queryParams['packagingSelections']
            .split(',')
            .map(variationId => {
              const foundVariationType = packagingOrder.product.variationTypes
                .find(variationType => !!~variationType.variations.findIndex(variation => variation.id === Number(variationId)));

              const foundVariation = foundVariationType.variations.find(variation => variation.id === Number(variationId));

              if (foundVariation) {
                return VariationManager.getSelectedVariation(foundVariation, foundVariationType, packagingOrder.incompatibilities);
              }
            });

        }

        // custom size
        if (queryParams['cp_height'] && queryParams['cp_width']) {
          updated = true;
          const customWidth = Number(queryParams['cp_width']);
          const customHeight = Number(queryParams['cp_height']);
          const sizeVariationType = packagingOrder.product.variationTypes.find(vt => vt.category === PackagingVariationTypeTypes.size);
          const customSizeVariation = VariationManager.createSelectedCustomSize(customWidth, customHeight, sizeVariationType);
          selectedVariations.push(customSizeVariation);
        }

        if (updated) {
          // quantity
          if (quantity) {
            const qtyVariationType = packagingOrder.product.variationTypes.find(vt => vt.category === PackagingVariationTypeTypes.quantity);
            if (!~selectedVariations.findIndex(qtyVariation => qtyVariation.vtID === qtyVariationType.id)) {
              const matchingQtyVariation = qtyVariationType.variations.find(variation => variation.name === String(quantity));
              let selectedQtyVariation: ISelectedVariation;
              if (matchingQtyVariation) {
                selectedQtyVariation = VariationManager.getSelectedVariation(matchingQtyVariation,
                  qtyVariationType,
                  packagingOrder.incompatibilities);
              } else {
                selectedQtyVariation = VariationManager.createSelectedCustomQty(quantity, qtyVariationType);
              }
              selectedVariations.push(selectedQtyVariation);
            }
          }
          this._store.dispatch(new ReplacePackagingVariations(selectedVariations));
        } else {
          this._store.dispatch(new ReplacePackagingVariations([]));
        }
      }

      resolve();
    });
  }

  /**
   * Load the artwork from the artworkData cookie
   */
  private _loadArtworkFromCookie(packagingProductId: number): IArtwork {
    const artworkData = this._cookieService.getJson(ArtworkCookieType.PACKAGING);
    if (artworkData && artworkData.data && artworkData.data.file) {
      if (packagingProductId === Number(artworkData.packageId)) {
        return artworkData.data;
      } else {
        this._cookieService.delete(ArtworkCookieType.PACKAGING);
      }
    }
  }
}
