<div class="form-login">
  <div class="form-login-inner">
    <form [formGroup]="loginForm">

      <h1 class="form-control-title">{{ title }}</h1>
      <h2 class="form-control-welcome-message" *ngIf="subtitle">
        {{ subtitle }}
      </h2>

      <div *ngIf="!hasEmail">
        <div class="form-control-wrapper">
          <label class="form-control-label">
            <span>Email:</span>
            <input type="email" formControlName="email" class="form-control">
          </label>
        </div>

      </div>

      <div *ngIf="hasEmail">
        <div class="user-view">
          <div class="user-name">{{user?.firstName}} {{user?.lastName}}</div>
          <button (click)="changeEmail()" class="change-email" type="button">Not you?</button>
          <div class="user-email">{{user?.email}}</div>
        </div>
      </div>

      <div class="form-control-wrapper">
        <label class="form-control-label">
          <span>Password:</span>
          <input [type]="showPassword ? 'password' : 'text'" formControlName="password" class="form-control">
        </label>
        <button class="pwd-toggle" (click)="togglePassword()" type="button">
          <span class="pwd-toggle-icon"><span></span></span>
        </button>
      </div>

      <div class="checkbox-wrap">
        <input type="checkbox" id="rememberMe" formControlName="rememberMe" />
        <label for="rememberMe">
          <span class="checkbox-icon"></span> Remember Me </label>
      </div>

      <a [routerLink]="[ '/shop/forgot' ]" class="forgot-pwd">Forgot Password?</a>
      <p *ngIf="invalidCredentialsError" class="invalid-login">Invalid credentials.</p>
      <button class="form-control-btn" (click)="login()" [disabled]="!loginForm.valid || isLoading">
        <span *ngIf="!isLoading; else loading">{{ continueText }}</span>
      </button>
    </form>
  </div>
</div>

<ng-template #loading>
  <span>Loading...</span>
</ng-template>
