import { IUploadedFile } from './IUploadedFile';

/**
 * @author Liviu Dima
 */
export interface IArtwork {
  files: IUploadedFile[] | undefined;
  description: string;
}

export enum ArtworkCookieType {
  ORDER = 'artworkData',
  PACKAGING = 'packagingArtworkData'
}

export interface IArtworkCookie {
  productId?: number;
  packageId?: number;
  data: IArtwork;
}
