import { Component, ElementRef, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import { environment } from '../../../../../../environments/environment';

// Base component
import { PaymentItemBaseComponent } from '../../payment-item-base.component';

// Services
import { CookieService } from '../../../../../core/services/cookie.service';
import { CustomerDataProvider } from '../../../../core/api/CustomerDataProvider';
import { SessionService } from '../../../../../utility/session.service';
import { ComStr } from '../../../../../core/services/ComStr';

import { SetTaxFee } from '../../../../../state-manager/shopping-cart/shopping-cart.actions';

// Types
import { UserRole } from '../../../../../types/user/IUser';
import { IShippingData } from '../../../../../types/payment/IFormState';
import { ICustomerInfo } from '../../../../../types/quote/IQuote';
import { IAppState } from '../../../../../types/app-state/IAppState';
import { IShoppingCart } from '../../../../../types/shopping-cart/IShoppingCart';

const US_VARIANTS = ['us', 'usa', 'u.s.', 'u.s.a.', 'united states', 'united states of america', 'america'];
const CA_VARIANTS = ['ca', 'cal', 'cali', 'calif', 'cal.', 'cali.', 'calif.', 'us-ca', 'california'];

/**
 * @author Liviu Dima
 */
@Component({
  selector: 'diy-shipping-address-form',
  templateUrl: './shipping-address-form.component.html',
  styleUrls: ['../address-form.component.scss']
})
export class ShippingAddressFormComponent extends PaymentItemBaseComponent implements OnInit, OnChanges {
  form: FormGroup;

  @Input() customerShippingData: IShippingData[];

  showTaxNotice = false;

  selectedAddress: IShippingData;

  showAddressForm = true;

  isLoggedIn = false;

  validLicense = false;

  // card data validators definition
  private _shippingValidators = {
    firstName: Validators.compose([
      Validators.required,
      Validators.minLength(2)
    ]),
    lastName: Validators.compose([
      Validators.required,
      Validators.minLength(2)
    ]),
    address: Validators.compose([
      Validators.required,
      Validators.minLength(2)
    ]),
    country: Validators.compose([
      Validators.required,
      Validators.minLength(2)
    ]),
    city: Validators.compose([
      Validators.required,
      Validators.minLength(2)
    ]),
    state: Validators.compose([
      Validators.required,
      Validators.minLength(2)
    ]),
    zip: Validators.compose([
      Validators.required,
      Validators.minLength(2)
    ]),
    phone: Validators.compose([
      Validators.required,
      Validators.minLength(2),
      Validators.pattern(ComStr.PHONE_REGEX)
    ])
  };

  private _email: string;

  /**
   * Class constructor
   * @param _formBuilder
   * @param el
   */
  constructor(
    protected el: ElementRef,
    private _formBuilder: FormBuilder,
    private _cookieService: CookieService,
    private _customerApi: CustomerDataProvider,
    private _sessionService: SessionService,
    private _store: Store<IAppState>,
  ) {
    super(el);

    this.form = this._formBuilder.group({
      blindShipping: [false],
      firstName: ['', this._shippingValidators.firstName],
      lastName: ['', this._shippingValidators.lastName],
      address: ['', this._shippingValidators.address],
      country: ['', this._shippingValidators.country],
      city: ['', this._shippingValidators.city],
      state: ['', this._shippingValidators.state],
      zip: ['', this._shippingValidators.zip],
      phone: ['', this._shippingValidators.phone],
      id: [''],
      saveShipping: [''],
    });

    let firstName = '';
    let lastName = '';

    // first check if a user is logged in
    if (this._sessionService.isLoggedIn() && !this._sessionService.isGranted(UserRole.SALES)) {
      this.isLoggedIn = true;
      // populate name field from session
      firstName = this._sessionService.user.firstName;
      lastName = this._sessionService.user.lastName;
      this._email = this._sessionService.user.email;
    } else {
      const customerInfo: ICustomerInfo = this._cookieService.getJson('customerInfo');
      // if we have no quote info, add email input
      if (!customerInfo || !customerInfo.email) {
        this.form.addControl('email',
          this._formBuilder.control('', [
            Validators.required,
            Validators.pattern(ComStr.EMAIL_REGEX)
          ]));
      } else {
        firstName = customerInfo && customerInfo.firstname ? customerInfo.firstname : '';
        lastName = customerInfo && customerInfo.lastname ? customerInfo.lastname : '';
        this._email = customerInfo.email;
      }
    }

    this.form.controls['firstName'].setValue(firstName);
    this.form.controls['lastName'].setValue(lastName);
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.data && !this.isLoggedIn) {
      this.form = this._loadFormFromDraft(this.form, this.data);
    }

    if (!this.isLoggedIn && !this._sessionService.isSalesLoggedIn()) {
      this.form.valueChanges
        .pipe(
          debounceTime(250)
        )
        .subscribe(newChange => {
          // get data from cookies
          const paymentData = this._cookieService.getJson('paymentData');

          const newDraft = Object.assign((paymentData ? paymentData : {}), { shippingData: this.form.getRawValue() });
          // update new data in cookies
          this._cookieService.setJson('paymentData', newDraft, 14);
        });
    }

    const subscription$ = this.form.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(
          (a, b) => (a.state === b.state) && (a.country === b.country) && (a.city === b.city)
        )
      )
      .subscribe(async (formValues) => {
        await this._checkIfCaliforniaState(formValues);
      });
    this._subscriptions$.push(subscription$);

    const phoneChanges$ = this.form.get('phone').valueChanges.subscribe(val => {
      const normalizedValue = this._normalizePhoneNumber(val);
      this.form.get('phone').setValue(normalizedValue, { emitEvent: false });
    });
    this._subscriptions$.push(phoneChanges$);

    this._checkIfCaliforniaState(this.form.value);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['customerShippingData'] && this.customerShippingData) {
      this.toggleShippingForm(false);
    }
  }

  chooseAddress(address: IShippingData) {
    this.selectedAddress = address;
    this.form.patchValue(address);
    this.isFormValid(this.form);
    if (!this.form.valid) {
      this.errorMessage = 'Invalid shipping address';
      return;
    }
    this.errorMessage = '';
  }

  toggleShippingForm(flag: boolean) {
    this.showAddressForm = flag;

    this._resetValidators();

    if (this.showAddressForm) {
      this.selectedAddress = null;
      window.scroll({ top: 0, behavior: 'smooth' });
      return this._updateValidators(this.form.controls, this._shippingValidators);
    }

    return this.form.controls.id.setValidators(Validators.required);
  }

  async saveAddress() {
    if (!this.form.valid) {
      // iterate through inputs and mark them as touched for validation
      Object.keys(this.form.controls).forEach(field => {
        const control = this.form.get(field);
        control.markAsTouched({ onlySelf: true });
      });
      return;
    }
    this.isLoading = true;

    const form = this.form.value;
    const shippingDetails = {
      blindShipping: !!form.blindShipping,
      name: `${form.firstName.trim()} ${form.lastName.trim()}`,
      address: form.address.trim(),
      country: form.country.trim(),
      city: form.city.trim(),
      state: form.state.trim(),
      zip: form.zip.trim(),
      phone: form.phone.trim(),
      id: form.id,
      saveShipping: form.saveShipping,
    };

    let resp = null;
    if (this._sessionService.isGranted(UserRole.SALES)) {
      const customerSesData = JSON.parse(sessionStorage.getItem('diy_quote'));
      const customerId = customerSesData.customerData.customerId;
      resp = await this._customerApi.saveShippingAsRep(String(customerId), shippingDetails);
    } else {
      resp = await this._customerApi.saveShipping(shippingDetails);
    }

    // add response id to form data
    if (resp && resp.id) {
      shippingDetails.id = resp.id;

      // update shipping list
      this.customerShippingData.push(shippingDetails);

      // mark it as selected
      this.chooseAddress(shippingDetails);

      // switch to list view
      this.toggleShippingForm(false);
      this.isLoading = false;
    }
  }

  async taxFileDeleted(event) {
    if (event) {
      await this._setCaliforniaTax(this.form.value.city, this.form.value.state);
    }
  }

  private _resetValidators() {

    const controls = this.form.controls;

    for (const key in controls) {
      if (controls.hasOwnProperty(key)) {
        controls[key].setValidators([]);
      }
    }
  }

  /**
   * Set validators
   * @param controls
   * @param validators
   */
  private _updateValidators(controls: Object, validators: Object) {
    for (const key in controls) {
      if (controls.hasOwnProperty(key)) {
        controls[key].setValidators(validators[key]);
      }
    }
  }

  private _normalizePhoneNumber(val: string): string {
    // Removing spaces, parenthesis, and dashes
    return val.replace(/[ \-\(\)]/g, '');
}

  /**
   * Check if user's address is in state of California
   */
  private async _checkIfCaliforniaState(formValues) {
    if (!environment.resellerTaxActive) {
      return;
    }

    const state: string = formValues.state.trim();
    const country: string = formValues.country.trim();
    const city: string = formValues.city.trim();
    const showTaxNotice = CA_VARIANTS.includes(state.toLowerCase()) &&
      US_VARIANTS.includes(country.toLowerCase());
    this.showTaxNotice = showTaxNotice;
    if (showTaxNotice) {
      await this._setCaliforniaTax(city, state);
    } else {
      this._store.dispatch(new SetTaxFee(undefined));
    }
  }

  private async _setCaliforniaTax(city: string, state: string) {
    let uploadedTaxFile;

    this._store.pipe(
      select('shoppingCart'),
      take(1)
    ).subscribe((data: IShoppingCart) => {
      uploadedTaxFile = data.resellerLicense;
    });

    if (this._email) {
      this.validLicense = (await this._customerApi.checkResellerLicense(this._email)).validLicence;
    }

    if (uploadedTaxFile || this.validLicense) {
      return this._store.dispatch(new SetTaxFee(undefined));
    }

    if (!this.validLicense) {
      const taxRate = await this._customerApi.getTaxRate({
        city: city,
        state: state
      });
      this._store.dispatch(new SetTaxFee(taxRate));
    }
  }

  ngOnDestroy() {
    this._subscriptions$.forEach(sub => sub.unsubscribe());
  }
}
