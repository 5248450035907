import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Params, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { take } from 'rxjs/operators';

// API Provider
import { ProductFlowApi } from '../api/productFlowApi';

// State Management Actions
import {
  UpdateCategory,
  UpdateReorderId,
  UpdateFirstInquiry,
  UpdateProduct,
  ReplaceVariations,
  RemoveVariations,
  ReplaceSpecialOptions,
  RemoveSpecialOptions,
  UpdateTurnaround,
  RemoveTurnaround,
  UpdateQuoteId,
  AddBundleItem,
  RemoveBundleItem,
  AddArtwork
} from '../../state-manager/order-item/order-item.actions';
import { ResetPackagingOrder } from '../../state-manager/packaging-order/packaging-order.actions';

// Services
import { VariationManager } from '../../core/services/variation-manager.service';
import { DataTransformerService } from '../../core/api/data-transformer.service';
import { BundleProvider } from '../../core/api/BundleProvider';
import { CookieService } from '../services/cookie.service';
import { SessionService } from '../../utility/session.service';

// Types
import { IOrderItem } from '../../types/app-state/IOrderItem';
import { IAppState } from '../../types/app-state/IAppState';
import { ISelectedVariation } from '../../types/app-state/ISelectedVariation';
import { AvailableVariationTypeTypes } from '../../types/product/variations/IVariationType';
import { ITurnaround } from '../../types/turnaround/ITurnaround';
import { ITurnaroundEstimationRequest, ITurnaroundEstimationBase } from '../../types/turnaround/ITurnaroundEstimationRequest';
import { IArtwork, ArtworkCookieType } from '../../types/artwork/IArtwork';
import { ICustomerInfo } from '../../types/quote/IQuote';

/**
 * @author Liviu Dima
 */
@Injectable()
export class OrderItemResolver implements Resolve<Observable<IOrderItem>> {

  private _orderItem$: Observable<IOrderItem> = this._store.select('orderItem');

  constructor(
    private _store: Store<IAppState>,
    private _router: Router,
    private _productFlowApi: ProductFlowApi,
    private _bundleProvider: BundleProvider,
    private _cookieService: CookieService,
    private _sessionService: SessionService,
  ) { }

  async resolve(route: ActivatedRouteSnapshot): Promise<Observable<IOrderItem>> {
    const currentRoute = this._determineCurrentRoute(route);

    // Wait to solve state and return app state from store
    await this._updateState(route.params, route.queryParams, currentRoute);

    return this._store.pipe(select('orderItem'));
  }


  /**
   * Update state of the app from URL params
   */
  private async _updateState(params: Params, queryParams: Params, currentRoute: string): Promise<void> {
    return new Promise<void>(async (resolve) => {

      let orderItem: IOrderItem;

      this._orderItem$.subscribe(currentOrderItem => {
        orderItem = currentOrderItem;
      });

      // take categoryId from URL
      const categoryId = Number(params['categoryId']);
      if (!categoryId) {
        throw new Error('Could not recreate state due to lacking categoryId.');
      }

      // Update category in state
      if (!orderItem.category || orderItem.category.id !== categoryId) {
        const categories = await this._productFlowApi.getCategoryList().toPromise();
        const foundCategory = categories.find(category => category.id === categoryId);

        this._store.dispatch(new UpdateCategory(foundCategory));
        this._store.dispatch(new ResetPackagingOrder());
      }

      const reorder = !!queryParams['reorder'];

      const reorderId = queryParams['reorderId'];
      if (reorderId) {
        this._store.dispatch(new UpdateReorderId(Number(reorderId)));
      }

      const firstInquiry = queryParams['firstInquiry'];
      if (firstInquiry) {
        this._store.dispatch(new UpdateFirstInquiry(firstInquiry));
      }

      const quoteId = queryParams['quoteId'];
      if (quoteId) {
        const customerInfo: ICustomerInfo = this._cookieService.getJson('customerInfo');
        const quoteInfo = {
          quoteId: Number(quoteId),
          customerEmail: customerInfo.email || ''
        };
        this._store.dispatch(new UpdateQuoteId(quoteInfo));
      }

      if (currentRoute === 'category' || currentRoute === 'product') {
        return resolve();
      }

      const productId = Number(params['productId']);
      if (!productId) {
        throw new Error('Could not recreate state due to lacking productId.');
      }
      // Trim queryParams by component name for regular orders (not reorder)
      // Redirects to same route without unnecessary params
      if (this._trimParams(currentRoute, queryParams, categoryId, productId)) {
        return resolve();
      }

      // Update product in state
      if (!orderItem.product || orderItem.product.id !== productId) {
        const product = await this._productFlowApi.getProduct(productId);
        if (product && product.id) {
          const artwork = this._loadArtworkFromCookie(product.id);
          if (artwork) {
            this._store.dispatch(new AddArtwork(artwork));
          }
        }
        this._store.dispatch(new UpdateProduct(product));
        this._store.dispatch(new ResetPackagingOrder());
      }

      if (queryParams['cd_origin']) {
        this._sessionService.CDOrigin = 'true';
      }

      // Customize -> Update state with selected variations from URL
      if (orderItem.product && orderItem.product.variationTypes.length) {

        let selectedVariations: ISelectedVariation[] = [];

        let updated = false;

        if (queryParams['selections_vt']) {
          updated = true;
          // fetch selected variations and reconstruct state

          selectedVariations = queryParams['selections_vt']
            .split(',')
            .map(variationId => {
              const foundVariationType = orderItem.product.variationTypes
                .find(variationType => !!~variationType.variations.findIndex(variation => variation.id === Number(variationId)));

              const foundVariation = foundVariationType.variations.find(variation => variation.id === Number(variationId));


              if (foundVariation) {
                return VariationManager.getSelectedVariation(foundVariation, foundVariationType, orderItem.incompatibilities);
              }
            });
        }

        // custom quantity
        if (queryParams['c_qty']) {
          updated = true;
          const customQty = Number(queryParams['c_qty']);
          const qtyVariationType = orderItem.product.variationTypes.find(vt => vt.category === AvailableVariationTypeTypes.quantity);

          const selectedCustomQty = VariationManager.createSelectedCustomQty(customQty, qtyVariationType);
          selectedVariations.push(selectedCustomQty);
        }

        // custom size
        if (queryParams['c_height'] && queryParams['c_width']) {
          updated = true;
          const customWidth = Number(queryParams['c_width']);
          const customHeight = Number(queryParams['c_height']);
          const sizeVariationType = orderItem.product.variationTypes.find(vt => vt.category === AvailableVariationTypeTypes.size);
          const customSizeVariation = VariationManager.createSelectedCustomSize(customWidth, customHeight, sizeVariationType);
          selectedVariations.push(customSizeVariation);
        }

        // custom size
        if (queryParams['b_id'] && queryParams['b_qty']) {
          updated = true;
          const quantity = Number(queryParams['b_qty']);
          const bundleId = queryParams['b_id'];

          const bundle = await this._bundleProvider.getBundle(bundleId);

          if (bundle && bundle.items) {

            const bundleItem = bundle.items.find(bundleItemEl => bundleItemEl.productId === orderItem.product.id);

            if (bundleItem) {
              const quantityVT = orderItem.product.variationTypes.find(vt => vt.category === AvailableVariationTypeTypes.quantity);
              bundleItem.bundleId = bundleId;
              this._store.dispatch(new AddBundleItem(bundleItem));
              const customBundleQuantity = VariationManager.createBundleQty(bundleItem, quantityVT, quantity);
              selectedVariations.push(customBundleQuantity);
            } else {

              this._store.dispatch(new RemoveBundleItem());
            }
          } else {
            this._store.dispatch(new RemoveBundleItem());
          }
        }

        if (updated) {
          this._store.dispatch(new ReplaceVariations(selectedVariations));
        } else {
          this._store.dispatch(new RemoveVariations());
        }
      }


      // Special Options -> Update state with selected variations from URL
      if (queryParams['selections_sp']) {
        // fetch selected variations and reconstruct state
        const selectedVariations: ISelectedVariation[] = queryParams['selections_sp']
          .split(',')
          .map(variationId => {
            const foundVariationType = orderItem.product.specialOptions
              .find(variationType => !!~variationType.variations.findIndex(variation => variation.id === Number(variationId)));

            const foundVariation = foundVariationType.variations.find(variation => variation.id === Number(variationId));

            if (foundVariation) {
              return VariationManager.getSelectedVariation(foundVariation, foundVariationType, orderItem.incompatibilities);
            }
          });

        this._store.dispatch(new ReplaceSpecialOptions(selectedVariations));
      } else {
        this._store.dispatch(new RemoveSpecialOptions());
      }


      // stop execution if we should not load Turnaround
      const loadTurnaround = reorder || (currentRoute && currentRoute === 'turnaround');

      // Turnaround
      if (loadTurnaround) {
        const skipSample = queryParams['skipSample'] ? queryParams['skipSample'] === 'true' : false;
        const turnaround = await this._getTurnaround(
          productId,
          [...orderItem.variationTypes, ...orderItem.specialOptions],
          !!(orderItem.artwork && orderItem.artwork.files && orderItem.artwork.files.length),
          skipSample,
          queryParams['delivery'],
          queryParams['guaranteedDate']
        );

        this._store.dispatch(new UpdateTurnaround(turnaround));
      } else {
        this._store.dispatch(new RemoveTurnaround());
      }

      return resolve();
    });
  }

  /**
   * Clear params if user goes back to specific component
   */
  private _trimParams(path: string, params: Params, categoryId: number, productId: number): boolean {

    if (!!params['reorder']) {
      return;
    }

    const newParams = Object.assign({}, params);

    let redirect = false;

    switch (path) {

      // If on ProductComponent clear all except PDP
      case 'welcome':
      case 'draft':
      case 'quote':
        if (
          params.hasOwnProperty('selections_vt') ||
          params.hasOwnProperty('selections_sp') ||
          params.hasOwnProperty('delivery') ||
          params.hasOwnProperty('skipSample') ||
          params.hasOwnProperty('guaranteedDate')
        ) {
          redirect = true;
        }
        delete newParams['b_id'];
        delete newParams['b_qty'];
        delete newParams['selections_vt'];
        delete newParams['selections_sp'];
        delete newParams['delivery'];
        delete newParams['skipSample'];
        delete newParams['guaranteedDate'];
        break;

      // If on DecorationComponent clear all except PDP & Decoration
      case 'customize':
        if (
          params.hasOwnProperty('selections_sp') ||
          params.hasOwnProperty('delivery') ||
          params.hasOwnProperty('skipSample') ||
          params.hasOwnProperty('guaranteedDate')
        ) {
          redirect = true;
        }
        delete newParams['selections_sp'];
        delete newParams['delivery'];
        delete newParams['skipSample'];
        delete newParams['guaranteedDate'];
        break;

      // If on CustomizationComponent clear Turnaround
      case 'specialOptions':
      case 'artwork':
        if (
          params.hasOwnProperty('delivery') ||
          params.hasOwnProperty('skipSample') ||
          params.hasOwnProperty('guaranteedDate')
        ) {
          redirect = true;
        }
        delete newParams['delivery'];
        delete newParams['skipSample'];
        delete newParams['guaranteedDate'];
        break;
    }

    if (redirect) {
      this._router.navigate(['category', categoryId, 'product', productId, path], { queryParams: newParams });
    }

    return !!redirect;
  }

  /**
 * Get turnaround
 */
  private async _getTurnaround(
    productId: number,
    variations: ISelectedVariation[],
    artwork: boolean,
    selectedSkipSample: boolean,
    selectedDelivery?: string,
    selectedGuaranteeDate?: string
  ) {
    const quantity = variations.find(variation => variation.vtCategory === AvailableVariationTypeTypes.quantity);

    if (!quantity) {
      return;
    }

    const quantityValue = VariationManager.getQuantityFromStack(variations);

    if (!quantityValue) {
      return;
    }

    const variationsIds = variations
      .filter(selectedVariation => !!selectedVariation.variation.id)
      .map(selectedVariation => Number(selectedVariation.variation.id));

    // packaging selections
    const packagingOrder = await this._store.select('packagingOrder').pipe(take(1)).toPromise();
    let packagingEstimationPayload: ITurnaroundEstimationBase;
    if (packagingOrder && packagingOrder.packagingSelections && packagingOrder.packagingSelections.length) {
      packagingEstimationPayload = {
        product: packagingOrder.product.id,
        variations: packagingOrder.packagingSelections
          .filter(packagingSelection => !!packagingSelection.variation.id)
          .map(packagingSelection => Number(packagingSelection.variation.id)),
        artwork: !!(packagingOrder.artwork && packagingOrder.artwork.files && packagingOrder.artwork.files.length),
        quantity: quantityValue
      };
    }

    const turnaroundEstimatesPayload: ITurnaroundEstimationRequest = {
      product: productId,
      quantity: quantityValue,
      variations: variationsIds,
      artwork: artwork,
      packaging: packagingEstimationPayload
    };
    const turnaroundEstimates = await this._productFlowApi.getTurnaroundTime(turnaroundEstimatesPayload);

    if (!turnaroundEstimates.success) {
      throw new Error(turnaroundEstimates.message);
    }

    const turnaround: ITurnaround = {
      availableOptions: DataTransformerService.createDeliveryOptions(turnaroundEstimates.estimations),
      skipSample: selectedSkipSample,
      selectedOption: undefined
    };

    // handle existing turnaround parameters
    if (selectedDelivery) {

      // find matching url delivery
      const chosenDelivery = turnaround.availableOptions.find(dOpt => {
        return dOpt.name === selectedDelivery;
      });

      if (chosenDelivery) {
        turnaround.selectedOption = chosenDelivery;
      }

      if (selectedGuaranteeDate) {
        turnaround.selectedOption.guaranteedDate = Number(selectedGuaranteeDate);
        turnaround.selectedOption.isGuaranteed = true;
      }
    }
    return turnaround;
  }

  /**
   * Determine on which route we currently are.
   * @param route from which we extract the route path.
   */
  private _determineCurrentRoute(route: ActivatedRouteSnapshot) {
    let path = '';
    const pathArray = route.routeConfig.path.split('/');

    if (pathArray.length < 3) { // if we don't have product selected, get fist param
      path = pathArray[0];
    } else if (pathArray.length >= 5) { // product selected
      path = pathArray[4];
    }

    return path;
  }

  /**
   * Load the artwork from the artworkData cookie
   */
  private _loadArtworkFromCookie(productId: number): IArtwork {
    const artworkData = this._cookieService.getJson(ArtworkCookieType.ORDER);
    if (artworkData && artworkData.data && artworkData.data.files && artworkData.data.files.length) {
      if (productId === Number(artworkData.productId)) {
        return artworkData.data;
      } else {
        this._cookieService.delete(ArtworkCookieType.ORDER);
      }
    }
  }

}
