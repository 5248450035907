import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Guards
import { NavigationGuardService } from './core/guards/nav-guard.service';
import { PaymentGuard } from './core/guards/payment-guard.service';
import { SalesRepGuard } from '../core/guards/sales-rep-guard.service';

// Resolvers
import { BundlePaymentResolver } from '../core/resolvers/bundle-payment.resolver';

// Pages
import { ShoppingCartComponent } from './pages/shopping-cart/shopping-cart.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { SuccessComponent } from './pages/success/success.component';
import { PaymentLoginComponent } from './pages/payment-login/payment-login.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { SalesSuccessComponent } from './pages/success/sales/sales-success.component';
import { BundlePaymentComponent } from './pages/bundle-payment/bundle-payment.component';
import { BundleSuccessComponent } from './pages/success/bundle/bundle-success.component';
import { BundleLoginComponent } from './pages/bundle-login/bundle-login.component';
import { InvoiceResolver } from './core/resolvers/invoice.resolver';

const routes: Routes = [
  // Login
  {
    path: 'login',
    component: PaymentLoginComponent
  },
  // Login in for bundles
  {
    path: 'bundle-login',
    component: BundleLoginComponent
  },
  // Forgot password
  {
    path: 'forgot',
    component: ForgotPasswordComponent
  },
  // Shopping Cart
  {
    path: 'cart',
    component: ShoppingCartComponent,
    canDeactivate: [NavigationGuardService]
  },
  // Payment
  {
    path: 'payment',
    component: PaymentComponent,
    canActivate: [PaymentGuard]
  },
  {
    path: 'payment-bundle/:bundleId',
    component: BundlePaymentComponent,
    resolve: { bundle: BundlePaymentResolver }
  },
  // Success
  {
    path: 'success/:invoiceId',
    component: SuccessComponent,
    resolve: { orderData: InvoiceResolver },
  },
  {
    path: 'sales-success',
    component: SalesSuccessComponent,
    canActivate: [SalesRepGuard]
  },
  {
    path: 'bundle-success',
    component: BundleSuccessComponent,
    canDeactivate: [NavigationGuardService]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShopRoutingModule { }
