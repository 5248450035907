import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

/**
 * @author Liviu Dima
 */
@Injectable()
export class CookieService {

  private _domain: string;
  private _path: string;

  constructor() {
    this._domain = environment.cookieDomainName;
    this._path = '/';
  }

  /**
   * Get Object from JSON string cookie
   */
  getJson(name: string): any {
    const value = this._get(name);
    try {
      return JSON.parse(value);
    } catch (err) {
      return undefined;
    }
  }

  /**
   * Set Object as JSON string
   */
  setJson(name: string, value: any, expires?: number | Date, path?: string, domain?: string, secure?: boolean): void {
    let stringifiedValue;
    try {
      stringifiedValue = JSON.stringify(value);
    } catch (err) {
      stringifiedValue = undefined;
    }

    this.set(name, stringifiedValue, expires, path, domain, secure);
  }

  // use default methods from cookie service
  get(name: string) {
    return this._get(name);
  }

  set(name: string, value: string, expires?: number | Date, path?: string, domain?: string, secure?: boolean): void {
    this._set(name, value, expires, path, domain, secure);
  }

  delete(name: string, path?: string, domain?: string): void {
    this._delete(name, path, domain);
  }


  private _set(name: string, value: string, expires?: number | Date, path?: string, domain?: string, secure?: boolean) {

    const domainString = domain ? domain : this._domain;
    const pathString = path ? path : this._path;

    let expirationString = '';

    if (expires instanceof Date) {

      // if we get a Date object, use it for expiration directly
      expirationString = expires.toUTCString();

    } else if (typeof (expires) === 'number') {

      // if we get a number, use it for days multiplier
      const date = new Date();
      date.setTime(date.getTime() + (expires * 24 * 60 * 60 * 1000));

      expirationString = date.toUTCString();
    } else {

      // Set it expire in 7 days default
      const date = new Date();
      date.setTime(date.getTime() + (7 * 24 * 60 * 60 * 1000));
      expirationString = date.toUTCString();
    }

    // Set it
    document.cookie = encodeURIComponent(name) + '=' + encodeURIComponent(value) +
      '; expires=' + expirationString +
      '; domain=' + domainString +
      '; path=' + pathString;
  }

  private _get(name: string) {
    const value = '; ' + document.cookie;
    const parts = value.split('; ' + name + '=');

    if (parts.length === 2) {
      return decodeURIComponent(parts.pop().split(';').shift());
    }
  }

  private _delete(name: string, path: string, domain: string) {
    const domainString = domain ? domain : this._domain;
    const pathString = path ? path : this._path;
    const date = new Date();

    // Set it expire in -1 days
    date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));

    // Set it
    document.cookie = name + '=' +
      '; expires=' + date.toUTCString() +
      '; domain=' + domainString +
      '; path=' + pathString;
  }
}
