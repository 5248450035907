import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';

import { LocalStorageService } from './localStorage.service';

import { IUser, UserRole } from '../types/user/IUser';

const TOKEN = 'diy_token';
const USER = 'diy_user';
const CD_ORIGIN = 'diy_cd_origin';
const TURNED_BACK = 'diy_turned_back';
const PAST_URLS = 'diy_past_urls';
const TRIGGER_BACK = 'diy_trigger_back';
export const IDLE_HALF = 'diy_idle_half';
export const IDLE_ONE = 'diy_idle_one';
export const IDLE_TWO = 'diy_idle_two';

/**
 * Service that manages the sessions.
 *
 * @author Andrei Diaconu
 */
@Injectable()
export class SessionService {

  private _token: string;
  private _userData: IUser;

  private _segmentation: boolean;

  constructor(
    private _localStorage: LocalStorageService
  ) {
    const token = _localStorage.getItem(TOKEN) || sessionStorage.getItem(TOKEN);
    const userData = _localStorage.getItem(USER) || sessionStorage.getItem(USER);
    this._token = token ? token : null;
    this._userData = userData ? JSON.parse(userData) : null;
  }

  setSession(token: string, userData: IUser, remember: boolean): void {
    this._token = token;
    this._userData = userData;
    if (remember) {
      this._localStorage.setItem(TOKEN, token);
      this._localStorage.setItem(USER, JSON.stringify(userData));
      sessionStorage.removeItem(TOKEN);
      sessionStorage.removeItem(USER);
    } else {
      this._localStorage.removeItem(TOKEN);
      this._localStorage.removeItem(USER);
      sessionStorage.setItem(TOKEN, token);
      sessionStorage.setItem(USER, JSON.stringify(userData));
    }
  }

  resetSession(): void {
    this._userData = null;
    this._token = null;
    this._localStorage.removeItem(TOKEN);
    this._localStorage.removeItem(USER);
    sessionStorage.removeItem(TOKEN);
    sessionStorage.removeItem(USER);
  }

  set segmentation(flag: boolean) {
    this._segmentation = flag;
  }

  get segmentation(): boolean {
    return this._segmentation;
  }

  get token(): string {
    return this._token;
  }

  get user(): IUser {
    return this._userData;
  }

  set CDOrigin(flag: string) {
    sessionStorage.setItem(CD_ORIGIN, flag);
  }

  get CDOrigin(): string {
    return sessionStorage.getItem(CD_ORIGIN);
  }

  set turnedBack(flag: string) {
    sessionStorage.setItem(TURNED_BACK, flag);
  }

  get turnedBack(): string {
    return sessionStorage.getItem(TURNED_BACK);
  }

  set pastUrls(urls: string) {
    sessionStorage.setItem(PAST_URLS, urls);
  }

  get pastUrls(): string {
    return sessionStorage.getItem(PAST_URLS);
  }

  set backTrigger(triggered: string) {
    sessionStorage.setItem(TRIGGER_BACK, triggered);
  }

  get backTrigger(): string {
    return sessionStorage.getItem(TRIGGER_BACK);
  }

  setItem(itemName: string, time: string) {
    sessionStorage.setItem(itemName, time);
  }

  getItem(itemName: string): string {
    return sessionStorage.getItem(itemName);
  }

  isLoggedIn(): boolean {
    return !!this._token;
  }

  isGranted(role: UserRole): boolean {
    return this._userData.role === role;
  }

  isSpecialAdmin(): boolean {
    if (!this.isLoggedIn()) {
      return false;
    }
    return environment.specialAdminEmails.includes(this._userData.email.toLowerCase());
  }

  isCustomerLoggedIn() {
    return this.isLoggedIn() && this.isGranted(UserRole.CUSTOMER);
  }

  isSalesLoggedIn() {
    return this.isLoggedIn() && this.isGranted(UserRole.SALES);
  }
}
