import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { NavigatorService } from '../../../services/navigator';
import { PriceCalculatorService } from '../../../../core/services/price-calculator';

import { IPackagingOrder, PackageVariationName } from '../../../../types/app-state/IPackagingOrder';
import { ISelectedVariation } from '../../../../types/app-state/ISelectedVariation';
import { PackagingVariationTypeTypes } from '../../../../types/product/variations/IVariationType';
import { IVariation } from '../../../../types/product/variations/IVariation';

@Component({
  selector: 'diy-packaging-summary',
  templateUrl: './packaging-summary.component.html',
  styleUrls: ['./packaging-summary.component.scss']
})
export class PackagingSummaryComponent implements OnInit {

  @Input() packagingOrderItem: IPackagingOrder;

  // package options and type
  packageOptions: ISelectedVariation[] = [];
  packageSize: ISelectedVariation;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _navigator: NavigatorService
  ) {
  }

  ngOnInit() {

    this.packageOptions = this.packagingOrderItem.packagingSelections.filter(selectedVariation =>
      selectedVariation.vtCategory === PackagingVariationTypeTypes.options ||
      selectedVariation.vtCategory === PackagingVariationTypeTypes.type
    );

    this.packageSize = this.packagingOrderItem.packagingSelections.find(selectedVariation =>
      selectedVariation.vtCategory === PackagingVariationTypeTypes.size
    );

  }

  editOption(name: string) {
    switch (name) {
      case PackageVariationName.SIZE: {
        this._navigator.navigate('packaging-size', this._activatedRoute.snapshot.queryParams);
        break;
      }
      case PackageVariationName.TYPE: {
        this._navigator.navigate('packaging-type', this._activatedRoute.snapshot.queryParams);
        break;
      }
      case PackageVariationName.OPTIONS: {
        this._navigator.navigate('packaging-option', this._activatedRoute.snapshot.queryParams);
        break;
      }
      case 'artwork': {
        this._navigator.navigate('packaging-upload-artwork', this._activatedRoute.snapshot.queryParams);
        break;
      }
      default:
        break;
    }
  }

  getPrice(variation: IVariation) {
    return PriceCalculatorService.getVariationPrice(
      variation,
      this.packagingOrderItem.price.quantity,
      this.packagingOrderItem.price.pricePerUnit
    );
  }

  getVariationIcon(variation: IVariation): string {
    if (variation.images && variation.images.length > 0) {
      return variation.images[0].small;
    }
    return '';
  }
}
