export interface IUser {

  firstName: string;
  lastName: string;
  email: string;
  role: UserRole;
}

export enum UserRole {
  CUSTOMER,
  SALES
}
export interface ILoginResponse {
  token: string;
  customerData: {
    firstName: string,
    lastName: string,
    email: string
  };
}
