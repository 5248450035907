import { Input, Output, EventEmitter, OnInit, OnDestroy, ElementRef, Directive } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { FormGroup, AbstractControl, FormControl } from '@angular/forms';

import { IFormState, IPaymentError, IShippingData, IBillingData, IFormValidation } from '../../../types/payment/IFormState';

/**
 * @author Liviu Dima
 */
@Directive()
export abstract class PaymentItemBaseComponent implements OnInit, OnDestroy {
  @Input() formValidation$: Subject<IFormValidation>;

  @Input() error$: Subject<IPaymentError>;

  @Input() data: IShippingData | IBillingData;

  @Output() formState = new EventEmitter<IFormState>();

  form: FormGroup;

  errorMessage = '';

  isLoading = false;

  protected _subscriptions$: Subscription[] = [];

  /**
   * Class constructor
   * @param el
   */
  constructor(
    protected el: ElementRef
  ) { }

  ngOnInit() {
    let subscription$: Subscription;
    // trigger form validation
    if (this.formValidation$) {
      subscription$ = this.formValidation$.subscribe(formValidation => {
        if (formValidation.validate) {
          this.isFormValid(this.form);
        }
        this.formState.emit({
          formData: this.form.getRawValue(),
          isValid: this.form.valid
        });
      });
      this._subscriptions$.push(subscription$);
    }

    // trigger errors
    if (this.error$) {
      subscription$ = this.error$.subscribe((error: IPaymentError) => {
        if (error.hasError) {
          this._scrollToFirstError();
          this.errorMessage = error.message;
        }
      });
      this._subscriptions$.push(subscription$);
    }
  }

  ngOnDestroy() {
    this._subscriptions$.map(subscription => subscription.unsubscribe());
  }

  /**
   * check validation
   * @param form
   */
  isFormValid(form: FormGroup): boolean {
    for (const key of Object.keys(form.controls)) {
      if (form.controls[key] instanceof FormControl) {
        form.controls[key].markAsDirty();
        form.controls[key].markAsTouched();
        form.controls[key].updateValueAndValidity();
      }

      if (form.controls[key] instanceof FormGroup) {
        const control = form.controls[key]['controls'];
        for (const controlKey in control) {
          if (control.hasOwnProperty(controlKey)) {
            control[controlKey].markAsDirty();
            control[controlKey].markAsTouched();
            control[controlKey].updateValueAndValidity();
          }
        }
      }
    }
    return form.valid;
  }

  /**
   * Add validation class
   * @param item
   */
  getStateClass(item: AbstractControl): string {
    if (item.valid && (item.dirty || item.touched)) {
      return 'has-success';
    } else if (item.invalid && (item.dirty || item.touched)) {
      return 'has-danger';
    }

    return '';
  }

  /**
   * Patch form from data
   * @param form
   * @param data
   */
  protected _loadFormFromDraft(form: FormGroup, data) {
    if (data) {
      form.patchValue(data);
    }

    return form;
  }

  /**
   * Scroll to first error
   */
  protected _scrollToFirstError() {
    const element = this.el.nativeElement;
    if (!element) {
      return window.scrollTo(0, 0);
    }

    const inputTarget = element.querySelector('.form-input.ng-invalid');
    if (inputTarget) {
      inputTarget.scrollIntoView(true);
    } else {
      element.scrollIntoView(true);
    }

    window.scrollBy(0, -150);
  }
}
