import { Injectable } from '@angular/core';

declare const dataLayer: any;
declare const gtag: any;
declare const fbq: any;
declare const uetq: any;
declare const ac: any;

@Injectable()
export class TrackingService {

  sendGoogleData(payload: any) {
    try {
      dataLayer.push(payload);
    } catch (err) {
      console.error(err);
    }
  }

  gtagReportConversion(payload: any) {
    this.gtagAction('conversion', payload);
  }

  gtagAction(action: string, payload: any) {
    try {
      if ((typeof gtag === 'undefined') || !gtag) {
        return;
      }

      gtag('event', action, payload);
      return false;
    } catch (err) {
      console.error(err);
    }
  }

  sendFacebookData(...args) {
    try {
      if ((typeof fbq === 'undefined') || !fbq) {
        return;
      }

      fbq(...args);
    } catch (err) {
      console.error(err);
    }
  }

  sendBingData(type: string, payload: any) {
    try {
      if ((typeof uetq === 'undefined') || !uetq) {
        return;
      }

      uetq.push('event', type, payload);
    } catch (err) {
      console.error(err);
    }
  }

  sendAltoCloudData(args: any[]) {
    try {
      if (typeof ac === 'undefined' || !ac) {
        return;
      }

      ac(...args);
    } catch (err) {
      console.error(err);
    }
  }

}
