<div (click)="toggleOptions()" title="{{ selectedCountry?.name }} {{ selectedCountry?.prefix }}" class="flag-container">
  <p [ngClass]="'flag flag-' + selectedCountry?.iso"></p>
  <p>{{ selectedCountry?.prefix }}</p>
</div>

<ul #select [hidden]="!isOpen">

  <li *ngFor="let country of countries; let i = index" (click)="selectValue(country);"
    title="{{ country.name }} {{ country.prefix }}"
    [ngClass]="{'selected': country.iso === selectedCountry.iso, 'focus': activeItemIndex === i}">

    <span [ngClass]="'flag flag-' + country.iso"></span>
    <span class="mx-small">{{ country.name }}</span>
    <span>{{ country.prefix }}</span>

  </li>

</ul>
