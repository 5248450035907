<div class="variation style--wrap">
  <div class="variation__title">
    <p>Select {{variationType.name}}*</p>
    <div class="variation__info">
      <button>i</button>
      <span class="hidden">{{variationType.info}} </span>
    </div>
  </div>
  <p class="variation__alert">Oops! You forgot to select style. This step is required.</p>
  <div *ngFor="let variation of variationType.variations; let i = index" (click)="selectStyle(variation)"
    class="variation__style">

    <div class="variation__style--image"><img src="{{getThumbnail(variation)}}" /></div>

    <div class="variation__style--wrap">
      <div class="variation__style--content">
        <p class="variation__style--title">
          <span class="variation__item--style">{{variation.name}}</span>
          <span class="variation__item--tag" [hidden]="!variation.popular">Most Popular</span>
        </p>
        <p class="variation__style--description" innerHtml="{{variation.info}}"></p>
        <p *ngIf="isCompatible(variation)" class="specialOption__style--incompatibility">
          {{getIncompatibility(variation)}}
        </p>
      </div>

      <div class="select-wrap">
        <input id="{{variationType?.dbname}}_{{i}}" type="radio" [checked]="isChecked(variation)"
          [disabled]="!isCompatible(variation)" name="{{variationType?.dbname}}" />

        <label class="button button--green">
          <span>Select</span>
        </label>
      </div>
    </div>
  </div>
</div>
