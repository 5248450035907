import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CollapseModule } from 'ngx-bootstrap';
import { TSCommonModule } from 'tscommon';

import { CommonComponentsModule } from '../common/common-components.module';
import { ShopRoutingModule } from './shop-routing.module';

// API
import { BundlePaymentApi } from './core/api/bundlePaymentApi';
import { CustomerDataProvider } from './core/api/CustomerDataProvider';

// Resolvers
import { BundlePaymentResolver } from '../core/resolvers/bundle-payment.resolver';

// Guards
import { SalesRepGuard } from '../core/guards/sales-rep-guard.service';
import { NavigationGuardService } from './core/guards/nav-guard.service';
import { PaymentGuard } from './core/guards/payment-guard.service';

// Services
import { PaymentSummaryService } from './core/services/payment-summary.service';
import { ShoppingCartStorageService } from './core/services/shopping-cart-storage.service';
import { CombineShippingService } from './core/services/combine-shipping.service';

// Pages
import { ShoppingCartComponent } from './pages/shopping-cart/shopping-cart.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { SuccessComponent } from './pages/success/success.component';
import { PaymentLoginComponent } from './pages/payment-login/payment-login.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { SalesSuccessComponent } from './pages/success/sales/sales-success.component';
import { BundlePaymentComponent } from './pages/bundle-payment/bundle-payment.component';
import { BundleSuccessComponent } from './pages/success/bundle/bundle-success.component';
import { BundleLoginComponent } from './pages/bundle-login/bundle-login.component';

// Components
import { PaymentFormComponent } from './pages/payment/payment-form/payment-form.component';
import { SalesPaymentFormComponent } from './pages/payment/sales-payment-form/sales-payment-form.component';
import { ShippingAddressFormComponent } from './pages/payment/address/shipping-address-form/shipping-address-form.component';
import { BillingAddressFormComponent } from './pages/payment/address/billing-address-form/billing-address-form.component';
import { CartItemComponent } from './pages/shopping-cart/cart-item/cart-item.component';
import { CartItemOrderComponent } from './pages/shopping-cart/cart-item/cart-item-order/cart-item-order.component';
import { PriceDisplayComponent } from './pages/shopping-cart/price-display/price-display.component';
import { PromoCodeComponent } from './pages/payment/promo-code/promo-code.component';
import { LoginPromptComponent } from './pages/bundle-payment/login-prompt/login-prompt.component';
import { PackagingDetailsComponent } from './pages/shopping-cart/packaging-details/packaging-details.component';
import { TaxNoticeComponent } from './pages/payment/tax-notice/tax-notice.component';
import { PaymentSummaryComponent } from './core/component/payment-summary/payment-summary.component';
import { PaymentSummaryItemComponent } from './core/component/payment-summary/payment-summary-item/payment-summary-item.component';
import { InvoiceApi } from './core/api/invoiceApi';
import { SurveyComponent } from './pages/survey/survey.component';
import { InvoiceResolver } from './core/resolvers/invoice.resolver';

@NgModule({
  declarations: [
    // Pages
    ShoppingCartComponent,
    PaymentLoginComponent,
    BundleLoginComponent,
    ForgotPasswordComponent,
    PaymentComponent,
    BundlePaymentComponent,
    SuccessComponent,
    SalesSuccessComponent,
    BundleSuccessComponent,
    // Components
    PaymentFormComponent,
    SalesPaymentFormComponent,
    ShippingAddressFormComponent,
    BillingAddressFormComponent,
    CartItemComponent,
    CartItemOrderComponent,
    PriceDisplayComponent,
    PromoCodeComponent,
    LoginPromptComponent,
    PackagingDetailsComponent,
    TaxNoticeComponent,

    PaymentSummaryComponent,
    PaymentSummaryItemComponent,
    SurveyComponent,
  ],
  imports: [
    CommonModule,
    ShopRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    TSCommonModule,
    CommonComponentsModule,
    CollapseModule.forRoot(),
  ],
  exports: [
    PaymentComponent,
  ],
  providers: [
    // API
    InvoiceApi,
    BundlePaymentApi,
    CustomerDataProvider,
    // Guards
    SalesRepGuard,
    NavigationGuardService,
    PaymentGuard,
    // Resolvers
    BundlePaymentResolver,
    InvoiceResolver,
    // Services
    ShoppingCartStorageService,
    CombineShippingService,
    PaymentSummaryService,
  ]
})
export class ShopModule { }
