import { Component } from '@angular/core';

@Component({
  selector: 'diy-spinner',
  template: '<div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div>',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {

  constructor() { }

}
