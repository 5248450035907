import { Component, OnInit, OnDestroy, HostListener, ViewChild } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subscription, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import { TSConfirmComponent } from 'tscommon';

// Env
import { environment } from '../../../../environments/environment';

// Base component
import { MultipleOrdersDraftBaseComponent } from '../../../core/components/draft/multiple-orders.draftBase.component';

// Services
import { AmplitudeService } from '../../../core/services/amplitude';
import { OrderMessengerService } from '../../../core/services/order-messenger';
import { VariationManager } from '../../../core/services/variation-manager.service';
import { NavigationService } from '../../../core/services/navigation.service';
import { DraftService } from '../../../core/services/draft.service';
import { CombineShippingService } from '../../core/services/combine-shipping.service';
import { CookieService } from '../../../core/services/cookie.service';
import { QuoteService } from '../../../core/services/quote.service';
import { TrackingService } from '../../../core/services/tracking';
import { SessionService } from '../../../utility/session.service';

// Store
import { ResetOrderItem } from '../../../state-manager/order-item/order-item.actions';
import { CombineCartItems, RemoveCartItem, SetQuoteId } from '../../../state-manager/shopping-cart/shopping-cart.actions';
import { ResetPackagingOrder } from '../../../state-manager/packaging-order/packaging-order.actions';

// Types
import { IShoppingCart } from '../../../types/shopping-cart/IShoppingCart';
import { IAppState } from '../../../types/app-state/IAppState';
import { ICartItemOrder } from '../../../types/shopping-cart/ICartItem';
import { ICustomerInfo } from '../../../types/quote/IQuote';
import {NavigatorService} from '../../../core/services/navigator';

@Component({
  selector: 'diy-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss']
})
export class ShoppingCartComponent extends MultipleOrdersDraftBaseComponent implements OnInit, OnDestroy {

  shoppingCartData: IShoppingCart;
  totalItems: number;
  selectedIndexes: number[] = [];

  bannerDiscountValue: number;
  showPromoBanner = false;

  itemChecked$ = new Subject<boolean>();

  @ViewChild('confirmModal', { static: true }) confirm: TSConfirmComponent;

  private _subscriptions$: Subscription[] = [];

  constructor(
    protected draftService: DraftService,
    protected router: Router,
    protected tracking: TrackingService,
    protected session: SessionService,
    protected cookies: CookieService,
    private _oms: OrderMessengerService,
    private _store: Store<IAppState>,
    private _amp: AmplitudeService,
    private _navService: NavigationService,
    private _quoteService: QuoteService,
    private _navigator: NavigatorService,
    private _activatedRoute: ActivatedRoute,
  ) {
    super(draftService, router, tracking, session, cookies);
    this.bannerDiscountValue = environment.promotionValue;
    this.showPromoBanner = environment.promotionActive;

    const subscription$ = this._store.pipe(select('shoppingCart')).subscribe((data: IShoppingCart) => {
      this.shoppingCartData = data;

      this.totalItems = this._getItemsTotal();
    });
    this._subscriptions$.push(subscription$);
  }

  ngOnInit() {
    this._oms.updateStep(6.5);

    this._checkCustomerEmail();

    // Order item store cleanup
    this._store.dispatch(new ResetOrderItem());
    this._store.dispatch(new ResetPackagingOrder());

    // Amplitude
    const orders = this.shoppingCartData.cartItems
      .reduce((accumulator, nextItem) => [...accumulator, ...nextItem.cartItemOrders], []);

    this._amp.logEvent('Navigation', {
      page: 'shopping cart',
      navigationStep: 4,
      productNames: JSON.stringify(orders.map(order => order.orderItem.product.name)),
      product_ids: JSON.stringify(orders.map(order => order.orderItem.product.id)),
      quantities: JSON.stringify(orders.map(order => VariationManager.getQuantityFromStack(order.orderItem.variationTypes))),
      turnarounds: JSON.stringify(orders.map(order => order.orderItem.turnaround.selectedOption.name)),
      skip_samples: JSON.stringify(orders.map(order => order.orderItem.turnaround.skipSample)),
      guaranteed_deliveries: JSON.stringify(orders.map(order => order.orderItem.turnaround.selectedOption.isGuaranteed))
    });


    // const products = orders.map((order: ICartItemOrder) => ({
    //   id: order.orderItem.product.id,
    //   name: order.orderItem.product.id,
    //   price: order.orderItem.price.finalTotal,
    //   category: order.orderItem.category.name,
    //   quantity: order.orderItem.price.quantity
    // }));

    // this.tracking.sendAltoCloudData(['set', {
    //   products: products,
    // }]);

    this.tracking.sendAltoCloudData(['pageview', {
      location: location.pathname,
      title: 'Shopping cart'
    }]);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscriptions$.map(subscription => subscription.unsubscribe());
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    event.preventDefault();
    this._navService.clickedBack = true;
  }

  combineShipping(): void {
    this._store.dispatch(new CombineCartItems(this.selectedIndexes.sort()));
    this.selectedIndexes = [];
    this.itemChecked$.next(false);
  }

  select(itemIndex: number) {
    const index = this.selectedIndexes.findIndex(indx => indx === itemIndex);
    index === -1 ? this.selectedIndexes.push(itemIndex) : this.selectedIndexes.splice(index, 1);
  }

  openCombinedShippingModal() {
    if (this.selectedIndexes.length > 1) {
      let cartItemOrders = [];
      for (const index of this.selectedIndexes) {
        cartItemOrders = cartItemOrders.concat(this.shoppingCartData.cartItems[index].cartItemOrders);
      }
      const maxTurnaroundItem = CombineShippingService.getMaxTurnaround(cartItemOrders);
      /* tslint:disable */
      this.confirm.subtitle = `You are combining these orders into a single delivery date of ${this._getMaxDeliveryDate(maxTurnaroundItem)}`;
      /* tslint:enable */
      this.confirm.show();
      this.confirm.onClose$.pipe(take(1)).subscribe(response => {
        if (response) {
          this.combineShipping();
        }
      });
    }
  }

  clearCheckedIndexes(cartItemOrdersLength: number, itemIndex: number) {
    if (!cartItemOrdersLength) {
      this._store.dispatch(new RemoveCartItem(itemIndex));
    }
    this.selectedIndexes = [];
    this.itemChecked$.next(false);
  }

  private _getItemsTotal(): number {
    let total = 0;
    if (this.shoppingCartData.cartItems.length) {
      this.shoppingCartData.cartItems.forEach((cartItem) => {
        total += cartItem.cartItemOrders.length;
      });
    }
    return total;
  }

  private _getMaxDeliveryDate(maxTurnaroundItem: ICartItemOrder): string {
    const formatter = Intl.DateTimeFormat('en-us', { month: 'long' });
    if (!maxTurnaroundItem.orderItem.turnaround.selectedOption.isGuaranteed) {
      let min = new Date(maxTurnaroundItem.orderItem.turnaround.selectedOption.datesInterval.normalStart);
      let max = new Date(maxTurnaroundItem.orderItem.turnaround.selectedOption.datesInterval.normalEnd);
      if (maxTurnaroundItem.orderItem.turnaround.skipSample) {
        min = new Date(maxTurnaroundItem.orderItem.turnaround.selectedOption.datesInterval.ssStart);
        max = new Date(maxTurnaroundItem.orderItem.turnaround.selectedOption.datesInterval.ssEnd);
      }
      return `${formatter.format(min)} ${min.getDate()} - ${formatter.format(max)}
      ${max.getDate()}, ${max.getFullYear()}`;
    } else {
      const guaranteedDate = new Date(maxTurnaroundItem.orderItem.turnaround.selectedOption.guaranteedDate);
      return `${formatter.format(guaranteedDate)} ${guaranteedDate.getDate()}, ${guaranteedDate.getFullYear()}`;
    }
  }

  private _checkCustomerEmail(): void {
    const customerInfo: ICustomerInfo = this.cookies.getJson('customerInfo');
    if (customerInfo) {
      this.shoppingCartData.cartItems.forEach(cartItem => {
        cartItem.cartItemOrders.forEach(async cartItemOrder => {
          if (cartItemOrder.orderItem.customerEmail !== customerInfo.email) {
            const quote = await this._quoteService.createQuote(customerInfo, cartItemOrder.orderItem.product.id);
            if (quote && quote.quoteId) {
              this._store.dispatch(new SetQuoteId({ uid: cartItemOrder.uid, quoteId: quote.quoteId, customerEmail: customerInfo.email }));
            }
          }
        });
      });
    }
  }

  async navigateToTurnaround() {
    const index = this.shoppingCartData.cartItems.length - 1;

    const turnaroundParams = await this._navigator.generateStateQueryParams(this.shoppingCartData.cartItems[index].cartItemOrders[0].orderItem);
    const uidParam = { uid: this.shoppingCartData.cartItems[index].cartItemOrders[0].uid, index };
    const quoteIdParam = { quoteId: this.shoppingCartData.cartItems[index].cartItemOrders[0].orderItem.quoteId };

    this.router.navigate(
      ['/category', this.shoppingCartData.cartItems[index].cartItemOrders[0].orderItem.category.id, 'product',
      this.shoppingCartData.cartItems[index].cartItemOrders[0].orderItem.product.id, 'turnaround'],
      { queryParams: { ...turnaroundParams, ...uidParam, ...quoteIdParam } }
    );
  }
}
