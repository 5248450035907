import { Component, Input } from '@angular/core';

import { environment } from '../../../../../environments/environment';

import { IPaymentSummary } from '../../../../types/payment/IPaymentSummary';

@Component({
  selector: 'diy-payment-summary',
  templateUrl: './payment-summary.component.html',
  styleUrls: ['payment-summary.component.scss']
})
export class PaymentSummaryComponent {

  resellerTaxActive = false;

  @Input() summaryData: IPaymentSummary;

  constructor() {
    this.resellerTaxActive = environment.resellerTaxActive;
  }

}
