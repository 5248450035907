import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'diy-save-design-success',
  templateUrl: './save-success.component.html',
  styleUrls: ['./save-success.component.scss']
})
export class SaveSuccessComponent {

  @Output() clickedClose = new EventEmitter();

  constructor() { }

  close() {
    this.clickedClose.emit();
  }
}
