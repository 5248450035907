import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { HttpEventType } from '@angular/common/http';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { FileApi } from '../../../../core/api/FileApi';

import { SetResellerLicense } from '../../../../state-manager/shopping-cart/shopping-cart.actions';
import { IUploadedFile } from '../../../../types/artwork/IUploadedFile';
import { IAppState } from '../../../../types/app-state/IAppState';
import { IShoppingCart } from '../../../../types/shopping-cart/IShoppingCart';

@Component({
  selector: 'diy-tax-notice',
  templateUrl: './tax-notice.component.html',
  styleUrls: ['./tax-notice.component.scss']
})
export class TaxNoticeComponent implements OnInit {

  @Input() validLicense: boolean;

  @Output() taxFileDeleted = new EventEmitter<boolean>();

  @ViewChild('fileSelect') fileSelect;

  uploadedTaxFile: IUploadedFile;
  errorMessage: string;

  private _subscriptions$: Subscription[] = [];

  constructor(
    private _fileHandler: FileApi,
    private _store: Store<IAppState>,
  ) { }

  async ngOnInit() {
    if (!this.validLicense) {
      const subscription$ = this._store.pipe(
        select('shoppingCart'),
        take(1)
      ).subscribe((data: IShoppingCart) => {
        this.uploadedTaxFile = data.resellerLicense;
        this._store.dispatch(new SetResellerLicense(data.resellerLicense));
      });
      this._subscriptions$.push(subscription$);
    }
  }

  public handleFileInput(event) {
    const files: File[] = Array.from(event.target.files);
    this.errorMessage = '';

    if (files && files.length) {

      const subscription$ = this._fileHandler.upload(files[0]).subscribe(
        response => {
          if (response.type === HttpEventType.Response) {
            if (response.body['newName'] && response.body['url']) {
              const resellerLicense = {
                name: files[0].name,
                newName: response.body['newName'],
                url: response.body['url']
              };
              this._store.dispatch(new SetResellerLicense(resellerLicense));
              this.uploadedTaxFile = resellerLicense;
              subscription$.unsubscribe();
            } else {
              this.errorMessage = response.body['message'];
            }
          }
        },
        error => {
          this.errorMessage = error;
        }
      );
    }
  }

  public deleteFile() {
    const uploadedTaxFile = Object.assign({}, this.uploadedTaxFile);
    this.uploadedTaxFile = undefined;
    this._store.dispatch(new SetResellerLicense(undefined));
    this._fileHandler.delete(uploadedTaxFile.newName);
    this.fileSelect.nativeElement.value = '';
    this.taxFileDeleted.emit(true);
  }
}
