import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { IBundleItem } from '../../../types/bundle/IBundle';
import { RemoveBundleItem, AddBundleItem } from '../../../state-manager/order-item/order-item.actions';
import { IAppState } from '../../../types/app-state/IAppState';
import { Store } from '@ngrx/store';
import { IVariationTypeListItem } from '../../../types/product/variations/IVariationType';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'diy-credit-selector',
  templateUrl: './credit-selector.component.html',
  styleUrls: ['./credit-selector.component.scss']
})
export class CreditSelectorComponent implements OnInit, OnDestroy {

  @Input() bundleItem: IBundleItem;
  @Input() bundleCustomisationList: IVariationTypeListItem[] = [];

  creditForm: FormGroup;

  protected _subscriptions$: Subscription[] = [];

  constructor(private _store: Store<IAppState>, private _fb: FormBuilder) {

    this.creditForm = this._fb.group({
      bundleRadio: ['']
    });

    const sub$ = this.creditForm.controls.bundleRadio.valueChanges
      .subscribe(value => this.bundleSelection(value === 'enabled'));

    this._subscriptions$.push(sub$);
  }

  ngOnInit() {
    // select bundle by default
    this.creditForm.controls.bundleRadio.setValue('enabled');
  }

  bundleSelection(stateFlag: boolean) {
    if (stateFlag) {
      return this._store.dispatch(new AddBundleItem(this.bundleItem));
    }

    return this._store.dispatch(new RemoveBundleItem());
  }


  ngOnDestroy() {
    this._subscriptions$.map(el => el.unsubscribe());
  }
}
