<form [formGroup]="form" class="payment__item">

  <div *ngIf="errorMessage" class="card-form__danger">
    <p>{{ errorMessage }}</p>
  </div>

  <p class="payment__item--title">Shipping Address</p>
  <p class="payment__item--subtitle">Please note that we do ship to FPO and APO addresses, but do not
    ship
    to PO Boxes</p>

  <!--Blind Shipping-->
  <div class="payment__item--notification">
    <div class="checkbox-wrap">
      <input formControlName="blindShipping" type="checkbox" id="paymentNotification" />
      <label for="paymentNotification"><span class="checkbox-icon"></span> Check here for Blind
        Shipping</label>
    </div>
    <div class="payment__item--info">
      <span class="span-icon">i</span>
      <p class="info-holder">If you'd like to withhold the fact that the shipment is being processed
        and
        sent through The/Studio, we're happy to accommodate.</p>
    </div>
  </div>
  <div *ngIf="!showAddressForm" class="shipping-list">
    <h1 class="payment-address-title">Choose a Shipping Address</h1>

    <div *ngFor="let address of customerShippingData" class="shipping-address"
      [ngClass]="{'active': selectedAddress === address}" (click)="chooseAddress(address)">
      {{address.name}} <br />
      {{address.address}} <br />
      {{address.city}}, {{address.state}} {{address.zip}}
    </div>
    <button class="simple-green-btn" (click)="toggleShippingForm(true)">+ New Address</button>
  </div>

  <div *ngIf="showAddressForm" class="shipping-form">
    <button *ngIf="customerShippingData?.length" class="simple-green-btn cancel-billing-form"
      (click)="toggleShippingForm(false)">Close</button>

    <input *ngIf="form.get(['email'])" type="email" placeholder="Email" formControlName="email" class="form-input"
      [ngClass]="getStateClass(form.get(['email']))" />

    <div class="form-row form-row--split">
      <input type="text" placeholder="First Name" formControlName="firstName"
        [ngClass]="getStateClass(form.get(['firstName']))" class="form-input" />

      <input type="text" placeholder="Last Name" formControlName="lastName"
        [ngClass]="getStateClass(form.get(['lastName']))" class="form-input" />
    </div>

    <!-- Address -->
    <div class="payment__address-form">
      <input class="form-input" type="text" placeholder="Address" formControlName="address"
        [ngClass]="getStateClass(form.get(['address']))" />

      <div class="form-row form-row--split">
        <input class="form-input form--city" type="text" placeholder="City" formControlName="city"
          [ngClass]="getStateClass(form.get(['city']))" />
        <input class="form-input payment__address-form--state form--state" type="text" placeholder="State"
          formControlName="state" [ngClass]="getStateClass(form.get(['state']))" />
        <input class="form-input payment__address-form--zip form--zip" type="text" placeholder="Zip"
               formControlName="zip" [ngClass]="getStateClass(form.get(['zip']))" />
      </div>

      <div class="form-row form-row--split">
        <input class="form-input payment__address-form--phone" type="text" placeholder="Phone" formControlName="phone"
          [ngClass]="getStateClass(form.get(['phone']))" />
        <input class="form-input" type="text" placeholder="Country" formControlName="country"
          [ngClass]="getStateClass(form.get(['country']))" />
      </div>
    </div>

    <div *ngIf="!isLoggedIn" class="checkbox-wrap">
      <input formControlName="saveShipping" type="checkbox" id="saveShippingCheckbox" value="false" />
      <label for="saveShippingCheckbox"><span class="checkbox-icon"></span> Save Shipping address</label>
    </div>

    <button *ngIf="isLoggedIn" class="save-btn" [disabled]="!form.valid || isLoading" (click)="saveAddress()">
      <span *ngIf="!isLoading; else loading;">Save Address</span>
    </button>
  </div>
</form>

<diy-tax-notice *ngIf="showTaxNotice" [validLicense]="validLicense" (taxFileDeleted)="taxFileDeleted($event)">
</diy-tax-notice>

<ng-template #loading>
  <span>Loading</span>
</ng-template>
