<!-- Header -->
<!--<div class="header-content">-->
<!--  <diy-header></diy-header>-->
<!--</div>-->

<diy-payment-summary [summaryData]="summaryData"></diy-payment-summary>

<!-- Page content -->
<div class="container">
  <div class="payment mt-5">
<!--    <div class="heading-primary pt-normal">-->
<!--      <h1>Payment</h1>-->
<!--      <p>Just a few more details, like who's paying and where your items are going</p>-->
<!--    </div>-->

    <!-- Shipping data -->
    <diy-shipping-address-form [customerShippingData]="customerData?.shipping"
      [formValidation$]="addressFormValidation$" [error$]="shippingError$" [data]="shippingDraftData"
      (formState)="getShippingData($event)">
    </diy-shipping-address-form>

    <!-- Promo Code -->
    <diy-promo-code></diy-promo-code>

    <!-- Billing Data -->
    <diy-billing-address-form [customerBillingData]="customerData?.billing" [formValidation$]="addressFormValidation$"
      [error$]="billingError$" [data]="billingDraftData" (formState)="getBillingData($event)">
    </diy-billing-address-form>

    <!-- Card data -->
    <diy-payment-form *ngIf="!isSalesLoggedIn" [customerCardData]="customerData?.card"
      [formValidation$]="paymentFormValidation$" [error$]="cardError$" (formState)="getPaymentData($event)"
      (submitForm$)="submitForm()"
    >
    </diy-payment-form>

    <diy-sales-payment-form *ngIf="isSalesLoggedIn" [customerId]="customerId" [customerCardData]="customerData?.card"
      [formValidation$]="paymentFormValidation$" [error$]="cardError$" [orderTotal]="orderTotal"
      [isConfirmed$]="isConfirmed$" (formState)="getPaymentData($event)"
      (updatePaymentMethod$)=updatePaymentMethod($event)>
    </diy-sales-payment-form>

    <button *ngIf="isSalesLoggedIn" class="button button--green d-block d-md-none" type="button" [disabled]="processing"
      (click)="triggerValidation(false, true)">
      <span *ngIf="!processing else processingTemplate">Save Orders</span>
    </button>

<!--    <button *ngIf="showConfirmButton" class="button button&#45;&#45;green d-block d-md-none" type="button"-->
<!--      [disabled]="processing" (click)="triggerValidation(true, true)">-->
<!--      <span *ngIf="!processing else processingTemplate">Confirm Payment</span>-->
<!--    </button>-->

    <button *ngIf="isSalesLoggedIn" class="button button--green mr-3" type="button" [disabled]="processing"
      (click)="triggerValidation(false, true)">
      <span *ngIf="!processing else processingTemplate">Save Orders</span>
    </button>
  </div>

  <ng-template #processingTemplate>
    <span>Processing Order...</span>
  </ng-template>
</div>

<div class="confirm-payment-button">
  <button *ngIf="showConfirmButton" class="button button--green" type="button" [disabled]="processing"
          (click)="triggerValidation(true, true)">
    <span *ngIf="!processing else processingTemplate">Confirm Payment</span>
  </button>
</div>

