import { Component, ElementRef, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

import { environment } from '../../../../../../environments/environment';

// Base component
import { PaymentItemBaseComponent } from '../../payment-item-base.component';

// Services
import { CustomerDataProvider } from '../../../../core/api/CustomerDataProvider';
import { CookieService } from '../../../../../core/services/cookie.service';
import { SessionService } from '../../../../../utility/session.service';
import { QuoteService } from '../../../../../core/services/quote.service';
import { ComStr } from '../../../../../core/services/ComStr';

// Types
import { IBillingData } from '../../../../../types/payment/IFormState';
import { UserRole } from '../../../../../types/user/IUser';
import { ICustomerInfo } from '../../../../../types/quote/IQuote';

/**
 * @author Liviu Dima
 */
@Component({
  selector: 'diy-billing-address-form',
  templateUrl: './billing-address-form.component.html',
  styleUrls: ['../address-form.component.scss']
})
export class BillingAddressFormComponent extends PaymentItemBaseComponent implements OnInit, OnChanges {
  form: FormGroup;

  shippingLikeBilling = false;

  @Input() customerBillingData: IBillingData[];

  selectedBillingAddress: IBillingData;

  showBillingForm = true;

  isLoggedIn = false;

  // card data validators definition
  private _billingValidators = {
    firstName: Validators.compose([
      Validators.required,
      Validators.minLength(2)
    ]),
    lastName: Validators.compose([
      Validators.required,
      Validators.minLength(2)
    ]),
    address: Validators.compose([
      Validators.required,
      Validators.minLength(2)
    ]),
    country: Validators.compose([
      Validators.required,
      Validators.minLength(2)
    ]),
    city: Validators.compose([
      Validators.required,
      Validators.minLength(2)
    ]),
    state: Validators.compose([
      Validators.required,
      Validators.minLength(2)
    ]),
    zip: Validators.compose([
      Validators.required,
      Validators.minLength(2)
    ]),
    phone: Validators.compose([
      Validators.required,
      Validators.minLength(2),
      Validators.pattern(ComStr.PHONE_REGEX)
    ])
  };

  /**
   * Class constructor
   * @param _formBuilder
   * @param el
   */
  constructor(
    protected el: ElementRef,
    private _formBuilder: FormBuilder,
    private _cookieService: CookieService,
    private _quoteService: QuoteService,
    private _sessionService: SessionService,
    private _customerApi: CustomerDataProvider
  ) {
    super(el);

    this.form = this._formBuilder.group({
      sameAsShipping: [true],
      firstName: ['', this._billingValidators.firstName],
      lastName: ['', this._billingValidators.lastName],
      address: ['', this._billingValidators.address],
      country: ['', this._billingValidators.country],
      city: ['', this._billingValidators.city],
      state: ['', this._billingValidators.state],
      zip: ['', this._billingValidators.zip],
      phone: ['', this._billingValidators.phone],
      company: [''],
      id: [''],
      saveBilling: [''],
    });

    let firstName = '';
    let lastName = '';

    // first check if a user is logged in
    if (this._sessionService.isLoggedIn()) {
      this.isLoggedIn = true;
      // populate name fields from session
      firstName = this._sessionService.user.firstName;
      lastName = this._sessionService.user.lastName;
    } else {
      const customerInfo: ICustomerInfo = this._quoteService.getCustomerInfo();
      firstName = customerInfo && customerInfo.firstname ? customerInfo.firstname : ' ';
      lastName = customerInfo && customerInfo.lastname ? customerInfo.lastname : ' ';
    }

    this.form.controls['firstName'].setValue(firstName);
    this.form.controls['lastName'].setValue(lastName);
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.data && !this.isLoggedIn) {
      this.form = this._loadFormFromDraft(this.form, this.data);
    }

    if (this.form.get('sameAsShipping').value) {
      this.toggleShipping();
    }

    if (!this.isLoggedIn && !this._sessionService.isSalesLoggedIn()) {
      this.form.valueChanges
        .pipe(
          debounceTime(250)
        )
        .subscribe(newChange => {
          // get data from cookies
          const paymentData = this._cookieService.getJson('paymentData');

          const newPaymentData = Object.assign((paymentData ? paymentData : {}), { billingData: this.form.getRawValue() });
          // update new data in cookies
          this._cookieService.setJson('paymentData', newPaymentData, 14);
        });
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['customerBillingData'] && this.customerBillingData) {
      this.toggleBillingForm(false);
    }
  }


  chooseAddress(billingAddress: IBillingData) {
    this.selectedBillingAddress = billingAddress;
    this.form.patchValue(billingAddress);
    this.isFormValid(this.form);
    if (!this.form.valid) {
      this.errorMessage = 'Invalid billing address';
      return;
    }
    this.errorMessage = '';
  }

  async saveAddress() {
    if (!this.form.valid) {
      // iterate through inputs and mark them as touched for validation
      Object.keys(this.form.controls).forEach(field => {
        const control = this.form.get(field);
        control.markAsTouched({ onlySelf: true });
      });
      return;
    }
    this.isLoading = true;

    const form = this.form.value;
    const billingDetails = {
      sameAsShipping: form.sameAsShipping,
      name: `${form.firstName.trim()} ${form.lastName.trim()}`,
      address: form.address.trim(),
      country: form.country.trim(),
      city: form.city.trim(),
      state: form.state.trim(),
      zip: form.zip.trim(),
      phone: form.phone.trim(),
      id: form.id,
      saveBilling: form.saveBilling
    };

    let resp = null;
    if (this._sessionService.isGranted(UserRole.SALES)) {
      const customerSesData = JSON.parse(sessionStorage.getItem('diy_quote'));
      const customerId = customerSesData.customerData.customerId;
      resp = await this._customerApi.saveBillingAsRep(String(customerId), billingDetails);
    } else {
      resp = await this._customerApi.saveBilling(billingDetails);
    }


    // add response id to form data
    if (resp && resp.id) {
      billingDetails.id = resp.id;

      // update shipping list
      this.customerBillingData.push(billingDetails);

      // mark it as selected
      this.chooseAddress(billingDetails);

      // switch to list view
      this.toggleBillingForm(false);
      this.isLoading = false;
    }
  }

  public toggleBillingForm(flag: boolean) {

    this.showBillingForm = flag;

    this._resetValidators();

    if (this.showBillingForm) {
      this.selectedBillingAddress = null;
      this.el.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      return this._updateValidators(this.form.controls, this._billingValidators);
    }

    return !this.shippingLikeBilling ? this.form.controls.id.setValidators(Validators.required) : '';
  }

  public toggleShipping() {

    this.shippingLikeBilling = !this.shippingLikeBilling;

    let validators = [];

    !this.shippingLikeBilling ? validators = [Validators.required] : this.errorMessage = '';

    for (const key of Object.keys(this.form.controls)) {
      // skip un-required fields
      if (['sameAsShipping', 'saveBilling', 'company'].includes(key)) {
        continue;
      }

      this.form.controls[key].setValidators(validators);
    }
  }

  private _resetValidators() {

    const controls = this.form.controls;

    for (const key in controls) {
      if (controls.hasOwnProperty(key)) {
        controls[key].setValidators([]);
      }
    }
  }

  /**
   * Set validators
   * @param controls
   * @param validators
   */
  private _updateValidators(controls: Object, validators: Object) {
    for (const key in controls) {
      if (controls.hasOwnProperty(key)) {
        controls[key].setValidators(validators[key]);
      }
    }
  }
}
