import { Action } from '@ngrx/store';
import { ICartItem, ICartItemOrder } from '../../types/shopping-cart/ICartItem';
import { IShoppingCart } from '../../types/shopping-cart/IShoppingCart';
import { IVoucher } from '../../types/voucher/IVoucher';
import { IUploadedFile } from '../../types/artwork/IUploadedFile';
import { ISalesTaxResponse } from '../../types/api/ISalesTax';

export enum ShoppingCartActionTypes {
  AddCartItem = '[AppState] Add Cart Item',
  RemoveCartItem = '[AppState] Remove Cart Item',
  RemoveCartItems = '[AppState] Remove Cart Items',
  SetShoppingCart = '[AppState] Set Shopping Cart',
  ClearShoppingCart = '[AppState] Clear Shopping Cart',
  SetInvoiceId = '[AppState] Set Invoice Id',
  UpdateCartItemOrder = '[AppState] Update Cart Item Order',
  RemoveCartItemOrder = '[AppState] Remove Cart Item Order',
  CombineCartItems = '[AppState] Combine Cart Items',
  UngroupCartItemOrder = '[AppState] Ungroup Cart Item Order',
  ApplyDiscountVoucher = '[AppState] Add Discount Voucher',
  RemoveDiscountVoucher = '[AppState] Remove Discount Voucher',
  RemoveQuoteIds = '[AppState] Remove Quote Ids',
  RemovePackagingOption = '[AppState] Remove Packaging Option',
  SetTaxFee = '[AppState] Set Tax Fee',
  SetResellerLicense = '[AppState] Set Reseller License',
  SetQuoteId = '[AppState] Set Quote Id',
}

export class AddCartItem implements Action {
  readonly type = ShoppingCartActionTypes.AddCartItem;
  constructor(public payload: { cartItem: ICartItem, index?: number }) { }
}

export class RemoveCartItem implements Action {
  readonly type = ShoppingCartActionTypes.RemoveCartItem;
  constructor(public index: number) { }
}

export class RemoveCartItems implements Action {
  readonly type = ShoppingCartActionTypes.RemoveCartItems;
  constructor(public indexes: number[]) { }
}

export class SetShoppingCart implements Action {
  readonly type = ShoppingCartActionTypes.SetShoppingCart;
  constructor(public payload: IShoppingCart) { }
}

export class ClearShoppingCart implements Action {
  readonly type = ShoppingCartActionTypes.ClearShoppingCart;
  constructor() { }
}

export class SetInvoiceId implements Action {
  readonly type = ShoppingCartActionTypes.SetInvoiceId;
  constructor(public payload: string) { }
}

export class UpdateCartItemOrder implements Action {
  readonly type = ShoppingCartActionTypes.UpdateCartItemOrder;
  constructor(public payload: { cartItemOrder: ICartItemOrder, index: number }) { }
}

export class RemoveCartItemOrder implements Action {
  readonly type = ShoppingCartActionTypes.RemoveCartItemOrder;
  constructor(public payload: { itemIndex: number, cartIndex: number }) { }
}

export class CombineCartItems implements Action {
  readonly type = ShoppingCartActionTypes.CombineCartItems;
  constructor(public payload: number[]) { }
}

export class UngroupCartItemOrder implements Action {
  readonly type = ShoppingCartActionTypes.UngroupCartItemOrder;
  constructor(public payload: { itemIndex: number, cartIndex: number }) { }
}

export class ApplyDiscountVoucher implements Action {
  readonly type = ShoppingCartActionTypes.ApplyDiscountVoucher;
  constructor(public payload: { voucher: IVoucher, productIds: number[] }) { }
}

export class RemoveDiscountVoucher implements Action {
  readonly type = ShoppingCartActionTypes.RemoveDiscountVoucher;
  constructor() { }
}

export class RemoveQuoteIds implements Action {
  readonly type = ShoppingCartActionTypes.RemoveQuoteIds;
  constructor() { }
}

export class RemovePackagingOption implements Action {
  readonly type = ShoppingCartActionTypes.RemovePackagingOption;
  constructor(public payload: string) { }
}

export class SetTaxFee implements Action {
  readonly type = ShoppingCartActionTypes.SetTaxFee;
  constructor(public payload: ISalesTaxResponse) { }
}

export class SetResellerLicense implements Action {
  readonly type = ShoppingCartActionTypes.SetResellerLicense;
  constructor(public payload: IUploadedFile) { }
}

export class SetQuoteId implements Action {
  readonly type = ShoppingCartActionTypes.SetQuoteId;
  constructor(public payload: { uid: string, quoteId: number, customerEmail: string }) { }
}

export type ShoppingCartActionUnion = AddCartItem | UpdateCartItemOrder | RemoveCartItem | RemoveCartItems |
  SetShoppingCart | ClearShoppingCart | SetInvoiceId | RemoveCartItemOrder | CombineCartItems | UngroupCartItemOrder |
  ApplyDiscountVoucher | RemoveDiscountVoucher | RemoveQuoteIds | RemovePackagingOption | SetTaxFee | SetResellerLicense | SetQuoteId;
