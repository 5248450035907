<div class="form-fileupload no-mb">

  {{ title }}
  <ng-content></ng-content>

  <div class="browse" [ngClass]="{'disabled': disabled}">
    <ul class="image-list">
      <li *ngFor="let file of uploadingFiles | keyvalue">
        <div class="file-name">
          {{ file.key }}

          <ng-container *ngIf="file.value?.id; else uploading">
            <div>
              <!-- Upload done icon -->
              <svg class="icon-ready" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none"
                viewBox="0 0 16 16">
                <path fill="#fff" d="M0 0h16v16H0z" opacity=".01" />
                <path stroke="#39C464" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M13 4l-6 8.003L3 8" />
              </svg>

              <!-- Delete button -->
              <svg class="icon-delete" (click)="delete(file?.key)" xmlns="http://www.w3.org/2000/svg" width="24"
                height="24" fill="none" viewBox="0 0 24 24">
                <path fill="#fff" d="M0 0h24v24H0z" opacity=".01" />
                <path stroke="#979797" stroke-linecap="round" stroke-linejoin="round"
                  d="M7 9h10v10a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1V9zM6 6h12v3H6zM9 6V5a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v1M10 12v5M12 12v5M14 12v5" />
              </svg>
            </div>
          </ng-container>

        </div>

        <ng-template #uploading>
          <div class="uploading-bar">
            <span [ngStyle]="{'width': file.value.percentDone + '%' }"></span>
          </div>
        </ng-template>
      </li>
    </ul>

    <label>
      Browse
      <ng-container [ngSwitch]="multiple">
        <ng-container *ngSwitchCase="true">
          Files
        </ng-container>
        <ng-container *ngSwitchCase="false">
          File
        </ng-container>
        <ng-container *ngSwitchDefault>
          Files
        </ng-container>
      </ng-container>
      <input class="init-file-field" id="file-field" (change)="handleUpload($event)" name="init-file-field" type="file"
        [accept]="acceptTypes" [multiple]="multiple" #fileSelect />
    </label>
    <small *ngIf="maxFileSizeMb">Maximum file size {{ maxFileSizeMb }}MB</small>
    <small *ngIf="allowedExtensions?.length">Allowed extensions
      <b *ngFor="let extension of allowedExtensions"> *.{{ extension }} </b>
    </small>
    <br>
    <p class="error">{{ errorMessage }}</p>
  </div>
</div>
