import { IArtwork } from '../artwork/IArtwork';
import { IProduct } from '../product/IProduct';
import { IPrice } from '../product/IPrice';
import { ISelectedVariation } from '../app-state/ISelectedVariation';

export interface IPackagingOrder {
  product: IProduct;

  artwork: IArtwork;

  price: IPrice;

  packagingSelections: ISelectedVariation[];

  incompatibilities: number[];
}

export enum PackageVariationName {
  SIZE = 'PackageSize',
  TYPE = 'PackageType',
  OPTIONS = 'PackageOptions',
  QUANTITY = 'PackageQuantity'
}
