import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * Add here any sharable design functionality*
 */
@Injectable()
export class PageHeightFixService {

  // used to keep the turnaround page higher than the order summary
  tallestPageHeight: BehaviorSubject<number> = new BehaviorSubject<number>(0);
}
