import { HostListener, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

// Base component
import { IdleBaseComponent } from '../idleTracking/idle.component';

// Services
import { DraftService } from '../../services/draft.service';
import { TrackingService } from '../../services/tracking';
import { SessionService } from '../../../utility/session.service';
import { CookieService } from '../../services/cookie.service';

/**
 * @author Liviu Dima
 */

export abstract class DraftBaseComponent extends IdleBaseComponent implements OnDestroy {

  private _subscription: Subscription;

  constructor(
    protected draftService: DraftService,
    protected router: Router,
    protected tracking: TrackingService,
    protected session: SessionService,
    protected cookies: CookieService,
  ) {
    super(tracking, router, session, cookies);

    this._subscription = router.events.subscribe(value => {
      if (value instanceof NavigationEnd) {
        this.draftService.saveDraft(true, value.id);
      }
    });
  }

  @HostListener('window:unload', ['$event'])
  async unloadEventHandler(event) {
    await this.draftService.saveDraft();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

}
