import { Injectable } from '@angular/core';

import { PriceCalculatorService } from '../../../core/services/price-calculator';

import {
  IPaymentSummary,
  IPaymentItem,
  IPaymentOption,
  IPaymentOptionSelections,
} from '../../../types/payment/IPaymentSummary';
import { IVariation } from '../../../types/product/variations/IVariation';
import { IPrice } from '../../../types/product/IPrice';
import { IShoppingCart } from '../../../types/shopping-cart/IShoppingCart';
import { ISelectedVariation } from '../../../types/app-state/ISelectedVariation';
import { AvailableVariationTypeTypes, PackagingVariationTypeTypes } from '../../../types/product/variations/IVariationType';
import { ICartItemOrder } from '../../../types/shopping-cart/ICartItem';
import { IPackagingOrder } from '../../../types/app-state/IPackagingOrder';

@Injectable()
export class PaymentSummaryService {

  formatShoppingCart(shoppingCart: IShoppingCart): IPaymentSummary {
    let cartItemOrders: ICartItemOrder[] = [];
    if (shoppingCart && shoppingCart.cartItems && shoppingCart.cartItems.length) {
      shoppingCart.cartItems.forEach(cartItem => {
        cartItemOrders = cartItemOrders.concat(cartItem.cartItemOrders);
      });
      if (cartItemOrders.length) {
        const items: IPaymentItem[] = [];
        cartItemOrders.forEach(cartItemOrder => {
          const savings = this._getItemSavings(cartItemOrder.orderItem.price);
          const options: IPaymentOption[] = [];
          const size = this._getItemSize(cartItemOrder.orderItem.variationTypes);
          options.push({
            name: size,
            price: cartItemOrder.orderItem.price.customizeTotal,
            selections: this._getQtySelections(cartItemOrder.orderItem.variationTypes, cartItemOrder.orderItem.price)
          });
          options.push(this._formatSpecialOptions(
            cartItemOrder.orderItem.specialOptions,
            cartItemOrder.orderItem.price.pricePerUnit,
            cartItemOrder.orderItem.price.quantity
          ));
          const product = {
            name: cartItemOrder.orderItem.product.name,
            price: cartItemOrder.orderItem.price.finalTotal,
            savings: savings,
            voucher: cartItemOrder.orderItem.price.voucher,
            options: options
          };
          const shippingSelections = [];
          shippingSelections.push({ name: cartItemOrder.orderItem.turnaround.selectedOption.description });

          if (
            cartItemOrder.packagingOrder &&
            cartItemOrder.packagingOrder.packagingSelections &&
            cartItemOrder.packagingOrder.packagingSelections.length
          ) {
            options.push(this._formatPackaging(cartItemOrder.packagingOrder));
          }

          options.push({
            name: 'Shipping Method:',
            price: cartItemOrder.orderItem.price.shippingTotal,
            selections: shippingSelections
          });

          if (cartItemOrder.orderItem.price.guaranteedFee) {
            options.push({
              name: 'Guaranteed Date',
              price: cartItemOrder.orderItem.price.guaranteedFee
            });
          }

          items.push({
            product: product,
            total: cartItemOrder.orderItem.price.finalTotal
          });
        });

        return {
          items: items,
          total: shoppingCart.orderTotal,
          taxFee: shoppingCart.taxFee
        };
      }
    }
  }

  private _formatSpecialOptions(specialOptions, price: number, quantity: number): IPaymentOption {
    if (specialOptions && specialOptions.length) {
      let total = 0;
      const selections = specialOptions.map(specialOption => {
        total += PriceCalculatorService.getVariationPrice(
          (specialOption.variation as IVariation),
          quantity, price
        );
        return {
          name: `${specialOption.vtName} - ${specialOption.variation.name}`
        };
      });

      return {
        name: 'Customizations:',
        price: total,
        selections: selections
      };
    }
  }

  private _getItemSize(variationTypes: ISelectedVariation[]): string {
    if (variationTypes && variationTypes.length) {
      const size = variationTypes.find(variation =>
        variation.vtCategory === AvailableVariationTypeTypes.size ||
        variation.vtCategory === PackagingVariationTypeTypes.size
      );
      if (size && size.variation) {
        return size.variation['customName'] ?
          `${size.variation['customName'].replace('(', '').replace(')', '')}` :
          `${size.variation.name}`;
      }
    }
  }

  private _getItemSavings(itemPrice: IPrice): number {
    return itemPrice.promotionalDiscountValue ?
      itemPrice.discountAmount + itemPrice.promotionalDiscountValue :
      itemPrice.discountAmount;
  }

  private _getQtySelections(variationTypes: ISelectedVariation[], price: IPrice): IPaymentOptionSelections[] {
    const qtySelections: IPaymentOptionSelections[] = [];
    const qty = variationTypes.find(vt => vt.vtCategory === AvailableVariationTypeTypes.quantity);
    if (qty && qty.variation) {
      if (qty.variation.name === 'Bundle Quantity') {
        qtySelections.push({
          name: `${qty.variation['unitsAvailable']} QTY x ${qty.variation['unitsAvailable']} Credits at $0.00`
        });
        if (qty.variation['unitsSelected'] > qty.variation['unitsAvailable']) {
          qtySelections.push({
            name: `${qty.variation['unitsSelected'] - qty.variation['unitsAvailable']} QTY x
            $${price.pricePerUnit.toFixed(2)}
            at $${(price.pricePerUnit * (qty.variation['unitsSelected'] - qty.variation['unitsAvailable'])).toFixed(2)}`
          });
        }
      } else {
        const qtyValue = qty.variation['customName'] ? `${qty.variation['customName']}` : `${qty.variation.name}`;
        qtySelections.push({
          name: `${qtyValue.replace('(', '').replace(')', '')} QTY x $${price.pricePerUnit.toFixed(2)}`
        });
      }
    }
    return qtySelections;
  }

  private _formatPackaging(packagingOrder: IPackagingOrder): IPaymentOption {
    const packageSize = this._getItemSize(packagingOrder.packagingSelections);
    const selections: IPaymentOptionSelections[] = [];
    selections.push({
      name: packageSize,
    });

    packagingOrder.packagingSelections.forEach(selection => {
      if (selection.vtCategory === PackagingVariationTypeTypes.quantity ||
        selection.vtCategory === PackagingVariationTypeTypes.size) {
        return;
      }
      selections.push({
        name: `${selection.vtName} - ${selection.variation.name}`
      });
    });

    return {
      name: 'Packaging Added',
      price: packagingOrder.price.finalTotal,
      selections: selections
    };
  }
}
