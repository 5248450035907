import { Component, Input } from '@angular/core';

@Component({
  selector: 'diy-image-loader',
  styles: [
    '.loading-img-spinner{width:100%;margin:0 auto;}',
    'diy-image-loader{width:100%;}'
  ],
  template: `<div *ngIf="!loaded" class="loading-img-spinner"><diy-spinner></diy-spinner></div>
              <img [hidden]="!loaded" (load)="loaded = true" [src]="src" style="height:100%; margin:0 auto;    display: block;"/>`
})
export class ImageLoaderComponent {
  @Input() src;
  loaded = false;
}
