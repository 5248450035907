import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { SessionService } from '../../utility/session.service';
import { OrderMessengerService } from '../../core/services/order-messenger';

import { IScript } from '../../types/api/IToken';

const NOTES = 'diy_scripts';

/**
 *
 * @author Andrei Diaconu
 */
@Component({
  selector: 'diy-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnDestroy {

  notes: string;
  isCollapsed = true;

  private _subscriptions$: Subscription[] = [];

  constructor(
    private _session: SessionService,
    private _oms: OrderMessengerService
  ) {
    const subscription$ = this._oms.getStep().subscribe(value => {
      const notes = this._getNotes(value);
      if (notes) {
        this.notes = notes.script;
      }
    });
    this._subscriptions$.push(subscription$);
  }


  ngOnDestroy() {
    this._subscriptions$.map(subscription$ => subscription$.unsubscribe());
  }

  toggle(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  isLoggedIn(): boolean {
    return this._session.isSalesLoggedIn();
  }

  private _getNotes(step: number): IScript {
    try {
      const notesString = sessionStorage.getItem(NOTES);
      if (notesString) {
        const notes = JSON.parse(notesString);
        return notes.find(note => note.step === step);
      }
    } catch (err) {

    }
  }

}
