<!-- Header -->
<div class="header-content">
  <diy-header></diy-header>
  <diy-order-details></diy-order-details>
  <diy-promo-banner></diy-promo-banner>
  <!-- we have the ngIf as we are setting currentStep after resolving route -->
  <diy-order-step-tracking *ngIf="currentStep" [currentStep]="currentStep"></diy-order-step-tracking>
</div>

<!-- Page content -->
<div class="container">
  <div *ngIf="!isLoading" class="draft draft__content">
    <h1 class="draft__content--title"> Heads Up </h1>
    <div *ngFor="let draft of drafts">
      <p class="draft__content--message">
        We noticed that you created a draft {{draft.productName}} order recently. Would you like to pick up where you
        left off?
      </p>
      <div class="draft__content--buttons">
        <button (click)="resumeDraft(draft)" class="draft__content--button button button--green">Yes</button>
      </div>
    </div>

    <button (click)="startFresh()" class="draft__content--button button button--green"> No, discard all drafts </button>

  </div>
</div>
