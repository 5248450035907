import { Component, Input } from '@angular/core';

import { environment } from '../../../../../environments/environment';

import { IPrice } from '../../../../types/product/IPrice';
import { IBundleItem } from '../../../../types/bundle/IBundle';

@Component({
  selector: 'diy-price-info',
  templateUrl: './price-info.component.html',
  styleUrls: ['./price-info.component.scss']
})
export class PriceInfoComponent {

  resellerTaxActive = false;

  @Input() price: IPrice;
  @Input() bundleItem: IBundleItem;

  constructor() {
    this.resellerTaxActive = environment.resellerTaxActive;
  }

}
