import { Component, ElementRef, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import smoothscroll from 'smoothscroll-polyfill'; // safary poylfill
import { TSModalComponent } from 'tscommon';

// Services
import { AmplitudeService } from '../../core/services/amplitude';
import { VariationManager } from '../../core/services/variation-manager.service';
import { SessionService } from '../../utility/session.service';
import { DraftService } from '../../core/services/draft.service';
import { NavigatorService } from '../../core/services/navigator';
import { CampaignTrackingService } from '../../core/services/campaign-tracking.service';
import { TrackingService } from '../../core/services/tracking';
import { CookieService } from '../../core/services/cookie.service';

// API Provider
import { SalesRepProductFlowApi } from '../../core/api/salesRepProductFlowApi';

import { DraftBaseComponent } from '../../core/components/draft/draftBase.component';

// Types
import { IOrderItem } from '../../types/app-state/IOrderItem';
import { IVariationType } from '../../types/product/variations/IVariationType';
import { ISelectedVariation } from '../../types/app-state/ISelectedVariation';
import { IOrderArtwork } from '../../types/api/IOrderPayload';
import { IDesign } from '../../types/api/IDesign';
import { ProductOrderSteps } from '../../types/step/IStep';

@Component({
  selector: 'diy-special-options',
  templateUrl: './special-options.component.html',
  styleUrls: ['./special-options.component.scss']
})
export class SpecialOptionsComponent extends DraftBaseComponent implements OnInit, OnDestroy {

  selectedOptions: ISelectedVariation[];
  requiredSpecialOptions: IVariationType[];
  optionalSpecialOptions: IVariationType[];

  orderItem: IOrderItem;
  isSpecialAdmin = false;
  isLoading = false;

  // Define current step for step-tracker
  currentStep: number = ProductOrderSteps.specialOptions;
  currentGroup = 0;

  // ids of all required variation types (Special Options)
  requiredIds: number[] = [];
  showError = false;
  optionsInitialized = false;

  @ViewChild('saveSuccessModal', { static: true }) saveSuccessModal: TSModalComponent;

  private _orderItem$: Observable<IOrderItem> = this._activatedRoute.snapshot.data.orderItem;
  private _subscriptions$: Subscription[] = [];

  constructor(
    protected draftService: DraftService,
    protected router: Router,
    protected tracking: TrackingService,
    protected session: SessionService,
    protected cookies: CookieService,
    private _elementRef: ElementRef,
    private _activatedRoute: ActivatedRoute,
    private _amp: AmplitudeService,
    private _salesRepProductFlowApi: SalesRepProductFlowApi,
    private _navigator: NavigatorService,
    private _campaignTrackingService: CampaignTrackingService,
  ) {
    super(draftService, router, tracking, session, cookies);
    this._campaignTrackingService.handleCampaignTracking(this._activatedRoute.snapshot.queryParams);
  }

  async ngOnInit() {
    // kick off the polyfill!
    smoothscroll.polyfill();

    if (this.session.isSalesLoggedIn()) {
      this.isSpecialAdmin = this.session.isSpecialAdmin();
    }

    const subscription$ = this._orderItem$.subscribe((data: IOrderItem) => {

      this.orderItem = { ...data };

      this.selectedOptions = this.orderItem.specialOptions;

      this.optionsInitialized = true;
    });

    this._subscriptions$.push(subscription$);

    const orderItem = await this._orderItem$.pipe(take(1)).toPromise();

    this.requiredSpecialOptions = orderItem.product.specialOptions.filter(arrayElement => arrayElement.required)
      .map(specialOption => ({ ...specialOption, variations: specialOption.variations.filter(variation => variation.showInDIY) }));
    this.optionalSpecialOptions = orderItem.product.specialOptions.filter(arrayElement => !arrayElement.required)
      .map(specialOption => ({ ...specialOption, variations: specialOption.variations.filter(variation => variation.showInDIY) }));

    const quantity = VariationManager.getQuantityFromStack(orderItem.variationTypes);

    // start with required tab if any exist.
    if (this.requiredSpecialOptions.length > 0) {
      this.requiredIds = this.requiredSpecialOptions.map(option => Number(option.id));
    }

    this._amp.logEvent('Navigation', {
      page: 'special options',
      navigationStep: 5,
      category: orderItem.category.name,
      category_id: orderItem.category.id,
      productName: orderItem.product.name,
      product_id: orderItem.product.id,
      quantity: quantity
    });

    this.tracking.sendAltoCloudData(['pageview', {
      location: location.pathname,
      title: 'Special Options'
    }]);

    this.optionsInitialized = true;
  }

  async OnDestroy() {
    super.ngOnDestroy();
    this._subscriptions$.map(subscription => subscription.unsubscribe());
  }

  async nextStep() {
    if (this.isValid()) {
      this.showError = false;
      await this._navigator.navigate('artwork', this._activatedRoute.snapshot.queryParams);
    } else {
      this.tracking.sendAltoCloudData(['record', 'userStuck']);
      const remainingIDs = this._getRemainingRequired();
      const target = this._elementRef.nativeElement.querySelector('#spOption_' + remainingIDs[0]);
      this._scroll(target);
      this.showError = true;
    }
  }

  async saveDesign() {
    if (!this.isValid()) {
      this.showError = true;
      return;
    }
    this.isLoading = true;
    const variationIds: number[] = this.orderItem.variationTypes.map(selectedVariation => +selectedVariation.variation.id);
    const specialOptionsIds: number[] = this.orderItem.specialOptions.map(specialOption => +specialOption.variation.id);
    let artwork: IOrderArtwork;
    if (this.orderItem.artwork && this.orderItem.artwork.files && this.orderItem.artwork.files.length) {
      artwork = {
        files: this.orderItem.artwork.files.map(file => {
          return {
            url: file.url,
            name: file.name
          };
        }),
        description: this.orderItem.artwork.description
      };
    }

    const newDesign: IDesign = {
      productId: this.orderItem.product.id,
      artwork: artwork || false,
      variations: variationIds,
      options: specialOptionsIds
    };

    const res = await this._salesRepProductFlowApi.saveDesign(newDesign);
    if (res && res.success) {
      this.saveSuccessModal.show();

      setTimeout(() => {
        this.router.navigate(['/']);
      }, 1500);
    }
  }

  isValid() {

    // don't trigger validation until the component has finished initializing
    if (!this.optionsInitialized) {
      return false;
    }
    const remainingIds = this._getRemainingRequired();
    return remainingIds.length <= 0;
  }

  closeSuccessModal() {
    this.saveSuccessModal.hide();
  }

  scrollToNextSection(tabIndex: number) {
    const nextOptionGroup = this._elementRef.nativeElement.querySelector(`#sp${tabIndex + 1}`);
    if (nextOptionGroup) {
      this.currentGroup = tabIndex;
      nextOptionGroup.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  }

  private _getRemainingRequired(): number[] {

    let remainingIds: number[] = [];

    if (this.requiredIds.length < 0) {
      return remainingIds;
    }

    remainingIds = this.requiredIds.slice(0);

    for (let i = 0; i < this.selectedOptions.length; i++) {

      const position = remainingIds.indexOf(Number(this.selectedOptions[i].vtID));

      if (position > -1) {
        remainingIds.splice(position, 1);
      }
    }

    return remainingIds;
  }

  private _scroll(el) {
    el.scrollIntoView(true);
    window.scrollBy(0, -150);
  }

}
