import { Component, OnInit, Input } from '@angular/core';

// Services
import { StepGenerator } from '../../../core/services/step-generator.service';

// Types
import { IStep, StepTypes } from '../../../types/step/IStep';

/**
 * Component for rendering packaging order step tracking
 * @author Liviu Dima
 */
@Component({
  selector: 'diy-packaging-step-tracking',
  templateUrl: './packaging-step-tracking.component.html',
  styleUrls: ['./packaging-step-tracking.component.scss']
})
export class PackagingStepTrackingComponent implements OnInit {

  @Input() currentStep: number;

  orderSteps: IStep[];
  activeStep: IStep;

  constructor(
    private _stepGenerator: StepGenerator,
  ) { }

  async ngOnInit() {
    this.orderSteps = await this._stepGenerator.getSteps(StepTypes.PACKAGING_ORDER_STEPS);
    const currentStep = this.orderSteps.find(step => step.number === Math.trunc(this.currentStep));
    this.activeStep = { ...currentStep, logStep: this.currentStep };
  }
}
