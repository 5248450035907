import { Injectable } from '@angular/core';

@Injectable()
export class NavigationService {
  private _clickedBack = false;

  constructor() { }

  set clickedBack(clicked: boolean) {
    this._clickedBack = clicked;
  }

  get clickedBack(): boolean {
    return this._clickedBack;
  }
}
