import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { environment } from '../../../environments/environment';

import { SessionService } from '../../utility/session.service';

import { IShoppingCart } from '../../types/shopping-cart/IShoppingCart';
import { IAppState } from '../../types/app-state/IAppState';

@Component({
  selector: 'diy-header',
  templateUrl: './header.component.html',
  styleUrls: ['header.component.scss']
})
export class HeaderComponent implements OnDestroy {

  telNumber: string;
  telShown: string;
  totalCartItems: number;
  userName: string;
  customerDashURL: string;
  customerDashboardOrigin = false;

  private _shoppingCart: IShoppingCart;
  private _subscriptions$: Subscription[] = [];

  constructor(
    private _store: Store<IAppState>,
    private _session: SessionService,
    private _router: Router
  ) {
    this.telNumber = environment.salesTel;
    this.telShown = environment.salesTelShown;
    this.customerDashURL = environment.customerDashURL;
    this.customerDashboardOrigin = !!_session.CDOrigin;

    let subscription$: Subscription;

    subscription$ = this._store.pipe(select('shoppingCart'))
      .subscribe((data: IShoppingCart) => {
        this._shoppingCart = data;
        this.totalCartItems = this._getItemsTotal();
      });

    this._subscriptions$.push(subscription$);
  }

  ngOnDestroy() {
    this._subscriptions$.map(subscription => subscription.unsubscribe());
  }

  logout(): void {
    this._session.resetSession();
    this._router.navigate(['/']);
  }

  isLoggedIn(): boolean {
    return this._session.isLoggedIn();
  }

  private _getItemsTotal(): number {
    let total = 0;
    if (this._shoppingCart.cartItems.length) {
      this._shoppingCart.cartItems.forEach((cartItem) => {
        total += cartItem.cartItemOrders.length;
      });
    }
    return total;
  }

}
