import { Router, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { NavigatorService } from '../core/services/navigator';

import { OrderItemActionTypes } from './order-item/order-item.actions';
import { PackagingOrderActionTypes } from './packaging-order/packaging-order.actions';

@Injectable()
export class URLEffects {

  constructor(
    private _router: Router,
    private _actions: Actions,
    private _navigator: NavigatorService,
    private _location: Location,
    private _activatedRoute: ActivatedRoute,
  ) { }

  @Effect({ dispatch: false })
  syncVariationState(): Observable<Action> {
    return this._actions.pipe(
      ofType(
        OrderItemActionTypes.AddVariation,
        OrderItemActionTypes.RemoveVariation,
        OrderItemActionTypes.AddSpecialOption,
        OrderItemActionTypes.RemoveSpecialOption,
        OrderItemActionTypes.UpdateTurnaround,
        PackagingOrderActionTypes.AddPackagingVariation,
        PackagingOrderActionTypes.ResetPackagingOrder,
      ),
      tap(async () => await this._updateUrl())
    );
  }

  /**
   * Update URL to reflect the state of the app
   */
  private async _updateUrl() {
    const queryParams = await this._navigator.generateStateQueryParams();

    const cartOrderParams = this._navigator.getCartOrderParams(this._activatedRoute.snapshot.queryParams);

    const url = this
      ._router
      .createUrlTree([], { queryParams: { ...queryParams, ...cartOrderParams } })
      .toString();

    return this._location.replaceState(url);
  }

}
