import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { SessionService } from '../../utility/session.service';

import { IBundle, IBundleCheck, IBundleItem } from '../../types/bundle/IBundle';
import { DataTransformerService } from './data-transformer.service';


@Injectable()
export class BundleProvider {

  constructor(
    private _http: HttpClient,
    private _sessionService: SessionService
  ) { }

  private routes = {
    listBundles: environment.baseUrl + 'api/diy/sales/list-bundles',
    checkCustomerItems: environment.baseUrl + 'api/diy/sales/check-customer-items',
    getItems: environment.baseUrl + 'api/cd/home/items',
  };

  getOptions() {
    return;
  }

  async getAvailableItems(productId: string, email: string): Promise<IBundleItem[]> {
    try {
      const headers = this._createAuthorizationHeader();
      let params = new HttpParams().set('product', productId);
      params = params.append('email', email);

      const res: any = await this._http.get(this.routes.getItems, { headers: headers, params: params }).toPromise();

      if (res.items) {
        return DataTransformerService.createBundleItems(res.items);
      }
      return [];
    } catch (err) {
      return err.error;
    }
  }

  async getBundle(bundleId: string): Promise<IBundle> {
    try {
      const params = new HttpParams().set('id', bundleId);

      const res: any = await this._http.get(this.routes.listBundles, {
        params: params
      }).toPromise();

      if (res && res.bundles) {
        return res.bundles[0] as IBundle;
      }
    } catch (err) {
      return err.error;
    }
  }

  async checkBundle(productId: number, email: string): Promise<boolean> {
    try {
      let params = new HttpParams().set('email', email);
      params = params.append('product', String(productId));

      const res = await this._http.get(this.routes.checkCustomerItems, {
        params: params
      }).toPromise();
      return (res as IBundleCheck).items;
    } catch (err) {
      return err.error;
    }
  }

  /**
   * todo replace with interceptor maybe?
   */
  private _createAuthorizationHeader(): HttpHeaders {
    const headers = new HttpHeaders();
    return headers.append('Authorization', `Bearer ${this._sessionService.token}`);
  }

}
