import {
  Component,
  ChangeDetectorRef,
  AfterViewChecked,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import {Subscription, Subject, zip} from 'rxjs';

import { MultipleOrdersDraftBaseComponent } from '../../../core/components/draft/multiple-orders.draftBase.component';

import { environment } from '../../../../environments/environment';

import { CustomerDataProvider } from '../../core/api/CustomerDataProvider';
import { InvoiceApi } from '../../core/api/invoiceApi';
import { DataTransformerService } from '../../../core/api/data-transformer.service';

import { OrderMessengerService } from '../../../core/services/order-messenger';
import { AmplitudeService } from '../../../core/services/amplitude';
import { TrackingService } from '../../../core/services/tracking';
import { DraftService } from '../../../core/services/draft.service';
import { CookieService } from '../../../core/services/cookie.service';
import { QuoteService } from '../../../core/services/quote.service';
import { PaymentSummaryService } from '../../core/services/payment-summary.service';
import { SessionService } from '../../../utility/session.service';

import { SetInvoiceId, ClearShoppingCart, SetTaxFee, SetResellerLicense } from '../../../state-manager/shopping-cart/shopping-cart.actions';

import { IAppState } from '../../../types/app-state/IAppState';
import { ICustomerInfo } from '../../../types/quote/IQuote';
import { IShoppingCart } from '../../../types/shopping-cart/IShoppingCart';
import { ITransactionData } from '../../../types/api/ITransactionData';
import { IPaypalInvoiceResponse } from '../../../types/api/IPaypalInvoiceResponse';

import {PaymentFormComponent} from './payment-form/payment-form.component';

import {
  IFormState,
  IShippingData,
  ICardData,
  IBillingData,
  IPaymentError,
  IPaymentData,
  IFormValidation
} from '../../../types/payment/IFormState';
import { UserRole, IUser } from '../../../types/user/IUser';
import { PaymentMethod } from '../../../types/payment/PaymentMethod';
import { ICustomerPaymentData } from '../../../types/api/ICustomerPaymentData';
import { ICartItemOrder } from '../../../types/shopping-cart/ICartItem';
import { IPaymentSummary } from '../../../types/payment/IPaymentSummary';

declare var paypal;

@Component({
  selector: 'diy-payment-component',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent extends MultipleOrdersDraftBaseComponent implements AfterViewChecked, OnDestroy, OnInit {

  @ViewChild(PaymentFormComponent) paymentFormComponent: PaymentFormComponent;

  processing = false;

  customerData: ICustomerPaymentData;

  // trigger validation for payment form components
  paymentFormValidation$: Subject<IFormValidation> = new Subject();

  // trigger validation for address form components
  addressFormValidation$: Subject<IFormValidation> = new Subject();

  // handling errors for form components
  shippingError$: Subject<IPaymentError> = new Subject<IPaymentError>();
  billingError$: Subject<IPaymentError> = new Subject<IPaymentError>();
  cardError$: Subject<IPaymentError> = new Subject<IPaymentError>();

  // draft data
  shippingDraftData: IShippingData;
  billingDraftData: IShippingData;
  cardDraftData: IShippingData;

  shoppingCart: IShoppingCart;
  summaryData: IPaymentSummary;

  // payment method
  showConfirmButton = true;

  orderTotal: number;

  customerId: number;

  isSalesLoggedIn = this.session.isSalesLoggedIn();

  private _customerInfo: ICustomerInfo;
  private _confirmPayment: boolean;

  private _subscriptions$: Subscription[] = [];

  private _shippingData$ = new Subject<IFormState>();
  private _billingData$ = new Subject<IFormState>();
  private _paymentData$ = new Subject<IFormState>();

  private _promoCode: string;

  private paypalInvoiceCreated = false;

  constructor(
    protected draftService: DraftService,
    protected router: Router,
    protected tracking: TrackingService,
    protected session: SessionService,
    protected cookies: CookieService,
    private _amp: AmplitudeService,
    private _oms: OrderMessengerService,
    private _invoiceApi: InvoiceApi,
    private _customerApi: CustomerDataProvider,
    private _ref: ChangeDetectorRef,
    private _store: Store<IAppState>,
    private _quoteService: QuoteService,
    private _paymentSummaryService: PaymentSummaryService,
  ) {
    super(draftService, router, tracking, session, cookies);

    let subscription$: Subscription;

    this._oms.updateStep(7);

    subscription$ = this._store.pipe(select('shoppingCart'))
      .subscribe((data: IShoppingCart) => {
        this.shoppingCart = data;

        // If there are no items in shopping cart, redirect to home
        // if (!this.shoppingCart.cartItems.length) {
        //   this.router.navigate(['/']);
        // }

        this.summaryData = this._getInvoiceSummaryData();

        this._promoCode = '';
        data.cartItems.forEach(cartItem => {
          cartItem.cartItemOrders.forEach(cartItemOrder => {
            if (cartItemOrder.orderItem.price.voucher) {
              this._promoCode = cartItemOrder.orderItem.price.voucher.code;
            }
          });
        });

        this.orderTotal = data.orderTotal;
      });

    this._subscriptions$.push(subscription$);

    if (environment.enableLeadCapture) {
      this._customerInfo = this.cookies.getJson('customerInfo');
    }

    subscription$ = zip(
      this._shippingData$,
      this._billingData$,
      this._paymentData$
    ).subscribe((formsState: Array<IFormState>) => {
      const values = formsState.map(data => data.formData);

      // if confirm payment button was pressed send cardData else send paymentData
      const payment: ICardData | IPaymentData = !this.isSalesLoggedIn ? (values[2] as ICardData) : (values[2] as IPaymentData);

      if (formsState[2].isValid && this.isSalesLoggedIn && !this._confirmPayment) {
        if (formsState[0].formData['saveShipping'] && !formsState[0].isValid) {
          return this._handleErrors(!formsState[0].isValid, !formsState[1].isValid, !formsState[2].isValid);
        }
        return this._processOrder((values[0] as IShippingData), (values[1] as IBillingData), payment);
      }

      if (formsState[0].isValid && formsState[1].isValid && formsState[2].isValid) {
        return this._processOrder((values[0] as IShippingData), (values[1] as IBillingData), payment);
      }

      return this._handleErrors(!formsState[0].isValid, !formsState[1].isValid, !formsState[2].isValid);
    });

    this._subscriptions$.push(subscription$);

    // if (!this.session.isLoggedIn()) {
    this._loadDataFromDraft();
    // }


    const productsIds = this.shoppingCart.cartItems
      .reduce((accumulator, nextItem) => [...accumulator, ...nextItem.cartItemOrders], [])
      .map((cartItemOrder: ICartItemOrder) => cartItemOrder.orderItem.product.id);

    this._amp.logEvent('Navigation', {
      page: 'payment',
      navigationStep: 7,
      product_ids: JSON.stringify(productsIds)
    });

  }

  async ngOnInit() {
    // get data as sales
    if (this.session.isLoggedIn()) {
      this.customerData = await this._getCustomerData() as ICustomerPaymentData;
    }

    this.tracking.sendAltoCloudData(['pageview', {
      location: location.pathname,
      title: 'Payment'
    }]);

    if (!this.isSalesLoggedIn) {
      // this.tracking.gtagAction('begin_checkout', this.shoppingCart);
    }
  }

  /**
   * Try to get customer data either for user or sales rep logged as user
   */
  private async _getCustomerData() {
    const user = this.session.user;

    let resp = null;
    if (user.role === UserRole.CUSTOMER) {
      resp = await this._customerApi.getCustomerData();
    } else if (user.role === UserRole.SALES) {
      const customerSesData = JSON.parse(sessionStorage.getItem('diy_quote'));
      if (customerSesData && customerSesData.customerData && customerSesData.customerData.customerId) {
        this.customerId = customerSesData.customerData.customerId;
        resp = await this._customerApi.getCustomerDataAsRep(String(this.customerId));
      }
    }

    if (resp) {
      return {
        shipping: resp.shippingData,
        billing: resp.billingData,
        card: resp.cardData
      };
    }

    this.session.resetSession();
    return this.router.navigate(['/shop/login']);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscriptions$.map(subscription => subscription.unsubscribe());
  }

  ngAfterViewChecked() {
    this._ref.detectChanges();

    // Hide live chat pop-up from payment page
    // const zopim: HTMLElement = document.querySelector('.zopim');
    // if (zopim) {
    //   zopim.style.display = 'none';
    // }
  }

  /**
   * Trigger validation
   */
  triggerValidation(confirmPayment: boolean, confirmAddress: boolean) {
    this._confirmPayment = confirmPayment;
    this.paymentFormValidation$.next({
      validate: true,
      isConfirmed: confirmPayment
    });

    this.addressFormValidation$.next({
      validate: confirmAddress,
      isConfirmed: confirmAddress
    });
  }

  /**
   * Emit new value for received shipping data
   * @param data
   */
  getShippingData(data: IFormState) {
    const formData = (data.formData as IShippingData);
    formData.name = `${formData.firstName} ${formData.lastName}`;
    delete formData.firstName;
    delete formData.lastName;

    data.formData = Object.assign({}, formData);

    window.dataLayer.push({
      'event': 'add_shipping_info',
      'ecommerce': {
        'currencyCode': 'USD',
        'value': this.shoppingCart.orderTotal,
        'shipping_tier': 'standard',
        'items': this.getProductsInCart()
      }
    });

    this._shippingData$.next(data);
  }

  /**
   * Emit new value for received billing data
   * @param data
   */
  getBillingData(data: IFormState) {
    const formData = (data.formData as IBillingData);
    formData.name = `${formData['firstName']} ${formData['lastName']}`;
    delete formData.firstName;
    delete formData.lastName;

    data.formData = Object.assign({}, formData);

    // window.dataLayer.push({
    //   'event': 'checkout-step',
    //   'ecommerce': {
    //     'currencyCode': 'USD',
    //     'checkout': {
    //       'actionField': {
    //         'step': '3', // begin at 1 and increment from there
    //       },
    //       'products': this.getProductsInCart()
    //     }
    //   }
    // });

    this._billingData$.next(data);
  }

  /**
   * Emit new value for received card data
   * @param data
   */
  getPaymentData(data: IFormState) {
    window.dataLayer.push({
      'event': 'add_payment_info',
      'ecommerce': {
        'currencyCode': 'USD',
        'value': this.shoppingCart.orderTotal,
        'payment_type': (data.formData as ICardData).paypal ? 'paypal' : 'card',
        'items': this.getProductsInCart()
      }
    });

    this._paymentData$.next(data);
  }

  /**
   * Display confirm button only for card payments
   */
  updatePaymentMethod(method: string) {
    this.showConfirmButton = method === PaymentMethod.Card;
  }

  submitForm() {
    if ( this.paypalInvoiceCreated ) {
      this.paymentFormComponent.showPaypalModal();
    } else {
      this.paymentFormComponent.isLoading = true;
      this.triggerValidation(true, true);
    }
  }
  /**
   * Check if sales person is logged in
   */
  customerLoggedIn(): boolean {
    return this.session.isCustomerLoggedIn();
  }

  /**
   * Update billing data if same as shipping
   * @param shippingData
   */
  private _patchBillingData(shippingData: IShippingData): IBillingData {
    return Object.assign({}, {
      address: shippingData.address,
      city: shippingData.city,
      country: shippingData.country,
      phone: shippingData.phone,
      state: shippingData.state,
      zip: shippingData.zip,
      name: shippingData.name,
      sameAsShipping: true
    });
  }

  /**
   * Send order to server
   * @param shippingData
   * @param billingData
   * @param cardData
   */
  private async _processOrder(
    shippingData: IShippingData,
    billingData: IBillingData,
    paymentData: ICardData | IPaymentData
  ): Promise<void> {
    this.processing = true;

    if (billingData.sameAsShipping) {
      billingData = this._patchBillingData(shippingData);
    }

    const isSalesLoggedIn = this.isSalesLoggedIn;

    const customer = isSalesLoggedIn ? false : this.session.user;

    const paymentPayload = !isSalesLoggedIn ?
      ((paymentData as ICardData).paypal ? { paymentType: 'paypal' } : paymentData) :
      (DataTransformerService.getPaymentPayload(paymentData as IPaymentData));

    const isPaypalPayment =  !isSalesLoggedIn ?
                        (paymentPayload as any).paymentType :
                        (paymentPayload as any).paymentMethod === PaymentMethod.Paypal;

    if ( isPaypalPayment ) {
      this.paymentFormComponent.showPaypalModal();
    }

    const paymentDetails = {
      customer: {
        name: shippingData.name,
        email: customer ?
          customer.email :
          this._customerInfo && this._customerInfo.email ?
            this._customerInfo.email :
            ''
      },
      address: {
        shipping: shippingData,
        billing: billingData
      },
      payment: paymentPayload,
      blindShipping: shippingData.blindShipping
    };

    let cartItemOrders = [];
    if (this.shoppingCart.cartItems.length) {
      this.shoppingCart.cartItems.forEach((cartItem) => {
        cartItemOrders = cartItemOrders.concat(cartItem.cartItemOrders);
      });
    }

    const payload = DataTransformerService.getOrderPayload(
      paymentDetails,
      cartItemOrders
    );

    // get campaign tracking cookie and set it on order payload
    // delete cookie after setting it
    payload.campaignTracking = this.cookies.get('campaignTracking');
    this.cookies.delete('campaignTracking');

    // get optimizely tracking cookie and set it on order payload
    // delete cookie after setting it
    payload.optimizelyTracking = this.cookies.get('optimizely_thestudio');
    this.cookies.delete('optimizely_thestudio');

    payload.orderTracking = this.cookies.get('orderTracking');
    this.cookies.delete('orderTracking');

    // Add sales rep id to payload if exists
    if (environment.enableLeadCapture && this._customerInfo && this._customerInfo.salesRepId) {
      payload.salesRepId = this._customerInfo.salesRepId;
    }

    // Add invoice id to payload if exists
    if (this.shoppingCart.invoiceId) {
      payload.invoiceId = this.shoppingCart.invoiceId;
    }

    // Add invoice id to payload if exists
    if (this.shoppingCart.resellerLicense) {
      payload.resellerLicense = this.shoppingCart.resellerLicense.url;
    }

    if (this._promoCode) {
      payload.voucher = this._promoCode;
    }

    const resp = !isSalesLoggedIn ?
      await this._invoiceApi.submitInvoice(payload) :
      this._confirmPayment ? await this._invoiceApi.newSalesInvoice(payload) : await this._invoiceApi.saveSalesInvoice(payload);

    this.cookies.delete('campaignTracking');

    // Tracking data
    this.tracking.sendAltoCloudData(['record', 'payment.type', (paymentData as ICardData).paypal ? 'paypal' : 'card']);

    this._reactOnOrder(resp, paymentPayload);
  }

  /**
   * Process response from server
   * @param data
   */
  private _reactOnOrder(data, paymentPayload) {
    const paymentType = paymentPayload.paymentType;

    if (data) {
      const totalOrders = data.totalOrders || 1;
      const orders = this.shoppingCart.cartItems
        .reduce((accumulator, nextItem) => [...accumulator, ...nextItem.cartItemOrders], []);

      const productsIds = orders.map((cartItemOrder: ICartItemOrder) => cartItemOrder.orderItem.product.id);

      if (data.success) {
        this._quoteService.deleteQuotes();

        if (data.transactionData) {
          this._sendTransactionData(data.transactionData);
        }

        this._amp.logEvent('Payment', {
          status: 'success',
          product_ids: JSON.stringify(productsIds),
        });

        // Tracking data
        if (this._promoCode) {
          this.tracking.sendAltoCloudData(['record', 'promo.code', 'used', {
            code: this._promoCode
          }]);
        }
        this.tracking.sendAltoCloudData(['record', 'payment.type', 'complete']);

        this.tracking.sendAltoCloudData([
          'record', 'order.placed', data.invoiceId, {
            id: data.invoiceId,
            products: orders.map((order: ICartItemOrder) => ({
              id: order.orderItem.product.id,
              name: order.orderItem.product.id,
              price: order.orderItem.price.finalTotal,
              category: order.orderItem.category.name,
              quantity: order.orderItem.price.quantity
            })),
          }
        ]);

        // Sales person processing
        if (this.isSalesLoggedIn) {
          if (data.invoiceId) {
            // Add invoiceId to shopping cart
            this._store.dispatch(new SetInvoiceId(data.invoiceId));
          }
          return this.router.navigate(['/shop/sales-success']);
        } else { // Customer processing
          this._store.dispatch(new SetInvoiceId(''));
          this.tracking.gtagAction('purchase', this.shoppingCart);

          if (data.token && data.customerData) {
            const customer = {
              ...data.customerData,
              role: UserRole.CUSTOMER
            } as IUser;

            this.session.setSession(data.token, customer, true);
          }

          this._subscriptions$.map(subscription => subscription.unsubscribe());
          this._store.dispatch(new ClearShoppingCart());
          this._store.dispatch(new SetTaxFee(undefined));
          this._store.dispatch(new SetResellerLicense(undefined));

          paymentType === 'paypal' ? this.showConfirmButton = false : this.showConfirmButton = true;

          if (paymentType === PaymentMethod.Paypal) {
            const invoiceId = data.invoiceId;
            const {items, totals} = data.details;

            this.paypalInvoiceCreated = true;

            const invoiceApi = this._invoiceApi;
            const ngThis = this;

            // remove "loading" data from paypal modal
            ngThis.paymentFormComponent.isLoading = false;

            paypal.Buttons({
              style: {
                layout: 'vertical',
                color:  'gold',
                shape:  'rect',
                label:  'paypal'
              },
              onInit: function(data, actions)  {
                ngThis.paymentFormComponent.isLoading = false;
              },
              createOrder: (data, actions) => {
                return actions.order.create({
                  'purchase_units': [{
                    'amount': {
                      'currency_code': totals.total.currency_code,
                      'value': totals.total.value,
                      'breakdown': {
                        'item_total': {
                          'currency_code': totals.total.currency_code,
                          'value': totals.total.value
                        }
                      }
                    },
                    'items': this.getPaypalItems(items)
                  }]
                }).then(function (transactionId) {
                  invoiceApi.savePaypalTransactionId({
                    paypal_widget: '1',
                    invoice: invoiceId,
                    resource_id: transactionId
                  }).then(function (resp) {
                    console.log('save transaction resp');
                  });

                  return transactionId;
                });
              },
              onApprove: async (data, actions) => {
                return actions.order.capture().then(async function (orderData) {
                  const transaction = orderData.purchase_units[0].payments.captures[0];
                  const markPaid = await ngThis._invoiceApi.submitPaypal({
                    paypal_widget: '1',
                    resource_id: data.orderID,
                    invoice: invoiceId
                  }).then(function (resp) {
                    return resp;
                  });
                  return ngThis.router.navigate([`/shop/success/${invoiceId}`], { queryParams: { to: totalOrders } });
                });
              },
              onCancel: (data) => {
                // Show a cancel page, or return to cart?
                console.log('canceled');
              },
              onError: err => {
                // Should we console.log something?
                console.log(err);
              }

            }).render(this.paymentFormComponent.paypalButton.nativeElement);
          } else {
            return data.redirectUrl ? (window.location.href = data.redirectUrl) : this.router.navigate([`/shop/success/${data.invoiceId}`], { queryParams: { to: totalOrders } });
          }

        }
      } else {
        if (data.invoiceId) {
          // Add invoiceId to shopping cart so on the next request it will update the same invoice
          this._store.dispatch(new SetInvoiceId(data.invoiceId));
        }

        this.tracking.sendAltoCloudData(['record', 'paymentStatusFailed', {
          reason: data.message
        }]);

        this._amp.logEvent('Payment', {
          status: 'failed',
          failed_reason: data.message,
          product_ids: JSON.stringify(productsIds),
        });

        this.cardError$.next({
          hasError: true,
          message: data.message
        });

        this.paymentFormValidation$.next({
          validate: false,
          isConfirmed: false
        });

      }

      this.processing = false;
    }
  }

  /**
   * Emit new errors
   * @param shippingError
   * @param billingError
   * @param cardError
   */
  private _handleErrors(shippingError, billingError, cardError) {
    if (shippingError) {
      return this.shippingError$.next({
        hasError: true,
        message: 'Invalid shipping address'
      });
    }

    if (billingError) {
      return this.billingError$.next({
        hasError: true,
        message: 'Invalid billing address'
      });
    }

    if (cardError) {
      return this.cardError$.next({
        hasError: true,
        message: 'Invalid payment data'
      });
    }
  }

  /**
   * Load data from draft for shipping and billing data
   */
  private _loadDataFromDraft() {
    if (!this.isSalesLoggedIn) {
      const paymentData = this.cookies.getJson('paymentData');
      if (paymentData) {
        this.shippingDraftData = paymentData['shippingData'];
        this.billingDraftData = paymentData['billingData'];
      }
    }
  }

  /**
   * Send transaction data after payment has been made
   * @param transactionData
   */
  private _sendTransactionData(transactionData: ITransactionData): void {
    // update data with constant values
    const data = Object.assign(transactionData, {
      transactionAffiliation: 'CREATE',
      transactionTax: 0.00
    });

    this.tracking.sendGoogleData(data);
  }

  private _getInvoiceSummaryData(): IPaymentSummary {
    return this._paymentSummaryService.formatShoppingCart(this.shoppingCart);
  }

  private getProductsInCart() {
    const arr = this.shoppingCart.cartItems.map(function(cartItem) {
      return {
        item_name: cartItem.cartItemOrders[0].orderItem.product.name,
        item_id: cartItem.cartItemOrders[0].uid,
        price: cartItem.cartItemOrders[0].orderItem.price.pricePerUnit,
        item_brand: 'The/Studio',
        item_category: cartItem.cartItemOrders[0].orderItem.category.name,
        item_variant: cartItem.cartItemOrders[0].orderItem.product.variationTypes[0].name,
        quantity: cartItem.cartItemOrders[0].orderItem.price.quantity,
        item_list_id: cartItem.cartItemOrders[0].orderItem.category.id,
        item_list_name: cartItem.cartItemOrders[0].orderItem.category.name,
      };
    });
    return arr;
  }

  private getPaypalItems(items: IPaypalInvoiceResponse) {
    const paypalItems = [];

    Object.entries(items).forEach(([key, item]) => {
      const {order_no, name, total} = item;

      paypalItems.push({
        'name': order_no,
        'description': name,
        'unit_amount': {
          'currency_code': total.currency_code,
          'value': total.value
        },
        'quantity': '1'
      });
    });

    return paypalItems;
  }
}
