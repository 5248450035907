<div class="product">
  <div *ngIf='!recommended'>
    <div class="discount" *ngIf="showDiscount && product?.productDiscount">
      SAVE {{ product?.productDiscount }}%
    </div>
  </div>
  <div *ngIf='recommended'>
    <div class="recommended">
      <img src='../../../../assets/img/like-white.svg' alt=''>
      RECOMMENDED
    </div>
  </div>

  <!-- <div *ngIf='a' ; else b>
    asd
  </div>
  <ng-template #b></ng-template> -->


  <div class="image-container" diyGallery [images]="product?.images">
    <img [src]="mainImage" />
    <div class="zoom-icon"></div>
  </div>
  <div class="description">
    <h1>{{ product?.name }}</h1>
    <p>
      {{ product?.description | slice:0:maxChars }}
      <span *ngIf="product?.description?.length > maxChars">...</span>
      <button class="learn-more-button" type="button" popover="{{ product?.description }}" placement="bottom"
        container="body" triggers="mouseenter:mouseleave">Learn
        More
      </button>
    </p>
    <button class="select-action" type="button" (click)="selectProduct()">{{ buttonName }}</button>
  </div>
</div>
