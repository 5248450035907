<!-- Header -->
<div class="header-content">
  <diy-header></diy-header>
  <diy-order-details></diy-order-details>
  <diy-promo-banner></diy-promo-banner>
  <!-- we have the ngIf as we are setting currentStep after resolving route -->
  <diy-order-step-tracking *ngIf="currentStep" [currentStep]="currentStep">
  </diy-order-step-tracking>
</div>
<diy-tat-announcement></diy-tat-announcement>
<!-- Page content -->
<div class="container">
  <div class="turnaround" #turnaroundPage>
    <div class="heading-primary pt-normal">
      <h1>Payment</h1>
      <p>Just a few more details, like who's paying and where your items are going</p>
    </div>
    <div class="production-time">
      <div class="heading-primary">
        <p class="production-time--title">Production Time</p>
        <p class="production-time--subtitle">
          <b>
            If you require a faster turnaround time than the options available below, please contact us via 
            <span class="spanAsLink" onclick="customPlugin.command('WebChat.open', customfields());">chat</span>, 
            <a class="anchorInText" href="mailto:sales@thestudio.com">email</a>
            or phone (877) 647-6447
          </b> <br/>
          Production time is based on the time required to manufacture and ship
          your products upon approval of digital mockup and physical sample. Your order arrival date
          will update on the right as you make your selections.
        </p>
      </div>

    <ng-container *ngIf="turnaround; else loading">

      <div class="production-time--delivery">
        <diy-delivery-option *ngFor="let deliveryOption of turnaround.availableOptions"
          (deliveryOptionChange)="updateDeliveryOption($event); _addToCart()" [deliveryOption]="deliveryOption"
          [isSelected]="turnaround.selectedOption?.name === deliveryOption.name" [skipSample]="turnaround.skipSample"
          [price]="orderItem.price">
        </diy-delivery-option>
      </div>

      <div class="turnaround__notification">
        <div *ngIf="currentSkipSampleStatus === skipSampleStatus.lessThanDefault; else sampling" class="checkbox-wrap">
          <input (change)="toggleSkipSample()" [checked]="!turnaround.skipSample" id="skipSample" type="checkbox" />
          <label for="skipSample">
            <span class="checkbox-icon">&nbsp;</span>
            <b>Email me a photographic sample before production</b>
            <p>
              Note that opting to receive a one-off photo sample increases your total
              production time while the sample is manufactured and we await your approval.
            </p>
          </label>
        </div>
        <ng-template #sampling>
          <div class="checkbox-wrap" [ngClass]="{'disabled': skipSampleDisabled}">
            <input [disabled]="skipSampleDisabled" (change)="toggleSkipSample()" [checked]="turnaround.skipSample"
              id="skipSample" type="checkbox" />
            <label for="skipSample">
              <span class="checkbox-icon">&nbsp;</span>
              <b>Skip the sample-making step before production</b>
              <p>
                Skipping this step will decrease your production time.
                However, there may differences between your shipped product and your
                approved design that we will be unable to address as a result.
              </p>
            </label>
          </div>

          <!-- <ul>
             <li>As in all orders, we will still send you a digital mockup for approval.</li>
            <li>
              We will manufacture your order upon approval of digital mockup; you
              <strong>cannot request changes</strong> after mockup approval.
            </li>

             <li *ngIf="skipSampleDisabled">
              <strong>
                This option is only available for order less than ${{ skipSampleMandatory }}.
                <!-- Please have our Creative Specialist evaluate your
                design by calling 877-647-6447 if your order value is higher. -->
               <!-- </strong>
            </li>
          </ul>  -->
        </ng-template>
      </div>

<!--      <hr>-->

<!--      <button class="button button&#45;&#45;green mr-medium" [ngClass]="{'disabled': nextDisabled()}" (click)="goToCart()">-->
<!--        <span *ngIf="!checkItemInCart(); else updateOrder">Add To Cart</span>-->
<!--      </button>-->

<!--      <button *ngIf="!cartItems.length" class="button button&#45;&#45;green" [ngClass]="{'disabled': nextDisabled()}"-->
<!--        (click)="goToCheckout()">-->
<!--        Checkout-->
<!--      </button>-->

    </ng-container>

    <ng-template #loading>
      <diy-spinner></diy-spinner>
    </ng-template>
  </div>

  <diy-notes></diy-notes>

<!--  <diy-order-summary [currentStep]="currentStep"></diy-order-summary>-->

</div>

<ng-template #updateOrder>
  <span>Update Order</span>
</ng-template>


<diy-payment-component></diy-payment-component>

  <div class="add--to--cart">
    <button class="button button--green" [ngClass]="{'disabled': nextDisabled()}" (click)="goToCart()">
      <span *ngIf="!checkItemInCart(); else updateOrder">Add To Cart</span>
    </button>
  </div>
