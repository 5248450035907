import { CategoriesActionsUnion, CategoriesActions } from './categories.actions';

import { ICategory } from '../../types/category/ICategory';

const initialState: ICategory[] = [];

export function reducer(state: ICategory[] = initialState, action: CategoriesActionsUnion): ICategory[] {
  switch (action.type) {

    case CategoriesActions.UpdateCategories: {
      return action.payload;
    }

    default: {
      return state;
    }

  }
}
