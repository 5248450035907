import { Component, Input } from '@angular/core';

@Component({
  selector: 'diy-simple-sidebar',
  templateUrl: './simple-sidebar.component.html',
  styleUrls: ['./simple-sidebar.component.scss']
})
export class SimpleSidebarComponent {

  @Input() title: string;

  constructor() { }

}
