import { Component, OnInit, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';

import { ProductOrderSteps } from '../../types/step/IStep';

// Services
import { AmplitudeService } from '../../core/services/amplitude';
import { TrackingService } from '../../core/services/tracking';

// Types
import { IStep } from '../../types/step/IStep';
import { SessionService } from '../../utility/session.service';

/**
 * Generic component for step tracking rendering
 * @author Liviu Dima
 */
@Component({
  selector: 'diy-step-tracking',
  templateUrl: './step-tracking.component.html',
  styleUrls: ['./step-tracking.component.scss']
})
export class StepTrackingComponent implements OnInit {

  @Input() activeStep: IStep;
  @Input() steps: IStep[];

  minimizeSteps = false;

  constructor(
    private _router: Router,
    private _amp: AmplitudeService,
    private _tracking: TrackingService,
    private _sessionService: SessionService,
  ) { }

  ngOnInit() {
    this.minimizeSteps = this._shouldMinimize();
  }

  isPartialStep(step: IStep): boolean {
    if (this.activeStep.number === step.number) {
      return this.activeStep.logStep === ProductOrderSteps.quote || this.activeStep.logStep === ProductOrderSteps.draft;
    }
    return false;
  }

  async navigateToStep(step: IStep) {
    if (step.number >= this.activeStep.number) {
      return;
    }
    this._amp.logEvent('Change Step', {
      from_page: this.activeStep.name,
      from_step: this.activeStep.number,
      to_page: step.name,
      to_step: step.number,
    });

    const currentTime = Math.floor((new Date()).getTime() / 1000);
    if (this._sessionService.backTrigger && currentTime - Number(this._sessionService.backTrigger) > 180) {
      this._sessionService.turnedBack = undefined;
    }

    if ((!this._sessionService.turnedBack || this._sessionService.turnedBack !== 'true')) {
      this._sessionService.turnedBack = 'true';
      this._sessionService.backTrigger = String(Math.floor((new Date()).getTime() / 1000));
      this._tracking.sendAltoCloudData(['record', 'navigationBack']);
    }

    this._router.navigateByUrl(step.url, { queryParamsHandling: 'merge' });

  }

  @HostListener('window:scroll', ['$event']) onScrollEvent($event) {
    this.minimizeSteps = this._shouldMinimize();
  }

  private _shouldMinimize(): boolean {
    const verticalOffset = window.pageYOffset
      || document.documentElement.scrollTop
      || document.body.scrollTop || 0;

    return (verticalOffset > 100);
  }

}
