<div class="delivery-option" [ngClass]="{'active': isSelected }">

  <div (click)="chooseDeliveryOption()" class="delivery-option__data">

    <div class="delivery-option__wrapper">
      <div class="delivery-option__information">
        <p class="delivery-option__title">{{deliveryOption.name}} Production</p>
        <div class="delivery-option__image"><img width="100%" src="{{deliveryOption.iconPath}}" /></div>
<!--        <p class="delivery-option__description">{{deliveryOption.description}}</p>-->
        <p class="delivery-option__tat-label">Arrival date</p>
        <p class="delivery-option__time">{{estimateInterval()}}</p>
        <p class="delivery-option__cost">Cost: {{formatDeliveryCost()}}</p>
      </div>
    </div>

    <div class="select-wrap">
      <input [checked]="isSelected" id="delivery_option_{{deliveryOption.name}}" name="deliveryOption" type="radio" />
      <label (click)="$event.preventDefault()" for="delivery_option_{{deliveryOption.name}}"
        class="button button--green">Select</label>
    </div>
  </div>

<!--  <div class="delivery-option__guarantee">-->
<!--    <form class="form-inline">-->
<!--      <div class="delivery-option__guarantee-text">-->

<!--        <div class="checkbox-wrap" [ngClass]="{'not-available': isUnavailableGuarantee}">-->
<!--          <input type="checkbox" (change)="guaranteedCheckBoxToggle()" [checked]="deliveryOption.isGuaranteed"-->
<!--            [disabled]="isUnavailableGuarantee" id="{{deliveryOption.name}}_gdate" />-->

<!--          <label for="{{deliveryOption.name}}_gdate">-->
<!--            <span class="checkbox-icon"></span> Want your order to arrive by a certain date?-->
<!--          </label>-->
<!--        </div>-->
<!--        <p>-->
<!--          <b *ngIf="isUnavailableGuarantee">Guaranteed Delivery is only available for order > $300</b>-->
<!--        </p>-->

<!--        <p>Pick a date and a dedicated concierge will ensure your order arrives by this date</p>-->
<!--      </div>-->

<!--      <div class="form-group datepicker-group">-->
<!--        <div class="input-group"-->
<!--          [ngClass]="{'has-danger': deliveryOption.isGuaranteed && !deliveryOption.guaranteedDate}">-->
<!--          <input placeholder="mm/dd/yyyy" class="form-control" #dp="bsDatepicker" bsDatepicker-->
<!--            (bsValueChange)="guaranteedDateChange($event)" value="{{getValue()}}" [minDate]="getMinDate()"-->
<!--            [maxDate]="getMaxDate()" [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY', showWeekNumbers:false }"-->
<!--            [disabled]="isUnavailableGuarantee" />-->


<!--          <div class="input-group-append">-->
<!--            <button class="btn btn-outline-secondary" (click)="dp.toggle()" [disabled]="isUnavailableGuarantee"-->
<!--              type="button">-->
<!--              <img src="assets/img/calendar-icon.svg" style="width: 1.2rem; height: 1rem; cursor: pointer;" />-->
<!--            </button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </form>-->
<!--  </div>-->
</div>
