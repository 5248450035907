import { Injectable } from '@angular/core';
import { Utils } from '../services/utils';

import { IProduct } from '../../types/product/IProduct';
import { IVariationType } from '../../types/product/variations/IVariationType';
import { IVariation } from '../../types/product/variations/IVariation';
import { IArtwork } from '../../types/artwork/IArtwork';
import { IDeliveryOption } from '../../types/turnaround/IDeliveryOption';
import { ISearchResult } from '../../types/search/ISearchResult';
import { DateUtils } from '../../utility/DateUtils';
import {
  IOrderPayload,
  IOrderArtwork,
  IOrderTurnaround,
  ISerializedOrder,
  IOrderPackaging,
  IOrderArtworkFile
} from '../../types/api/IOrderPayload';
import { ITurnaround } from '../../types/turnaround/ITurnaround';
import { IVariationsData } from '../../types/api/IVariationsData';
import { ICartItemOrder } from '../../types/shopping-cart/ICartItem';
import { ISetupFee } from '../../types/product/ISetupFee';
import { IPaymentData } from '../../types/payment/IFormState';
import { PaymentMethod } from '../../types/payment/PaymentMethod';
import { IBundleItem } from '../../types/bundle/IBundle';
import { ISelectedVariation } from '../../types/app-state/ISelectedVariation';
import { ICustomSize } from '../../types/product/variations/ICustomSize';

@Injectable()
export class DataTransformerService {

  public static createBundleItems(bundleItems: any[]): IBundleItem[] {

    if (bundleItems.length <= 0) {
      return [];
    }

    const newItems = bundleItems.map(bundleItem => {
      return {
        id: bundleItem.bundleItem,
        bundleId: bundleItem.bundleId,
        bundleName: bundleItem.bundleName,
        customerBundleItem: bundleItem.customerBundleItem,
        productId: Number(bundleItem.productId),
        productName: bundleItem.productName,
        quantity: Number(bundleItem.remQuantity),
        variations: bundleItem.variations ? bundleItem.variations.map(el => Number(el)) : []
      } as IBundleItem;
    });

    return newItems;
  }

  public static createDeliveryOptions(estimates): IDeliveryOption[] {
    const deliveryOptions = [
      {
        name: 'standard',
        description: 'Standard Turnaround with Standard Shipping',
        guaranteedDate: undefined,
        isGuaranteed: false,
        iconPath: '/assets/img/delivery-standard.svg',
        datesInterval: {
          normalStart: new Date(estimates.normal.standard.start).getTime(),
          normalEnd: new Date(estimates.normal.standard.end).getTime(),
          ssStart: new Date(estimates.skipSample.standard.start).getTime(),
          ssEnd: new Date(estimates.skipSample.standard.end).getTime(),
        }
      } as IDeliveryOption,
      {
        name: 'urgent',
        description: 'Hand-On, Rush Production with Upgraded Urgent Shipping',
        guaranteedDate: undefined,
        isGuaranteed: false,
        iconPath: '/assets/img/delivery-urgent.svg',
        datesInterval: {
          normalStart: new Date(estimates.normal.urgent.start).getTime(),
          normalEnd: new Date(estimates.normal.urgent.end).getTime(),
          ssStart: new Date(estimates.skipSample.urgent.start).getTime(),
          ssEnd: new Date(estimates.skipSample.urgent.end).getTime(),
        }
      } as IDeliveryOption,
    ];

    if (estimates.hasRushOption) {
      deliveryOptions.splice(1, 0, {
        name: 'rush',
        description: 'Rush Production with Upgraded Overnight Shipping',
        guaranteedDate: undefined,
        isGuaranteed: false,
        iconPath: '/assets/img/delivery-rush.svg',
        datesInterval: {
          normalStart: new Date(estimates.normal.rush.start).getTime(),
          normalEnd: new Date(estimates.normal.rush.end).getTime(),
          ssStart: new Date(estimates.skipSample.rush.start).getTime(),
          ssEnd: new Date(estimates.skipSample.rush.end).getTime(),
        }
      } as IDeliveryOption);
    }

    return deliveryOptions;
  }


  public static createProduct(data): IProduct {
    const x = {
      id: data.id,
      name: data.name,
      description: data.description,
      productDiscount: Number(data.productDiscount),
      popular: data.popular,
      images: data.images,
      packageId: data.packageId,
      variationTypes: DataTransformerService.createVariationTypes(data.variation_types),
      specialOptions: DataTransformerService.createVariationTypes(data.options),
      pricingTable: {
        rows: data.pricingTable,
        columnCount: Object.keys(data.pricingTable[0]).length - 1
      },
    };

    return x;
  }

  public static createVariationTypes(data): IVariationType[] {

    const variationsData: IVariationsData[] = Object.values(data);

    const variationTypes = variationsData.map(variation => {
      return {
        id: String(variation.id),
        name: variation.name,
        info: variation.info,
        required: Boolean(variation.required),
        multiple: Boolean(variation.multiple),
        category: variation.typeCamp,
        dbname: variation.dbname,
        image: variation.image,
        variations: DataTransformerService.createVariations(variation.variations, variation.typeCamp),
        typeSize: variation.typeSize,
        dimensions: variation.dimensions,
        toggled: variation.toggled
      };
    });

    const qtyPos = variationTypes.findIndex((el) => el.dbname === 'Quantity');
    variationTypes.push(variationTypes.splice(qtyPos, 1)[0]);

    return variationTypes;
  }

  public static createVariations(data, type: string = 'vts'): IVariation[] {

    const variations = data.map(variation => {

      return {
        id: Number(variation.key),
        name: variation.name,
        info: variation.info,
        value: variation.value,
        valueType: variation.valueType,
        moq: variation.moq,
        isDefault: Boolean(variation.isDefault),
        showInDIY: Boolean(variation.showInDIY),
        images: variation.images,
        incompatibilities: variation.incompatibility,
        popular: Boolean(variation.popular),
        promoted: Boolean(variation.promoted),
        setupFee: (Array.isArray(variation.setupFee)) ? variation.setupFee.map(element => element as ISetupFee) : [],
      };
    });

    // todo move dis maybe
    if (type === 'specialOptionCategory') {
      // also sort special options only
      variations.sort((x, y) => {
        return (x.promoted === y.promoted) ? 0 : x.promoted ? -1 : 1;
      });
    }

    return variations;
  }

  public static createSearchResults(data): ISearchResult[] {

    const results = [];

    for (const keyProp in data) {
      if (data.hasOwnProperty(keyProp)) {
        const searchResult: ISearchResult = {
          id: data[keyProp].id,
          name: data[keyProp].name,
          type: data[keyProp].type,
          other: data[keyProp].other,
          categoryId: (data[keyProp].other.hasOwnProperty('category_id')) ? data[keyProp].other.category_id : '',
          weight: 0
        };
        results.push(searchResult);
      }
    }

    return results;
  }

  public static getOrderPayload(formData, cartItemsOrder: ICartItemOrder[]): IOrderPayload {
    const customer = formData.customer;
    customer.sms = formData.sms;

    const address = formData.address;
    address.shipping.blind = formData.blindShipping;
    address.billing.blind = formData.blindShipping;

    const orders = [];
    cartItemsOrder.forEach(orderItem => {
      orders.push(DataTransformerService._serialiseCoi(orderItem));

      // add the packaging as an separate order
      if (orderItem.packagingOrder && orderItem.packagingOrder.packagingSelections.length) {
        orders.push(DataTransformerService._formatPackaging(orderItem));
      }
    });

    return {
      orders: orders,
      customer: customer,
      address: address,
      payment: formData.payment
    };
  }

  public static getPaymentPayload(paymentData: IPaymentData) {
    const partialPayment = paymentData.partialPayment === 'Yes';

    let initialPayment;
    let payload = {};

    switch (paymentData.paymentMethod) {

      case PaymentMethod.Card:
        payload = paymentData.cardData;
        break;

      case PaymentMethod.Check:
      case PaymentMethod.NET30:
      case PaymentMethod.NET60:
        payload = {
          accepted_order: paymentData.checkData.initialInvoice,
          artwork: paymentData.checkData.mockup,
          factory_sample: paymentData.checkData.sample,
          shipping: paymentData.checkData.shipping,
          shipped: paymentData.checkData.finalInvoice,
          paymentAfterProductionReason: paymentData.checkData.paymentAfterProductionReason
        };
        if (paymentData.checkData.afterShipFollowup) {
          payload['afterShipFollowup'] = paymentData.checkData.afterShipFollowup;
        }
        break;
    }

    if (partialPayment) {
      initialPayment = {
        percent: Number(paymentData.prePaymentPercentage),
        remainingBalanceDue: paymentData.remainingBalanceDue
      };
    }

    return {
      paymentType: paymentData.paymentMethod,
      partialPayment: partialPayment,
      initialPayment: initialPayment,
      payload: payload
    };
  }

  private static _serialiseCoi(cartItemOrder: ICartItemOrder): ISerializedOrder {
    const artwork = (
      cartItemOrder.orderItem.artwork &&
      cartItemOrder.orderItem.artwork.files && cartItemOrder.orderItem.artwork.files.length
    ) ? DataTransformerService._formatArtwork(cartItemOrder.orderItem.artwork) : false;

    const serializedOrder = {
      quoteId: cartItemOrder.orderItem.quoteId,
      price: cartItemOrder.orderItem.price,
      product: cartItemOrder.orderItem.product.id,
      artwork: artwork,
      variations: DataTransformerService._serializeVariations(cartItemOrder.orderItem.variationTypes),
      options: DataTransformerService._serializeVariations(cartItemOrder.orderItem.specialOptions),
      turnaround: DataTransformerService._formatTurnaround(cartItemOrder.orderItem.turnaround),
      uid: cartItemOrder.uid,
      combinedWith: cartItemOrder.combinedWith,
      reorderId: cartItemOrder.orderItem.reorderId,
      firstInquiry: cartItemOrder.orderItem.firstInquiry ? cartItemOrder.orderItem.firstInquiry : '',
      bundleItem: cartItemOrder.orderItem.bundleItem ? cartItemOrder.orderItem.bundleItem.id : 0,
      copyQuoteArtwork: cartItemOrder.orderItem.copyQuoteArtwork || false,
    };

    const updatedOrder =
      DataTransformerService._addCustomSizeToOrder(serializedOrder, cartItemOrder.orderItem.variationTypes);

    return updatedOrder;
  }

  private static _serializeVariations(selectedVariations: ISelectedVariation[]) {
    return selectedVariations
      .filter(selectedVariation => !!selectedVariation.variation.id)
      .map(selectedVariation => selectedVariation.variation.id);
  }

  /**
   * @param {IArtwork} artwork
   * @returns {any}
   */
  private static _formatArtwork(artwork: IArtwork): IOrderArtwork {
    const files: IOrderArtworkFile[] = artwork.files.map(file => {
      return {
        name: file.name,
        url: file.url
      };
    });

    return {
      files,
      description: artwork.description
    };
  }

  private static _formatTurnaround(turnaround: ITurnaround): IOrderTurnaround {
    return {
      guaranteedDate: turnaround.selectedOption.guaranteedDate ?
        DateUtils.guaranteedDateFormat(new Date(turnaround.selectedOption.guaranteedDate)) :
        '',
      type: turnaround.selectedOption.name,
      skipSample: turnaround.skipSample,
      timezoneOffset: (new Date).getTimezoneOffset(),
    };
  }

  private static _formatPackaging(cartItemOrder: ICartItemOrder): IOrderPackaging {
    const packagingArtwork = (
      cartItemOrder.packagingOrder.artwork &&
      cartItemOrder.packagingOrder.artwork.files &&
      cartItemOrder.packagingOrder.artwork.files.length
    ) ? DataTransformerService._formatArtwork(cartItemOrder.packagingOrder.artwork) : false;

    const serializedPackagingOrder = {
      price: cartItemOrder.packagingOrder.price,
      product: cartItemOrder.packagingOrder.product.id,
      artwork: packagingArtwork,
      variations: DataTransformerService._serializeVariations(cartItemOrder.packagingOrder.packagingSelections),
      options: [],
      packagingFor: cartItemOrder.uid,
      uid: Utils.generateUid(),
      combinedWith: cartItemOrder.combinedWith,
      quoteId: cartItemOrder.orderItem.quoteId,
      turnaround: DataTransformerService._formatTurnaround(cartItemOrder.orderItem.turnaround),
    };

    const updatedOrder =
      DataTransformerService._addCustomSizeToOrder(serializedPackagingOrder, cartItemOrder.packagingOrder.packagingSelections);

    return updatedOrder;
  }

  private static _addCustomSizeToOrder(
    order: any,
    variations: ISelectedVariation[]
  ) {
    let customData = {};
    const customSize = variations.find(selectedVariation => {
      const variation = selectedVariation.variation as ICustomSize;

      return !!(variation.customType && variation.width && variation.height);
    });
    if (customSize) {
      customData = {
        c_width: (customSize.variation as ICustomSize).width,
        c_height: (customSize.variation as ICustomSize).height,
      };
    }

    return { ...order, ...customData };
  }
}
