import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpParams } from '@angular/common/http';

import { environment } from '../../../environments/environment';

@Injectable()
export class FileApi {

  constructor(
    private _http: HttpClient
  ) { }

  upload(file: File) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    const req = new HttpRequest(
      'POST',
      environment.uploadUrl,
      formData,
      { reportProgress: true }
    );

    return this._http.request(req);
  }

  async delete(fileName: string): Promise<void> {
    const params = new HttpParams()
      .set('image', fileName)
      .set('action', 'delete');

    try {
      await this._http.post(environment.uploadUrl, params).toPromise();
    } catch (err) {
      console.error(err);
    }
  }
}
