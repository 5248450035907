import { Component, OnDestroy, OnInit, HostListener } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { environment } from '../../../../../environments/environment';

import { OrderMessengerService } from '../../../../core/services/order-messenger';
import { AmplitudeService } from '../../../../core/services/amplitude';
import { TrackingService } from '../../../../core/services/tracking';
import { NavigationService } from '../../../../core/services/navigation.service';

import { ClearBundleData } from '../../../../state-manager/bundle/bundle.actions';

import { IBundle } from '../../../../types/bundle/IBundle';
import { IAppState } from '../../../../types/app-state/IAppState';

@Component({
  templateUrl: './bundle-success.component.html',
  styleUrls: [
    '../success.component.scss',
    './bundle-success.component.scss'
  ]
})
export class BundleSuccessComponent implements OnInit, OnDestroy {

  bundle: IBundle;
  dashboardUrl = 'http://customer.thestudio.com/';

  private _subscriptions$: Subscription[] = [];

  constructor(
    private _oms: OrderMessengerService,
    private _amp: AmplitudeService,
    private _store: Store<IAppState>,
    private _tracking: TrackingService,
    private _navService: NavigationService,
  ) {
    this._oms.updateStep(0);

    const subscription$ = this._store.pipe(select('bundleData'))
      .subscribe((data: IBundle) => {
        if (data) {
          this.bundle = data;

          this._sendTrackingData(data.price, data.invoiceId);
          this._amp.logEvent('Navigation', {
            page: 'payment',
            navigationStep: 8,
            bundle_id: data.id
          });
        }
      });

    this._subscriptions$.push(subscription$);
  }


  async ngOnInit() {
    this._tracking.sendAltoCloudData(['pageview', {
      location: location.pathname,
      title: 'Bundle Success'
    }]);

    this.dashboardUrl = environment.production ? 'http://customer.thestudio.com/' : 'http://customer.devback.thestudio.com/';
  }

  ngOnDestroy() {
    this._subscriptions$.map(subscription => subscription.unsubscribe());
    this._store.dispatch(new ClearBundleData());
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    event.preventDefault();
    this._navService.clickedBack = true;
  }

  private _sendTrackingData(total: number, invoiceId: string) {
    this._tracking.gtagReportConversion({
      'send_to': 'AW-1069576120/4It-CPaPrYkBELjfgf4D',
      'value': total,
      'currency': 'USD',
      'transaction_id': invoiceId
    });
    this._tracking.sendFacebookData('track', 'Purchase', {
      value: total,
      currency: 'USD',
    });

    this._tracking.sendBingData('sale', { event_category: 'cart', event_label: 'order', 'event_value': String(total) });
  }

}
