import { Component, Input } from '@angular/core';
import { ISelectedVariation } from '../../../../types/app-state/ISelectedVariation';
import { IPrice } from '../../../../types/product/IPrice';
import { IBundleItem } from '../../../../types/bundle/IBundle';

@Component({
  selector: 'diy-variation-types-summary',
  templateUrl: './variation-types-summary.component.html',
  styleUrls: ['./variation-types-summary.component.scss']
})
export class VariationTypesSummaryComponent {

  @Input() selectedVariations: ISelectedVariation[];
  @Input() price: IPrice;

  @Input() bundleItem: IBundleItem;

  constructor() { }

  showCustomiseTotal(): boolean {
    if (!this.price) {
      return false;
    }

    if (this.bundleItem) {
      return false;
    }

    if (this.price.pricePerUnit <= 0) {
      return false;
    }

    return true;
  }
}
